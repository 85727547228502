import type { DataAttribute, DataObject } from "~/model";
// eventBus.js
import mitt from "mitt";

interface EventBusData {
  "workspace:dataObjectsUpdated": DataObject[];
  "workspace:dataAttributeUpdated": { dataObject: DataObject; dataAttribute: DataAttribute };
  "workspace:dataObjectUpdated": DataObject;
  "app:open-organization-popup": void;
  [key: string]: any;
}

const emitter = mitt<EventBusData>();
export default emitter;
