<script setup lang="ts">
import type { PropType } from "vue";
import type { Option } from "~/model";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    required: false,
  },
});

const emit = defineEmits(["update:modelValue"]);
const { project } = storeToRefs(appStore.projectStore);

const {
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div>
    <KodexaDropDown
      v-model="localValue"
      :name="`${item.name}-${uuidv4()}`" :label="item.label || item.name"
      :items="project.taskStatuses"
      text-field="label"
      value-field="id"
    />
  </div>
</template>
