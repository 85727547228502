<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useMagicKeys } from "@vueuse/core";

import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useListProjects } from "~/api/projects/projects";
import router from "~/router/router";
import { createGridHelper } from "~/store/useGridHelper";

const open = ref(false);

const gridHelper = createGridHelper("projects-list-command-palette", {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProjects(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

function openModal() {
  open.value = true;
}

const keys = useMagicKeys();
const CmdK = keys["Meta+K"];

watch(CmdK, (v) => {
  if (v) {
    openModal();
  }
});

const activeOption = ref(null);

function onSelect(project) {
  open.value = false;
  router.push({ path: `/a/o/${project.organization.id}/p/${project.id}/home` });
}

function handleEnter() {
  if (activeOption.value) {
    onSelect(activeOption.value);
  }
}
</script>

<template>
  <TransitionRoot :show="open" as="template" appear @after-leave="computedQuery = ''">
    <Dialog as="div" class="relative z-[99]" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white dark:bg-gray-800 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox>
              <div class="relative">
                <MaterialDesignIcon
                  name="search"
                  class="pointer-events-none absolute top-2 left-4 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..." @change="computedQuery = $event.target.value" @keydown.enter.prevent="handleEnter"
                />
              </div>

              <ComboboxOptions
                v-if="data?.content" static
                class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
              >
                <li>
                  <ul class="-mx-4 mt-2 text-sm text-gray-700">
                    <ComboboxOption
                      v-for="project in data?.content" :key="project.id" v-slot="{ active }"
                      :value="project" as="template" @mouseenter="activeOption = project"
                      @focus="activeOption = project"
                    >
                      <li
                        class="flex cursor-default select-none items-center px-4 py-2"
                        :class="[active && 'bg-indigo-600 text-white']"
                      >
                        <MaterialDesignIcon
                          name="folder"
                          class="h-6 w-6 flex-none" :class="[active ? 'text-white' : 'text-gray-400']"
                          aria-hidden="true"
                        />
                        <span class="ml-3 flex-auto truncate">{{ project.name }}</span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div
                v-if="computedQuery !== '' && data && data.content?.length === 0"
                class="py-14 px-6 text-center text-sm sm:px-14"
              >
                <MaterialDesignIcon
                  name="alertBox"
                  class="mx-auto h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <p class="mt-4 font-semibold text-gray-900">
                  No results found
                </p>
                <p class="mt-2 text-gray-500">
                  We couldn’t find anything with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>

</style>
