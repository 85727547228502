<script lang="ts" setup>
import type { PropType } from "vue";
import type { Task, User } from "~/model";
import { removeAssignee, updateAssignee } from "~/api/tasks/tasks";
import KodexaTaskAssigneeDropdown from "~/components/tasks/kodexa-task-assignee-dropdown.vue";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
  saveValue: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["updated"]);

const selectedAssignee = ref<User | undefined>(props.task?.assignee);
const editing = ref(false);

watch(() => props.task?.assignee, (newValue) => {
  selectedAssignee.value = newValue;
}, { immediate: true });

async function saveChanges() {
  await updateHandler(updateAssignee(props.task.id as string, selectedAssignee.value?.user as User), "Assignee updated");
}

async function updated(member) {
  selectedAssignee.value = member;
  if (props.saveValue) {
    await saveChanges();
  }
  editing.value = false;
  emit("updated", selectedAssignee.value);
}

async function clear() {
  selectedAssignee.value = undefined;
  editing.value = false;
  if (props.saveValue) {
    await updateHandler(removeAssignee(props.task.id as string), "Assignee cleared");
  }
  emit("updated", undefined);
}
</script>

<template>
  <div v-if="editing">
    <KodexaTaskAssigneeDropdown :key="task.id" v-model="selectedAssignee" :task="task" :project="task.project" @clear="clear" @updated="updated" />
  </div>
  <div v-else-if="task.assignee">
    <KodexaUserAvatar id="currentTaskAssignee" :user="selectedAssignee" :show-name="true" style="height: 87px; display: flex; align-items: center;" @click="editing = true" />
  </div>
  <div v-else id="editTaskAssignee" style="height: 87px; display: flex; align-items: center;" @click="editing = true">
    <span>-</span>
  </div>
</template>

<style scoped>

</style>
