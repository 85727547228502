<script lang="ts" setup>
import type { PropType } from "vue";
import type { DataFlowNode, Taxonomy } from "~/model";
import type { Tab } from "~/utils/tab";
import { storeToRefs } from "pinia";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "step-deleted"]);

const { taxonomies } = storeToRefs(appStore.projectStore);

const taxonomy = computed(() => {
  return taxonomies.value.find((taxonomy: Taxonomy) => props.node && taxonomy.ref === props.node.id?.split("//")[1]);
});

const currentNavigation = ref({ ref: "general", name: "General" });

const tabs = [
  { ref: "general", name: "General" },
] as Tab[];

watch(
  () => taxonomy.value,
  (newValue) => {
    appStore.projectStore.addToTaxonomiesToUpdate(newValue);
  },
  { deep: true },
);
</script>

<template>
  <div>
    <div v-if="taxonomy" class="flex h-full flex-col bg-white dark:bg-gray-800">
      <div>
        <KodexaTabStrip :selected="currentNavigation" :items="tabs" :small="true" @selected="currentNavigation = $event" />
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextInput
              v-model="taxonomy.name"
              name="name"
              type="text"
              autocomplete="taxonomy-name"
              label="Name"
            />
          </div>
          <div class="mt-4 mb-4 text-gray-600">
            <KodexaClipboardable :content="taxonomy.ref" :show-content="false" message="Copy Data Definition Reference" />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextArea
              v-model="taxonomy.description" name="taxonomy-description" label="Description"
              :rows="9"
            />
          </div>
          <KodexaResourceDelete :resource="taxonomy" label="Data Definition" @deleted="emit('step-deleted')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
