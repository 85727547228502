import debounce from "lodash/debounce";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { log } from "~/utils/logger";

export interface KeyboardShortcut {
  key: string;
  altKey: any;
  description: string;
  callback: () => void;
}

export const useKeyboard = defineStore("keyboard", () => {
  const shortcuts = ref<Map<string, KeyboardShortcut>>(new Map());
  const _popupOpen = ref(false);
  const availableShortcuts = computed(() => {
    const values = Array.from(shortcuts.value.values());
    return values.filter((shortcut, index) =>
      values.findIndex(s => s.key === shortcut.key) === index,
    );
  });

  const popupOpen = computed({
    get: () => _popupOpen.value,
    set: (value) => {
      _popupOpen.value = value;
      log.info(`Popup ${value ? "opened" : "closed"}`);
    },
  });

  function addShortcut(shortcut: KeyboardShortcut) {
    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    const modifiedShortcut = {
      ...shortcut,
      key: isMac ? shortcut.key?.replace(/alt/i, "meta").replace(/control/i, "meta") : shortcut.key,
    };

    // check we don't already have this shortcut
    if (shortcuts.value.has(modifiedShortcut.key)) {
      return;
    }

    log.info(`Adding shortcut ${shortcut.key}`);

    shortcuts.value.set(modifiedShortcut.key, modifiedShortcut);
    if (modifiedShortcut.altKey) {
      if (Array.isArray(modifiedShortcut.altKey)) {
        modifiedShortcut.altKey.forEach((k) => {
          shortcuts.value.set(k, modifiedShortcut);
        });
      } else {
        shortcuts.value.set(modifiedShortcut.altKey, modifiedShortcut);
      }
    }
  }

  function removeShortcut(shortcut: KeyboardShortcut) {
    log.info(`Removing shortcut ${shortcut.key}`);
    shortcuts.value.delete(shortcut.key);
    if (shortcut.altKey) {
      shortcuts.value.delete(shortcut.altKey);
    }
  }

  const debouncedToggle = debounce(() => {
    _popupOpen.value = !_popupOpen.value;
    log.info(`Popup ${_popupOpen.value ? "opened" : "closed"}`);
  }, 300, { leading: true, trailing: false });

  function togglePopup() {
    debouncedToggle();
  }

  function openPopup() {
    _popupOpen.value = true;
    log.info("Popup opened");
  }

  function closePopup() {
    _popupOpen.value = false;
    log.info("Popup closed");
  }

  return {
    shortcuts,
    addShortcut,
    removeShortcut,
    availableShortcuts,
    popupOpen,
    openPopup,
    closePopup,
    togglePopup,
  };
});
