<script setup lang="ts">
import type { PropType } from "vue";
import type { Card, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

const exceptions = computed(() => {
  if (props.parentDataObject) {
    return props.parentDataObject.dataExceptions?.filter((exception) => {
      return exception.message === props.card.properties.exceptionMessage;
    });
  } else {
    return [];
  }
});
</script>

<template>
  <div>
    <div class="bg-white dark:bg-gray-800 shadow sm:rounded-lg ml-2">
      <div class="px-4 py-5 sm:p-6">
        <div v-for="exception in exceptions" :key="exception.id">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            {{ exception.message }}
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <KodexaMarkdown :content="exception.exceptionDetails" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
