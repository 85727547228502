<script setup lang="ts">
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import type { Ref } from "vue";
import type { DataForm } from "~/model";
import { storeToRefs } from "pinia";
import { parse, stringify } from "yaml";

import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});
const emit = defineEmits(["formSaved", "dataExceptionsChanged"]);

const viewId = props.params.api.id.replace("view-", "") as string;

const useDataFormViewer = createDataFormViewerStore(viewId as string);
const {
  dataForm,
  dataObjects: _dataObjects,
  dataExceptions,
  overrideExceptionMapper: _overrideExceptionMapper,
} = storeToRefs(useDataFormViewer);

watch(dataExceptions, () => {
  const allExceptions = dataExceptions.value.dataAttributeExceptions.concat(dataExceptions.value.dataObjectExceptions);
  emit("dataExceptionsChanged", allExceptions);
});

function buildDataFormViewer() {
  return useDataFormViewer.buildDataFormViewer();
}

const showSource: Ref<boolean> = ref(false);
const latestDataFormYaml = ref("");

watch(showSource, (newValue) => {
  if (newValue) {
    latestDataFormYaml.value = stringify(dataForm.value, { indent: 2 });
  } else {
    const dataFormYaml = parse(latestDataFormYaml.value) as DataForm;
    if (dataFormYaml.cards) {
      useDataFormViewer.updateCard(dataFormYaml.cards[0], true);
    }
  }
});

function updateYaml(value: string) {
  latestDataFormYaml.value = value;
}
watch(dataForm, () => {
  if (dataForm.value) {
    let attempts = 0;
    const maxAttempts = 30; // 30 * 100ms = 3000ms
    const interval = setInterval(() => {
      attempts++;
      const firstAttributeElement = document.querySelector("[data-attr-uuid]");
      if (firstAttributeElement) {
        // Find all input elements and select the first one that isn't disabled
        const inputs = document.querySelectorAll("[data-attr-uuid] input:not([disabled])");
        const firstEnabledInput = inputs[0];

        if (firstEnabledInput) {
          // Trigger a pointer event instxead of focus
          const pointerEvent = new PointerEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          (firstEnabledInput as HTMLElement).dispatchEvent(pointerEvent);
          clearInterval(interval); // Success - stop polling
        }
      }

      if (attempts >= maxAttempts) {
        clearInterval(interval); // Time limit reached - stop polling
      }
    }, 100);
  }
}, { immediate: true });
</script>

<template>
  <div v-if="dataForm">
    <div v-if="showSource">
      <KodexaCodeEditor
        v-model="latestDataFormYaml"
        theme="vs"
        style="height: calc(100vh - 7rem); width: 100%;"
        language="yaml"
        @change="updateYaml"
      />
    </div>
    <div v-else>
      <div class="data-form-scroll mx-2" style="overflow: scroll; height: calc(100vh - 9rem)">
        <KodexaRowLayout
          :rows="buildDataFormViewer()"
          :view-id="viewId"
        />
      </div>
    </div>
  </div>
</template>
