<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { NumberFormatOptions } from "@progress/kendo-intl/dist/npm/numbers";
import type { PropType } from "vue";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import Decimal from "decimal.js";
import { storeToRefs } from "pinia";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import { log } from "~/utils/logger";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const maximumFractionDigits = computed(() => {
  if (!props.tagMetadata?.taxon?.typeFeatures?.truncateDecimal) {
    return 10;
  }
  return Number(props.tagMetadata?.taxon?.typeFeatures?.decimalPlaces || 10);
});

function formatNumber(value: number | undefined | string) {
  if (value === undefined || value === null) {
    return undefined;
  }

  // Leave value as is if truncateDecimal is not enabled
  if (!props.tagMetadata?.taxon?.typeFeatures?.truncateDecimal) {
    return Number(value);
  }

  try {
    const decimalPlaces = Number(maximumFractionDigits.value);
    const decimalNumber = new Decimal(value);
    // 3 is floor
    return decimalNumber.toDecimalPlaces(decimalPlaces, 3).toNumber();
  } catch (error) {
    log.error(`Error formatting number [${error}]`);
    return Number(value);
  }
}

const decimalValue = computed({
  get() {
    return formatNumber(props.attribute.decimalValue);
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      decimalValue: formatNumber(value),
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

function focus() {
  nextTick(() => {
    const numericTextBox = document.querySelector(`#kdx-attribute-${props.attribute.uuid}`);
    if (numericTextBox) {
      numericTextBox.select();
    }
  });
  dataViewHelper.focus();
  emit("focus");
}

const { style, conditionalValidationIcon, valid } = storeToRefs(dataViewHelper);

const numberFormat = computed(() => {
  return {
    style: "decimal",
  } as NumberFormatOptions;
});
</script>

<template>
  <div @click="focus">
    <NumericTextBox
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="decimalValue"
      class="kodexa-input"
      :style="style"
      :format="numberFormat"
      :valid="valid"
      :disabled="props.dataObject?.documentFamily.locked === true"
      rounded="medium"
      :spinners="false"
      :step="0"
      input-prefix="prefix"
      @focus="focus"
      @blur="dataViewHelper.blur()"
    >
      <template #prefix>
        <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
      </template>
    </NumericTextBox>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.kodexa-input {
  @apply appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}

:deep(.k-input-inner) {
  height: 29px !important;
  padding-top: 0 !important;
  font-size: var(--k-textbox-font-size) !important;
  font-weight: var(--k-textbox-font-weight) !important;
  @apply dark:text-gray-50 sm:text-sm dark:bg-gray-700
}
</style>
