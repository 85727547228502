import type { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import type { StoreGeneric } from "pinia";
import type { Ref } from "vue";
import ls from "localstorage-slim";
import { defineStore } from "pinia";
import { log } from "~/utils/logger";

/**
 * Represents a query object for grid data retrieval.
 */
export interface GridQuery {
  page: number;
  pageSize: number;
  filter: string | string[];
  legacyFilter?: boolean;
  filters?: string[];
  sort: string;
  query: string;
}

/**
 * Interface representing page settings for pagination.
 *
 * @interface
 */
export interface IPageSettings {
  page?: number;
  skip?: number;
  take?: number;
  pageCount?: number;
  total?: number;
  pageSize?: number;
  pageSizes?: number[];
  sort?: string;
  info?: boolean;
}

/**
 * Represents a filter object used to construct complex filter expressions.
 *
 * @interface
 */
interface IFilter {
  field: string;
  operator: string;
  value: string;
  logic: string;
  filters: IFilter[];
}

/**
 * Class for grid related helper functions
 */
export class GridHelper {
  /**
   * Converts a filter object to its string representation.
   *
   * @param {IFilter} filter - The filter object to be converted.
   * @param {boolean} legacy - Whether to use legacy RSQL or not.
   *
   * @return {string} - The string representation of the filter.
   */
  public static convertFilter(filter: IFilter, legacy: boolean): string {
    if (!filter) {
      return "";
    }

    if (filter.logic) {
      return GridHelper.convertLogicFilter(filter, legacy);
    }
    if (filter.operator) {
      return GridHelper.convertOperatorFilter(filter, legacy);
    }

    return "";
  }

  /**
   * Converts a logic filter into a string representation.
   *
   * @param {IFilter} logicFilter - The logic filter object to be converted.
   * @param {boolean} legacy - Whether to use legacy RSQL or not.
   * @return {string} - The string representation of the logic filter.
   */
  private static convertLogicFilter(logicFilter: IFilter, legacy: boolean) {
    const filters = logicFilter.filters.map((filter: any) => {
      return GridHelper.convertFilter(filter, legacy);
    });
    if (legacy) {
      return filters.join("|||");
    } else {
      if (logicFilter.logic === "and") {
        if (filters.length === 1) {
          return filters[0];
        } else {
          return filters.join(" AND ");
        }
      }
      if (logicFilter.logic === "or") {
        if (filters.length === 1) {
          return filters[0];
        } else {
          return filters.join("  OR  ");
        }
      }
    }
    return "";
  }

  /**
   * Converts the given filter object into a string representation of an operator filter.
   *
   * @param {IFilter} filter - The filter object to convert.
   * @param {boolean} legacy - Whether to use legacy RSQL or not.
   * @returns {string} - The string representation of the operator filter.
   */
  private static convertOperatorFilter(filter: IFilter, legacy: boolean): string {
    const field = filter.field.replaceAll("/", "_").replaceAll("-", "__");
    if (legacy) {
      // We are doing RSQL
      if (filter.operator === "eq") {
        return `${field}==${filter.value}`;
      }
      if (filter.operator === "neq") {
        return `${field}!=${filter.value}`;
      }
      if (filter.operator === "contains") {
        return `${field}==*${filter.value}*`;
      }
      if (filter.operator === "notcontains") {
        return `${field}!=*${filter.value}*`;
      }
    } else {
      // Only convert to ISO string if the value is already a Date object
      let finalValue = filter.value;
      if (filter.value && filter.value instanceof Date) {
        // It's already a Date object, convert to ISO string
        finalValue = filter.value.toISOString();
      }

      if (filter.operator === "eq") {
        return `${field}: '${finalValue}'`;
      }
      if (filter.operator === "neq") {
        return `${field}! '${finalValue}'`;
      }
      if (filter.operator === "contains") {
        return `${field} ~~ '%${finalValue}%'`;
      }
      if (filter.operator === "notcontains") {
        return `${field} !~~ '%${finalValue}%'`;
      }
      if (filter.operator === "startswith") {
        return `${field} ~~ '${finalValue}%'`;
      }
      if (filter.operator === "endswith") {
        return `${field} ~~ '%${finalValue}'`;
      }
      if (filter.operator === "gte") {
        return `${field} >= '${finalValue}'`;
      }
      if (filter.operator === "gt") {
        return `${field} > '${finalValue}'`;
      }
      if (filter.operator === "lte") {
        return `${field} <= '${finalValue}'`;
      }
      if (filter.operator === "lt") {
        return `${field} < '${finalValue}'`;
      }
      if (filter.operator === "isnull") {
        return `${field} is null`;
      }
      if (filter.operator === "isempty") {
        return `${field} is null`;
      }
      if (filter.operator === "isnotnull") {
        return `${field} is not null`;
      }
      if (filter.operator === "isnotempty") {
        return `${field} is not null`;
      }
    }
    return "";
  }

  /**
   * Converts an array of SortDescriptor objects into a string representation.
   *
   * @param {SortDescriptor[]} value - The array of SortDescriptor objects to convert.
   * @returns {string} The string representation of the converted sort descriptors.
   */
  static convertSort(value: SortDescriptor[]): string {
    if (!value || value.length === 0) {
      return "id:asc";
    }
    return value.map((sort: any) => {
      return `${sort.field}:${sort.dir}`;
    }).join(";");
  }
}

/**
 * Creates a grid helper for managing grid state and functionality.
 *
 * @param {string} gridIdToCreate - The ID of the grid to create the helper for.
 * @param {GridQuery} _gridQuery - The initial query parameters for the grid. Default is `{ page: 1, pageSize: 10, filter: "", sort: "", query: "" }`.
 * @param {string} baseFilterToUse - The base filter to use for the grid. Default is `undefined`.
 * @param {SortDescriptor[]} baseSortToUse - The base sort to use for the grid. Default is `undefined`.
 * @param {boolean} isLegacy - Whether to use legacy RSQL or not. Default is `false`.
 *
 * @return {object} - The created grid helper object.
 */
export function createGridHelper(gridIdToCreate: string, _gridQuery: GridQuery = {
  page: 1,
  pageSize: 10,
  filter: "",
  filters: [],
  sort: "",
  query: "",
}, baseFilterToUse: string | undefined = undefined, baseSortToUse: SortDescriptor[] | undefined = undefined, isLegacy: boolean = false): StoreGeneric {
  return defineStore(`grid-helper-${gridIdToCreate}`, () => {
    const gridQuery = ref(_gridQuery);
    const gridId = ref(gridIdToCreate);
    const showFilter = ref(false);
    const baseFilter = ref<string | undefined>();
    const pageSettings = ref<IPageSettings>({ info: true }) as Ref<IPageSettings>;
    const page = ref(1);
    const pageSize = ref(10);
    const filter: Ref<CompositeFilterDescriptor> = ref({} as CompositeFilterDescriptor);
    const sort: Ref<SortDescriptor[]> = ref([]);
    const query = ref("");
    const selected = ref(new Set<string>());
    const allSelected = ref(false);
    const legacy = ref(isLegacy);
    const groups = ref<string[]>([]);
    const groupedFilters = ref({ filters: [] });

    if (baseFilterToUse) {
      baseFilter.value = baseFilterToUse;
    }

    log.debug(`Creating grid helper for ${gridId}`);

    pageSettings.value.page = gridQuery.value.page;
    pageSettings.value.skip = gridQuery.value.pageSize * (gridQuery.value.page - 1);
    pageSettings.value.take = gridQuery.value.pageSize;
    pageSettings.value.pageSize = gridQuery.value.pageSize;

    /**
     * Sets the base filter for the grid.
     *
     * @param {string} newBaseFilter - The new base filter to be applied.
     *
     * @return {void}
     */
    function setBaseFilter(newBaseFilter: string) {
      log.info(`${gridId.value} - Setting base filter to ${newBaseFilter}`);
      baseFilter.value = newBaseFilter;
      filterChange(undefined);
      pageSettings.value.page = gridQuery.value.page;
      pageSettings.value.skip = gridQuery.value.pageSize * (gridQuery.value.page - 1);
      pageSettings.value.take = gridQuery.value.pageSize;
      pageSettings.value.pageSize = gridQuery.value.pageSize;
    }

    if (baseFilterToUse) {
      setBaseFilter(baseFilterToUse);
    }

    /**
     * Toggles the filter state.
     *
     * @returns {void}
     */
    function toggleFilter(): void {
      showFilter.value = !showFilter.value;
      filterChange(undefined);
    }

    /**
     * Sets the total number of elements and updates the page count based on the given value.
     *
     * @param {number} totalElements - The total number of elements.
     * @return {void}
     */
    function setTotal(totalElements: number): void {
      pageSettings.value.total = totalElements;
      pageSettings.value.pageCount = Math.ceil(totalElements / (pageSettings.value.pageSize ? pageSettings.value.pageSize : 10));
    }

    /**
     * Increments the current page number and updates page settings and grid query accordingly.
     *
     * @returns {void}
     */
    function nextPage(): void {
      if (pageSettings.value.pageCount === undefined || page.value < pageSettings.value.pageCount) {
        page.value++;
        pageSettings.value.page = page.value;
        pageSettings.value.skip = pageSize.value * (page.value - 1);
        pageSettings.value.take = pageSize.value;
        gridQuery.value.page = page.value;
        gridQuery.value.pageSize = pageSize.value;
      }
    }

    /**
     * Sets the page number for pagination.
     *
     * @param {number} pageNumber - The page number to set.
     * @returns {void}
     */
    function setPage(pageNumber: number): void {
      if (pageSettings.value.pageCount === undefined || pageNumber < pageSettings.value.pageCount) {
        page.value = pageNumber;
        pageSettings.value.page = page.value;
        pageSettings.value.skip = pageSize.value * (page.value - 1);
        pageSettings.value.take = pageSize.value;
        gridQuery.value.page = page.value;
        gridQuery.value.pageSize = pageSize.value;
      }
    }

    /**
     * Moves to the previous page of the grid.
     *
     * @returns {void} - This function does not return anything.
     */
    function previousPage(): void {
      if (page.value > 1) {
        page.value--;
        pageSettings.value.page = page.value;
        pageSettings.value.skip = pageSize.value * (page.value - 1);
        pageSettings.value.take = pageSize.value;
        gridQuery.value.page = page.value;
        gridQuery.value.pageSize = pageSize.value;
      }
    }

    /**
     * Handles the change event when the page is changed.
     *
     * @param {any} event - The event object.
     * @return {void} - This method does not return a value.
     */
    function pageChangeHandler(event: any): void {
      if (!event.page) {
        event.page = {
          skip: event.skip,
          take: event.take,
        };
      }
      pageSize.value = event.page.take;
      page.value = (event.page.skip / pageSize.value) + 1;
      pageSettings.value.page = page.value;
      pageSettings.value.skip = event.page.skip;
      pageSettings.value.take = event.page.take;
      gridQuery.value.page = page.value;
      gridQuery.value.pageSize = pageSize.value;
    }

    /**
     * Modifies the filter and gridQuery values based on the provided event.
     *
     * @param {any | undefined} event - The event object containing the filter information.
     * @return {void} - Does not return any value.
     */
    function filterChange(event: any | undefined) {
      if (event) {
        groupedFilters.value = event.filter;

        if (event.filter && event.filter.filters) {
          filter.value = event.filter;
          if (legacy.value) {
            gridQuery.value.legacyFilter = true;
            const allFilters = [] as string[];
            event.filter.filters.forEach((filter: any) => {
              allFilters.push(GridHelper.convertFilter(filter, legacy.value));
            });
            gridQuery.value.filter = allFilters.join(" and ");
          } else {
            gridQuery.value.legacyFilter = false;
            const allFilters = [] as string[];
            event.filter.filters.forEach((filter: any) => {
              allFilters.push(GridHelper.convertFilter(filter, legacy.value));
            });
            gridQuery.value.filter = ((baseFilter.value ? `${baseFilter.value} and ` : "")) + allFilters.join(" and ");
          }
        } else {
          filter.value = {} as CompositeFilterDescriptor;
          gridQuery.value.filter = (baseFilter.value || "");
        }
      } else {
        filter.value = {} as CompositeFilterDescriptor;
        gridQuery.value.filter = (baseFilter.value || "");
      }
    }

    /**
     * Sorts the change event and updates the sort value and grid query sort.
     *
     * @param {object} event - The change event object.
     * @param {Array} event.sort - The sort value.
     *
     * @return {undefined}
     */
    function sortChange(event: any): undefined {
      let baseSort;
      if (groups) {
        baseSort = groups.value.join(";");
      }

      if (!event) {
        sort.value = [];
        gridQuery.value.sort = baseSort;
      } else {
        sort.value = event.sort;
        if (baseSort) {
          gridQuery.value.sort = `${baseSort};${GridHelper.convertSort(event.sort)}`;
        } else {
          gridQuery.value.sort = GridHelper.convertSort(event.sort);
        }
      }
    }

    function setGroups(newGroups: string[]) {
      groups.value = newGroups;
      sortChange({ sort: sort.value });
    }

    /**
     * Saves the current state of the grid.
     * This method saves the current page, page size, filter, show filter, query, and sort of the grid.
     *
     * @returns {void} - Returns nothing.
     */
    function saveState(): void {
      const state = {
        page: gridQuery.value.page,
        pageSize: gridQuery.value.pageSize,
        filter: gridQuery.value.filter,
        showFilter: showFilter.value,
        query: gridQuery.value.query,
        sort: gridQuery.value.sort,
      };

      ls.set(`kodexa-grid-helper-${gridId}`, state);
    }

    /**
     * Computes whether all items are selected.
     *
     * @function
     * @returns {boolean} - true if all items are selected, false otherwise.
     */
    const areAllSelected = computed(() => {
      return allSelected.value;
    });

    function loadState() {
      const state: any = ls.get(`kodexa-grid-helper-${gridId}`);
      if (state) {
        page.value = state.page;
        pageSize.value = state.pageSize;
        gridQuery.value.filter = state.filter;
        showFilter.value = state.showFilter;
        gridQuery.value.query = state.query;
        gridQuery.value.filter = state.gridFilter;
        gridQuery.value.sort = state.sort;
        filterChange({ filter: gridQuery.value.filter });
        sortChange({ sort: gridQuery.value.sort });
      }
    }

    function setQuery(queryString: string) {
      query.value = queryString;
      gridQuery.value.query = queryString;
    }

    if (baseSortToUse) {
      sortChange({ sort: baseSortToUse });
    }

    function toggleSelect(id: string, data: any[]) {
      if (selected.value.has(id)) {
        selected.value.delete(id);
        allSelected.value = false;
      } else {
        selected.value.add(id);

        // if all items are selected, set allSelected to true
        if (data.every((item: any) => selected.value.has(item.id))) {
          allSelected.value = true;
        }
      }
    }

    function buildData(data: any, selected: Set<string>): any[] {
      const finalData: any[] = [];
      if (data) {
        setTotal(data.totalElements);
        setPage(data.page);

        data.content.forEach((item: any) => {
          if (selected.has(item.id)) {
            finalData.push({ ...item, selected: true });
          } else {
            finalData.push({ ...item, selected: false });
          }
        });
      }
      return finalData;
    }

    function toggleSelectAll(data: any[]) {
      if (areAllSelected.value) {
        allSelected.value = false;
        selected.value.clear();
      } else {
        allSelected.value = true;
        data.forEach((item: any) => {
          selected.value.add(item.id);
        });
      }
    }

    return {
      loadState,
      setBaseFilter,
      toggleFilter,
      setTotal,
      pageChangeHandler,
      filterChange,
      saveState,
      setQuery,
      pageSettings,
      sortChange,
      filter,
      sort,
      showFilter,
      query,
      gridQuery,
      selected,
      areAllSelected,
      buildData,
      nextPage,
      previousPage,
      setPage,
      toggleSelect,
      toggleSelectAll,
      setGroups,
      groupedFilters,
    };
  })();
}
