<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import appStore from "~/store";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import { log } from "~/utils/logger";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  editorOptions: {
    type: Object as PropType<AttributeEditorOptions>,
    required: false,
    default: () => {
      return {} as AttributeEditorOptions;
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

const checkboxValue = ref(false);
let stringValue: string | undefined = getStringValue();

function getStringValue(): string | undefined {
  if (props.editorOptions?.sourceDateFormat && props.editorOptions?.maskDateFormat) {
    if (props.editorOptions?.onCheckValue && props.editorOptions.onCheckValue === props.attribute?.stringValue) {
      return props.attribute.stringValue;
    }
    if (!props.attribute.stringValue) {
      return undefined;
    }
    const formattedDate = DateTime.fromFormat(props.attribute.stringValue as string, props.editorOptions?.sourceDateFormat);
    return formattedDate.isValid
      ? formattedDate.toFormat(
          props.editorOptions?.maskDateFormat,
        )
      : undefined;
  } else {
    return props.attribute.stringValue;
  }
}

watch(checkboxValue, (newValue) => {
  if (newValue) {
    stringValue = props?.editorOptions?.onCheckValue;
  } else {
    stringValue = undefined;
  }

  emit("update", {
    ...props.attribute,
    stringValue,
  });
  dataViewHelper.updated();
});

function onMaskInput(event) {
  if (props.editorOptions?.sourceDateFormat && props.editorOptions?.maskDateFormat) {
    if (event.value) {
      const formattedDate = DateTime.fromFormat(event.value, props.editorOptions.maskDateFormat);
      stringValue = formattedDate.isValid ? formattedDate.toFormat(props.editorOptions?.sourceDateFormat) : undefined;
    }
  }
  checkboxValue.value = event.target.checked;
  emit("update", {
    ...props.attribute,
    stringValue,
  });
  dataViewHelper.updated();
}

function onStringInput(event) {
  stringValue = event.value !== undefined ? event.value : undefined;
  emit("update", {
    ...props.attribute,
    stringValue,
  });
  dataViewHelper.updated();
}

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const isDisabled = computed(() => {
  const tagMeta = tagMetadataMap.value.get(props.attribute.path);
  return props.dataObject?.documentFamily.locked === true || tagMeta?.taxon?.valuePath === "METADATA";
});

const isLongText = computed(() => {
  const tagMeta = tagMetadataMap.value.get(props.attribute.path);
  return tagMeta?.taxon?.typeFeatures?.longText === true;
});

const maxTextRows = computed(() => {
  const tagMeta = tagMetadataMap.value.get(props.attribute.path);
  return tagMeta?.taxon?.typeFeatures?.maxTextRows ?? 3;
});

const { style, conditionalValidationIcon, valid } = storeToRefs(dataViewHelper);

const textareaRef = ref<HTMLTextAreaElement | null>(null);
const errors = ref({});
const adjustedRows = computed(() => {
  // We need to adjust the rows based on the number of lines in the text
  // and also consider the width of the container
  if (isLongText.value) {
    if (!stringValue) {
      return 1;
    }

    // Base calculation on newlines
    const lines = (stringValue?.split("\n") || []).length;

    // Consider text wrapping based on container width if we have access to the textarea
    // textareaRef points to the KodexaTextArea component instance
    const kodexaTextArea = textareaRef.value;
    if (kodexaTextArea?.$el) {
      // Find the actual <textarea> element within the Kendo component's structure
      const textarea = kodexaTextArea.$el.querySelector("textarea");

      if (textarea) {
        const style = window.getComputedStyle(textarea);
        // Use a more robust way to get line height, falling back to font size * 1.2
        const lineHeight = Number.parseInt(style.lineHeight);
        const effectiveLineHeight = !Number.isNaN(lineHeight) && lineHeight > 0 ? lineHeight : Number.parseInt(style.fontSize) * 1.0; // Fallback multiplier

        // Reset height temporarily to calculate scrollHeight
        const originalHeight = textarea.style.height;
        textarea.style.height = "auto";
        log.info("effectiveLineHeight", effectiveLineHeight);

        // Calculate actual lines needed based on scroll height
        // Add a small epsilon to handle potential floating point inaccuracies
        const wrappedLines = Math.ceil((textarea.scrollHeight) / effectiveLineHeight);

        // Restore original height
        textarea.style.height = originalHeight;

        // Use the greater of newline count or wrapped lines
        log.info("wrappedLines", wrappedLines, lines, maxTextRows.value);
        return Math.min(Math.max(lines, wrappedLines), maxTextRows.value);
      }
    }

    // Fallback if textarea element isn't accessible yet
    return Math.min(lines, maxTextRows.value);
  }
  return 1;
});

// Watch stringValue to recalculate rows when content changes
watch(() => stringValue, () => {
  // Allow DOM to update before recalculating
  nextTick(() => {
    if (textareaRef.value && isLongText.value) {
      // Force re-evaluation of adjustedRows
      const _ = adjustedRows.value;
    }
  });
}, { immediate: true });
</script>

<template>
  <div :class="{ 'no-due-date': checkboxValue }" @click="focus">
    <template v-if="!editable">
      <KodexaNonEditableValue
        v-if="!editable"
        :style="style" :conditional-validation-icon="conditionalValidationIcon"
        :string-value="stringValue"
      />
    </template>
    <template v-else>
      <MaskedTextBox
        v-if="editorOptions?.isMaskedText && !checkboxValue"
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="stringValue"
        class="kodexa-input"
        :mask="editorOptions?.maskedText"
        rounded="medium"
        :valid="valid"
        :disabled="isDisabled"
        data-1p-ignore
        input-prefix="prefix"
        :style="style"
        @focus="focus"
        @blur="dataViewHelper.blur()"
        @change="onMaskInput"
      >
        <template #prefix>
          <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
        </template>
      </MaskedTextBox>
      <KodexaTextInput
        v-else-if="!checkboxValue && !isLongText"
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="stringValue"
        :style="style"
        :name="`kdx-attribute-${props.attribute.uuid}`"
        :disabled="isDisabled"
        :valid="valid"
        :show-errors="false"
        @input="onStringInput"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      >
        <template #prefix>
          <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
        </template>
      </KodexaTextInput>
      <KodexaTextArea
        v-else-if="!checkboxValue && isLongText"
        :id="`data-attribute-${props.attribute.uuid}`"
        ref="textareaRef"
        v-model="stringValue"
        :name="`kdx-attribute-${props.attribute.uuid}`"
        :disabled="isDisabled"
        :errors="errors"
        :rows="adjustedRows"
        :style="style"
        @input="onStringInput"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      >
        <template #prefix>
          <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
        </template>
      </KodexaTextArea>

      <div v-if="editorOptions?.isCheckbox">
        <input
          v-if="checkboxValue"
          id="disabled-due-date"
          type="text"
          class="block w-full cursor-not-allowed rounded-lg border border-gray-300 bg-gray-100 p-2.5 text-base text-gray-900"
          value="None"
          disabled
          style="background-color: white;"
        >
        <div class="mt-1 flex items-center">
          <KodexaCheckbox
            id="due-date"
            v-model="checkboxValue"
            :valid="valid"
            :name="`checkbox-${props.attribute?.id}`"
            label="Check if none"
            :disabled="isDisabled"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
#disabled-due-date {
  border-radius: 4px;
  padding-block: 4px;
  padding-inline: 8px;
  display: none;
}

.no-due-date .k-maskedtextbox {
  display: none;
}

.no-due-date #disabled-due-date {
  display: block;
}
.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}

:deep(.k-input-inner) {
  font-size: var(--k-textbox-font-size) !important;
  font-weight: var(--k-textbox-font-weight) !important;
  @apply dark:text-gray-50 sm:text-sm dark:bg-gray-700
}
</style>
