<script setup lang="ts">
import type { ModelInteraction } from "~/model";
import { onMounted, ref } from "vue";
import KodexaCostAnalysisView from "~/components/costAnalysis/kodexa-cost-analysis-view.vue";
import appStore from "~/store";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
  kioskMode: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const viewId = computed(() => {
  return props.params.params.viewId;
});

const view = ref(null);
const modelInteractions = ref([] as ModelInteraction[]);
const totalCost = ref("0");
const dataLoaded = ref(false);

onMounted(() => {
  if (viewId.value) {
    view.value = appStore.workspaceStore.views.find(v => v.id === viewId.value);
    if (view.value) {
      modelInteractions.value = view.value.modelInteractions || [];
      totalCost.value = view.value.totalCost || "0";
      dataLoaded.value = true;
    }
  }
});
</script>

<template>
  <div style="height: calc(100vh - 7rem); overflow-y: scroll; width: 100%; position: relative">
    <KodexaCostAnalysisView
      v-if="dataLoaded"
      :model-interactions="modelInteractions"
      :total-cost="totalCost"
    />
    <div v-else>
      Loading cost data...
    </div>
  </div>
</template>
