<script setup lang="ts">
import type { Tab } from "~/utils/tab";
import { computed, watch } from "vue";

const props = defineProps({
  items: Array<Tab>,
  selected: Object,
  tabName: {
    type: String,
    default: "tab",
  },
  small: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["selected"]);

const currentNavigation = computed({
  get: () => props.selected,
  set: (value: Tab) => {
    emit("selected", value);
    updateUrlWithTab(value);
  },
});

function updateUrlWithTab(tab: Tab | undefined) {
  if (!tab) {
    return;
  }
  const tabName = tab.ref.toLowerCase();
  const url = new URL(window.location.href);
  url.searchParams.set(props.tabName, tabName);
  window.history.pushState({}, "", url.toString());
}

watch(() => props.items, () => {
  if (props.items && props.items.length > 0) {
    const urlTab = new URL(window.location.href).searchParams.get(props.tabName);
    const tab = props.items.find((item: any) => {
      if (item.ref === undefined) {
        return false;
      }
      const tabName = item.ref.toLowerCase();
      return tabName === urlTab && urlTab !== undefined;
    });

    if (tab) {
      currentNavigation.value = tab;
      updateUrlWithTab(currentNavigation.value);
    } else if (props.items.length > 0 && currentNavigation.value === undefined) {
      currentNavigation.value = props.items[0];
      updateUrlWithTab(currentNavigation.value);
    }
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div class="border-b border-gray-200 dark:border-gray-700">
    <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
      <li
        v-for="(item) in items" :key="item.ref"
        class="mr-2"
        @click="currentNavigation = item"
      >
        <a
          :id="item.ref"
          :class="[
            item.ref === currentNavigation?.ref
              ? 'cursor-pointer inline-flex items-center justify-center text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group'
              : 'cursor-pointer inline-flex items-center justify-center border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group',
            small ? 'p-2 text-sm' : 'p-4 text-md',
          ]"
        >
          <MaterialDesignIcon
            v-if="item?.icon"
            :name="item.icon"
            class="text-theme-primary mr-3"
            :size="small ? 14 : 18"
          />
          {{ item?.name }}
        </a>
      </li>
      <li class="ml-auto items-center justify-center">
        <slot />
      </li>
    </ul>
  </div>
</template>
