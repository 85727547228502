/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AssistantConnection,
  CreateAssistantConnection400,
  DeleteAssistantConnection400,
  ExceptionResponse,
  GetAssistantConnection400,
  GetChangeSequenceForAssistantConnection400,
  ListAssistantConnections400,
  ListAssistantConnectionsParams,
  PageAssistantConnection,
  ThrowableProblem,
  UpdateAssistantConnection400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getAssistantConnection = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<AssistantConnection>(
      {url: `/api/assistantConnections/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantConnectionQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assistantConnections',id] as const;
    }

    
export const getGetAssistantConnectionQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantConnection>>, TError = ErrorType<GetAssistantConnection400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantConnection>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantConnectionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantConnection>>> = ({ signal }) => getAssistantConnection(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantConnection>>, TError, TData> 
}

export type GetAssistantConnectionQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantConnection>>>
export type GetAssistantConnectionQueryError = ErrorType<GetAssistantConnection400 | ThrowableProblem | ExceptionResponse>



export function useGetAssistantConnection<TData = Awaited<ReturnType<typeof getAssistantConnection>>, TError = ErrorType<GetAssistantConnection400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantConnection>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAssistantConnectionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateAssistantConnection = (
    id: MaybeRef<string>,
    assistantConnection: MaybeRef<AssistantConnection>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantConnection = unref(assistantConnection);
      
      return customAxios<AssistantConnection>(
      {url: `/api/assistantConnections/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantConnection
    },
      options);
    }
  


export const getUpdateAssistantConnectionMutationOptions = <TError = ErrorType<UpdateAssistantConnection400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantConnection>>, TError,{id: string;data: AssistantConnection}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantConnection>>, TError,{id: string;data: AssistantConnection}, TContext> => {
    
const mutationKey = ['updateAssistantConnection'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantConnection>>, {id: string;data: AssistantConnection}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAssistantConnection(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantConnectionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantConnection>>>
    export type UpdateAssistantConnectionMutationBody = AssistantConnection
    export type UpdateAssistantConnectionMutationError = ErrorType<UpdateAssistantConnection400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateAssistantConnection = <TError = ErrorType<UpdateAssistantConnection400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantConnection>>, TError,{id: string;data: AssistantConnection}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateAssistantConnection>>,
        TError,
        {id: string;data: AssistantConnection},
        TContext
      > => {

      const mutationOptions = getUpdateAssistantConnectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteAssistantConnection = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/assistantConnections/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssistantConnectionMutationOptions = <TError = ErrorType<DeleteAssistantConnection400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantConnection>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantConnection>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteAssistantConnection'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssistantConnection>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteAssistantConnection(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssistantConnectionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssistantConnection>>>
    
    export type DeleteAssistantConnectionMutationError = ErrorType<DeleteAssistantConnection400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteAssistantConnection = <TError = ErrorType<DeleteAssistantConnection400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantConnection>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteAssistantConnection>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteAssistantConnectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listAssistantConnections = (
    params?: MaybeRef<ListAssistantConnectionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAssistantConnection>(
      {url: `/api/assistantConnections`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantConnectionsQueryKey = (params?: MaybeRef<ListAssistantConnectionsParams>,) => {
    return ['api','assistantConnections', ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantConnectionsQueryOptions = <TData = Awaited<ReturnType<typeof listAssistantConnections>>, TError = ErrorType<ListAssistantConnections400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListAssistantConnectionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantConnections>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantConnectionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistantConnections>>> = ({ signal }) => listAssistantConnections(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistantConnections>>, TError, TData> 
}

export type ListAssistantConnectionsQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistantConnections>>>
export type ListAssistantConnectionsQueryError = ErrorType<ListAssistantConnections400 | ThrowableProblem | ExceptionResponse>



export function useListAssistantConnections<TData = Awaited<ReturnType<typeof listAssistantConnections>>, TError = ErrorType<ListAssistantConnections400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListAssistantConnectionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantConnections>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAssistantConnectionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createAssistantConnection = (
    assistantConnection: MaybeRef<AssistantConnection>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      assistantConnection = unref(assistantConnection);
      
      return customAxios<AssistantConnection>(
      {url: `/api/assistantConnections`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistantConnection, signal
    },
      options);
    }
  


export const getCreateAssistantConnectionMutationOptions = <TError = ErrorType<CreateAssistantConnection400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistantConnection>>, TError,{data: AssistantConnection}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createAssistantConnection>>, TError,{data: AssistantConnection}, TContext> => {
    
const mutationKey = ['createAssistantConnection'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssistantConnection>>, {data: AssistantConnection}> = (props) => {
          const {data} = props ?? {};

          return  createAssistantConnection(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAssistantConnectionMutationResult = NonNullable<Awaited<ReturnType<typeof createAssistantConnection>>>
    export type CreateAssistantConnectionMutationBody = AssistantConnection
    export type CreateAssistantConnectionMutationError = ErrorType<CreateAssistantConnection400 | ThrowableProblem | ExceptionResponse>

    export const useCreateAssistantConnection = <TError = ErrorType<CreateAssistantConnection400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistantConnection>>, TError,{data: AssistantConnection}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createAssistantConnection>>,
        TError,
        {data: AssistantConnection},
        TContext
      > => {

      const mutationOptions = getCreateAssistantConnectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForAssistantConnection = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/assistantConnections/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForAssistantConnectionQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assistantConnections',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForAssistantConnectionQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>, TError = ErrorType<GetChangeSequenceForAssistantConnection400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForAssistantConnectionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>> = ({ signal }) => getChangeSequenceForAssistantConnection(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>, TError, TData> 
}

export type GetChangeSequenceForAssistantConnectionQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>>
export type GetChangeSequenceForAssistantConnectionQueryError = ErrorType<GetChangeSequenceForAssistantConnection400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForAssistantConnection<TData = Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>, TError = ErrorType<GetChangeSequenceForAssistantConnection400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistantConnection>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForAssistantConnectionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



