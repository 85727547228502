<script setup lang="ts">
import type { PropType } from "vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import type { Assistant, ContentObject, Option } from "~/model";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Object],
    required: false,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: Object,
  contentObject: Object as PropType<ContentObject | undefined>,
  assistant: Object as PropType<Assistant | undefined>,
});

const emit = defineEmits(["update:modelValue"]);

const {
  localValue,
} = useConfigurationBase(props, emit);

const numberValue = computed({
  get: () => {
    const val = localValue.value?.[props.item.name] ?? localValue.value;
    return val === null || val === undefined ? "" : val;
  },
  set: (value) => {
    const newValue = value === "" ? null : Number(value);
    if (typeof localValue.value === "object" && localValue.value !== null) {
      localValue.value = { ...localValue.value, [props.item.name]: newValue };
    } else {
      localValue.value = newValue;
    }
  },
});
</script>

<template>
  <KodexaNumericInput
    :id="`option-${item.name}`"
    v-model="numberValue"
    :disabled="disabled"
    :name="`option-${item.name}`"
    :label="item.label ? item.label : item.name"
  />
</template>
