export const optionMetadata = {
  label: "Chart Type",
  description: "Select chart visualization type",
  options: [
    {
      name: "type",
      label: "Chart Type",
      type: "select",
      options: [
        { value: "bar", label: "Bar Graph" },
        { value: "pie", label: "Pie Graph" },
      ],
      description: "Type of chart to display",
    },
  ],
};
