import type { ComputedRef, Ref } from "vue";
import type { Card, DataAttribute, DataException, DataForm, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { DataFormViewer } from "~/store/useWorkspace";
import { defineStore, storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store/index";
import { log } from "~/utils/logger";

export interface DataFormCardLayout extends Card {
  card: Card;
  colSpan: number;
}

interface OverrideException {
  parentUuid: string | undefined;
  openFlag: boolean;
}

export function createDataFormViewerStore(viewId: string) {
  const viewById: DataFormViewer = appStore.workspaceStore.getViewById(viewId);

  if (!viewById) {
    throw new Error(`Unable to find view with id ${viewId}`);
  }

  return defineStore(`dataFormViewer-${viewId}`, () => {
    log.info("Creating data form view store");
    const dataFormId: Ref<string> = ref(uuidv4());
    const dataFormViewer: Ref<DataFormViewer> = ref(viewById);

    const focusedAttributeUuid: Ref<string | undefined> = ref(undefined);

    const overrideExceptionMapper = ref<Map<string, OverrideException[]>>(new Map());

    const validatedExceptionUuids: Ref<string[]> = ref([]);

    const documentFamilyIds = computed(() => {
      return dataFormViewer.value.documentFamilyIds || [];
    });

    const dataForm = computed(() => {
      const { allDataForms } = storeToRefs(appStore.projectStore);
      if (allDataForms.value === undefined) {
        return undefined;
      }
      const dataF = allDataForms.value.find((dataForm: DataForm) => dataForm.ref === viewById.dataFormRef);
      if (dataF && !dataF.views) {
        dataF.views = [{ cards: [] }];
      }
      return dataF;
    });

    const selectedCard: Ref<Card | undefined> = ref(undefined);
    const title: Ref<string> = ref("");

    const rootCards = computed(() => {
      return dataForm.value.cards;
    });

    const newDataObjects: Ref<DataObject[]> = ref([]);

    const dataObjects: ComputedRef<DataObject[]> = computed(() => {
      const { dataObjects } = storeToRefs(appStore.workspaceStore);

      if (dataForm.value && dataForm.value.entrypoints.includes("workspace")) {
        return [...dataObjects.value.values()];
      }

      const formDataObjects: DataObject[] = [];
      for (const dato of Array.from(dataObjects.value.values())) {
        if (dato.documentFamily.id && documentFamilyIds.value.includes(dato.documentFamily.id)) {
          if (formDataObjects.find(d => d.uuid === dato.uuid) === undefined) {
            formDataObjects.push(dato);
          }
        }
      }

      return formDataObjects.concat(newDataObjects.value);
    });

    const dataExceptions = computed(() => {
      const dataAttributeExceptions: DataException[] = [];
      const dataObjectExceptions: DataException[] = [];
      for (const dataObject of dataObjects.value) {
        if (dataObject.dataExceptions) {
          dataObjectExceptions.push(...dataObject.dataExceptions);
        }
        if (dataObject.attributes) {
          for (const dataAttribute of dataObject.attributes) {
            if (dataAttribute.dataExceptions) {
              dataAttributeExceptions.push(...dataAttribute.dataExceptions);
            }
          }
        }
      }
      return { dataAttributeExceptions, dataObjectExceptions };
    });

    const availableCardTypes = computed(() => {
      return [
        {
          name: "label",
          label: "Label",
          designOnly: false,
          options: [
            {
              name: "label",
              label: "Label",
              type: "string",
              default: "New Label",
            },
          ],
        },
        {
          name: "dataAttributeEditor",
          label: "Data Attribute",
          designOnly: false,
          options: [
            {
              name: "taxon",
              label: "Attribute",
              type: "taxon",
              default: undefined,
            },
          ],
        },
        {
          name: "cardPanel",
          label: "Card Panel",
          designOnly: false,
          options: [
            {
              name: "groupTaxon",
              label: "Group Taxon",
              type: "groupTaxon",
              default: undefined,
            },
            {
              name: "showHeader",
              label: "Show Header",
              type: "boolean",
              default: false,
            },
            {
              name: "title",
              label: "Title",
              type: "string",
              default: "Title",
            },
            {
              name: "subTitle",
              label: "Subtitle",
              type: "string",
              default: "",
            },
            {
              name: "hideCollapse",
              label: "Hide Collapse",
              type: "boolean",
              default: false,
            },
            {
              name: "hideAdd",
              label: "Hide Add",
              type: "boolean",
              default: false,
            },
          ],
        },
        {
          name: "dataObjectGrid",
          label: "Data Object Grid",
          designOnly: false,
          options: [
            {
              name: "groupTaxon",
              label: "Group Taxon",
              type: "groupTaxon",
              default: undefined,
            },
          ],
        },
        {
          name: "tabs",
          label: "Tabs",
          designOnly: false,
          options: [],
        },
        {
          name: "transposedGrid",
          label: "Transposed Grid",
          designOnly: false,
          options: [
            {
              name: "groupTaxon",
              label: "Group Taxon",
              type: "groupTaxon",
              default: undefined,
            },
            {
              name: "headerTaxon",
              label: "Header Taxon",
              type: "headerTaxon",
              default: undefined,
            },
          ],
        },
      ];
    });

    function addCard(newCard: Card, parentCard?: Card) {
      if (parentCard && parentCard.id) {
        const reactiveParent = findCard(parentCard.id);
        if (reactiveParent) {
          // @ts-expect-error OpenAPI issue
          reactiveParent.children.push(newCard);
        }
      } else {
        dataForm.value.cards.push(newCard);
      }
    }

    function removeCard(card: Card, parentCard?: Card) {
      if (parentCard?.id) {
        const reactiveParent = findCard(parentCard.id);
        if (reactiveParent && reactiveParent.children) {
          reactiveParent.children = reactiveParent.children.filter(c => c.id !== card.id);
        }
      } else {
        dataForm.value.cards = dataForm.value.cards.filter(c => c.id !== card.id);
      }
    }

    function selectCard(card: Card) {
      selectedCard.value = card;
    }

    function findCardInChildren(cardId: string, cards: Card[] | undefined, updateCard?: Card, overwrite = false): Card | undefined {
      if (!cards) {
        return undefined;
      }

      for (const card of cards) {
        if (card.id === cardId) {
          if (updateCard) {
            card.type = updateCard.type;
            card.properties = updateCard.properties;
            if (overwrite) {
              card.children = updateCard.children;
            }
            if (selectedCard.value && selectedCard.value.id === cardId) {
              selectedCard.value = card;
            }
          }
          return card;
        }
        if (card.children) {
          const foundCard = findCardInChildren(cardId, card.children, updateCard, overwrite);
          if (foundCard) {
            return foundCard;
          }
        }
      }
      return undefined;
    }

    function findCard(cardId: string, updateCard?: Card, overwrite = false): Card | undefined {
      return findCardInChildren(cardId, dataForm.value.cards, updateCard, overwrite);
    }

    function getPositions(parentCard?: Card) {
      if (parentCard) {
        if (!parentCard.children) {
          return [];
        }
        return parentCard.children.map((card: Card) => {
          return {
            id: card.id,
            col: card.properties?.col || 0,
            colSpan: card.properties?.colSpan || 2,
            rowSpan: card.properties?.rowSpan || 2,
            row: card.properties?.row || 0,
            order: card.properties?.order || 0,
          };
        });
      } else {
        const cards = dataForm.value.cards || [];
        return cards.map((card: Card) => {
          return {
            id: card.id,
            col: card.properties?.col || 0,
            colSpan: card.properties?.colSpan || 2,
            rowSpan: card.properties?.rowSpan || 2,
            row: card.properties?.row || 0,
            order: card.properties?.order || 0,
          };
        });
      }
    }

    function updateCard(card: Card | undefined, overwrite = false) {
      if (!card || !card.id) {
        return card;
      }
      return findCard(card.id, card, overwrite);
    }

    // We will want to build a structure since when they user is not in design mode we want to use good old-fashioned
    // HTML to render the form
    function buildDataFormViewer(card: Card | undefined = undefined): DataFormCardLayout[] {
      // Our basic form structure is made up of for a card we can return multiple rows - each row is going
      // to be turned into a div using tailwind grid layout.  We do this to allow us to have variable height
      // and use the standard HTML layout
      const rows = [];
      const positions = getPositions(card);

      // Get the maximum row
      const maxRow = Math.max(...positions.map((p: any) => p.row));

      const sortedPositions = positions.sort((a: any, b: any) => a.order - b.order);

      for (let i = 0; i <= maxRow; i++) {
        const row = sortedPositions.filter((p: any) => p.row === i);
        const rowCards = [];
        for (const rowItem of row) {
          const card = findCard(rowItem.id);
          if (card) {
            const layout = JSON.parse(JSON.stringify(card)) as DataFormCardLayout;
            layout.colSpan = rowItem.colSpan;
            rowCards.push(layout);
          }
        }

        rows.push({ cards: rowCards });
      }
      return rows;
    }

    function addAttribute(tagMetadata: TagMetadata, dataObject: DataObject, uuid = uuidv4()) {
      // Important, when we create an attribute or anything else that we intend to store
      // in the platform we can't create an ID since it hasn't been stored yet
      // therefore must provide an uuid - the workspace will tracked by UUID so that it
      // can handle data that is either saved to the backend or not yet save

      const attribute = {
        uuid,
        path: tagMetadata.taxon.path,
        dataExceptions: [],
        tag: tagMetadata.taxon.name,
        typeAtCreation: tagMetadata.taxon.taxonType,
        dataObject: {
          uuid: dataObject.uuid,
          id: dataObject.id,
          storeRef: dataObject.storeRef,
          documentFamily: {
            id: dataObject.documentFamily.id,
            path: dataObject.documentFamily.path,
          },
        },
      } as DataAttribute;

      if (dataObject.id) {
        attribute.dataObjectId = dataObject.id;
      }
      appStore.workspaceStore.addAttribute(dataObject, attribute);
      return attribute;
    }

    function setFocusedAttributeUuid(uuid: string | undefined) {
      focusedAttributeUuid.value = uuid;
    }

    return {
      dataObjects,
      selectedCard,
      title,
      dataForm,
      documentFamilyIds,
      dataFormId,
      availableCardTypes,
      addCard,
      removeCard,
      rootCards,
      dataFormViewer,
      selectCard,
      findCard,
      updateCard,
      buildDataFormViewer,
      addAttribute,
      focusedAttributeUuid,
      setFocusedAttributeUuid,
      dataExceptions,
      overrideExceptionMapper,
      validatedExceptionUuids,
    };
  })();
}
