<script setup lang="ts">
import type { PropType } from "vue";
import type { Task } from "~/model";
import { storeToRefs } from "pinia";
import router from "~/router/router";
import appStore from "~/store";

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
  includeAssignee: {
    type: Boolean,
    default: false,
  },
  includeTags: {
    type: Boolean,
    default: true,
  },
  emitOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["updated", "open"]);

const { currentOrganization } = storeToRefs(appStore.organizationStore);

function gotoTask(task: Task) {
  if (props.emitOpen) {
    emit("open", task);
  } else {
    router.push(`/f/o/${currentOrganization.value.id}/p/${task.project.id}/t/${task.id}`);
  }
}

function truncateDescription(description: string | undefined) {
  if (!description) {
    return "";
  }
  const lines = description.split("\n").slice(0, 2);
  const text = lines.join("\n");
  return text.length > 60 ? `${text.substring(0, 60)}...` : text;
}
</script>

<template>
  <div>
    <div class="flex items-center">
      <div class="ml-1">
        <div class="cursor-pointer font-bold text-gray-900 dark:text-gray-100" @click="gotoTask(task)">
          <span v-if="task?.title" id="taskTitle" class="wrap-text">{{ task?.title }}</span>
          <span v-else id="untitledTask" class="wrap-text">Untitled Task</span>
        </div>
        <div v-if="includeAssignee" class="mb-2 flex text-sm font-medium capitalize dark:text-gray-300">
          {{ task.assignee?.fullName }}
        </div>
        <div class="text-xs text-gray-700 dark:text-gray-400">
          {{ truncateDescription(task.description) }}
        </div>
        <div v-if="includeTags && task.tags" class="ml-2 flex items-center">
          <KodexaTaskTags :task="task" class="-ml-4 mt-4" @updated="emit('updated')" />
        </div>
      </div>
    </div>
  </div>
</template>
