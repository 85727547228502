/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  Activity,
  CreateActivity400,
  DeleteActivity400,
  ExceptionResponse,
  GetActivity400,
  GetChangeSequenceForActivity400,
  ListActivities400,
  ListActivitiesParams,
  PageActivity,
  ThrowableProblem,
  UpdateActivity400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Activity>(
      {url: `/api/activities/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','activities',id] as const;
    }

    
export const getGetActivityQueryOptions = <TData = Awaited<ReturnType<typeof getActivity>>, TError = ErrorType<GetActivity400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivity>>> = ({ signal }) => getActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getActivity>>, TError, TData> 
}

export type GetActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getActivity>>>
export type GetActivityQueryError = ErrorType<GetActivity400 | ThrowableProblem | ExceptionResponse>



export function useGetActivity<TData = Awaited<ReturnType<typeof getActivity>>, TError = ErrorType<GetActivity400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateActivity = (
    id: MaybeRef<string>,
    activity: MaybeRef<Activity>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
activity = unref(activity);
      
      return customAxios<Activity>(
      {url: `/api/activities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: activity
    },
      options);
    }
  


export const getUpdateActivityMutationOptions = <TError = ErrorType<UpdateActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateActivity>>, TError,{id: string;data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateActivity>>, TError,{id: string;data: Activity}, TContext> => {
    
const mutationKey = ['updateActivity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateActivity>>, {id: string;data: Activity}> = (props) => {
          const {id,data} = props ?? {};

          return  updateActivity(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateActivityMutationResult = NonNullable<Awaited<ReturnType<typeof updateActivity>>>
    export type UpdateActivityMutationBody = Activity
    export type UpdateActivityMutationError = ErrorType<UpdateActivity400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateActivity = <TError = ErrorType<UpdateActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateActivity>>, TError,{id: string;data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateActivity>>,
        TError,
        {id: string;data: Activity},
        TContext
      > => {

      const mutationOptions = getUpdateActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/activities/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteActivityMutationOptions = <TError = ErrorType<DeleteActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteActivity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteActivity>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteActivity(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteActivityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteActivity>>>
    
    export type DeleteActivityMutationError = ErrorType<DeleteActivity400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteActivity = <TError = ErrorType<DeleteActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteActivity>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listActivities = (
    params?: MaybeRef<ListActivitiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageActivity>(
      {url: `/api/activities`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListActivitiesQueryKey = (params?: MaybeRef<ListActivitiesParams>,) => {
    return ['api','activities', ...(params ? [params]: [])] as const;
    }

    
export const getListActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof listActivities>>, TError = ErrorType<ListActivities400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListActivitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listActivities>>> = ({ signal }) => listActivities(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listActivities>>, TError, TData> 
}

export type ListActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof listActivities>>>
export type ListActivitiesQueryError = ErrorType<ListActivities400 | ThrowableProblem | ExceptionResponse>



export function useListActivities<TData = Awaited<ReturnType<typeof listActivities>>, TError = ErrorType<ListActivities400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListActivitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createActivity = (
    activity: MaybeRef<Activity>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      activity = unref(activity);
      
      return customAxios<Activity>(
      {url: `/api/activities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activity, signal
    },
      options);
    }
  


export const getCreateActivityMutationOptions = <TError = ErrorType<CreateActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createActivity>>, TError,{data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createActivity>>, TError,{data: Activity}, TContext> => {
    
const mutationKey = ['createActivity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createActivity>>, {data: Activity}> = (props) => {
          const {data} = props ?? {};

          return  createActivity(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateActivityMutationResult = NonNullable<Awaited<ReturnType<typeof createActivity>>>
    export type CreateActivityMutationBody = Activity
    export type CreateActivityMutationError = ErrorType<CreateActivity400 | ThrowableProblem | ExceptionResponse>

    export const useCreateActivity = <TError = ErrorType<CreateActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createActivity>>, TError,{data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createActivity>>,
        TError,
        {data: Activity},
        TContext
      > => {

      const mutationOptions = getCreateActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/activities/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','activities',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForActivityQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError = ErrorType<GetChangeSequenceForActivity400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForActivity>>> = ({ signal }) => getChangeSequenceForActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError, TData> 
}

export type GetChangeSequenceForActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForActivity>>>
export type GetChangeSequenceForActivityQueryError = ErrorType<GetChangeSequenceForActivity400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForActivity<TData = Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError = ErrorType<GetChangeSequenceForActivity400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



