export const optionMetadata = {
  label: "Pipeline Model Options",
  description: "Configure options for pipeline model steps",
  options: [
    {
      name: "steps",
      label: "Pipeline Steps",
      type: "array",
      description: "Configuration for each pipeline step",
    },
    {
      name: "showTabs",
      label: "Show Option Tabs",
      type: "boolean",
      description: "Show options organized in tabs",
      default: true,
    },
  ],
};
