<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script>
export default {
  name: "TaxonLookupOption",
  props: {
    item: Object,
    value: Object,
    taxonomies: Array,
  },

  data() {
    return {
      selectedTaxon: null,
      availableTaxons: [],
    };
  },

  computed: {
    myValue: {
      get() {
        if (this.taxonomies) {
          this.rebuild();
        }

        const currentPath = this.value[this.item.name];

        if (currentPath) {
          const taxon = this.availableTaxons.find(t => t.path === currentPath);
          if (taxon) {
            return taxon;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      set(value) {
        const updatedValue = { ...this.value };
        if (value) {
          updatedValue[this.item.name] = value.path;
        } else {
          delete updatedValue[this.item.name];
        }
        this.$emit("input", updatedValue);
      },
    },
  },
  watch: {
    item() {
      if (this.taxonomies) {
        this.rebuild();
      }
    },
    taxonomies: {
      deep: true,
      immediate: true,
      handler() {
        this.rebuild();
      },
    },
  },
  methods: {
    rebuild() {
      this.availableTaxons = [];
      this.selectedTaxon = null;
      this.taxonomies.forEach((taxonomy) => {
        if (taxonomy.taxonomyType === "CONTENT" || taxonomy.taxonomyType === "MODEL") {
          this.buildAvailable(taxonomy.taxons, "", taxonomy.name);
        }
      });
    },
    clear() {
      this.myValue = null;
    },
    buildAvailable(taxons, path, labelPath) {
      taxons.forEach((taxon) => {
        if (taxon.valuePath !== "METADATA") {
          taxon.fullName = `${labelPath} / ${taxon.label}`;
          if (taxon.path === this.value) {
            this.selectedTaxon = taxon;
          }
          if (!taxon.group) {
            this.availableTaxons.push(taxon);
          }
        }

        if (taxon.children) {
          this.buildAvailable(taxon.children, taxon.path, taxon.fullName);
        }
      });
    },
  },
};
</script>

<template>
  <div v-if="item">
    <VAutocomplete
      v-model="myValue" :hint="item.description" :items="availableTaxons"
      :label="item.label"
      chips
      item-text="fullName"
      item-value="path"
      clearable
      persistent-hint
      return-object @click.clear="clear"
    >
      <template #item="{ item }">
        <VChip color="green" style="color:white" label small>
          {{ item.fullName }}
        </VChip>
      </template>
      <template #selection="{ item }">
        <VChip color="green" style="color:white" label small>
          {{ item.fullName }}
        </VChip>
      </template>
    </VAutocomplete>
  </div>
</template>

<style>

</style>
