/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ContentObject,
  CreateContentObject400,
  DeleteContentObject400,
  ExceptionResponse,
  GetChangeSequenceForContentObject400,
  GetContentObject400,
  ListContentObjects400,
  ListContentObjectsParams,
  PageContentObject,
  ThrowableProblem,
  UpdateContentObject400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getContentObject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ContentObject>(
      {url: `/api/contentObjects/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetContentObjectQueryKey = (id: MaybeRef<string>,) => {
    return ['api','contentObjects',id] as const;
    }

    
export const getGetContentObjectQueryOptions = <TData = Awaited<ReturnType<typeof getContentObject>>, TError = ErrorType<GetContentObject400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentObjectQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentObject>>> = ({ signal }) => getContentObject(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentObject>>, TError, TData> 
}

export type GetContentObjectQueryResult = NonNullable<Awaited<ReturnType<typeof getContentObject>>>
export type GetContentObjectQueryError = ErrorType<GetContentObject400 | ThrowableProblem | ExceptionResponse>



export function useGetContentObject<TData = Awaited<ReturnType<typeof getContentObject>>, TError = ErrorType<GetContentObject400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetContentObjectQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateContentObject = (
    id: MaybeRef<string>,
    contentObject: MaybeRef<NonReadonly<ContentObject>>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
contentObject = unref(contentObject);
      
      return customAxios<ContentObject>(
      {url: `/api/contentObjects/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: contentObject
    },
      options);
    }
  


export const getUpdateContentObjectMutationOptions = <TError = ErrorType<UpdateContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContentObject>>, TError,{id: string;data: NonReadonly<ContentObject>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateContentObject>>, TError,{id: string;data: NonReadonly<ContentObject>}, TContext> => {
    
const mutationKey = ['updateContentObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateContentObject>>, {id: string;data: NonReadonly<ContentObject>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateContentObject(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateContentObjectMutationResult = NonNullable<Awaited<ReturnType<typeof updateContentObject>>>
    export type UpdateContentObjectMutationBody = NonReadonly<ContentObject>
    export type UpdateContentObjectMutationError = ErrorType<UpdateContentObject400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateContentObject = <TError = ErrorType<UpdateContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContentObject>>, TError,{id: string;data: NonReadonly<ContentObject>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateContentObject>>,
        TError,
        {id: string;data: NonReadonly<ContentObject>},
        TContext
      > => {

      const mutationOptions = getUpdateContentObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteContentObject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/contentObjects/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteContentObjectMutationOptions = <TError = ErrorType<DeleteContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentObject>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteContentObject>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteContentObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContentObject>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteContentObject(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteContentObjectMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContentObject>>>
    
    export type DeleteContentObjectMutationError = ErrorType<DeleteContentObject400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteContentObject = <TError = ErrorType<DeleteContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentObject>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteContentObject>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteContentObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listContentObjects = (
    params?: MaybeRef<ListContentObjectsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageContentObject>(
      {url: `/api/contentObjects`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListContentObjectsQueryKey = (params?: MaybeRef<ListContentObjectsParams>,) => {
    return ['api','contentObjects', ...(params ? [params]: [])] as const;
    }

    
export const getListContentObjectsQueryOptions = <TData = Awaited<ReturnType<typeof listContentObjects>>, TError = ErrorType<ListContentObjects400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListContentObjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listContentObjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListContentObjectsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listContentObjects>>> = ({ signal }) => listContentObjects(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listContentObjects>>, TError, TData> 
}

export type ListContentObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof listContentObjects>>>
export type ListContentObjectsQueryError = ErrorType<ListContentObjects400 | ThrowableProblem | ExceptionResponse>



export function useListContentObjects<TData = Awaited<ReturnType<typeof listContentObjects>>, TError = ErrorType<ListContentObjects400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListContentObjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listContentObjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListContentObjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createContentObject = (
    contentObject: MaybeRef<NonReadonly<ContentObject>>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      contentObject = unref(contentObject);
      
      return customAxios<ContentObject>(
      {url: `/api/contentObjects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: contentObject, signal
    },
      options);
    }
  


export const getCreateContentObjectMutationOptions = <TError = ErrorType<CreateContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContentObject>>, TError,{data: NonReadonly<ContentObject>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createContentObject>>, TError,{data: NonReadonly<ContentObject>}, TContext> => {
    
const mutationKey = ['createContentObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContentObject>>, {data: NonReadonly<ContentObject>}> = (props) => {
          const {data} = props ?? {};

          return  createContentObject(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateContentObjectMutationResult = NonNullable<Awaited<ReturnType<typeof createContentObject>>>
    export type CreateContentObjectMutationBody = NonReadonly<ContentObject>
    export type CreateContentObjectMutationError = ErrorType<CreateContentObject400 | ThrowableProblem | ExceptionResponse>

    export const useCreateContentObject = <TError = ErrorType<CreateContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContentObject>>, TError,{data: NonReadonly<ContentObject>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createContentObject>>,
        TError,
        {data: NonReadonly<ContentObject>},
        TContext
      > => {

      const mutationOptions = getCreateContentObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForContentObject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/contentObjects/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForContentObjectQueryKey = (id: MaybeRef<string>,) => {
    return ['api','contentObjects',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForContentObjectQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForContentObject>>, TError = ErrorType<GetChangeSequenceForContentObject400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForContentObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForContentObjectQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForContentObject>>> = ({ signal }) => getChangeSequenceForContentObject(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForContentObject>>, TError, TData> 
}

export type GetChangeSequenceForContentObjectQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForContentObject>>>
export type GetChangeSequenceForContentObjectQueryError = ErrorType<GetChangeSequenceForContentObject400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForContentObject<TData = Awaited<ReturnType<typeof getChangeSequenceForContentObject>>, TError = ErrorType<GetChangeSequenceForContentObject400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForContentObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForContentObjectQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



