<script lang="ts" setup>
import type { PropType } from "vue";
import type { Taxon, Taxonomy } from "~/model";
import type { TaxonValidation } from "~/model/taxonValidation";
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Array as PropType<TaxonValidation[]>,
    required: true,
  },
  taxon: {
    type: Object as PropType<Taxon>,
    required: true,
  },
  taxonomy: {
    type: Object as PropType<Taxonomy>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const validationRules = ref<TaxonValidation[]>(props.modelValue || []);

// Watch for changes in validation rules and emit updates
watch(validationRules, (newValue) => {
  emit("update:modelValue", newValue);
}, { deep: true });

// Add a new validation rule
function addValidationRule() {
  const newRule: TaxonValidation = {
    name: "",
    description: "",
    ruleFormula: "",
    messageFormula: "",
    detailFormula: "",
    overridable: false,
    exceptionId: crypto.randomUUID(),
    supportArticleId: "",
  };
  validationRules.value.push(newRule);
}

// Delete a validation rule
function deleteValidationRule(index: number) {
  validationRules.value.splice(index, 1);
}
</script>

<template>
  <div>
    <div class="mb-1">
      <KodexaArticle article-id="10389368" text="Learn more about validations." :slide="false" />
    </div>

    <KodexaButton class="mt-2 mb-3" type="secondary" size="small" icon="plus" @click="addValidationRule">
      Add Validation
    </KodexaButton>

    <div v-for="(rule, index) in validationRules" :key="index">
      <hr v-if="index > 0" class="my-8 border-t border-gray-200">

      <div class="mb-6">
        <KodexaTextInput
          v-model="rule.name"
          label="Name"
          type="text"
          name="name"
        />
        <KodexaTextArea
          v-model="rule.description"
          label="Description"
          class="mt-2"
          name="description"
          :rows="3"
        />
        <div class="mt-1">
          <KodexaCheckbox
            v-model="rule.conditional"
            label="Is Conditional"
            name="conditional"
          />
        </div>
        <div v-if="rule.conditional" class="mt-1">
          <label class="block text-sm text-gray-700 mb-1">Conditional</label>
          <label class="block text-xs text-gray-500 mb-1">If this condition formula is true we will apply the rule</label>
          <FormulaInput
            v-model="rule.conditionalFormula"
            :taxon="taxon"
            :loading="false"
            :num-rows="5"
            :toggle-available-elements="true"
            :taxonomy="taxonomy"
            name="ruleFormula"
          />
        </div>
        <div class="mt-1">
          <label class="block text-sm text-gray-700 mb-1">Rule</label>
          <label class="block text-xs text-gray-500 mb-1">If this rule formula is false we will create an exception</label>
          <FormulaInput
            v-model="rule.ruleFormula as string"
            :taxon="taxon"
            :loading="false"
            :num-rows="5"
            :toggle-available-elements="true"
            :taxonomy="taxonomy"
            name="ruleFormula"
          />
        </div>
        <div class="mt-1">
          <label class="block text-sm text-gray-700 mb-1">Message</label>
          <label class="block text-xs text-gray-500 mb-1">The result of this formula will be used as the message for the exception</label>
          <FormulaInput
            v-model="rule.messageFormula"
            :taxon="taxon"
            :num-rows="5"
            :toggle-available-elements="true"
            :taxonomy="taxonomy"
            :loading="false"
            name="messageFormula"
          />
        </div>
        <div class="mt-1">
          <label class="block text-sm text-gray-700 mb-1">Detail</label>
          <label class="block text-xs text-gray-500 mb-1">The result of this formula will be used as the details for the exception</label>
          <FormulaInput
            v-model="rule.detailFormula"
            :taxon="taxon"
            :num-rows="5"
            :toggle-available-elements="true"
            :taxonomy="taxonomy"
            :loading="false"
            name="detailFormula"
          />
        </div>
        <KodexaCheckbox
          v-model="rule.overridable"
          label="Overridable"
          name="overridable"
          class="mt-8"
        />
        <KodexaTextInput
          v-model="rule.exceptionId"
          class="mt-2"
          label="Exception ID"
          type="text"
          name="exceptionId"
        />
        <KodexaTextInput
          v-model="rule.supportArticleId"
          class="mt-2"
          label="Support Article ID"
          type="text"
          name="supportArticleId"
        />

        <KodexaButton
          size="small"
          type="danger"
          class="mt-7"
          icon="delete"
          @click="deleteValidationRule(index)"
        >
          Delete Validation Rule
        </KodexaButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border-t {
  border-top-width: 1px;
}

.border-gray-200 {
  border-color: #e5e7eb;
}
</style>
