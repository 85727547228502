<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import VueOfficeExcel from "@vue-office/excel";
import { storeToRefs } from "pinia";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import "@vue-office/excel/lib/index.css";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const useDocumentViewStore = createDocumentViewerStore(props.viewId);
const { nativeDocument } = storeToRefs(useDocumentViewStore);

const excel = computed(() => {
  if (!nativeDocument.value) {
    return null;
  }
  return new Blob([nativeDocument.value]);
});

onMounted(async () => {
  await useDocumentViewStore?.loadNative();
});
</script>

<template>
  <div style="height: calc(100vh - 220px); overflow-y: scroll; width: 100%;">
    <VueOfficeExcel
      v-if="nativeDocument"
      :src="excel"
    />
  </div>
</template>

<style>
</style>
