/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CloudModel,
  CloudModelCompletion,
  CloudModelCompletion400,
  CloudModelRequest,
  CloudModelRequestCapacity400,
  CloudModelResponse,
  CreateCloudModel400,
  DeleteCloudModel400,
  ExceptionResponse,
  GetChangeSequenceForCloudModel400,
  GetCloudModel400,
  ListCloudModels400,
  ListCloudModelsParams,
  PageCloudModel,
  ThrowableProblem,
  UpdateCloudModel400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getCloudModel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<CloudModel>(
      {url: `/api/cloudModels/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCloudModelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','cloudModels',id] as const;
    }

    
export const getGetCloudModelQueryOptions = <TData = Awaited<ReturnType<typeof getCloudModel>>, TError = ErrorType<GetCloudModel400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetCloudModelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudModel>>> = ({ signal }) => getCloudModel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCloudModel>>, TError, TData> 
}

export type GetCloudModelQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudModel>>>
export type GetCloudModelQueryError = ErrorType<GetCloudModel400 | ThrowableProblem | ExceptionResponse>



export function useGetCloudModel<TData = Awaited<ReturnType<typeof getCloudModel>>, TError = ErrorType<GetCloudModel400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetCloudModelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateCloudModel = (
    id: MaybeRef<string>,
    cloudModel: MaybeRef<CloudModel>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
cloudModel = unref(cloudModel);
      
      return customAxios<CloudModel>(
      {url: `/api/cloudModels/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: cloudModel
    },
      options);
    }
  


export const getUpdateCloudModelMutationOptions = <TError = ErrorType<UpdateCloudModel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCloudModel>>, TError,{id: string;data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCloudModel>>, TError,{id: string;data: CloudModel}, TContext> => {
    
const mutationKey = ['updateCloudModel'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCloudModel>>, {id: string;data: CloudModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCloudModel(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCloudModelMutationResult = NonNullable<Awaited<ReturnType<typeof updateCloudModel>>>
    export type UpdateCloudModelMutationBody = CloudModel
    export type UpdateCloudModelMutationError = ErrorType<UpdateCloudModel400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateCloudModel = <TError = ErrorType<UpdateCloudModel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCloudModel>>, TError,{id: string;data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateCloudModel>>,
        TError,
        {id: string;data: CloudModel},
        TContext
      > => {

      const mutationOptions = getUpdateCloudModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteCloudModel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/cloudModels/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteCloudModelMutationOptions = <TError = ErrorType<DeleteCloudModel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCloudModel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCloudModel>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteCloudModel'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCloudModel>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteCloudModel(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCloudModelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCloudModel>>>
    
    export type DeleteCloudModelMutationError = ErrorType<DeleteCloudModel400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteCloudModel = <TError = ErrorType<DeleteCloudModel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCloudModel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteCloudModel>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteCloudModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listCloudModels = (
    params?: MaybeRef<ListCloudModelsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageCloudModel>(
      {url: `/api/cloudModels`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListCloudModelsQueryKey = (params?: MaybeRef<ListCloudModelsParams>,) => {
    return ['api','cloudModels', ...(params ? [params]: [])] as const;
    }

    
export const getListCloudModelsQueryOptions = <TData = Awaited<ReturnType<typeof listCloudModels>>, TError = ErrorType<ListCloudModels400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListCloudModelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCloudModels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListCloudModelsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCloudModels>>> = ({ signal }) => listCloudModels(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCloudModels>>, TError, TData> 
}

export type ListCloudModelsQueryResult = NonNullable<Awaited<ReturnType<typeof listCloudModels>>>
export type ListCloudModelsQueryError = ErrorType<ListCloudModels400 | ThrowableProblem | ExceptionResponse>



export function useListCloudModels<TData = Awaited<ReturnType<typeof listCloudModels>>, TError = ErrorType<ListCloudModels400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListCloudModelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCloudModels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListCloudModelsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createCloudModel = (
    cloudModel: MaybeRef<CloudModel>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      cloudModel = unref(cloudModel);
      
      return customAxios<CloudModel>(
      {url: `/api/cloudModels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cloudModel, signal
    },
      options);
    }
  


export const getCreateCloudModelMutationOptions = <TError = ErrorType<CreateCloudModel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCloudModel>>, TError,{data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createCloudModel>>, TError,{data: CloudModel}, TContext> => {
    
const mutationKey = ['createCloudModel'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCloudModel>>, {data: CloudModel}> = (props) => {
          const {data} = props ?? {};

          return  createCloudModel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCloudModelMutationResult = NonNullable<Awaited<ReturnType<typeof createCloudModel>>>
    export type CreateCloudModelMutationBody = CloudModel
    export type CreateCloudModelMutationError = ErrorType<CreateCloudModel400 | ThrowableProblem | ExceptionResponse>

    export const useCreateCloudModel = <TError = ErrorType<CreateCloudModel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCloudModel>>, TError,{data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createCloudModel>>,
        TError,
        {data: CloudModel},
        TContext
      > => {

      const mutationOptions = getCreateCloudModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const cloudModelRequestCapacity = (
    cloudModelRequest: MaybeRef<CloudModelRequest>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      cloudModelRequest = unref(cloudModelRequest);
      
      return customAxios<CloudModelResponse>(
      {url: `/api/cloudModels/request`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cloudModelRequest, signal
    },
      options);
    }
  


export const getCloudModelRequestCapacityMutationOptions = <TError = ErrorType<CloudModelRequestCapacity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudModelRequestCapacity>>, TError,{data: CloudModelRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudModelRequestCapacity>>, TError,{data: CloudModelRequest}, TContext> => {
    
const mutationKey = ['cloudModelRequestCapacity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudModelRequestCapacity>>, {data: CloudModelRequest}> = (props) => {
          const {data} = props ?? {};

          return  cloudModelRequestCapacity(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CloudModelRequestCapacityMutationResult = NonNullable<Awaited<ReturnType<typeof cloudModelRequestCapacity>>>
    export type CloudModelRequestCapacityMutationBody = CloudModelRequest
    export type CloudModelRequestCapacityMutationError = ErrorType<CloudModelRequestCapacity400 | ThrowableProblem | ExceptionResponse>

    export const useCloudModelRequestCapacity = <TError = ErrorType<CloudModelRequestCapacity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudModelRequestCapacity>>, TError,{data: CloudModelRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof cloudModelRequestCapacity>>,
        TError,
        {data: CloudModelRequest},
        TContext
      > => {

      const mutationOptions = getCloudModelRequestCapacityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const cloudModelCompletion = (
    cloudModelCompletion: MaybeRef<CloudModelCompletion>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      cloudModelCompletion = unref(cloudModelCompletion);
      
      return customAxios<void>(
      {url: `/api/cloudModels/completion`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cloudModelCompletion, signal
    },
      options);
    }
  


export const getCloudModelCompletionMutationOptions = <TError = ErrorType<CloudModelCompletion400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudModelCompletion>>, TError,{data: CloudModelCompletion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudModelCompletion>>, TError,{data: CloudModelCompletion}, TContext> => {
    
const mutationKey = ['cloudModelCompletion'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudModelCompletion>>, {data: CloudModelCompletion}> = (props) => {
          const {data} = props ?? {};

          return  cloudModelCompletion(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CloudModelCompletionMutationResult = NonNullable<Awaited<ReturnType<typeof cloudModelCompletion>>>
    export type CloudModelCompletionMutationBody = CloudModelCompletion
    export type CloudModelCompletionMutationError = ErrorType<CloudModelCompletion400 | ThrowableProblem | ExceptionResponse>

    export const useCloudModelCompletion = <TError = ErrorType<CloudModelCompletion400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudModelCompletion>>, TError,{data: CloudModelCompletion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof cloudModelCompletion>>,
        TError,
        {data: CloudModelCompletion},
        TContext
      > => {

      const mutationOptions = getCloudModelCompletionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForCloudModel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/cloudModels/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForCloudModelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','cloudModels',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForCloudModelQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError = ErrorType<GetChangeSequenceForCloudModel400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForCloudModelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>> = ({ signal }) => getChangeSequenceForCloudModel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError, TData> 
}

export type GetChangeSequenceForCloudModelQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>>
export type GetChangeSequenceForCloudModelQueryError = ErrorType<GetChangeSequenceForCloudModel400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForCloudModel<TData = Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError = ErrorType<GetChangeSequenceForCloudModel400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForCloudModelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



