<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, AssistantConnection } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  modelValue: {
    type: Object as PropType<AssistantConnection>,
    required: false,
  },
  assistant: {
    type: Object as PropType<Assistant>,
    required: true,
  },
});

const {
  documentStores,
  dataStores,
} = storeToRefs(appStore.projectStore);

const connectionTypes = [
  { name: "Workspace", value: "WORKSPACE", resource: false },
  { name: "Store", value: "STORE", resource: true },
  { name: "Channel", value: "CHANNEL", resource: false },
  { name: "Task", value: "TASK", resource: false },
];

const localConnection = computed(() => {
  return props.modelValue;
});

watch(localConnection, (value) => {
  log.info("Local connection changed");
  appStore.projectStore.saveAssistantConnection(value);
}, { deep: true });

const stores = computed(() => {
  return dataStores.value.concat(documentStores.value);
});
</script>

<template>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <div class="flex flex-col w-1/2">
            <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Type</label>
            <KodexaDropDown
              v-model="localConnection.sourceType"
              name="sourceTypeDropdown"
              :items="connectionTypes"
              text-field="name"
              value-field="value"
            />
          </div>
        </div>
        <div v-if="localConnection.sourceType === 'STORE'" class="flex flex-col">
          <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Resource</label>
          <KodexaDropDown
            v-model="localConnection.sourceRef"
            name="sourceRef"
            :items="stores"
            text-field="name"
            value-field="ref"
          />
          <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Subscription</label>
          <KodexaCodeEditor
            v-model="localConnection.subscription"
            style="height: 200px"
            name="subscription"
            type="text"
            autocomplete="source-connection-subscription"
            language="groovy"
          />
          <KodexaArticle article-id="10099723" text="Learn about subscriptions" :slide="false" class="py-2" />
        </div>
        <KodexaButton type="danger" size="small" icon="delete" class="mt-2" @click="appStore.projectStore.removeConnection(localConnection)">
          Delete Connection
        </KodexaButton>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
