import type { LoadingEvent } from "./usePlatform";
import type { DataObject, DocumentStatus, Task, TaskActivity, TaskDocumentFamily, TaskStatus } from "~/model";
import _ from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { updateDocumentFamilyStatus } from "~/api/document-families/document-families";
import { createTaskActivity, listTaskActivities } from "~/api/task-activity/task-activity";
import { listTaskDocumentFamilies } from "~/api/task-document-families/task-document-families";
import { getTask, updateTask } from "~/api/tasks/tasks";
import appStore from "~/store/index";
import { updateHandler } from "~/utils/error-handler";
import { log } from "~/utils/logger";

interface TaskAttribute {
  taxon: string;
  valueType: string;
  metadataKey?: string;
  booleanValue?: boolean;
  value?: string;
}

export const useTask = defineStore("task", () => {
  const currentTask: Ref<Task | undefined> = ref(undefined);
  const originalTask: Ref<Task | undefined> = ref(undefined);
  const taskActivities: Ref<TaskActivity[]> = ref([]);
  const taskDocumentFamilies: Ref<TaskDocumentFamily[]> = ref([]);

  async function loadTask(taskId: string, loadingEvent = {} as LoadingEvent) {
    log.info(`Loading task with ID: ${taskId}`);
    loadingEvent.subtitle = "Loading task details...";
    currentTask.value = await getTask(taskId);
    originalTask.value = { ...currentTask.value };
    // await loadActivities(taskId);
    loadingEvent.subtitle = "Loading task activities...";
    await loadDocumentFamilies(taskId);
  }

  async function updateTaskStatus(status: TaskStatus) {
    if (!currentTask.value) {
      return;
    }

    currentTask.value.status = status;

    await saveChanges();
  }

  async function updateTaskDocumentStatus(status: DocumentStatus) {
    if (!currentTask.value || !taskDocumentFamilies.value.length) {
      return;
    }

    log.info(`Updating document status to ${status.status} for all documents in task ${currentTask.value.id}`);

    // Update status for all document families
    const updatePromises = taskDocumentFamilies.value.map(async (docFamily) => {
      await updateDocumentFamilyStatus(docFamily.id as string, status);
    });

    await Promise.all(updatePromises);

    // Reload document families to get the updated data
    await loadDocumentFamilies(currentTask.value.id as string);
  }

  async function loadDocumentFamilies(taskId: string) {
    log.info(`Loading document families for task with ID: ${taskId}`);
    const taskDocumentFamiliesResponse = await listTaskDocumentFamilies({ filter: `task.id:'${taskId}'` });
    if (taskDocumentFamiliesResponse.content) {
      taskDocumentFamilies.value = taskDocumentFamiliesResponse.content;
    } else {
      taskDocumentFamilies.value = [];
    }
  }

  async function addActivity(activity: TaskActivity) {
    if (!currentTask.value) {
      return;
    }

    activity.task = currentTask.value;

    log.info(`Adding activity for task with ID: ${currentTask.value.id}`);
    await updateHandler(
      createTaskActivity(activity),
      "Activity added successfully",
    );

    await loadActivities(currentTask.value.id as string);
  }

  async function loadActivities(taskId: string) {
    log.info(`Loading task activities for task with ID: ${taskId}`);
    const activityPage = await listTaskActivities({ filter: `task.id:'${taskId}'`, sort: "createdOn:desc", pageSize: 99 });
    if (activityPage.content) {
      taskActivities.value = activityPage.content;
    } else {
      taskActivities.value = [];
    }
  }

  const hasChanges = computed(() => {
    if (!currentTask.value || !originalTask.value) {
      return false;
    }

    const currentValues = {
      title: currentTask.value.title,
      description: currentTask.value.description,
      status: currentTask.value.status,
      dueDate: currentTask.value.dueDate,
      project: currentTask.value.project?.id,
      assignee: currentTask.value.assignee?.id,
    };

    const originalValues = {
      title: originalTask.value.title,
      description: originalTask.value.description,
      status: originalTask.value.status,
      dueDate: originalTask.value.dueDate,
      project: originalTask.value.project?.id,
      assignee: originalTask.value.assignee?.id,
    };

    const taskChanged = !_.isEqual(currentValues, originalValues);

    const { currentWorkspaceId, isDirty } = storeToRefs(appStore.workspaceStore);
    if (currentWorkspaceId.value && isDirty.value) {
      return true;
    } else {
      return taskChanged;
    }
  });

  async function saveChanges(): Promise<void> {
    if (!currentTask.value?.id || !hasChanges.value) {
      return;
    }

    // We need to determine if we have a workspace and if we do we need to save the data objects etc
    const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

    if (currentWorkspaceId.value) {
      await appStore.workspaceStore.saveWorkspaceObjects();
    }

    if (hasChanges.value) {
      // Save the task

      const updatedTask = await updateHandler(updateTask(currentTask.value.id.toString(), currentTask.value), "Task updated successfully");
      if (!updatedTask) {
        return;
      }

      originalTask.value = { ...updatedTask };
      currentTask.value = { ...updatedTask };

      if (currentTask.value) {
        await loadActivities(currentTask.value.id as string);
        await loadDocumentFamilies(currentTask.value.id as string);
      }
    } else {
      await loadDocumentFamilies(currentTask.value.id as string);
    }
  }

  function resetChanges() {
    if (!currentTask.value || !originalTask.value) {
      return;
    }

    currentTask.value = { ...originalTask.value };
  }

  async function updateCurrentTask(task: Task) {
    if (!currentTask.value) {
      return;
    }

    currentTask.value = { ...task };
  }

  async function updateTaskAttributes(attributes: TaskAttribute[]) {
    if (!currentTask.value) {
      return;
    }

    const { dataObjects } = storeToRefs(appStore.workspaceStore);
    if (!dataObjects.value) {
      return;
    }

    attributes.forEach((attribute) => {
      // The data object path is the basename of the attribute.name, so
      // we need to find all the data objects,  then see if they have an
      // attribute that will match the leaf name of the attribute.name

      const attributeBaseName = attribute.taxon.split("/").pop();
      const dataObjectPath = attribute.taxon.split("/").slice(0, -1).join("/");

      log.info(`Processing attribute ${attribute.taxon} with base name ${attributeBaseName} and path name ${dataObjectPath}`);

      // Find all matching data objects from the Map
      const matchingDataObjects: DataObject[] = [];
      dataObjects.value.forEach((obj) => {
        if (obj.path === dataObjectPath) {
          matchingDataObjects.push(obj);
        }
      });

      log.info(`Found ${matchingDataObjects.length} matching data objects`);

      const userEmail = appStore.userStore.user?.email;

      // Determine the value based on valueType
      let value;
      if (attribute.valueType === "metadata" && attribute.metadataKey === "currentUserEmail") {
        value = userEmail;
      } else if (attribute.valueType === "boolean") {
        value = attribute.booleanValue?.toString();
      } else {
        value = attribute.value;
      }

      // Update attributes in all matching data objects
      matchingDataObjects.forEach((dataObject) => {
        // if we don't find an attribute we want to add one
        let attributeFound = false;
        if (dataObject.attributes) {
          dataObject.attributes.forEach((dataObjectAttribute) => {
            if (dataObjectAttribute.tag === attributeBaseName) {
              log.info(`Updating attribute ${attribute.taxon} to data object ${dataObject.path}`);

              // Set the appropriate value based on valueType
              if (attribute.valueType === "metadata" && attribute.metadataKey === "currentUserEmail") {
                dataObjectAttribute.value = userEmail;
                dataObjectAttribute.stringValue = userEmail;
              } else if (attribute.valueType === "boolean") {
                dataObjectAttribute.booleanValue = attribute.booleanValue;
                dataObjectAttribute.stringValue = attribute.booleanValue?.toString();
              } else {
                dataObjectAttribute.value = attribute.value;
                dataObjectAttribute.stringValue = attribute.value;
              }

              attributeFound = true;
            }
          });
        }

        // Add the attribute if it wasn't found
        if (!attributeFound) {
          log.info(`Adding attribute ${attribute.taxon} to data object ${dataObject.path}`);

          const newAttribute: any = {
            uuid: uuidv4(),
            tag: attributeBaseName,
            path: attribute.taxon,
            dataExceptions: [],
            dataObject: {
              id: dataObject.id,
              uuid: dataObject.uuid,
            } as DataObject,
          };

          // Set the appropriate value based on valueType
          if (attribute.valueType === "metadata" && attribute.metadataKey === "currentUserEmail") {
            newAttribute.value = userEmail;
            newAttribute.stringValue = userEmail;
            newAttribute.typeAtCreation = "string";
          } else if (attribute.valueType === "boolean") {
            newAttribute.booleanValue = attribute.booleanValue;
            newAttribute.stringValue = attribute.booleanValue?.toString();
            newAttribute.typeAtCreation = "boolean";
          } else {
            newAttribute.value = attribute.value;
            newAttribute.stringValue = attribute.value;
            newAttribute.typeAtCreation = "string";
          }

          log.info(`Added attribute ${attribute.taxon} to data object ${dataObject.path}`);
          appStore.workspaceStore.addAttribute(dataObject, newAttribute);
        }
      });
    });
  }

  return {
    hasChanges,
    currentTask,
    loadTask,
    saveChanges,
    resetChanges,
    updateCurrentTask,
    taskActivities,
    addActivity,
    taskDocumentFamilies,
    updateTaskStatus,
    updateTaskDocumentStatus,
    updateTaskAttributes,
  };
});
