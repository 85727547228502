<script lang="ts" setup>
import type { PropType } from "vue";
import type { DataFlowNode, Taxonomy } from "~/model";
import { Handle, Position } from "@vue-flow/core";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "selectedStep"]);

const { taxonomies } = storeToRefs(appStore.projectStore);

const taxonomy = computed(() => {
  return taxonomies.value.find((taxonomy: Taxonomy) => props.node && taxonomy.ref === props.node.id?.split("//")[1]);
});
</script>

<template>
  <div class="-mx-2">
    <div class="flex" @click="emit('selected')">
      <div class="flex-none">
        <img
          class="h-16 rounded-md p-1"
          src="/assets/icon-taxonomy.png"
          alt="Data Definition"
        >
      </div>
      <div class="flex-grow ml-3 mr-3">
        <div class="pt-1 text-gray-700 dark:text-gray-300">
          {{ taxonomy?.name || 'Data Definition' }}
        </div>
        <div class="text-xs text-gray-500 dark:text-gray-400">
          Data Definition
        </div>
      </div>
    </div>
  </div>
  <Handle id="resourceConnection" type="source" :position="Position.Top" />
</template>

<style scoped>

</style>
