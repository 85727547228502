<script setup lang="ts">
import { TextArea } from "@progress/kendo-vue-inputs";
import { computed, nextTick, onMounted, ref, toRef, watch } from "vue";
import { log } from "~/utils/logger";

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    default: "",
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  successMessage: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  rows: {
    type: Number,
    default: 3,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "onEnter", "onBlur"]);

const name = toRef(props, "name");
const textareaRef = ref<HTMLTextAreaElement | null>(null);

const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <TextArea
      :id="id || name"
      ref="textareaRef"
      v-model="localValue"
      :disabled="disabled"
      :loading="loading"
      :show-loading-icon="loading"
      :enable="true"
      :placeholder="placeholder"
      class="kodexa-text-area"
      :rows="rows"
      @on-blur="emit('onBlur', $event)"
      @on-enter="emit('onEnter', $event)"
    />
    <p v-if="hint" class="text-sm dark:text-gray-400 text-gray-500">
      {{ hint }}
    </p>

    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600 dark:text-red-400">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block dark:text-gray-300 text-gray-700 mb-1 !important;
}

.kodexa-text-area {
  @apply w-full appearance-none rounded-md border
  dark:border-gray-600 border-gray-300
  dark:bg-gray-800 bg-white
  dark:text-gray-100 text-gray-900
  px-0 py-0
  dark:placeholder:text-gray-500 placeholder:text-gray-400
}

.kodexa-text-area:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
  dark:border-blue-400 dark:ring-blue-400
}

.kodexa-text-area:focus {
  @apply border-blue-500 ring-1 ring-blue-500
  dark:border-blue-400 dark:ring-blue-400
}
</style>
