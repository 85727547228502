<script setup lang="ts">
import type { Ref } from "vue";
import type { Product } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListProducts } from "~/api/product-overview/product-overview";
import { createGridHelper } from "~/store/useGridHelper";

const gridHelper = createGridHelper("product-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProducts(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

const columns = [
  {
    title: "Product Name",
    field: "name",
    cell: "name",
  },
  {
    title: "Description",
    field: "description",
  },
  {
    title: "HasQuantity",
    field: "hasQuantity",
  },
  {
    title: "Product Group",
    field: "productGroup.name",
    cell: "productGroup",
  },
  {
    title: "Price              ",
    field: "price",
  },
  {
    title: "Stripe Price ID",
    field: "priceId",
  },
  {
    title: "isActive",
    field: "active",
  },
];

const showCreateProduct = ref(false);
const selectedProduct: Ref<Product | undefined> = ref(undefined);
const showSelectedProduct = ref(false);
function openNewProduct() {
  showCreateProduct.value = true;
}

function editProduct(product: Product) {
  selectedProduct.value = product;
  showSelectedProduct.value = true;
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <KodexaToolbar>
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <ToolbarSpacer />
        <KodexaButton id="createNewTeam" icon="plus" @click="openNewProduct">
          New Product
        </KodexaButton>
      </KodexaToolbar>
      <Grid
        ref="grid"
        style="height: calc(100vh - 11rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords />
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div class="flex-0 cursor-pointer font-bold text-gray-900" @click="editProduct(props.dataItem)">
                {{ props.dataItem?.name }}
              </div>
            </div>
          </td>
        </template>
        <!-- Warning icon -->
        <template #productGroup="{ props }">
          <td>
            <div class="flex items-center">
              <!-- Warning icon -->
              <div v-if="!props.dataItem.productGroup.active" class="mr-1 text-yellow-400">
                <MaterialDesignIcon v-tooltip="'Product Group is Inactive'" name="warning" size="22" aria-hidden="true" />
              </div>
              {{ props.dataItem.productGroup.name }}
            </div>
          </td>
        </template>
      </Grid>
      <NewProduct v-model="showCreateProduct" @refetch-product="refetch" />
      <EditProduct v-if="showSelectedProduct" v-model="showSelectedProduct" :product="selectedProduct" @refetch-product="refetch" />
    </div>
  </div>
</template>
