<script setup lang="ts">
import type { AssignNextRequest, ProjectStatus, Task, TaskStatus } from "~/model";
import { filterClearIcon, filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { createConfirmDialog } from "vuejs-confirm-dialog";
import { getProjectTaskStatuses } from "~/api/projects/projects";
import { assignNextTask, deleteTask, useListTasks } from "~/api/tasks/tasks";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import KodexaTaskStatus from "~/components/tasks/kodexa-task-status.vue";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import { updateHandler } from "~/utils/error-handler";

const router = useRouter();
const route = useRoute();
const organizationId = computed(() => route.params.organizationId as string);

const { project } = storeToRefs(appStore.projectStore);
const { user, isStudioUser } = storeToRefs(appStore.userStore);

// Define a type for our filter options
interface FilterOption {
  id: string;
  label: string;
  filter: string;
  type: "status" | "assignee";
}

const quickFilterValue = ref<FilterOption | undefined>(undefined);
const STORAGE_KEY_PREFIX = "project-task-filter-";
const ASSIGN_ON_OPEN_KEY_PREFIX = "project-task-assign-on-open-";

const filterOptions = ref<FilterOption[]>([]);
const computedQuery = ref("");
const assignOnOpen = ref(false);

const organizationFilter = computed(() => {
  const baseFilter = `project.id:'${project.value.id}'`;
  return quickFilterValue.value
    ? `${baseFilter} AND ${quickFilterValue.value.filter}`
    : baseFilter;
});

const lowerQuery = computed(() => {
  return computedQuery.value.toLowerCase();
});

const gridHelper = createGridHelper(`project-tasks-${project.value.id}-${crypto.randomUUID()}`, {
  page: 1,
  pageSize: 6,
  sort: "createdOn:desc",
  query: lowerQuery,
}, organizationFilter);

const showNewTask = ref(false);
const selectedTask = ref<Task | null>(null);

const taskStatuses = ref<TaskStatus[]>([]);

const lastProjectId = ref<string | undefined>(undefined);

// Save the selected filter to localStorage
function saveSelectedFilter() {
  if (project.value?.id) {
    const storageKey = `${STORAGE_KEY_PREFIX}${project.value.id}`;
    if (quickFilterValue.value) {
      localStorage.setItem(storageKey, JSON.stringify({
        id: quickFilterValue.value.id,
        type: quickFilterValue.value.type,
        filter: quickFilterValue.value.filter,
        label: quickFilterValue.value.label,
      }));
    } else {
      localStorage.removeItem(storageKey);
    }
  }
}

// Load the selected filter from localStorage
function loadSelectedFilter() {
  if (project.value?.id && filterOptions.value.length > 0) {
    const storageKey = `${STORAGE_KEY_PREFIX}${project.value.id}`;
    const savedFilterJson = localStorage.getItem(storageKey);

    if (savedFilterJson) {
      try {
        const savedFilterData = JSON.parse(savedFilterJson);
        const savedFilter = filterOptions.value.find(
          option => option.id === savedFilterData.id && option.type === savedFilterData.type,
        );
        if (savedFilter) {
          quickFilterValue.value = savedFilter;
        }
      } catch (e) {
        console.error("Error parsing saved filter", e);
        localStorage.removeItem(storageKey);
      }
    }
  }
}

// Build filter options from task statuses and add "My Tasks" option
function buildFilterOptions() {
  const options: FilterOption[] = [];

  // Add "My Tasks" option if user is available
  if (user.value?.id) {
    options.push({
      id: user.value.id,
      label: "My Tasks",
      filter: `assignee.id:'${user.value.id}'`,
      type: "assignee",
    });
  }

  // Add status options
  taskStatuses.value.forEach((status) => {
    options.push({
      id: status.id || "",
      label: status.label,
      filter: `status.id:'${status.id}'`,
      type: "status",
    });
  });

  filterOptions.value = options;
  loadSelectedFilter();
}

// Save assignOnOpen to localStorage
function saveAssignOnOpen() {
  if (project.value?.id) {
    const storageKey = `${ASSIGN_ON_OPEN_KEY_PREFIX}${project.value.id}`;
    localStorage.setItem(storageKey, JSON.stringify(assignOnOpen.value));
  }
}

// Load assignOnOpen from localStorage
function loadAssignOnOpen() {
  if (project.value?.id) {
    const storageKey = `${ASSIGN_ON_OPEN_KEY_PREFIX}${project.value.id}`;
    const savedValue = localStorage.getItem(storageKey);
    if (savedValue !== null) {
      assignOnOpen.value = JSON.parse(savedValue);
    }
  }
}

// Watch for changes to assignOnOpen and save to localStorage
watch(assignOnOpen, () => {
  saveAssignOnOpen();
});

// Load assignOnOpen when project changes
watch(
  () => project.value.id,
  (newProjectId) => {
    if (lastProjectId.value !== newProjectId && newProjectId) {
      getProjectTaskStatuses(newProjectId).then((statuses) => {
        taskStatuses.value = statuses;
        lastProjectId.value = newProjectId;
        buildFilterOptions();
        loadAssignOnOpen();
      });
    }
  },
  { immediate: true },
);

// Rebuild filter options when user changes
watch(
  () => user.value?.id,
  () => {
    if (taskStatuses.value.length > 0) {
      buildFilterOptions();
    }
  },
  { immediate: true },
);

const {
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTasks(gridQuery, {
  query: {
    refetchInterval: computed(() => 5000),
    retry: false,
  },
});

watch(quickFilterValue, async () => {
  saveSelectedFilter();
});

watch(organizationId, async (newOrgId) => {
  if (newOrgId && gridHelper) {
    try {
      // Wait for grid helper to be ready
      await nextTick();
      if (typeof gridHelper.setFilter === "function") {
        gridHelper.setFilter(organizationFilter.value);
        await refetch();
      }
    } catch (error) {
      console.error("Error updating grid filter:", error);
    }
  }
}, { immediate: false });

const columns = [
  {
    title: "Task",
    field: "title",
    cell: "taskCard",
    filter: "text",
  },
  {
    title: "Status",
    field: "status.label",
    cell: "status",
    width: "300px",
  },
  {
    title: "Created",
    field: "createdOn",
    cell: "date",
    filter: "date",
    width: "200px",
  },
  {
    title: "Assignee",
    field: "assignee.searchText",
    cell: "assignee",
    width: "300px",
  },
  {
    title: "Actions",
    field: "description",
    cell: "actions",
    width: "120px",
    filterable: false,
    sortable: false,
  },
];

function editTask(task: Task) {
  if (!task.id) {
    console.error("Cannot edit task without ID");
    return;
  }
  selectedTask.value = task;
  showNewTask.value = true;
}

async function handleTaskUpdated() {
  await refetch();
  selectedTask.value = null;
  await updateHandler(Promise.resolve(), "Task updated successfully");
}

function handleDialogClose() {
  showNewTask.value = false;
  selectedTask.value = null;
}

async function doDeleteTask(task: Task) {
  if (!task?.id) {
    return;
  }

  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete task ${task.title}?`,
    message: "This action cannot be undone. All associated activities will also be deleted.",
  });

  // Then delete the task
  if (!isCanceled) {
    await deleteTask(task.id);
    await refetch();
  }
}

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

async function assignTask(taskToOpen: Task) {
  const request: AssignNextRequest = {
    releaseInMinutes: 10,
    filter: `id:'${taskToOpen.id}' and (assignee is null or assignee.id:'${user.value?.id}')`,
  };
  const task = await updateHandler(assignNextTask(request), "Task assigned successfully");

  // We will navigate to the task
  if (task) {
    router.push({
      path: `/f/o/${organizationId.value}/p/${task.project?.id}/t/${task.id}`,
    });
  } else {
    const noTasksDialog = createConfirmDialog(KodexaConfirm);
    const response = await noTasksDialog.reveal({
      icon: "information-outline",
      title: "Task is already assigned",
      message: "The task is already assigned to another user.",
      confirmText: "Open Task",
      confirmIcon: "check",
      showCancel: true,
    });
    if (!response.isCanceled) {
      router.push({
        path: `/f/o/${organizationId.value}/p/${task.project?.id}/t/${task.id}`,
      });
    }
  }
}

async function assignNextAvailable() {
  const request: AssignNextRequest = {
    releaseInMinutes: 10,
    filter: `project.id:'${project.value.id}' and status.statusType:'TODO' and assignee is null`,
  };
  const task = await updateHandler(assignNextTask(request), "Task assigned successfully");

  // We will navigate to the task
  if (task) {
    router.push({
      path: `/f/o/${organizationId.value}/p/${task.project?.id}/t/${task.id}`,
    });
  } else {
    const noTasksDialog = createConfirmDialog(KodexaConfirm);
    await noTasksDialog.reveal({
      icon: "information-outline",
      title: "No Available Tasks",
      message: "There are no more available tasks to work on.",
      confirmText: "OK",
      confirmIcon: "check",
      showCancel: false,
    });
  }
}

function clearQuickFilter() {
  quickFilterValue.value = undefined;
}
</script>

<template>
  <div>
    <div class="mx-4">
      <KodexaToolbar>
        <label for="query" class="sr-only">Query</label>
        <KodexaTextInput
          id="query"
          v-model="computedQuery"
          :show-clear="true"
          type="text"
          name="query"
          class="-ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder="Filter Tasks"
        />
        <KodexaDropDown
          v-model="quickFilterValue"
          name="quickFilter"
          text-field="label"
          value-field="id"
          :is-object="true"
          :items="filterOptions"
          placeholder="Quick filter..."
          style="width: 200px"
        />
        <Button name="clearQuickFilter" :svg-icon="filterClearIcon" title="Clear" :disabled="!quickFilterValue" @click="clearQuickFilter" />
        <Button
          class="rounded-md bg-white  dark:text-gray-50  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-900"
          :svg-icon="filterIcon"
          title="Filter"
          :togglable="true"
          @click="gridHelper.toggleFilter()"
        />
        <KodexaCheckbox v-model="assignOnOpen" name="assignOnOpen" label="Assign on open" />
        <KodexaArticle class="ml-2 mt-2" article-id="9893941" text="Learn more about tasks" :slide="false" />

        <ToolbarSpacer />
        <KodexaButton id="assignNextAvailable" icon="account-plus-outline" title="Take Next Task" type="cta" @click="assignNextAvailable">
          Take Next Task
        </KodexaButton>
      </KodexaToolbar>
      <Grid
        style="height: calc(100vh - 17rem)"
        :data-items="data?.content || []"
        :pageable="gridHelper.pageSettings"
        :loader="isLoading"
        :sort="gridHelper.sort"
        :skip="gridHelper.pageSettings.skip as number"
        :total="data?.totalElements || 0"
        :page-size="gridHelper.pageSettings.take as number"
        :take="gridHelper.pageSettings.take as number"
        :columns="columns"
        :filterable="gridHelper.showFilter"
        :filter="gridHelper.filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error"
            :is-loading="isLoading"
            :is-error="isError"
            no-records-message="No tasks found"
          />
        </GridNoRecords>
        <template #taskCard="{ props }">
          <td>
            <KodexaTaskCard
              :task="props.dataItem"
              :emit-open="assignOnOpen"
              @open="assignTask(props.dataItem)"
            />
          </td>
        </template>
        <template #status="{ props }">
          <td>
            <KodexaTaskStatus :task="props.dataItem" />
          </td>
        </template>
        <template #date="{ props }">
          <td>
            <KodexaDateTimeView :date="props.dataItem[props.field]" :ago="true" />
          </td>
        </template>
        <template #assignee="{ props }">
          <td>
            <KodexaTaskAssignee :task="props.dataItem" />
          </td>
        </template>
        <template #actions="{ props }">
          <td>
            <div class="flex justify-center space-x-2">
              <KodexaButton
                icon="pencil"
                title="Edit Task"
                size="small"
                @click="editTask(props.dataItem)"
              />
              <KodexaButton
                v-if="isStudioUser"
                icon="delete"
                title="Delete Task"
                size="small"
                type="danger"
                @click="doDeleteTask(props.dataItem)"
              />
            </div>
          </td>
        </template>
      </Grid>
      <NewTaskPopover
        v-if="project"
        v-model="showNewTask"
        :task-to-edit="selectedTask"
        :organization-id="organizationId"
        :project="project"
        @update:task-to-edit="(val) => selectedTask = val"
        @task-created="refetch"
        @task-updated="handleTaskUpdated"
        @update:model-value="handleDialogClose"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(button .icon) {
  margin-right: 0 !important;
}
</style>
