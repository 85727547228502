/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  DataAttribute,
  DataAttributeNormalize,
  DeleteAsset400,
  ExceptionResponse,
  Extract200,
  Extract400,
  ExtractBody,
  ExtractParams,
  ExtractWithExceptions400,
  ExtractWithExceptionsBody,
  ExtractWithExceptionsParams,
  ExtractionDTO,
  GetAsset400,
  GetAssetParams,
  GetConfiguration400,
  GetMarketPlace400,
  GetMarketPlaceParams,
  GetPlatformOverview400,
  GetPlatformStatistics400,
  GetPlatformStatisticsParams,
  GetTerms400,
  Normalize400,
  PageSlugBasedMetadata,
  PlatformConfiguration,
  PlatformOverview,
  PlatformStatistics,
  ResetConfiguration400,
  TermsProperties,
  ThrowableProblem,
  ToggleCleanup400,
  UpdateConfiguration400,
  UploadAsset400,
  UploadAssetBody
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the configuration information for this instance
 */
export const getConfiguration = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<PlatformConfiguration>(
      {url: `/api/configuration`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConfigurationQueryKey = () => {
    return ['api','configuration'] as const;
    }

    
export const getGetConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof getConfiguration>>, TError = ErrorType<GetConfiguration400 | ThrowableProblem | ExceptionResponse>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetConfigurationQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfiguration>>> = ({ signal }) => getConfiguration(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData> 
}

export type GetConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getConfiguration>>>
export type GetConfigurationQueryError = ErrorType<GetConfiguration400 | ThrowableProblem | ExceptionResponse>



export function useGetConfiguration<TData = Awaited<ReturnType<typeof getConfiguration>>, TError = ErrorType<GetConfiguration400 | ThrowableProblem | ExceptionResponse>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetConfigurationQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the configuration information for this instance
 */
export const updateConfiguration = (
    platformConfiguration: MaybeRef<PlatformConfiguration>,
 options?: SecondParameter<typeof customAxios>,) => {
      platformConfiguration = unref(platformConfiguration);
      
      return customAxios<PlatformConfiguration>(
      {url: `/api/configuration`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: platformConfiguration
    },
      options);
    }
  


export const getUpdateConfigurationMutationOptions = <TError = ErrorType<UpdateConfiguration400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{data: PlatformConfiguration}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{data: PlatformConfiguration}, TContext> => {
    
const mutationKey = ['updateConfiguration'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateConfiguration>>, {data: PlatformConfiguration}> = (props) => {
          const {data} = props ?? {};

          return  updateConfiguration(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof updateConfiguration>>>
    export type UpdateConfigurationMutationBody = PlatformConfiguration
    export type UpdateConfigurationMutationError = ErrorType<UpdateConfiguration400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateConfiguration = <TError = ErrorType<UpdateConfiguration400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{data: PlatformConfiguration}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateConfiguration>>,
        TError,
        {data: PlatformConfiguration},
        TContext
      > => {

      const mutationOptions = getUpdateConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Reset the configuration to the defaults
 */
export const resetConfiguration = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<PlatformConfiguration>(
      {url: `/api/configuration/reset`, method: 'PUT'
    },
      options);
    }
  


export const getResetConfigurationMutationOptions = <TError = ErrorType<ResetConfiguration400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetConfiguration>>, TError,void, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof resetConfiguration>>, TError,void, TContext> => {
    
const mutationKey = ['resetConfiguration'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetConfiguration>>, void> = () => {
          

          return  resetConfiguration(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResetConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof resetConfiguration>>>
    
    export type ResetConfigurationMutationError = ErrorType<ResetConfiguration400 | ThrowableProblem | ExceptionResponse>

    export const useResetConfiguration = <TError = ErrorType<ResetConfiguration400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetConfiguration>>, TError,void, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof resetConfiguration>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getResetConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Toggle the cleanup of the platform
 */
export const toggleCleanup = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<boolean>(
      {url: `/api/_toggleCleanup`, method: 'PUT'
    },
      options);
    }
  


export const getToggleCleanupMutationOptions = <TError = ErrorType<ToggleCleanup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof toggleCleanup>>, TError,void, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof toggleCleanup>>, TError,void, TContext> => {
    
const mutationKey = ['toggleCleanup'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof toggleCleanup>>, void> = () => {
          

          return  toggleCleanup(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ToggleCleanupMutationResult = NonNullable<Awaited<ReturnType<typeof toggleCleanup>>>
    
    export type ToggleCleanupMutationError = ErrorType<ToggleCleanup400 | ThrowableProblem | ExceptionResponse>

    export const useToggleCleanup = <TError = ErrorType<ToggleCleanup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof toggleCleanup>>, TError,void, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof toggleCleanup>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getToggleCleanupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Normalizes a data attribute when provided a data attribute and taxon type
 */
export const normalize = (
    dataAttributeNormalize: MaybeRef<DataAttributeNormalize>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      dataAttributeNormalize = unref(dataAttributeNormalize);
      
      return customAxios<DataAttribute>(
      {url: `/api/extractionEngine/normalize`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataAttributeNormalize, signal
    },
      options);
    }
  


export const getNormalizeMutationOptions = <TError = ErrorType<Normalize400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof normalize>>, TError,{data: DataAttributeNormalize}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof normalize>>, TError,{data: DataAttributeNormalize}, TContext> => {
    
const mutationKey = ['normalize'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof normalize>>, {data: DataAttributeNormalize}> = (props) => {
          const {data} = props ?? {};

          return  normalize(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type NormalizeMutationResult = NonNullable<Awaited<ReturnType<typeof normalize>>>
    export type NormalizeMutationBody = DataAttributeNormalize
    export type NormalizeMutationError = ErrorType<Normalize400 | ThrowableProblem | ExceptionResponse>

    export const useNormalize = <TError = ErrorType<Normalize400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof normalize>>, TError,{data: DataAttributeNormalize}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof normalize>>,
        TError,
        {data: DataAttributeNormalize},
        TContext
      > => {

      const mutationOptions = getNormalizeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Extracts data when provided with a taxonomy and a document in an output format
 */
export const extract = (
    extractBody: MaybeRef<ExtractBody>,
    params?: MaybeRef<ExtractParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      extractBody = unref(extractBody);
params = unref(params);
      
      return customAxios<Extract200>(
      {url: `/api/extractionEngine/extract`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: extractBody,
        params: unref(params), signal
    },
      options);
    }
  


export const getExtractMutationOptions = <TError = ErrorType<Extract400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof extract>>, TError,{data: ExtractBody;params?: ExtractParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof extract>>, TError,{data: ExtractBody;params?: ExtractParams}, TContext> => {
    
const mutationKey = ['extract'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof extract>>, {data: ExtractBody;params?: ExtractParams}> = (props) => {
          const {data,params} = props ?? {};

          return  extract(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExtractMutationResult = NonNullable<Awaited<ReturnType<typeof extract>>>
    export type ExtractMutationBody = ExtractBody
    export type ExtractMutationError = ErrorType<Extract400 | ThrowableProblem | ExceptionResponse>

    export const useExtract = <TError = ErrorType<Extract400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof extract>>, TError,{data: ExtractBody;params?: ExtractParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof extract>>,
        TError,
        {data: ExtractBody;params?: ExtractParams},
        TContext
      > => {

      const mutationOptions = getExtractMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Extracts data when provided with a taxonomy and a document (including a structure that holds any content exceptions identified in extraction)
 */
export const extractWithExceptions = (
    extractWithExceptionsBody: MaybeRef<ExtractWithExceptionsBody>,
    params?: MaybeRef<ExtractWithExceptionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      extractWithExceptionsBody = unref(extractWithExceptionsBody);
params = unref(params);
      
      return customAxios<ExtractionDTO>(
      {url: `/api/extractionEngine/extractWithExceptions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: extractWithExceptionsBody,
        params: unref(params), signal
    },
      options);
    }
  


export const getExtractWithExceptionsMutationOptions = <TError = ErrorType<ExtractWithExceptions400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof extractWithExceptions>>, TError,{data: ExtractWithExceptionsBody;params?: ExtractWithExceptionsParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof extractWithExceptions>>, TError,{data: ExtractWithExceptionsBody;params?: ExtractWithExceptionsParams}, TContext> => {
    
const mutationKey = ['extractWithExceptions'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof extractWithExceptions>>, {data: ExtractWithExceptionsBody;params?: ExtractWithExceptionsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  extractWithExceptions(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExtractWithExceptionsMutationResult = NonNullable<Awaited<ReturnType<typeof extractWithExceptions>>>
    export type ExtractWithExceptionsMutationBody = ExtractWithExceptionsBody
    export type ExtractWithExceptionsMutationError = ErrorType<ExtractWithExceptions400 | ThrowableProblem | ExceptionResponse>

    export const useExtractWithExceptions = <TError = ErrorType<ExtractWithExceptions400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof extractWithExceptions>>, TError,{data: ExtractWithExceptionsBody;params?: ExtractWithExceptionsParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof extractWithExceptions>>,
        TError,
        {data: ExtractWithExceptionsBody;params?: ExtractWithExceptionsParams},
        TContext
      > => {

      const mutationOptions = getExtractWithExceptionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAsset = (
    assetType: MaybeRef<string>,
    assetId: MaybeRef<string>,
    params?: MaybeRef<GetAssetParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      assetType = unref(assetType);
assetId = unref(assetId);
params = unref(params);
      
      return customAxios<Blob>(
      {url: `/api/assets/${assetType}/${assetId}`, method: 'GET',
        params: unref(params),
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetAssetQueryKey = (assetType: MaybeRef<string>,
    assetId: MaybeRef<string>,
    params?: MaybeRef<GetAssetParams>,) => {
    return ['api','assets',assetType,assetId, ...(params ? [params]: [])] as const;
    }

    
export const getGetAssetQueryOptions = <TData = Awaited<ReturnType<typeof getAsset>>, TError = ErrorType<GetAsset400 | ThrowableProblem | ExceptionResponse>>(assetType: MaybeRef<string>,
    assetId: MaybeRef<string>,
    params?: MaybeRef<GetAssetParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssetQueryKey(assetType,assetId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAsset>>> = ({ signal }) => getAsset(assetType,assetId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(assetType) && unref(assetId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData> 
}

export type GetAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getAsset>>>
export type GetAssetQueryError = ErrorType<GetAsset400 | ThrowableProblem | ExceptionResponse>



export function useGetAsset<TData = Awaited<ReturnType<typeof getAsset>>, TError = ErrorType<GetAsset400 | ThrowableProblem | ExceptionResponse>>(
 assetType: MaybeRef<string>,
    assetId: MaybeRef<string>,
    params?: MaybeRef<GetAssetParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAssetQueryOptions(assetType,assetId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



export const uploadAsset = (
    assetType: MaybeRef<string>,
    assetId: MaybeRef<string>,
    uploadAssetBody: MaybeRef<UploadAssetBody>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      assetType = unref(assetType);
assetId = unref(assetId);
uploadAssetBody = unref(uploadAssetBody);
      
      return customAxios<void>(
      {url: `/api/assets/${assetType}/${assetId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadAssetBody, signal
    },
      options);
    }
  


export const getUploadAssetMutationOptions = <TError = ErrorType<UploadAsset400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadAsset>>, TError,{assetType: string;assetId: string;data: UploadAssetBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadAsset>>, TError,{assetType: string;assetId: string;data: UploadAssetBody}, TContext> => {
    
const mutationKey = ['uploadAsset'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadAsset>>, {assetType: string;assetId: string;data: UploadAssetBody}> = (props) => {
          const {assetType,assetId,data} = props ?? {};

          return  uploadAsset(assetType,assetId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadAssetMutationResult = NonNullable<Awaited<ReturnType<typeof uploadAsset>>>
    export type UploadAssetMutationBody = UploadAssetBody
    export type UploadAssetMutationError = ErrorType<UploadAsset400 | ThrowableProblem | ExceptionResponse>

    export const useUploadAsset = <TError = ErrorType<UploadAsset400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadAsset>>, TError,{assetType: string;assetId: string;data: UploadAssetBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof uploadAsset>>,
        TError,
        {assetType: string;assetId: string;data: UploadAssetBody},
        TContext
      > => {

      const mutationOptions = getUploadAssetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteAsset = (
    assetType: MaybeRef<string>,
    assetId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      assetType = unref(assetType);
assetId = unref(assetId);
      
      return customAxios<void>(
      {url: `/api/assets/${assetType}/${assetId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssetMutationOptions = <TError = ErrorType<DeleteAsset400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAsset>>, TError,{assetType: string;assetId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAsset>>, TError,{assetType: string;assetId: string}, TContext> => {
    
const mutationKey = ['deleteAsset'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAsset>>, {assetType: string;assetId: string}> = (props) => {
          const {assetType,assetId} = props ?? {};

          return  deleteAsset(assetType,assetId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAsset>>>
    
    export type DeleteAssetMutationError = ErrorType<DeleteAsset400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteAsset = <TError = ErrorType<DeleteAsset400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAsset>>, TError,{assetType: string;assetId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteAsset>>,
        TError,
        {assetType: string;assetId: string},
        TContext
      > => {

      const mutationOptions = getDeleteAssetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the terms and conditions
 */
export const getTerms = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<TermsProperties>(
      {url: `/api/terms`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTermsQueryKey = () => {
    return ['api','terms'] as const;
    }

    
export const getGetTermsQueryOptions = <TData = Awaited<ReturnType<typeof getTerms>>, TError = ErrorType<GetTerms400 | ThrowableProblem | ExceptionResponse>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTerms>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTermsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTerms>>> = ({ signal }) => getTerms(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTerms>>, TError, TData> 
}

export type GetTermsQueryResult = NonNullable<Awaited<ReturnType<typeof getTerms>>>
export type GetTermsQueryError = ErrorType<GetTerms400 | ThrowableProblem | ExceptionResponse>



export function useGetTerms<TData = Awaited<ReturnType<typeof getTerms>>, TError = ErrorType<GetTerms400 | ThrowableProblem | ExceptionResponse>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTerms>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTermsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get Overview
 */
export const getPlatformStatistics = (
    params?: MaybeRef<GetPlatformStatisticsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PlatformStatistics>(
      {url: `/api/statistics`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetPlatformStatisticsQueryKey = (params?: MaybeRef<GetPlatformStatisticsParams>,) => {
    return ['api','statistics', ...(params ? [params]: [])] as const;
    }

    
export const getGetPlatformStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof getPlatformStatistics>>, TError = ErrorType<GetPlatformStatistics400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<GetPlatformStatisticsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformStatistics>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetPlatformStatisticsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformStatistics>>> = ({ signal }) => getPlatformStatistics(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPlatformStatistics>>, TError, TData> 
}

export type GetPlatformStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformStatistics>>>
export type GetPlatformStatisticsQueryError = ErrorType<GetPlatformStatistics400 | ThrowableProblem | ExceptionResponse>



export function useGetPlatformStatistics<TData = Awaited<ReturnType<typeof getPlatformStatistics>>, TError = ErrorType<GetPlatformStatistics400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<GetPlatformStatisticsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformStatistics>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetPlatformStatisticsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get platform overview information
 */
export const getPlatformOverview = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<PlatformOverview>(
      {url: `/api/overview`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPlatformOverviewQueryKey = () => {
    return ['api','overview'] as const;
    }

    
export const getGetPlatformOverviewQueryOptions = <TData = Awaited<ReturnType<typeof getPlatformOverview>>, TError = ErrorType<GetPlatformOverview400 | ThrowableProblem | ExceptionResponse>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformOverview>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetPlatformOverviewQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformOverview>>> = ({ signal }) => getPlatformOverview(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPlatformOverview>>, TError, TData> 
}

export type GetPlatformOverviewQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformOverview>>>
export type GetPlatformOverviewQueryError = ErrorType<GetPlatformOverview400 | ThrowableProblem | ExceptionResponse>



export function useGetPlatformOverview<TData = Awaited<ReturnType<typeof getPlatformOverview>>, TError = ErrorType<GetPlatformOverview400 | ThrowableProblem | ExceptionResponse>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlatformOverview>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetPlatformOverviewQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * List available resources from the marketplace
 */
export const getMarketPlace = (
    params: MaybeRef<GetMarketPlaceParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageSlugBasedMetadata>(
      {url: `/api/marketplace`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetMarketPlaceQueryKey = (params: MaybeRef<GetMarketPlaceParams>,) => {
    return ['api','marketplace', ...(params ? [params]: [])] as const;
    }

    
export const getGetMarketPlaceQueryOptions = <TData = Awaited<ReturnType<typeof getMarketPlace>>, TError = ErrorType<GetMarketPlace400 | ThrowableProblem | ExceptionResponse>>(params: MaybeRef<GetMarketPlaceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketPlace>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetMarketPlaceQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketPlace>>> = ({ signal }) => getMarketPlace(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketPlace>>, TError, TData> 
}

export type GetMarketPlaceQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketPlace>>>
export type GetMarketPlaceQueryError = ErrorType<GetMarketPlace400 | ThrowableProblem | ExceptionResponse>



export function useGetMarketPlace<TData = Awaited<ReturnType<typeof getMarketPlace>>, TError = ErrorType<GetMarketPlace400 | ThrowableProblem | ExceptionResponse>>(
 params: MaybeRef<GetMarketPlaceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketPlace>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetMarketPlaceQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



