<script lang="ts" setup>
import type { DataFormAction, TemplateDataForm } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps<{
  templateForm: TemplateDataForm;
}>();

const emit = defineEmits<{
  "update:templateForm": [value: TemplateDataForm];
  "remove": [];
}>();

const { dataForms } = storeToRefs(appStore.projectStore);

const localValue = computed({
  get: () => props.templateForm,
  set: (value: TemplateDataForm) => {
    emit("update:templateForm", value);
  },
});

function addAction() {
  if (!localValue.value.actions) {
    localValue.value.actions = [];
  }
  localValue.value.actions.push({
    label: "New Action",
    properties: {},
  });
}

function removeAction(action: DataFormAction) {
  if (!localValue.value.actions) {
    return;
  }
  localValue.value.actions = localValue.value.actions.filter(a => a.label !== action.label);
}
</script>

<template>
  <div>
    <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
      <label
        for="dataForm"
        class="block text-sm font-medium text-gray-900 sm:mt-1.5"
      >
        Data Form
      </label>
      <div class="sm:col-span-2">
        <KodexaDropDown
          v-model="localValue.dataFormRef"
          name="dataForm"
          :items="dataForms"
          text-field="name"
          value-field="ref"
          placeholder="Select data form..."
          :is-object="false"
        />
        <div>
          <KodexaButton icon="plus" size="small" type="primary" @click="addAction">
            Add Action
          </KodexaButton>
          <div>
            <ProjectTaskTemplateAction v-for="action in localValue.actions" :key="action.id" :data-form-action="action" @remove="removeAction(action)" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <KodexaButton
        icon="delete" size="small" type="danger"
        @click="emit('remove')"
      >
        Remove Form
      </KodexaButton>
    </div>
  </div>
</template>

<style scoped>

</style>
