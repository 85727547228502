<script setup lang="ts">
import { ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";

const {
  project,
} = storeToRefs(appStore.projectStore);

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
const editID = ref(null);

const localProject = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

const documentStatuses = computed({
  get: () => {
    return localProject.value?.documentStatuses || [];
  },
  set: (value) => {
    if (localProject.value) {
      localProject.value.documentStatuses = value;
    }
  },
});

const columns = ref([
  {
    field: "status",
    title: "Label",
    cell: "status",
  },
  {
    field: "color",
    title: "Color",
    cell: "color",
  },
  {
    field: "icon",
    title: "Icon",
    cell: "icon",
  },
  {
    title: " ",
    field: "actions",
    cell: "actions",
    width: 200,
  },
]);

function addNewStatus() {
  documentStatuses.value = [...documentStatuses.value, {
    status: "New Status",
    color: "#000000",
    icon: "flag",
  }];
  editID.value = Date.now();
}

async function removeStatus(statusToRemove) {
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: "Delete Status",
    message: "Are you sure you want to delete this status?",
  });

  if (!isCanceled) {
    documentStatuses.value = documentStatuses.value.filter(status => status.id !== statusToRemove.id);
  }
}

function onEdit(event) {
  editID.value = event.dataItem.id;
}

function onCancel() {
  editID.value = null;
}

function onSave() {
  editID.value = null;
}
</script>

<template>
  <div>
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 ">
      <div class="space-y-6 sm:px-6 lg:col-span-12 lg:px-0">
        <div>
          <div class="space-y-6 bg-white dark:bg-gray-800 px-4 py-6 sm:p-6" style="height: calc(100vh - 250px)">
            <KodexaToolbar>
              <KodexaArticle article-id="9787707" text="Learn more about task status" :slide="false" />
              <ToolbarSpacer />
              <KodexaButton id="createNewNotification" icon="plus" @click="addNewStatus">
                Add Document Status
              </KodexaButton>
            </KodexaToolbar>

            <Grid
              :data-items="documentStatuses"
              :columns="columns"
              edit-field="inEdit"
            >
              <template #status="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <KodexaTextInput
                        v-model="props.dataItem.status"
                        name="status"
                      />
                    </div>
                    <div v-else>
                      {{ props.dataItem.status }}
                    </div>
                  </div>
                </td>
              </template>

              <template #color="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <KodexaColorSelector
                        v-model="props.dataItem.color"
                        name="color"
                      />
                    </div>
                    <div v-else>
                      <div class="w-6 h-6 rounded" :style="{ backgroundColor: props.dataItem.color }" />
                    </div>
                  </div>
                </td>
              </template>

              <template #icon="{ props }">
                <td>
                  <div>
                    <div v-if="editID === props.dataItem.id">
                      <KodexaTextInput
                        v-model="props.dataItem.icon"
                        name="icon"
                      />
                    </div>
                    <div v-else>
                      <i :class="props.dataItem.icon" />
                    </div>
                  </div>
                </td>
              </template>

              <template #actions="{ props }">
                <td>
                  <div v-if="editID === props.dataItem.id">
                    <KodexaButton
                      type="primary" icon="content-save" size="small" class="mr-1"
                      @click="onSave"
                    />
                    <KodexaButton
                      type="secondary" icon="close" size="small"
                      class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      @click="onCancel"
                    />
                  </div>
                  <div v-else>
                    <KodexaButton
                      type="primary" icon="pencil" size="small" class="mr-1"
                      @click="onEdit({ dataItem: props.dataItem })"
                    />
                    <KodexaButton
                      type="danger" icon="delete" size="small"
                      @click="removeStatus(props.dataItem)"
                    />
                  </div>
                </td>
              </template>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
