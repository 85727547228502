<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { storeToRefs } from "pinia";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);
const { project } = storeToRefs(appStore.projectStore);

const {
  isShown,
  collapsed,
  type,
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div v-if="isShown">
    <KodexaDropDown
      v-model="localValue"
      name="documentStatusOption"
      text-field="status"
      value-field="id"
      :items="project.documentStatuses"
    />
  </div>
</template>
