<script setup lang="ts">
import type { Ref } from "vue";
import type { ProductGroup } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListProductGroups } from "~/api/product-groups/product-groups";
import { createGridHelper } from "~/store/useGridHelper";

const gridHelper = createGridHelper("product-group", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProductGroups(gridQuery.value);

const columns = [
  {
    title: "Product Group Name",
    field: "name",
    cell: "name",
  },
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Overview Markdown",
    field: "overviewMarkdown",
  },
  {
    title: "isActive",
    field: "isActive",
  },
];

const showCreateProductGroup = ref(false);
const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

const selectedProductGroup: Ref<ProductGroup | undefined> = ref(undefined);
const showEditProductGroup = ref(false);

function openNewProduct() {
  showCreateProductGroup.value = true;
}

function editProductGroup(productGroup: ProductGroup) {
  selectedProductGroup.value = productGroup;
  showEditProductGroup.value = true;
}

function shouldShowWarningIcon(dataItem) {
  return dataItem.products === "This product group does not have any Product.";
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <KodexaToolbar>
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <KodexaTextInput
          id="query" v-model="computedQuery" type="text" name="query" :show-clear="true"
          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Search product group"
        />
        <ToolbarSpacer />
        <KodexaButton id="createProductGroup" icon="plus" @click="openNewProduct">
          New Product Group
        </KodexaButton>
      </KodexaToolbar>
      <Grid
        ref="grid"
        style="height: calc(100vh - 11rem);"
        :style="{ height: '520px' }"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <!-- Define custom cell template for the "Product(s)" column -->
        <template #productsCell="{ props }">
          <td>
            <div class="flex items-center">
              <!-- Warning icon -->
              <div class="mr-1 text-yellow-400">
                <MaterialDesignIcon v-if="shouldShowWarningIcon(props.dataItem)" name="warning" size="22" aria-hidden="true" />
              </div>
              {{ props.dataItem.products }}
            </div>
          </td>
        </template>
        <GridNoRecords>
          <KodexaGridNoRecords />
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div class="flex-0 cursor-pointer font-bold text-gray-900" @click="editProductGroup(props.dataItem)">
                {{ props.dataItem?.name }}
              </div>
            </div>
          </td>
        </template>
      </Grid>
      <NewProductGroup v-model="showCreateProductGroup" @refetch-product-group="refetch" />
      <EditProductGroup v-if="showEditProductGroup" v-model="showEditProductGroup" :product-group="selectedProductGroup" @refetch-product-group="refetch" />
    </div>
  </div>
</template>

<style>
.k-grid-header col:nth-of-type(5){
  width: 7%;
}
.k-grid-table col:nth-of-type(5){
  width: 7%;
}
</style>
