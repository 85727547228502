<script lang="ts" setup>
</script>

<template>
  <div style="width: 100%">
    <div class="flex grid-cols-1 justify-center">
      <div>
        <div class="mt-40 flex items-center justify-center mb-4" style="height: calc(100vh - 40rem)">
          <div class="text-center">
            <MaterialDesignIcon aria-hidden="true" class="size-12 text-gray-400" name="gesture-tap-button" />
            <h2 class="text-base font-semibold leading-6 text-gray-900">
              Nothing Focused
            </h2>
            <p class="mt-4 px-10 text-sm text-gray-500">
              Select an attribute to view the source document
            </p>
            <KodexaArticle class="mt-8 justify-center" article-id="9034579" text="Learn more about workspace sidecars" :slide="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
