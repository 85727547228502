import type { DurationObjectUnits } from "luxon";
import { format, isValid, parse } from "date-fns";
import { DateTime, Duration } from "luxon";

export function toHumanDuration(start: DateTime, end: DateTime): string {
  // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
  const duration = end
    .diff(start)
    .shiftTo("days", "hours", "minutes", "seconds")
    .toObject();

  if ("seconds" in duration && duration.seconds) {
    duration.seconds = Math.round(duration.seconds);
  }

  const cleanedDuration = Object.fromEntries(
    Object.entries(duration).filter(([_k, v]) => v !== 0),
  ) as DurationObjectUnits;

  if (Object.keys(cleanedDuration).length === 0) {
    cleanedDuration.seconds = 0;
  }

  return Duration.fromObject(cleanedDuration).toHuman();
}

export function parseLocalDate(dateString: string | Date): DateTime {
  // if the date is a date object, convert it to a string
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let stringDate;
  if (dateString instanceof Date) {
    stringDate = dateString.toISOString();
  } else {
    stringDate = dateString;
  }
  // take the input format 2023-05-01T00:00:00.000Z and build a date object
  const dateObject = {
    year: Number.parseInt(stringDate.substring(0, 4)),
    month: Number.parseInt(stringDate.substring(5, 7)),
    day: Number.parseInt(stringDate.substring(8, 10)),
  };

  return DateTime.fromObject(dateObject, { zone: timeZone });
}

export function parseLocalDateTime(dateString: string | Date): DateTime {
  let stringDate;
  if (dateString instanceof Date) {
    stringDate = dateString.toISOString();
  } else {
    stringDate = dateString;
  }
  // take the input format 2023-05-01T00:00:00.000Z and build a date object
  const dateObject = {
    year: Number.parseInt(stringDate.substring(0, 4)),
    month: Number.parseInt(stringDate.substring(5, 7)),
    day: Number.parseInt(stringDate.substring(8, 10)),
    hour: Number.parseInt(stringDate.substring(11, 13)),
    minute: Number.parseInt(stringDate.substring(14, 16)),
    second: Number.parseInt(stringDate.substring(17, 19)),
  };

  return DateTime.fromObject(dateObject, { zone: "utc" });
}

export function parseDateMath(input: string): Date {
  let baseDateTime = DateTime.now();

  // Handle specific starting points like "last Monday" or "next Tuesday"
  const startDayPattern = /(last|next)\s+(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)/;
  const startDayMatch = input.match(startDayPattern);

  if (startDayMatch) {
    const direction = startDayMatch[1].toLowerCase();
    const dayOfWeek = startDayMatch[2].toUpperCase();

    baseDateTime = calculateStartPoint(direction, dayOfWeek) as DateTime<true>;
    input = input.substring(startDayMatch[0].length).trim(); // Remove the matched part
  } else if (input.startsWith("today")) {
    baseDateTime = DateTime.fromObject({}, { zone: "local" }).startOf("day") as DateTime<true>;
    input = input.substring(5).trim();
  } else if (input.startsWith("now")) {
    baseDateTime = DateTime.now();
    input = input.substring(3).trim();
  } else {
    throw new Error("Invalid input: Must start with 'today', 'now', 'last <day>' or 'next <day>'");
  }

  // Handle time adjustments like "+ 3 hours - 2 days"
  const adjustmentPattern = /[+-](\s*\d+\s*(day|hour|minute|second|month|week|year)s?)/g;
  let adjustmentMatch = adjustmentPattern.exec(input);
  while (adjustmentMatch !== null) {
    const operator = input[adjustmentMatch.index];
    const parts = adjustmentMatch[1].trim().split(/\s+/);
    const value = Number.parseInt(parts[0]) * (operator === "-" ? 1 : -1); // Invert for minus() call
    const unit = parts[1].toLowerCase().replace(/s$/, ""); // Remove potential 's' at end

    const adjustmentObj: { [key: string]: number } = {};
    switch (unit) {
      case "day":
        adjustmentObj.days = value;
        break;
      case "hour":
        adjustmentObj.hours = value;
        break;
      case "minute":
        adjustmentObj.minutes = value;
        break;
      case "second":
        adjustmentObj.seconds = value;
        break;
      case "week":
        adjustmentObj.weeks = value;
        break;
      case "month":
        adjustmentObj.months = value;
        break;
      case "year":
        adjustmentObj.years = value;
        break;
      default:
        throw new Error(`Invalid unit: ${unit}`);
    }

    baseDateTime = baseDateTime.minus(adjustmentObj);
    adjustmentMatch = adjustmentPattern.exec(input);
  }

  return baseDateTime.toJSDate();
}

function calculateStartPoint(direction: string, targetDay: string): DateTime {
  const today = DateTime.now();
  let result: DateTime;

  if (direction === "last") {
    result = today.minus({ days: ((today.weekday - DateTime.fromFormat(targetDay, "EEEE").weekday + 7) % 7) || 7 });
  } else if (direction === "next") {
    result = today.plus({ days: ((DateTime.fromFormat(targetDay, "EEEE").weekday - today.weekday + 7) % 7) || 7 });
  } else {
    throw new Error(`Invalid direction: ${direction}`);
  }

  return result.startOf("day");
}

export function parseAnyDate(dateString: string): Date {
  // Common date formats to try
  const formats = [
    "yyyy-MM-dd", // ISO: 2023-04-15
    "MM/dd/yyyy", // US: 04/15/2023
    "dd/MM/yyyy", // EU: 15/04/2023
    "MMMM d, yyyy", // Long: April 15, 2023
    "MMM d, yyyy", // Medium: Apr 15, 2023
    "yyyy-MM-dd HH:mm:ss", // ISO with time: 2023-04-15 14:30:45
    "MM/dd/yyyy HH:mm:ss", // US with time
    "dd/MM/yyyy HH:mm:ss", // EU with time
    "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", // ISO 8601 with timezone
  ];

  // Try each format until we find a valid date
  for (const formatString of formats) {
    const parsedDate = parse(dateString, formatString, new Date());
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  // If we reach here, try a last resort approach with Date constructor
  const fallbackDate = new Date(dateString);
  if (isValid(fallbackDate)) {
    return fallbackDate;
  }

  // Return null if all parsing attempts fail
  return null;
}
