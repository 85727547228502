export const optionMetadata = {
  label: "Attribute Status",
  description: "Select an attribute status from the project",
  options: [
    {
      name: "status",
      label: "Status",
      type: "string",
      description: "The status to display",
    },
    {
      name: "clearable",
      label: "Allow Clear",
      type: "boolean",
      description: "Allow clearing the selected status",
      default: true,
    },
  ],
};
