<script lang="ts" setup>
import type { PropType } from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
  nodeColor: {
    type: String,
    default: "#23ABDC",
  },
  textColor: {
    type: String,
    default: "white",
  },
});

function openThinkingResponse() {
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} Response`, props.node.data.raw[props.keyName]);
}
</script>

<template>
  <KodexaButton
    v-tooltip="`Open Thinking Response`"
    class="open-thinking-response"
    name="openThinkingResponse"
    size="medium"
    icon="message-reply-text-outline"
    @click="openThinkingResponse"
  />
</template>

<style scoped>
.open-thinking-response {
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: white !important;
  color: v-bind(nodeColor) !important;
  border-radius: 100px;
  border: 1px solid v-bind(nodeColor);
}

.open-thinking-response:hover {
  opacity: 0.8;
}
</style>
