<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { Card, DataObject, Taxon } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store";

defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

defineEmits(["addCard", "selectCard", "removeCard"]);

const { contentTaxonomies } = storeToRefs(appStore.projectStore);

const rootTaxons = computed(() => {
  // We want to get all the root taxons from the content taxonomies
  const rootTaxons: Taxon[] = [];
  if (!contentTaxonomies.value) {
    return [];
  }

  contentTaxonomies.value.forEach((taxonomy) => {
    // add all the taxons
    taxonomy.taxons.forEach((taxon) => {
      rootTaxons.push(taxon);
    });
  });

  return rootTaxons;
});

const tabs = computed(() => {
  return rootTaxons.value
    .filter((taxon: Taxon) => taxon.enabled)
    .map((taxon: Taxon) => {
      return {
        id: taxon.id,
        ref: taxon.id,
        name: taxon.label,
        content: "tabContent",
        titleRender: "titleRender",
        taxon,
      };
    });
});

const currentTab: Ref<any | undefined> = ref(tabs.value[0]);

function onSelect(e: any) {
  currentTab.value = e;
}

function getCard(id: string): Card | undefined {
  const taxon = rootTaxons.value.find((taxon) => {
    return taxon.id === id;
  });

  if (!taxon) {
    return;
  }

  return {
    id: uuidv4() as string,
    type: "transposedGrid",
    properties: {
      attributeLabel: taxon.label, // Changed from title to attributeLabel
      groupTaxon: taxon.name, // Changed from groupTaxon to taxon
    },
    children: [],
  } as Card;
}

const { sidecarPanelOpen } = storeToRefs(appStore.workspaceStore);

function toggleSidecar() {
  appStore.workspaceStore.toggleSidecar();
}
</script>

<template>
  <div class="w-full">
    <KodexaTabStrip :selected="currentTab" :items="tabs" class="-mt-1 dark:bg-gray-800" @selected="onSelect" />
    <div class="w-full">
      <KodexaToolbar>
        <KodexaButton :icon="sidecarPanelOpen ? 'close' : 'dock-right'" size="small" :title="sidecarPanelOpen ? 'Close Sidecar' : 'Open Sidecar'" type="secondary" class="dark:text-white dark:hover:bg-gray-600" @click="toggleSidecar">
          {{ sidecarPanelOpen ? 'Close' : 'Open' }} Sidecar
        </KodexaButton>
        <KodexaFormCardTransposedGrid
          class="w-full dark:bg-gray-800"
          :card="getCard(currentTab.id)"
          :parent-data-object="parentDataObject"
          :parent-tag-metadata="parentTagMetadata"
          :view-id="viewId"
        />
      </kodexatoolbar>
    </div>
  </div>
</template>

<style scoped>
</style>
