<script setup lang="ts">
import type {
  DocumentFamily,
  NewTaskRequest,
  Project,
  Task,
  TaskStatus,
  TaskTemplate,
  User,
} from "~/model";
import { Dialog, DialogPanel, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useListMemberships } from "~/api/memberships/memberships";
import { createTaskWithRequest, updateTask } from "~/api/tasks/tasks";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

interface Props {
  modelValue: boolean;
  documentFamilies?: DocumentFamily[];
  taskToEdit?: Task | undefined;
  project?: Project | undefined;
  documentFamily?: any;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:modelValue": [value: boolean];
  "taskCreated": [];
  "taskUpdated": [];
  "update:taskToEdit": [value: Task | undefined];
}>();

const { currentOrganization } = storeToRefs(appStore.organizationStore);
const formatUserName = (user: User): string => `${user.firstName} ${user.lastName}`;

const isExpanded = ref(false);
const newTask = ref<Task>({
  title: "",
  project: props.project as Project,
  description: "",
  status: undefined,
  dueDate: undefined,
});

const { data: memberships } = useListMemberships({
  page: 1,
  pageSize: 100,
  filter: `organization.id:'${currentOrganization.value?.id}'`,
  sort: "",
});

const assigneeOptions = computed(() =>
  memberships.value?.content?.map(m => ({
    id: m.user?.id,
    name: formatUserName(m.user as User),
    email: m.user?.email,
    user: m.user,
    text: formatUserName(m.user as User),
    avatarUrl: m.user?.avatarUrl,
  })) ?? [],
);

const selectedAssigneeId = computed({
  get: () => newTask.value.assignee?.id ?? "",
  set: (value: string) => {
    newTask.value.assignee = assigneeOptions.value.find(option => option.id === value)?.user ?? undefined;
  },
});

const statusOptions = computed(() => {
  return props.project?.taskStatuses ?? [];
});

const selectedStatus = computed({
  get: () => newTask.value?.status?.id ?? "",
  set: (value: string) => {
    if (newTask.value) {
      if (value && newTask.value.project) {
        const statusExists = statusOptions.value.some(status => status.id === value);
        if (statusExists) {
          newTask.value.status = { id: value } as TaskStatus;
        }
      } else {
        newTask.value.status = undefined;
      }
    }
  },
});

const today = new Date();

const taskDueDate = computed({
  get: () => newTask.value.dueDate ? new Date(newTask.value.dueDate) : undefined,
  set: (value: Date | null) => {
    newTask.value.dueDate = value?.toISOString() || undefined;
  },
});

const taskTemplates = asyncComputed(() => {
  return appStore.projectStore.fetchTaskTemplates();
});

const selectedTaskTemplate: Ref<TaskTemplate | undefined> = ref(undefined);

watch(selectedTaskTemplate, (template: TaskTemplate) => {
  if (!template) {
    newTask.value.template = undefined;
    return;
  }

  if (!newTask.value.title || newTask.value.title === "") {
    newTask.value.title = template.title;
  }

  newTask.value.template = template;
});

watch(() => props.modelValue, (value) => {
  if (value && props.taskToEdit) {
    newTask.value = {
      ...JSON.parse(JSON.stringify(props.taskToEdit)),
      changeSequence: props.taskToEdit.changeSequence,
      assignee: props.taskToEdit.assignee,
    };
  } else {
    newTask.value = {
      title: "",
      description: "",
      project: props.project as Project,
      assignee: undefined,
      status: undefined,
      dueDate: undefined,
      template: selectedTaskTemplate.value,
    };
  }
}, { immediate: true });

watch(() => props.modelValue, (value) => {
  if (!value) {
    isExpanded.value = false;
  }
});

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
}

function close() {
  isExpanded.value = false;
  emit("update:modelValue", false);
  emit("update:taskToEdit", undefined);
}

const saving = ref(false);

async function save() {
  saving.value = true;

  if (!newTask.value.title?.trim() || !newTask.value.project) {
    return;
  }

  try {
    if (props.taskToEdit?.id) {
      const taskId = props.taskToEdit.id.toString();
      await updateHandler(updateTask(taskId, {
        ...newTask.value,
        changeSequence: props.taskToEdit.changeSequence,
      }), "Task updated successfully");
      emit("taskUpdated");
    } else {
      const newTaskRequest = {
        task: newTask.value,
        documentFamilies: props.documentFamilies,
      } as NewTaskRequest;

      await updateHandler(createTaskWithRequest(newTaskRequest), "Task created successfully");
      emit("taskCreated");
    }
    close();
  } catch (error) {
    console.error("Error saving task:", error);
  } finally {
    saving.value = false;
  }
}
</script>

<template>
  <TransitionRoot appear as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0 bg-gray-50/75 dark:bg-gray-900/75 transition-opacity duration-300" />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center p-4 text-center sm:p-0">
          <DialogPanel
            :class="`
              relative w-full transform overflow-visible rounded-lg bg-white dark:bg-gray-800
              text-left shadow-xl transition-all duration-300 ease-in-out z-50
              ${isExpanded ? 'sm:max-w-[65vw] sm:h-[90vh] sm:mt-8' : 'sm:max-w-4xl sm:mt-48'}
            `"
          >
            <div class="flex flex-col h-full">
              <!-- Header -->
              <div class="flex items-center justify-between px-4 py-3">
                <span class="px-2 py-1 rounded-md border-2 border-gray-100 text-sm font-bold">
                  {{ taskToEdit ? 'Update Task' : 'New Task' }}
                </span>
                <div class="flex items-center space-x-4">
                  <button
                    class="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                    @click="toggleExpand"
                  >
                    <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                      />
                    </svg>
                  </button>
                  <button
                    class="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                    @click="close"
                  >
                    <span class="sr-only">Close</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <!-- Content -->
              <div class="flex-grow px-4 py-4 h-96">
                <!-- Task Template Dropdown -->
                <KodexaDropDown
                  v-if="!taskToEdit && taskTemplates?.length"
                  v-model="selectedTaskTemplate"
                  name="taskTemplate"
                  :items="taskTemplates"
                  value-field="id"
                  :is-object="true"
                  text-field="name"
                  placeholder="Select Task Template"
                  class="mb-4"
                />

                <KodexaTextInput
                  v-model="newTask.title"
                  :borderless="true"
                  font-size="xl"
                  font-weight="bold"
                  name="task-title"
                  class="w-full bg-transparent text-gray-900 placeholder-gray-400 border-none focus:ring-0 focus:outline-none"
                  placeholder="Add title..."
                />
                <textarea
                  v-model="newTask.description"
                  class="w-full h-5/6 mt-4 bg-transparent text-gray-700 placeholder-gray-400 border-none focus:ring-0 focus:outline-none resize-none"
                  placeholder="Add description..."
                />
              </div>

              <!-- Dropdowns -->
              <div class="px-4 py-4">
                <div class="flex flex-wrap gap-2">
                  <!-- Assignee Dropdown -->
                  <div :class="[selectedAssigneeId ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="assignee"
                      v-model="selectedAssigneeId"
                      v-tooltip="selectedAssigneeId ? `${assigneeOptions.find(a => a.id === selectedAssigneeId)?.name} (${assigneeOptions.find(a => a.id === selectedAssigneeId)?.email})` : ''"
                      name="assignee"
                      :items="assigneeOptions"
                      value-field="id"
                      text-field="text"
                      placeholder="Assignee"
                      class="mt-1"
                    >
                      <template #item-render="{ item }">
                        <div class="flex items-center gap-2">
                          <KodexaUserAvatar
                            :user="item.user"
                            size="6"
                            class="flex-shrink-0"
                          />
                          <div class="flex flex-col overflow-hidden">
                            <span class="font-medium" :title="item.name">{{ item.name }}</span>
                            <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
                          </div>
                        </div>
                      </template>
                      <template #value-render="{ item }">
                        <div class="flex items-center gap-2">
                          <KodexaUserAvatar
                            v-if="item?.user"
                            :user="item.user"
                            size="6"
                            class="flex-shrink-0"
                          />
                          <span class="font-medium truncate" :title="item?.name">{{ item?.name }}</span>
                        </div>
                      </template>
                    </KodexaDropDown>
                  </div>

                  <!-- Status Dropdown -->
                  <div :class="[selectedStatus ? 'w-fit max-w-64' : 'w-52']">
                    <KodexaDropDown
                      id="status"
                      v-model="selectedStatus"
                      name="status"
                      :items="statusOptions"
                      value-field="id"
                      text-field="label"
                      placeholder="Status"
                      class="mt-1"
                    />
                  </div>

                  <!-- Due Date Picker -->
                  <div class="w-52">
                    <KodexaDatePicker
                      v-model="taskDueDate"
                      name="dueDate"
                      :min="today"
                      class="w-full date-picker-custom mt-1"
                      :clearable="true"
                      placeholder="Set Due Date"
                    />
                  </div>
                </div>
              </div>

              <!-- Footer -->
              <div class="flex items-center justify-end px-4 py-3 border-t border-gray-200">
                <KodexaButton
                  type="primary"
                  class="ml-2"
                  :loading="saving"
                  :disabled="!newTask.project || !newTask.title?.trim()"
                  :icon="taskToEdit ? 'content-save' : 'auto-fix'"
                  @click="save"
                >
                  {{ taskToEdit ? 'Save Changes' : 'Create Task' }}
                </KodexaButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
:deep(.k-button) {
  --tw-ring-offset-shadow: 0 0 #0000 !important;
  --tw-ring-shadow: 0 0 #0000 !important;
}
</style>
