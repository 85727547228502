<script lang="ts" setup>
import type { AddPanelOptions, DockviewApi, DockviewReadyEvent, IDockviewPanel } from "dockview-vue";
import { useDark } from "@vueuse/core";
import { DockviewVue } from "dockview-vue";
import { storeToRefs } from "pinia";
import { getCurrentInstance } from "vue";
import KodexaDataFormEditor from "~/components/dataForm/kodexa-data-form-editor.vue";
import KodexaDataFormView from "~/components/dataForm/kodexa-data-form-view.vue";
import KodexaDocumentView from "~/components/document/kodexa-document-view.vue";
import KodexaCostAnalysis from "~/components/execution/kodexa-cost-analysis.vue";
import KodexaStepGraph from "~/components/execution/kodexa-step-graph.vue";
import KodexaTextView from "~/components/text/kodexa-text-view.vue";
import appStore from "~/store";
import { log } from "~/utils/logger";

const dockviewApi: Ref<DockviewApi | undefined> = ref(undefined);
const app = getCurrentInstance()?.appContext;

const {
  views,
  activeView,
} = storeToRefs(appStore.workspaceStore);

watch(activeView, () => {
  if (dockviewApi.value) {
    dockviewApi.value?.panels.forEach((panel) => {
      if (panel.id === activeView.value?.id) {
        panel.focus();
      }
    });
  }
}, {
  immediate: true,
  deep: true,
});

function syncViews() {
  if (!dockviewApi.value) {
    return;
  }

  // Remove panels that aren't in views
  dockviewApi.value.panels.forEach((panel: IDockviewPanel) => {
    if (!views.value.find(view => view.id === panel.id)) {
      dockviewApi.value?.removePanel(panel);
    }
  });

  // Add new panels from views
  views.value.forEach((view) => {
    const currentView = dockviewApi.value?.panels.find(panel => panel.id === view.id);
    if (currentView) {
      return;
    }

    if (view.viewType === "document") {
      dockviewApi.value?.addPanel({
        id: view.id,
        component: "DocumentView",
        title: view.title,
        tabComponent: "ViewTab",
        params: {
          viewId: view.id,
        },
      } as AddPanelOptions);
    } else if (view.viewType === "dataFormEditor") {
      dockviewApi.value?.addPanel({
        id: view.id,
        component: "DataFormEditor",
        title: view.title,
        tabComponent: "ViewTab",
        params: {
          viewId: view.id,
        },
      } as AddPanelOptions);
    } else if (view.viewType === "dataForm") {
      dockviewApi.value?.addPanel({
        id: view.id,
        component: "DataFormView",
        title: view.title,
        tabComponent: "ViewTab",
        params: {
          viewId: view.id,
        },
      } as AddPanelOptions);
    } else if (view.viewType === "text") {
      dockviewApi.value?.addPanel({
        id: view.id,
        component: "KodexaTextView",
        title: view.title,
        tabComponent: "ViewTab",
        params: {
          viewId: view.id,
        },
      } as AddPanelOptions);
    } else if (view.viewType === "processingStep") {
      dockviewApi.value?.addPanel({
        id: view.id,
        component: "KodexaStepGraph",
        title: view.title,
        tabComponent: "ViewTab",
        params: {
          viewId: view.id,
        },
      } as AddPanelOptions);
    } else if (view.viewType === "costView") {
      dockviewApi.value?.addPanel({
        id: view.id,
        component: "KodexaCostAnalysis",
        title: view.title,
        tabComponent: "ViewTab",
        params: {
          viewId: view.id,
        },
      } as AddPanelOptions);
    } else {
      log.error(`Unknown view type ${view.viewType}`);
    }
  });
}

function dockReady(api: DockviewReadyEvent) {
  dockviewApi.value = api.api;

  // We need to register the view components
  if (app && !app.app.component("DocumentView")) {
    app.app.component("DocumentView", KodexaDocumentView);
    app.app.component("DataFormView", KodexaDataFormView);
    app.app.component("DataFormEditor", KodexaDataFormEditor);
    app.app.component("KodexaTextView", KodexaTextView);
    app.app.component("KodexaStepGraph", KodexaStepGraph);
    app.app.component("KodexaCostAnalysis", KodexaCostAnalysis);
  }

  syncViews();
}

watch(views, () => {
  if (dockviewApi.value) {
    syncViews();
  }
}, { deep: true, immediate: true });

const isDark = useDark({
  selector: "html",
  attribute: "class",
  valueDark: "dark",
  valueLight: "light",
});
</script>

<template>
  <div>
    <DockviewVue
      :class="!isDark ? 'dockview-theme-light' : 'dockview-theme-dark'" style="height: calc(100vh - 7rem)"
      default-tab-component="ViewTab"
      right-header-actions-component="RightViewAction"
      watermark-component="WorkspaceEmpty" @ready="dockReady"
    />
  </div>
</template>

<style scoped>

</style>
