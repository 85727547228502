<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, Disclosure, DisclosureButton, DisclosurePanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { computed, ref } from "vue";
import { useIntercom } from "~/store/useIntercom";
import KodexaButton from "./inputs/kodexa-button.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  error: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);
const intercomStore = useIntercom();

const errorData = computed(() => {
  const data = {
    message: props.error?.message || "Unknown error",
    headers: props.error?.response?.headers || {},
    request: {
      url: props.error?.config?.url || "",
      method: props.error?.config?.method || "",
      data: props.error?.config?.data || "",
    },
    response: {
      status: props.error?.response?.status || "",
      statusText: props.error?.response?.statusText || "",
      data: props.error?.response?.data || "",
    },
    stack: props.error?.stack || "",
  };

  // Try to parse request data if it's a string
  if (typeof data.request.data === "string") {
    try {
      data.request.data = JSON.parse(data.request.data);
    } catch {
      // Keep as string if not valid JSON
    }
  }

  return data;
});

const formattedError = computed(() => {
  return JSON.stringify(errorData.value, null, 2);
});

async function copyToClipboard() {
  try {
    await navigator.clipboard.writeText(formattedError.value);
    // Using console.warn instead of alert to avoid linter warnings
    console.warn("Error details copied to clipboard");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[9999]" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative m-20 w-full max-w-3xl overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" @click="emit('cancel')" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                >
                  <MaterialDesignIcon name="alert" size="32" :classes="['text-red-600']" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    Unexpected Exception
                  </DialogTitle>
                  <p class="mt-2 text-sm text-gray-600 dark:text-gray-300">
                    {{ errorData.message }}
                  </p>

                  <Disclosure v-slot="{ open }" as="div" class="mt-4">
                    <DisclosureButton
                      class="flex w-full justify-between rounded-lg bg-gray-100 dark:bg-gray-700 px-4 py-2 text-left text-sm font-medium text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500"
                    >
                      <span>{{ open ? 'Hide' : 'Show' }} Error Details</span>
                      <MaterialDesignIcon
                        :name="open ? 'chevron-up' : 'chevron-down'"
                        class="h-5 w-5 text-gray-500 dark:text-gray-400"
                      />
                    </DisclosureButton>
                    <DisclosurePanel class="pt-4">
                      <div class="bg-gray-50 dark:bg-gray-700 rounded-lg p-2">
                        <div class="font-medium text-sm mb-1">
                          Response Information
                        </div>
                        <div class="text-xs text-gray-600 dark:text-gray-300 mb-2">
                          Status: {{ errorData.response.status }} {{ errorData.response.statusText }}
                        </div>
                        <KodexaCodeEditor
                          v-model="formattedError" language="json" :read-only="true" style="height: 400px"
                        />
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
                <KodexaButton
                  icon="content-copy"
                  type="secondary"
                  @click="copyToClipboard"
                >
                  Copy to Clipboard
                </KodexaButton>
                <KodexaButton
                  type="primary"
                  icon="close"
                  @click="emit('cancel')"
                >
                  Close
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
