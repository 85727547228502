<script setup lang="ts">
import type { TaskActivity, TaskActivityDetail, TaskStatus } from "~/model";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { storeToRefs } from "pinia";
import { computed, nextTick, watch } from "vue";
import { useGetMembers } from "~/api/organizations/organizations";
import { getProjectTaskStatuses } from "~/api/projects/projects";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import appStore from "~/store";
import { getColorByBgColor } from "~/utils/colors";

const emit = defineEmits(["update:currentTask"]);

const { currentTask, taskActivities, taskDocumentFamilies } = storeToRefs(appStore.taskStore);
const { allDataExceptions } = storeToRefs(appStore.workspaceStore);

const localTask = computed({
  get: () => currentTask.value,
  set: () => appStore.taskStore.updateCurrentTask(localTask.value),
});

const taskDueDate = computed({
  get: () => localTask.value?.dueDate ? new Date(localTask.value.dueDate) : undefined,
  set: (value: Date | undefined) => {
    localTask.value.dueDate = value?.toISOString() || undefined;
    emit("update:currentTask", localTask.value);
  },
});

const { project } = storeToRefs(appStore.projectStore);
const today = new Date();

const newComment = ref("");

function addComment() {
  if (newComment.value) {
    appStore.taskStore.addActivity({
      details: {
        type: "COMMENT",
      } as TaskActivityDetail,
      comment: newComment.value,
    } as TaskActivity);
    newComment.value = "";
  }
}

const lastProjectId = ref<string | undefined>(undefined);
const taskStatuses = ref([] as TaskStatus[]);

watch(
  () => project.value?.id,
  async (newProjectId) => {
    if (lastProjectId.value !== newProjectId && newProjectId) {
      taskStatuses.value = await getProjectTaskStatuses(newProjectId);
      lastProjectId.value = newProjectId;
    }
  },
  { immediate: true },
);

function selectItem(dataItem: any) {
  localTask.value.status = dataItem;
}

const isExceptionsExpanded = ref(false);

const openExceptions = computed(() => allDataExceptions.value.filter(exception => exception.open).length);
const closedExceptions = computed(() => allDataExceptions.value.filter(exception => !exception.open).length);
</script>

<template>
  <div class="grid grid-cols-1 lg:grid-cols-12 gap-6">
    <!-- Main Content -->
    <div class="col-span-1 lg:col-span-6 space-y-4 mt-4 ml-4">
      <KodexaTextInput
        v-model="localTask.title"
        borderless
        font-size="xl"
        font-weight="bold"
        name="task-title"
        class="w-full border-none focus:outline-none focus:ring-0 placeholder-gray-400"
        placeholder="Task Name"
      />
      <KodexaMarkdownEditor
        v-model="localTask.description"
        class="w-full min-h-[24px] text-gray-500 border-none focus:outline-none focus:ring-0 placeholder-gray-400 resize-none transition-all duration-100 ease-in-out"
        placeholder="Add description..."
      />

      <div class="ml-2">
        <!-- Activity Logs and Comment Section -->
        <div v-if="false" class="mt-8 pt-8 border-t border-gray-200">
          <div class="flex gap-6">
            <!-- Activity Section -->
            <div class="flex-1 space-y-4">
              <h3 class="text-lg font-bold">
                Activity
              </h3>
              <div class="space-y-2">
                <div
                  v-for="activity in taskActivities"
                  :key="`${activity.id}-${activity.createdOn}`"
                  class="flex items-start gap-x-3 text-sm"
                >
                  <TaskActivity :activity="activity" />
                </div>
              </div>
            </div>

            <!-- Comment Section -->
            <div class="w-1/2">
              <div class="flex items-center gap-4">
                <KodexaUserAvatar :user="appStore.userStore.user" size="8" />
                <div class="w-full">
                  <textarea
                    v-model="newComment"
                    class="w-full min-h-[24px] text-gray-500 border-none focus:outline-none focus:ring-0 placeholder-gray-400 resize-none transition-all duration-100 ease-in-out"
                    placeholder="Add a comment..."
                    rows="1"
                  />
                  <div class="flex justify-end mt-2">
                    <KodexaButton
                      v-if="newComment"
                      icon="send"
                      size="small"
                      @click="addComment"
                    >
                      Add Comment
                    </KodexaButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Sidebar -->
    <div class="col-span-1 col-span-6  h-auto lg:h-[90vh] px-4 lg:px-6 py-6 bg-white dark:bg-gray-800">
      <!-- Status -->
      <div class="mb-2">
        <h3 class="font-md font-bold mb-2">
          Status
        </h3>
        <KodexaDropDown
          v-if="project"
          v-model="localTask.status"
          name="status"
          :items="taskStatuses"
          text-field="label"
          grid-prefix="task-status"
          placeholder="Select status..."
          :is-object="true"
        >
          <template #item-render="{ item }">
            <div
              v-if="item" class="kodexa-input mx-2 mt-1 cursor-pointer py-0.5"
              @click="selectItem(item)"
            >
              <div style="width: 100%">
                <div :style="{ 'background-color': item.color, 'color': getColorByBgColor(item.color) }" class="text-sm inline-flex items-center rounded-md px-2 py-1 ">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </template>
          <template #value-render="{ item }">
            <div v-if="item" id="currentStatusDropdown" style="width: 100%">
              <div :style="{ 'background-color': item.color, 'color': getColorByBgColor(item.color) }" class="text-md inline-flex items-center rounded-md px-2 py-1">
                {{ item.label }}
              </div>
            </div>
            <div v-else id="statusDropdown" class="py-1 w-full">
              <span class="text-gray-400">Select status...</span>
            </div>
          </template>
        </KodexaDropDown>
      </div>

      <!-- Assignee -->
      <div class="mb-2 mt-2">
        <h3 class="font-md font-bold mb-2">
          Assignee
        </h3>
        <KodexaDynamicDropDown
          v-model="localTask.assignee"
          name="assignee"
          :query-fn="useGetMembers.bind(undefined, project.organization.id)"
          text-field="fullName"
          grid-prefix="`task-assignee-${project.organization.id}`"
          placeholder="Select assignee..."
          class="mt-1"
          :is-object="true"
        >
          <template #item-render="{ item }">
            <div class="flex items-center gap-2 h-8">
              <KodexaUserAvatar
                :user="item"
                size="6"
                class="flex-shrink-0"
              />
              <div class="flex flex-col overflow-hidden">
                <span class="font-medium" :title="item.fullName">{{ item.fullName }}</span>
                <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
              </div>
            </div>
          </template>
          <template #value-render="{ item }">
            <div class="flex items-center gap-2 h-8">
              <KodexaUserAvatar
                :user="item"
                size="6"
                class="flex-shrink-0"
              />
              <div class="flex flex-col overflow-hidden">
                <span class="font-medium" :title="item.fullName">{{ item.fullName }}</span>
                <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
              </div>
            </div>
          </template>
        </KodexaDynamicDropDown>
        <!-- Due Date -->
        <div>
          <h3 class="font-md font-bold mb-2 mt-2">
            Due Date
          </h3>
          <DatePicker
            v-model="taskDueDate"
            :min="today"
            class="w-full date-picker-custom"
            :clearable="true"
            placeholder="Set Due Date"
          />
        </div>
        <div v-if="taskDocumentFamilies.length" class="space-y-2 mt-2">
          <h3 class="text-md font-bold">
            Related Documents
          </h3>
          <div class="flex flex-wrap gap-2">
            <span
              v-for="taskDocumentFamily in taskDocumentFamilies"
              :key="taskDocumentFamily.id"
              class="mr-1 py-1 text-sm"
            >
              <KodexaDocumentFamilyCard
                :document-family="taskDocumentFamily.documentFamily"
                :project="project"
                :show-actions="false"
                :show-labels="false"
                :show-checkbox="false"
              />
            </span>
          </div>
        </div>
        <div class="exceptions-section mt-2">
          <div
            class="flex items-center cursor-pointer mb-2"
            @click="isExceptionsExpanded = !isExceptionsExpanded"
          >
            <MaterialDesignIcon
              :name="isExceptionsExpanded ? 'chevron-down' : 'chevron-right'"
              class="mr-2"
              size="20"
            />
            <span class="font-medium">
              Exceptions ({{ openExceptions }} open, {{ closedExceptions }} closed)
            </span>
          </div>

          <div v-show="isExceptionsExpanded" class="pl-6">
            <div
              v-for="exception in allDataExceptions"
              :key="exception.uuid"
              class="exception-container"
            >
              <div class="flex">
                <VMenu>
                  <MaterialDesignIcon
                    name="alertbox"
                    class="mr-1"
                    :class="exception.open ? ['text-red-600'] : ['text-gray-600']"
                    size="20"
                  />
                  <template #popper>
                    <div class="bg-white dark:bg-gray-800 shadow sm:rounded-lg">
                      <div class="px-2 py-2">
                        <div class="mt-2 max-w-xl text-sm text-gray-700 min-w-22">
                          <KodexaDataException
                            :exception="exception"
                            :attribute="exception.dataAttribute"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </VMenu>
                <div
                  class="mt-1 text-gray-700"
                  :class="{ 'line-through': !exception.open }"
                >
                  {{ exception.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="localTask" class="space-y-6 mt-4 text-xs text-gray-500">
        <div v-if="localTask.template" class="space-y-2">
          Created using template <span class="font-medium">{{ localTask.template.name }}</span> <KodexaDateTimeView :date="localTask.createdOn" :ago="true" />
        </div>
        <div v-else>
          Created <KodexaDateTimeView :date="localTask.createdOn" :ago="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.kodexa-input .k-input-inner::placeholder) {
  @apply font-normal text-gray-400 !important;
}
</style>
