import type { ILogObj } from "tslog";
import type { DataAttribute, DataException, DataObject, Taxon, Taxonomy, TaxonValidation } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { defineStore, storeToRefs } from "pinia";
import { Logger } from "tslog";
import appStore from "~/store/index";
import emitter from "~/utils/event-bus";

/**
 * We have a single instance of validators that we use within the
 * workspace to ensure that all the validations are run on the data objects and
 * data attributes
 */
export const useValidators = defineStore("validators", () => {
  const log: Logger<ILogObj> = new Logger({ name: "validators" });

  const { dataObjects } = storeToRefs(appStore.workspaceStore);
  const { contentTaxonomies, tagMetadataMap } = storeToRefs(appStore.projectStore);
  const taxonomyFormulaServices = new Map<string, any>();

  // We have a few ways in which we can get validation
  // 1. From the taxonomy, so we want to store those by path
  // 2. From the data object, so we want to store those by data object
  // 3. From the data attribute, so we want to store those by data object and path
  const validationsByTaxonPath = computed(() => {
    const validations = new Map<string, TaxonValidation[]>();

    function processTaxon(taxon: Taxon) {
      // Process validations for current node
      if (taxon.validationRules) {
        taxon.validationRules.forEach((validation) => {
          if (!validations.has(taxon.path as string)) {
            validations.set(taxon.path as string, []);
          }
          validations.get(taxon.path as string)?.push(validation);
        });
      }

      // Recursively process children
      if (taxon.children) {
        taxon.children.forEach(child => processTaxon(child));
      }
    }

    contentTaxonomies.value.forEach((taxonomy: Taxonomy) => {
      log.info(`Building formula services for taxonomy ${taxonomy.ref}`);
      taxonomyFormulaServices.set(taxonomy.ref as string, createKodexaFormulaService(ref(taxonomy)).formulaService);
      taxonomy.taxons?.forEach((taxon: Taxon) => {
        processTaxon(taxon);
      });
    });

    return validations;
  });

  const validationsByDataObject = computed(() => {
    const validations = new Map<string, TaxonValidation[]>();
    dataObjects.value.forEach((dataObject: DataObject) => {
      if (dataObject.lineage?.validations) {
        validations.set(dataObject.uuid as string, dataObject.lineage.validations);
      }

      // if we have attribute validations we will add them to the data object dict with /path on the end
      if (dataObject.lineage?.attributeValidations) {
        Object.keys(dataObject.lineage.attributeValidations).forEach((path) => {
          if (!validations.has(`${dataObject.uuid}/${path}`)) {
            validations.set(`${dataObject.uuid}/${path}`, []);
          }
          if (dataObject.lineage?.attributeValidations) {
            validations.get(`${dataObject.uuid}/${path}`)?.push(...dataObject.lineage.attributeValidations[path]);
          }
        });
      }
    });

    return validations;
  });

  function createValidationException(formulaService: any, validation: TaxonValidation, dataObject: DataObject, attribute: DataAttribute | string) {
    const message = formulaService.evaluateFormula(validation.messageFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values())).result;
    const exceptionDetails = formulaService.evaluateFormula(validation.detailFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values())).result;

    // if attribute is a path we have just path else we have attribute and path
    if (typeof attribute === "string") {
      return { dataObjectUuid: dataObject.uuid, message, exceptionDetails, exceptionType: validation.exceptionId, path: attribute, open: true, uuid: crypto.randomUUID() } as DataException;
    } else {
      return {
        message,
        exceptionDetails,
        dataAttrUuid: attribute.uuid,
        dataObjectUuid: dataObject.uuid,
        exceptionType: validation.exceptionId,
        path: attribute ? attribute.path : dataObject.path,
        open: true,
        uuid: crypto.randomUUID(),
      } as DataException;
    }
  }

  function processValidation(formulaService: any, validation: TaxonValidation, dataObject: DataObject, attribute: DataAttribute | string | undefined): DataException[] {
    log.info(`Processing validation for ${dataObject.path} ${dataObject.uuid}`);
    const exceptionsToAdd: DataException[] = [];
    try {
      if (validation.conditional && validation.conditionalFormula && validation.conditionalFormula.trim() !== "") {
        const result = formulaService.evaluateFormula(validation.conditionalFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values()));
        if (result.error) {
          log.error(`Error processing conditional formula [${result.error}]`);
          return exceptionsToAdd;
        }
        if (result.result === false || (Array.isArray(result.result) && result.result.length === 0)) {
          return exceptionsToAdd;
        }
      }

      const result = formulaService.evaluateFormula(validation.ruleFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values()));

      if (result.error) {
        log.error(`Error processing validation [${result.error}]`);
        let attributePath;
        if (typeof attribute === "string") {
          attributePath = attribute;
        }
        const dataException = {
          message: "Unable to process validation",
          exceptionDetails: result.error,
          dataAttrUuid: attributePath ? undefined : attribute?.uuid,
          dataObjectUuid: dataObject.uuid,
          exceptionType: validation.exceptionId,
          path: attributePath || (attribute ? attribute.path : dataObject.path),
          open: true,
          uuid: crypto.randomUUID(),
        } as DataException;
        exceptionsToAdd.push(dataException);
      } else {
        // if attribute is a string then we are dealing with a data object level validation but we are using the path
        // to determine if we need to add an exception
        const exceptionIsOpen = (result.result === false || (Array.isArray(result.result) && result.result.length === 0));
        if (typeof attribute === "string") {
          const newDataException = createValidationException(formulaService, validation, dataObject, attribute);
          newDataException.open = exceptionIsOpen;
          exceptionsToAdd.push(newDataException);
          return exceptionsToAdd;
        } else if (attribute) {
          const path = `${dataObject.uuid}/${attribute.path}`;
          log.info(`Validation failed for ${path} result was ${result.result}`);
          const newDataException = createValidationException(formulaService, validation, dataObject, attribute);
          newDataException.open = exceptionIsOpen;
          exceptionsToAdd.push(newDataException);
        }
      }
    } catch (e) {
      log.error(`Error processing validation ${e});`);
      let attributePath;
      if (typeof attribute === "string") {
        attributePath = attribute;
      }
      const dataException = {
        message: "Unable to process validation",
        exceptionDetails: e,
        dataAttrUuid: attributePath ? undefined : attribute?.uuid,
        dataObjectUuid: dataObject.uuid,
        exceptionType: validation.exceptionId,
        path: attributePath || (attribute ? attribute.path : dataObject.path),
        open: true,
        uuid: crypto.randomUUID(),
      } as DataException;
      exceptionsToAdd.push(dataException);
    }
    return exceptionsToAdd;
  }

  function runDataObjectValidation(dataObject: DataObject, validations: TaxonValidation[]): { updatedObject: DataObject; exceptionsToAdd: DataException[] } {
    log.info(`Running validations for ${dataObject.uuid}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);

    if (!formulaService) {
      log.error(`Unable to find formula service for ${dataObject.taxonomyRef}`);
      return { updatedObject: dataObject, exceptionsToAdd: [] };
    }

    const allExceptionsToAdd: DataException[] = [];

    log.info(`Running ${validations.length} validations for ${dataObject.uuid}`);
    validations.forEach((validation) => {
      allExceptionsToAdd.push(...processValidation(formulaService, validation, dataObject, undefined));
    });

    const updatedObject = { ...dataObject };

    return { updatedObject, exceptionsToAdd: allExceptionsToAdd };
  }

  function runDataAttributeValidationWithoutAttribute(dataObject: DataObject, attributePath: string, validations: TaxonValidation[]): { updatedObject: DataObject; exceptionsToAdd: DataException[] } {
    log.info(`Running ${validations.length} validations for ${dataObject.uuid}/${attributePath}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);
    const allExceptionsToAdd: DataException[] = [];

    validations.forEach((validation) => {
      allExceptionsToAdd.push(...processValidation(formulaService, validation, dataObject, attributePath));
    });

    const updatedObject = { ...dataObject };

    return { updatedObject, exceptionsToAdd: allExceptionsToAdd };
  }

  function runDataAttributeValidation(dataObject: DataObject, attribute: DataAttribute, validations: TaxonValidation[]): { updatedObject: DataObject; exceptionsToAdd: DataException[] } {
    log.info(`Running ${validations.length} validations for ${dataObject.uuid}/${attribute.path}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);
    const allExceptionsToAdd: DataException[] = [];

    validations.forEach((validation) => {
      allExceptionsToAdd.push(...processValidation(formulaService, validation, dataObject, attribute));
    });

    const updatedObject = { ...dataObject };

    return { updatedObject, exceptionsToAdd: allExceptionsToAdd };
  }

  const isProcessing = ref(false);

  const pendingDataObjects = ref<Set<DataObject>>(new Set());

  emitter.on("workspace:dataAttributeUpdated", async (payload: { dataObject: DataObject; dataAttribute: DataAttribute }) => {
    if (isProcessing.value) {
      return;
    }
    log.info(`Pending: Data attribute updated: ${payload.dataObject.uuid}`);
    pendingDataObjects.value.add(payload.dataObject);
  });

  emitter.on("workspace:dataObjectUpdated", async (dataObject: DataObject) => {
    if (isProcessing.value) {
      return;
    }
    log.info(`Pending: Data object updated: ${dataObject.uuid}`);
    pendingDataObjects.value.add(dataObject);
  });

  emitter.on("workspace:dataObjectsUpdated", async (dataObjectsToProcess: DataObject[]) => {
    if (isProcessing.value) {
      return;
    }
    log.info(`Pending: Data objects updated: ${dataObjectsToProcess.map(d => d.uuid).join(", ")}`);
    pendingDataObjects.value = new Set([...pendingDataObjects.value, ...dataObjectsToProcess]);
  });

  watchDebounced([pendingDataObjects], async () => {
    log.info(`Pending data objects changed: ${pendingDataObjects.value.size} objects`);
    if (pendingDataObjects.value.size === 0) {
      return;
    }

    const dataObjectsToProcess = Array.from(pendingDataObjects.value);
    pendingDataObjects.value.clear();
    await processDataObjects(dataObjectsToProcess);
  }, { immediate: true, deep: true, debounce: 1000 });

  async function processDataObjects(dataObjectsToProcess: DataObject[]) {
    if (isProcessing.value) {
      return;
    }

    isProcessing.value = true;

    // Create a set to track all objects that need processing
    const objectsToProcess = new Set<DataObject>();

    // Add initial objects to the set
    dataObjectsToProcess.forEach(obj => objectsToProcess.add(obj));

    // Recursively add all parent objects
    const addParents = (dataObject: DataObject) => {
      if (dataObject.parent?.uuid) {
        const parentObject = dataObjects.value.get(dataObject.parent?.uuid as string);
        if (parentObject && !objectsToProcess.has(parentObject)) {
          objectsToProcess.add(parentObject);
          addParents(parentObject);
        }
      }
    };

    // Process each object's parents
    dataObjectsToProcess.forEach(obj => addParents(obj));

    // Convert set back to array for processing
    const allObjectsToProcess = Array.from(objectsToProcess);

    // Collect all updates first
    log.info(`Processing ${allObjectsToProcess.length} data objects (including parents)`);
    const updates = allObjectsToProcess.map((dataObject: DataObject) => {
      let updatedObject = { ...dataObject };
      const allExceptionsToAdd: DataException[] = [];

      // Process data object level validations
      if (validationsByDataObject.value.has(dataObject.uuid as string)) {
        const result = runDataObjectValidation(
          updatedObject,
          validationsByDataObject.value.get(dataObject.uuid as string) as TaxonValidation[],
        );
        updatedObject = result.updatedObject;
        allExceptionsToAdd.push(...result.exceptionsToAdd);
      }

      // Process attribute level validations, we need to do this based on the attributes from
      // the taxonomy, this is so we can fire validations for when an attribute doesn't exist
      const dataObjectTaxon: TagMetadata = tagMetadataMap.value.get(dataObject.path);

      const result = runDataObjectValidation(
        updatedObject,
        dataObjectTaxon.taxon.validationRules as TaxonValidation[],
      );
      updatedObject = result.updatedObject;
      allExceptionsToAdd.push(...result.exceptionsToAdd);

      dataObjectTaxon.taxon.children?.forEach((childTaxon: Taxon) => {
        if (childTaxon.group) {
          return;
        }

        if (validationsByTaxonPath.value.has(childTaxon.path as string)) {
          const taxonAttributes = dataObject.attributes?.filter(attribute => attribute.path === childTaxon.path);
          if (taxonAttributes?.length && taxonAttributes.length > 0) {
            taxonAttributes.forEach((attribute: DataAttribute) => {
              const result = runDataAttributeValidation(
                updatedObject,
                attribute,
                validationsByTaxonPath.value.get(childTaxon.path as string) as TaxonValidation[],
              );
              updatedObject = result.updatedObject;
              allExceptionsToAdd.push(...result.exceptionsToAdd);
            });
          } else {
            const result = runDataAttributeValidationWithoutAttribute(
              updatedObject,
              childTaxon.path as string,
              validationsByTaxonPath.value.get(childTaxon.path as string) as TaxonValidation[],
            );
            updatedObject = result.updatedObject;
            allExceptionsToAdd.push(...result.exceptionsToAdd);
          }
        }
      });

      return {
        uuid: updatedObject.uuid,
        updatedObject,
        exceptionsToAdd: allExceptionsToAdd,
        requiresUpdate: allExceptionsToAdd.length > 0,
      };
    });

    // Batch update all changes at once
    await nextTick(() => {
      updates.forEach(({ updatedObject, requiresUpdate, exceptionsToAdd }) => {
        if (requiresUpdate) {
          appStore.workspaceStore.updateDataExceptions(updatedObject, exceptionsToAdd);
        }
      });
      nextTick(() => {
        isProcessing.value = false;
      });
    });
  }

  // If any of the data objects change we need to recompute the validations
  watchDebounced([validationsByTaxonPath], async () => {
    log.info("Validations by taxon path changed");
    await processDataObjects(Array.from(dataObjects.value.values));
  }, { immediate: true, deep: true, debounce: 1000 });

  return {
  };
});
