<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget, TaskTemplate } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useListTaskTemplates } from "~/api/task-templates/task-templates";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import ProjectTaskTemplateModal from "./taskTemplates/project-task-template-modal.vue";

// Props
const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const componentMetadata = {
  label: "Project Task Template",
  description: "Manages and displays project task templates with customizable styling",
  options: [
    {
      label: "Custom Style",
      key: "style",
      type: "object",
      required: false,
      description: "Custom styling for the grid component",
    },
    {
      label: "Row Span",
      key: "defaultPosition.rowSpan",
      type: "number",
      required: false,
      description: "Number of rows the grid should span",
      default: 6,
    },
  ],
};

// Explicitly expose the metadata
defineExpose({
  metadata: componentMetadata,
});

// Store
const { project } = storeToRefs(appStore.projectStore);

// Grid setup
const gridHelper = createGridHelper("task-template-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const { pageSettings, sort, gridQuery, showFilter, filter } = storeToRefs(gridHelper);

// Data fetching
const { data, isLoading, refetch } = useListTaskTemplates(gridQuery.value);

// Watchers
watch(data, (newValue) => {
  if (newValue?.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(project, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`project.id: '${project.value.id}'`);
  }
}, { immediate: true });

// Grid configuration
const columns = [
  {
    title: "Template Name",
    field: "name",
    cell: "name",
  },
  {
    title: "Description",
    field: "description",
  },
];

// Template state
const showTemplate = ref(false);
const selectedTemplate = ref<TaskTemplate | undefined>(undefined);

// Methods
function openNewTemplate() {
  selectedTemplate.value = undefined;
  showTemplate.value = true;
}

function editTemplate(template: TaskTemplate) {
  selectedTemplate.value = JSON.parse(JSON.stringify(template));
  showTemplate.value = true;
}

async function handleTemplateDeleted(_: string) {
  await refetch();
}

const gridStyle = computed(() => {
  if (props.widget?.properties?.style) {
    return props.widget.properties.style;
  }

  const rowSpan = props.widget?.properties?.defaultPosition?.rowSpan || 6;
  return {
    height: `${(rowSpan * 85) - 130}px`,
  };
});
</script>

<template>
  <div class="">
    <div class="mx-4">
      <!-- Toolbar -->
      <KodexaToolbar>
        <Button
          :svg-icon="filterIcon"
          class=" dark:text-gray-50"
          title="Filter"
          :togglable="true"
          @click="gridHelper.toggleFilter()"
        />
        <KodexaArticle
          class="mt-2 ml-2"
          article-id="9787665"
          text="Learn more about task templates"
        />
        <ToolbarSpacer />
        <KodexaButton
          id="createNewTemplate"
          icon="plus"
          @click="openNewTemplate"
        >
          New Task Template
        </KodexaButton>
      </KodexaToolbar>

      <!-- Grid -->
      <Grid
        :style="gridStyle"
        :data-items="data?.content || []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <div class="p-4 text-center text-gray-500">
            No task templates found
          </div>
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div
                class="flex-0 cursor-pointer font-bold text-gray-900 dark:text-gray-100"
                @click="editTemplate(props.dataItem)"
              >
                {{ props.dataItem.name }}
              </div>
            </div>
          </td>
        </template>
      </Grid>

      <!-- Modal -->
      <ProjectTaskTemplateModal
        :key="selectedTemplate?.id"
        v-model="showTemplate"
        :template="selectedTemplate"
        @refetch-templates="refetch"
        @template-deleted="handleTemplateDeleted"
      />
    </div>
  </div>
</template>
