<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import imageUrl from "~/images/kodexa-large-pict-only.png";
import appStore from "~/store";
import { clearCache } from "~/utils/general";

defineProps({});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);
const version = import.meta.env.VITE_APP_VERSION || "[Internal]";
const chartUiVersion = inject("chartUiVersion");

const kodexaVersion = computed(() => {
  return chartUiVersion ? `${version}/${chartUiVersion}` : version;
});

const { platformOverview } = storeToRefs(appStore.platformStore);
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[99]" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50/75 dark:bg-gray-900/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" size="16" aria-hidden="true" @click="emit('cancel')" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto mt-6 flex h-12 w-16 shrink-0 items-center justify-center rounded-full sm:mx-0"
                >
                  <img
                    class="-ml-6 block"
                    :src="imageUrl" alt="Kodexa"
                  >
                </div>
                <div class="text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h1" class="mt-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    Kodexa Platform
                  </DialogTitle>
                  <div class="mt-4 h-60">
                    <p class="mt-7 text-sm text-gray-500 dark:text-gray-400">
                      Kodexa Studio is an Enterprise platform that allows you to build, manage and deploy
                      document automation workflows.
                    </p>

                    <p class="mt-2 text-xs text-gray-500 dark:text-gray-400">
                      This product contains software developed by the Apache Software Foundation.
                    </p>
                    <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      Release Name: {{ platformOverview.release }}
                    </p>
                    <p class="mt-2 text-xs text-gray-500 dark:text-gray-400">
                      UI Build: {{ kodexaVersion }}
                    </p>
                    <p class="mt-2 text-xs text-gray-500 dark:text-gray-400">
                      Server Build: {{ platformOverview.version }}
                    </p>
                    <p class="mt-10 text-xs text-gray-500 dark:text-gray-400">
                      © 2024 Kodexa, Inc. All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-8 sm:flex sm:flex-row-reverse">
                <KodexaButton
                  id="closeBtn"
                  type="secondary"
                  class="mr-2 dark:border-gray-600 dark:text-gray-200"
                  icon="close"
                  size="small"
                  @click="emit('cancel')"
                >
                  Close
                </KodexaButton>
                <KodexaButton
                  id="clearCacheBtn"
                  type="secondary"
                  size="small"
                  class="mr-2 dark:border-gray-600 dark:text-gray-200"
                  icon="cloud-refresh-outline"
                  @click="clearCache"
                >
                  Clear Cache and Reload
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
