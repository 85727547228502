export const optionMetadata = {
  label: "Component Lookup",
  description: "Select from available components",
  options: [
    {
      name: "componentType",
      label: "Component Type",
      type: "string",
      description: "Type of components to list",
    },
    {
      name: "filter",
      label: "Filter Pattern",
      type: "string",
      description: "Pattern to filter components",
    },
  ],
};
