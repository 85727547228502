export const optionMetadata = {
  label: "Article",
  description: "Display an article from the knowledge base",
  options: [
    {
      name: "articleId",
      label: "Article ID",
      type: "string",
      description: "ID of the article to display",
    },
    {
      name: "text",
      label: "Text Content",
      type: "text",
      description: "Text content to display",
    },
    {
      name: "slide",
      label: "Slide Number",
      type: "number",
      description: "Slide number to display (optional)",
    },
  ],
};
