<script setup lang="ts">
import type { AssignNextRequest, Task, User } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { createConfirmDialog } from "vuejs-confirm-dialog";
import { assignNextTask, deleteTask, useListTasks } from "~/api/tasks/tasks";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import { updateHandler } from "~/utils/error-handler";

const router = useRouter();
const route = useRoute();
const organizationId = computed(() => route.params.organizationId as string);
const { user } = storeToRefs(appStore.userStore);

const quickFilterValue = ref("");

const organizationFilter = computed(() => {
  const baseFilter = `project.organization.id:'${organizationId.value}'`;
  return quickFilterValue.value
    ? `${baseFilter} AND ${quickFilterValue.value.query}`
    : baseFilter;
});

const gridHelper = createGridHelper(`organization-tasks-${organizationId.value}`, {
  page: 1,
  pageSize: 10,
  sort: "createdOn:desc",
  filter: organizationFilter,
  query: "",
});

const showNewTask = ref(false);
const selectedTask = ref<Task | null>(null);

const {
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTasks(gridQuery, {
  query: {
    refetchInterval: computed(() => 5000),
    retry: false,
  },
});

watch(organizationId, async (newOrgId) => {
  if (newOrgId && gridHelper) {
    try {
      // Wait for grid helper to be ready
      await nextTick();
      if (typeof gridHelper.setFilter === "function") {
        gridHelper.setFilter(organizationFilter.value);
        await refetch();
      }
    } catch (error) {
      console.error("Error updating grid filter:", error);
    }
  }
}, { immediate: false });

const computedQuery = computed({
  get: () => gridHelper.query,
  set: value => gridHelper.setQuery(value),
});

const columns = [
  {
    title: "Task",
    field: "title",
    cell: "taskCard",
  },
  {
    title: "Project",
    field: "project.name",
    cell: "project",
    width: "300px",
  },
  {
    title: "Status",
    field: "status.label",
    cell: "status",
    width: "300px",
  },
  {
    title: "Created",
    field: "createdOn",
    cell: "date",
    filter: "date",
    width: "200px",
  },
  {
    title: "Assignee",
    field: "assignee.searchText",
    cell: "assignee",
    width: "300px",
  },
  {
    title: "Actions",
    field: "description",
    cell: "actions",
    width: "120px",
    filterable: false,
    sortable: false,
  },
];

function navigateToTask(task: Task) {
  const orgId = route.params.organizationId;
  const projectId = task.project?.id;

  if (orgId && projectId && task.id) {
    router.push({
      path: `/f/o/${orgId}/p/${projectId}/t/${task.id}`,
    });
  }
}

function editTask(task: Task) {
  if (!task.id) {
    console.error("Cannot edit task without ID");
    return;
  }
  selectedTask.value = task;
  showNewTask.value = true;
}

function formatUserDisplay(user: User | null): string {
  if (!user) {
    return "-";
  }
  return `${user.firstName} ${user.lastName} (${user.email})`;
}

async function handleTaskUpdated() {
  await refetch();
  selectedTask.value = null;
  await updateHandler(Promise.resolve(), "Task updated successfully");
}

function handleDialogClose() {
  showNewTask.value = false;
  selectedTask.value = null;
}

async function doDeleteTask(task: Task) {
  if (!task?.id) {
    return;
  }

  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete task ${task.title}?`,
    message: "This action cannot be undone. All associated activities will also be deleted.",
  });

  // Then delete the task
  if (!isCanceled) {
    await deleteTask(task.id);
    await refetch();
  }
}

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const quickFilters = [
  { text: "My Tasks", query: `assignee.id:'${user.value.id}'` },
  {
    text: "To Do",
    query: "status.statusType:'TODO'",
  },
  {
    text: "In Progress",
    query: "status.statusType:'IN_PROGRESS'",
  },
  {
    text: "Done",
    query: "status.statusType:'DONE'",
  },
];
async function assignNextAvailable() {
  const request: AssignNextRequest = {
    releaseInMinutes: 10,
    filter: `project.organization.id:'${organizationId.value}' and status.statusType:'TODO' and assignee is null`,
  };
  const task = await updateHandler(assignNextTask(request), "Task assigned successfully");

  // We will navigate to the task
  if (task) {
    router.push({
      path: `/f/o/${organizationId.value}/p/${task.project?.id}/t/${task.id}`,
    });
  } else {
    const noTasksDialog = createConfirmDialog(KodexaConfirm);
    await noTasksDialog.reveal({
      icon: "information-outline",
      title: "No Available Tasks",
      message: "There are no more available tasks to work on.",
      confirmText: "OK",
      confirmIcon: "check",
      showCancel: false,
    });
  }
}
</script>

<template>
  <div>
    <div class="mx-4">
      <KodexaToolbar>
        <label for="query" class="sr-only">Query</label>
        <KodexaTextInput
          id="query"
          v-model="computedQuery"
          :show-clear="true"
          type="text"
          name="query"
          class="-ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder="Filter Tasks"
        />
        <KodexaDropDown
          v-model="quickFilterValue"
          placeholder="Choose quick filter"
          name="quickFilters"
          :items="quickFilters"
          value-field="text"
          id-field="text"
          :is-object="true"
          style="width: 200px"
        />
        <KodexaButton
          name="clearQuickFilter"
          icon="close"
          size="small"
          type="secondary"
          :disabled="!quickFilterValue"
          @click="quickFilterValue = undefined"
        >
          Clear
        </KodexaButton>
        <Button
          class="rounded-md bg-white dark:text-gray-50 dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-900"
          :svg-icon="filterIcon"
          title="Filter"
          :togglable="true"
          @click="gridHelper.toggleFilter()"
        />
        <KodexaArticle class="ml-2 mt-2" article-id="9893941" text="Learn more about tasks" :slide="false" />

        <ToolbarSpacer />
        <KodexaButton id="assignNextAvailable" icon="account-plus-outline" title="Take Next Task" type="cta" @click="assignNextAvailable">
          Take Next Task
        </KodexaButton>
      </KodexaToolbar>
      <Grid
        style="height: calc(100vh - 208px)"
        :data-items="data?.content || []"
        :pageable="gridHelper.pageSettings"
        :loader="isLoading"
        :sort="gridHelper.sort"
        :skip="gridHelper.pageSettings.skip as number"
        :total="data?.totalElements || 0"
        :page-size="gridHelper.pageSettings.take as number"
        :take="gridHelper.pageSettings.take as number"
        :columns="columns"
        :filterable="gridHelper.showFilter"
        :filter="gridHelper.filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error"
            :is-loading="isLoading"
            :is-error="isError"
            no-records-message="No tasks found"
          />
        </GridNoRecords>
        <template #taskCard="{ props }">
          <td>
            <KodexaTaskCard
              :task="props.dataItem"
              @click="navigateToTask(props.dataItem)"
            />
          </td>
        </template>
        <template #project="{ props }">
          <td>
            <div class="text-sm text-blue-700 hover:text-blue-600 cursor-pointer" @click="router.push(`/f/o/${organizationId}/p/${props.dataItem.project?.id}/home`)">
              {{ props.dataItem.project?.name || '-' }}
            </div>
          </td>
        </template>
        <template #status="{ props }">
          <td>
            <KodexaTaskStatus :task="props.dataItem" />
          </td>
        </template>
        <template #date="{ props }">
          <td>
            <KodexaDateTimeView :date="props.dataItem[props.field]" :ago="true" />
          </td>
        </template>
        <template #assignee="{ props }">
          <td>
            <div class="flex items-center">
              <KodexaUserAvatar
                v-if="props.dataItem.assignee"
                :user="props.dataItem.assignee"
                size="8"
                class="mr-2"
              />
              <span class="text-sm text-gray-900">
                {{ formatUserDisplay(props.dataItem.assignee) }}
              </span>
            </div>
          </td>
        </template>
        <template #actions="{ props }">
          <td>
            <div class="flex justify-center space-x-2">
              <KodexaButton
                icon="pencil"
                title="Edit Task"
                size="small"
                @click="editTask(props.dataItem)"
              />
              <KodexaButton
                icon="delete"
                title="Delete Task"
                size="small"
                type="danger"
                @click="doDeleteTask(props.dataItem)"
              />
            </div>
          </td>
        </template>
      </Grid>
      <NewTaskPopover
        v-model="showNewTask"
        :task-to-edit="selectedTask"
        :organization-id="organizationId"
        @update:task-to-edit="(val) => selectedTask = val"
        @task-created="refetch"
        @task-updated="handleTaskUpdated"
        @update:model-value="handleDialogClose"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(button .icon) {
  margin-right: 0 !important;
}
</style>
