export const optionMetadata = {
  label: "Text Input",
  description: "Text input field with optional multiline support",
  options: [
    {
      name: "lines",
      label: "Number of Lines",
      type: "number",
      description: "Number of lines for multiline input",
      default: 1,
    },
    {
      name: "password",
      label: "Password Field",
      type: "boolean",
      description: "Mask input as password",
      default: false,
    },
    {
      name: "placeholder",
      label: "Placeholder",
      type: "string",
      description: "Placeholder text when empty",
    },
  ],
};
