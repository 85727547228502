export const optionMetadata = {
  label: "Cloud Model",
  description: "Select a cloud model",
  options: [
    {
      name: "modelId",
      label: "Model",
      type: "string",
      description: "Cloud model to use",
    },
    {
      name: "showDescription",
      label: "Show Description",
      type: "boolean",
      description: "Show model description as hint",
      default: true,
    },
    {
      name: "showProvider",
      label: "Show Provider",
      type: "boolean",
      description: "Show provider in model name",
      default: true,
    },
  ],
};
