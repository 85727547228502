import type { Taxon, Taxonomy } from "~/model";
import type { TagMetadata } from "~/store/useProject";

export function getParent(taxons: Taxon[], id: string): Taxon | undefined {
  for (let i = 0; i < taxons.length; i++) {
    if (taxons[i].children) {
      if (taxons[i].children?.some(child => child.id === id)) {
        return taxons[i];
      }

      if (taxons[i].children) {
        const parent = getParent(taxons[i].children || [], id);
        if (parent) {
          return parent;
        }
      }
    }
  }
  return undefined;
}

function getParentPath(taxons: Taxon[], id: string, currentPath: string | undefined): string | undefined {
  for (let i = 0; i < taxons.length; i++) {
    if (taxons[i].id === id) {
      return currentPath;
    }

    if (taxons[i].children) {
      const result = getParentPath(taxons[i].children || [], id, currentPath ? `${currentPath}/${taxons[i].name}` : taxons[i].name);
      if (result) {
        return result;
      }
    }
  }

  return undefined;
}

function getParentLabel(taxons: Taxon[], id: string, currentLabel: string | undefined): string | undefined {
  for (let i = 0; i < taxons.length; i++) {
    if (taxons[i].id === id) {
      return currentLabel;
    }

    if (taxons[i].children) {
      const result = getParentLabel(taxons[i].children || [], id, currentLabel ? `${currentLabel} / ${taxons[i].label}` : taxons[i].label);
      if (result) {
        return result;
      }
    }
  }

  return undefined;
}

const taxonBasedTagMetadataMapCache: Map<string, Map<string, TagMetadata>> = new Map<string, Map<string, TagMetadata>>();

export function buildTagMetadata(taxon: Taxon, taxonomy: Taxonomy, tagMetadataMap: Map<string, TagMetadata>, parentExternalNameRelativePath: string | undefined = undefined) {
  if (taxon && taxon.path) {
    if (taxonBasedTagMetadataMapCache.has(taxon.path)) {
      tagMetadataMap = new Map([...tagMetadataMap.entries(), ...taxonBasedTagMetadataMapCache.get(taxon.path).entries()]);
    } else {
      if (taxon.enabled === undefined) {
        taxon.enabled = true;
      }
      if (taxonomy.enabled === undefined) {
        taxonomy.enabled = true;
      }
      const parentLabel = taxonomy.taxons && taxon.id ? getParentLabel(taxonomy.taxons, taxon.id, undefined) : undefined;
      const parentPath = taxonomy.taxons && taxon.id ? getParentPath(taxonomy.taxons, taxon.id, undefined) : undefined;

      const processing = taxonomy ? taxonomy.taxonomyType === "MODEL" || taxonomy.taxonomyType === "PROCESSING" : false;

      let externalNameRelativePath = taxon.externalName;
      if (parentExternalNameRelativePath) {
        externalNameRelativePath = `${parentExternalNameRelativePath}/${taxon.externalName}`;
      }

      tagMetadataMap.set(taxon.path, {
        label: taxon.label ? taxon.label : taxon.name,
        taxon,
        taxonomy,
        parentLabel,
        parentPath,
        processing,
        externalNameRelativePath,
        path: taxon.path,
      } as TagMetadata);
    }

    if (taxon && taxon.children) {
      taxon.children.forEach((childTaxon) => {
        buildTagMetadata(childTaxon, taxonomy, tagMetadataMap);
      });
    }
  }
}

export function isFromPrompt(taxon: Taxon): boolean {
  return taxon.valuePath === "VALUE" || taxon.valuePath === "VALUE_OR_ALL_CONTENT";
}
