export const optionMetadata = {
  label: "Document Lookup",
  description: "Search and select a document from stores",
  options: [
    {
      name: "filter",
      label: "Filter Pattern",
      type: "string",
      description: "Pattern to filter documents",
    },
    {
      name: "pageSize",
      label: "Page Size",
      type: "number",
      description: "Number of results per page",
      default: 10,
    },
  ],
};
