<script lang="ts" setup>
import type { PropType } from "vue";
import type { DataAttribute, DataException, DataObject } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  exception: {
    type: Object as PropType<DataException>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: false,
  },
  validatedExceptionUuids: {
    type: Array as PropType<string[]>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const tempUuid = ref(`${props.exception.dataObjectUuid}-${props.exception.path}`);

const taxon = computed(() => getTaxon());

function getTaxon() {
  const taxon = tagMetadataMap.value.get(props.exception.path);
  if (taxon) {
    return taxon;
  }
  return null;
}

function overrideExceptionUuid(dataObject, exception) {
  const dataView = createDataFormViewerStore(props.viewId);
  dataView.overrideExceptionMapper(dataObject, exception);
}

function scrollToElement(id, type) {
  if (id) {
    const dataView = createDataFormViewerStore(props.viewId);
    if (type === "attribute") {
      dataView.setFocusedAttributeUuid(id);
    } else {
      dataView.setFocusedAttributeUuid(tempUuid.value);
    }
    nextTick(() => {
      const selector = type === "attribute" ? `[data-attr-uuid="${id}"]` : `[data-attr-uuid="${tempUuid.value}"]`;
      const element = document.querySelector(selector);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }
}
</script>

<template>
  <div v-if="exception.open" class="flex">
    <VMenu>
      <MaterialDesignIcon
        name="alertbox" class="mr-1" :class="validatedExceptionUuids.includes(exception.uuid)
          ? ['text-red-600'] : ['text-[#922FB7]']
        " size="20"
      />
      <template #popper>
        <div class="bg-white dark:bg-gray-800 shadow sm:rounded-lg">
          <div class="px-2 py-2">
            <div class="mt-2 text-sm text-gray-700" style="width: 400px">
              <KodexaDataException :exception="exception" :attribute="exception.dataAttribute" />
              <div v-if="false" class="text-right">
                <KodexaButton
                  v-if="!validatedExceptionUuids.includes(exception.uuid)"
                  class="mt-2 mb-2"
                  icon="alert-circle-check-outline"
                  type="danger"
                  size="small"
                  @mousedown="overrideExceptionUuid(dataObject, exception)"
                >
                  Override Exception
                </KodexaButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </VMenu>
    <div>
      <div class="mt-1 text-gray-700">
        {{ exception.message }}
      </div>
      <p v-if="taxon" class="text-xs text-gray-600 dark:text-gray-300 font-medium">
        <span
          :class="{ 'cursor-pointer text-blue-500 hover:underline': exception.dataAttrUuid || exception.dataObjectUuid }"
          @click="exception.dataAttrUuid ? scrollToElement(exception.dataAttrUuid, 'attribute')
            : exception.dataObjectUuid ? scrollToElement(exception.dataObjectUuid, 'object') : null"
        >
          {{ taxon.label }}
        </span>
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
