<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import appStore from "~/store";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  notes: {
    type: String,
    required: false,
    default: "",
  },
  confirmText: {
    type: String,
    required: false,
    default: "Confirm",
  },
  confirmIcon: {
    type: String,
    required: false,
    default: "check",
  },
  cancelText: {
    type: String,
    required: false,
    default: "Cancel",
  },
  cancelIcon: {
    type: String,
    required: false,
    default: "cancel",
  },
  type: {
    type: String,
    required: false,
    default: "primary",
  },
  acceptClasses: {
    type: Array,
    required: false,
    default: () => ["bg-red-600 dark:bg-red-700"],
  },
  icon: {
    type: String,
    required: false,
    default: "alert",
  },
  iconClasses: {
    type: Array,
    required: false,
    default: () => ["text-red-600 dark:text-red-400"],
  },
  showCancel: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);

watch(open, (newValue) => {
  if (!newValue) {
    appStore.platformStore.showLoad();
  } else {
    appStore.platformStore.hideLoad();
  }
}, { immediate: true });
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[101]" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="min-height: 300px;width: 90%"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" size="16" class="text-gray-400 dark:text-gray-300" aria-hidden="true" @click="emit('cancel')" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                >
                  <MaterialDesignIcon :name="icon" size="32" :classes="iconClasses" aria-hidden="true" />
                </div>
                <div class="mt-4 text-center sm:ml-4 sm:mt-0 sm:text-left w-full" style="min-height: 200px">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                    {{ props.title }}
                  </DialogTitle>
                  <div class="mt-4">
                    <hr class="my-2 h-px border-0 bg-gray-200 dark:bg-gray-700">
                    <p class="text-sm text-gray-700 dark:text-gray-300">
                      {{ props.message }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-gray-400 mt-6">
                      {{ props.notes }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-8 sm:flex sm:flex-row-reverse">
                <KodexaButton
                  id="dialogConfirm"
                  style="z-index: 1000"
                  :type="type"
                  :class="acceptClasses"
                  :icon="confirmIcon"
                  @click="emit('confirm')"
                >
                  {{ props.confirmText }}
                </KodexaButton>
                <KodexaButton
                  v-if="showCancel"
                  id="dialogCancel"
                  style="z-index: 1000"
                  type="secondary"
                  class="mr-2 dark:border-gray-600 dark:text-gray-300"
                  :icon="cancelIcon"
                  @click="emit('cancel')"
                >
                  {{ props.cancelText }}
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
