<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { storeToRefs } from "pinia";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const booleanValue = computed({
  get() {
    return props.attribute.booleanValue;
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      booleanValue: value,
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.attribute?.dataExceptions?.length === 0;
});

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div class="flex justify-center mt-1" :style="style" @click="focus">
    <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
    <KodexaCheckbox
      :id="`kdx-attribute-${props.attribute?.uuid}`"
      v-model="booleanValue"
      :name="`kdx-attribute-${props.attribute?.uuid}`"
      :tabindex="props.tabIndex"
      :style="style"
      :disabled="props.dataObject?.documentFamily.locked === true || !editable"
      :valid="valid"
      @focus="focus"
      @blur="dataViewHelper.blur()"
    />
  </div>
</template>

<style scoped>
</style>
