<script setup lang="ts">
import type { TaxonFeatures } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const availableTabs = [
  {
    ref: "groupDefaults",
    name: "Data Element Groups",
  },
  {
    ref: "nonGroupDefauls",
    name: "Data Elements",
  },
];

const selectedTab = ref(availableTabs[0]);
const groupFeatures = ref([] as TaxonFeatures[]);
const nonGroupFeatures = ref([] as TaxonFeatures[]);
const { modelMetadata } = storeToRefs(appStore.projectStore);
watch(modelMetadata, () => {
  const groupFeaturesArray: TaxonFeatures[] = [];
  const nonGroupFeaturesArray: TaxonFeatures[] = [];

  Array.from(modelMetadata.value.values()).forEach((metadata) => {
    metadata?.taxonFeatures?.forEach((taxonFeature) => {
      if (taxonFeature.groupOnly) {
        groupFeaturesArray.push({ ...taxonFeature, id: crypto.randomUUID() });
      } else if (!taxonFeature.groupOnly) {
        nonGroupFeaturesArray.push({ ...taxonFeature, id: crypto.randomUUID() });
      }
    });
  });

  groupFeatures.value = groupFeaturesArray;
  nonGroupFeatures.value = nonGroupFeaturesArray;
}, { immediate: true, deep: true });

const projectGroupFeatureValues = ref({});
const projectNonGroupFeatureValues = ref({});
</script>

<template>
  <div class="mx-2">
    <KodexaToolbar>
      <KodexaArticle article-id="9787707" text="Learn more about data definition feature defaults" :slide="false" />
    </KodexaToolbar>
    <KodexaTabStrip
      :small="true"
      :items="availableTabs"
      :selected="selectedTab"
      @selected="selectedTab = $event"
    />
    <div v-if="selectedTab.ref === 'groupDefaults'">
      <div v-for="additionalTaxonFeature in groupFeatures" :key="additionalTaxonFeature.id">
        <ConfigurationOption
          v-for="option in additionalTaxonFeature.options"
          :key="option.name"
          v-model="projectGroupFeatureValues"
          :item="option"
        />
      </div>
    </div>
    <div v-if="selectedTab.ref === 'nonGroupDefauls'">
      <div v-for="additionalTaxonFeature in nonGroupFeatures" :key="additionalTaxonFeature.id">
        <ConfigurationOption
          v-for="option in additionalTaxonFeature.options"
          :key="option.name"
          v-model="projectNonGroupFeatureValues"
          :item="option"
        />
      </div>
    </div>
  </div>
</template>
