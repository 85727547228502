import type { RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import NProgress from "nprogress";
import { storeToRefs } from "pinia";
import { createRouter, createWebHistory } from "vue-router";
import appStore from "~/store";
import { log } from "~/utils/logger";
import AppContainer from "~/views/common/app-container.vue";
import AppAdmin from "~/views/studio/home/app-admin.vue";
import AppHome from "~/views/studio/home/app-home.vue";
import OrganizationNew from "~/views/studio/organizations/home/choose-organization-subscription.vue";
import NoOrganization from "~/views/studio/organizations/home/no-organization.vue";
import OrganizationAdmin from "~/views/studio/organizations/home/organization-admin.vue";
import ProjectAdmin from "~/views/studio/organizations/project/project-admin.vue";
import ProfilePopup from "~/views/studio/profile-popup.vue";
import TermsCondition from "~/views/studio/terms-condition.vue";
import AuthCallback from "~/views/studio/user-signup.vue";
import FlowHome from "~/views/workflow/home/workflow-home.vue";
import TaskView from "~/views/workflow/organizations/project/task/task-view.vue";

const Organization = () => import("~/views/studio/organizations/organization.vue");
const WorkflowOrganization = () => import("~/views/workflow/organizations/organization.vue");
const Workspace = () => import("~/views/studio/organizations/project/workspace/workspace.vue");
const Profile = () => import("~/views/studio/profile/profile.vue");
const OrganizationHome = () => import("~/views/studio/organizations/home/organization-home.vue");
const WorkflowOrganizationHome = () => import("~/views/workflow/organizations/home/organization-home.vue");
const WorkflowProjectHome = () => import("~/views/workflow/organizations/project/project-home.vue");
const Sidecar = () => import("~/views/sidecar/sidecar.vue");
const ProjectHome = () => import("~/views/studio/organizations/project/project-home.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/a/home",
  },
  {
    path: "/404",
    name: "ErrorPage",
    component: () => import("~/views/errorPages/index.vue"),
  },
  {
    path: "/you-ve-got-mail",
    name: "Signup",
    component: AuthCallback,
  },
  {
    name: "payment",
    path: "/new/payments",
    component: OrganizationNew,
    meta: { requiresAuth: true },
  },
  {
    name: "sidecar",
    path: "/sidecar/:organizationId/:projectId/:workspaceId",
    component: Sidecar,
    meta: { requiresAuth: false },
  },
  {
    name: "no-organization",
    path: "/no-organization",
    component: NoOrganization,
    meta: { requiresAuth: true },
  },
  {
    path: "/a",
    name: "Studio",
    meta: { requiresAuth: true },
    component: AppContainer,
    redirect: "/a/home",
    beforeEnter: authGuard,
    children: [
      {
        path: "profile",
        component: Profile,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "home",
        component: AppHome,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "callback",
        component: AppHome,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "admin",
        component: AppAdmin,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "terms",
        component: TermsCondition,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "user-profile",
        component: ProfilePopup,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "o",
        children: [
          {
            path: "new",
            component: OrganizationNew,
          },
          {
            path: ":organizationId",
            component: Organization,
            children: [
              {
                path: "p",
                children: [
                  {
                    path: ":projectId",
                    children: [
                      {
                        path: "home",
                        name: "projectHome",
                        component: ProjectHome,
                      },
                      {
                        path: "workspaces/:workspaceId",
                        component: Workspace,
                      },
                      {
                        path: "admin",
                        component: ProjectAdmin,
                      },
                    ],
                  },
                ],
              },
              {
                path: "home",
                component: OrganizationHome,
              },
              {
                path: "admin",
                component: OrganizationAdmin,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/f",
    name: "Workflow",
    meta: { requiresAuth: true },
    component: AppContainer,
    redirect: "/f/home",
    beforeEnter: authGuard,
    children: [
      {
        path: "profile",
        component: Profile,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "home",
        component: FlowHome,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "terms",
        component: TermsCondition,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "user-profile",
        component: ProfilePopup,
        meta: {
          header: {
            title: "",
          },
        },
      },
      {
        path: "o/:organizationId",
        component: WorkflowOrganization,
        children: [
          {
            path: "home",
            component: WorkflowOrganizationHome,
            meta: {
              header: {
                title: "Organization",
              },
            },
          },
          {
            path: "p/:projectId/t/:taskId",
            component: TaskView,
            meta: {
              header: {
                title: "Tasks",
              },
            },
          },
          {
            path: "p/:projectId/home",
            component: WorkflowProjectHome,
            meta: {
              header: {
                title: "Tasks",
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  NProgress.done();
  useIntercom().update();
  useIntercom().hide();
});

router.beforeEach(async (to, from, next) => {
  const currentUrl = to.fullPath;
  localStorage.setItem("lastVisitedUrl", currentUrl);
  if (to.path === "/you-ve-got-mail") {
    next();
    return;
  }

  if (!await authGuard(to)) {
    next(false);
    appStore.userStore.login(to.query.signup === "true");
    return;
  }

  if (!NProgress.isStarted()) {
    NProgress.start();
  }

  const workspaceId = to.params.workspaceId;
  if (workspaceId || to.query.workspaceId) {
    log.info(`Loading workspace ${workspaceId || to.query.workspaceId}`);
    await appStore.workspaceStore.loadWorkspace(workspaceId || to.query.workspaceId);
  } else {
    if (!await appStore.workspaceStore.clearCurrentWorkspace()) {
      next(false);
      return;
    }
  }

  if (to.params.projectId) {
    await appStore.projectStore.loadProject(to.params.projectId);
  } else {
    if (!await appStore.projectStore.clearCurrentProject()) {
      // We couldn't clear the project so we need to stop
      // the navigation
      next(false);
      return;
    }
  }

  if (to.params.organizationId) {
    log.info(`Loading organization ${to.params.organizationId}`);
    await appStore.organizationStore.loadOrganization(to.params.organizationId);
  } else {
    if (!await appStore.organizationStore.clearCurrentOrganization()) {
      next(false);
      return;
    }
  }

  if (to.params.taskId) {
    const loadingEvent = {
      id: crypto.randomUUID(),
      title: "Loading task...",
    };
    appStore.platformStore.addLoadingEvent(loadingEvent);
    await appStore.taskStore.loadTask(to.params.taskId, loadingEvent);
    appStore.platformStore.removeLoadingEvent(loadingEvent);
  }

  if (to.meta.header) {
    appStore.platformStore.setCurrentHeading(to.meta.header);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!await appStore.userStore.isLoggedIn()) {
      next({ path: "/a/home" });
    } else {
      // check if user has viewed profile
      // route should not include "/a/terms", "/a/profile", or "/new/payments" to prevent infinite loop
      if ((to.path === "/a/home" || to.path === "/f/home") && appStore.userStore.hasNoUserDetails()) {
        next({ path: "/a/user-profile" });
        return;
      }

      // check if user has accepted terms / part of any membership
      // route should not be in either of those path to prevent infinite loop
      if ((to.path === "/a/home" || to.path === "/f/home") && !appStore.userStore.needsToAcceptTerms()) {
        next({ path: "/a/terms" });
        return;
      }

      // check if user is part of any memberships
      if (to.path !== "/no-organization" && to.path !== "/a/terms" && to.path !== "/a/user-profile" && appStore.userStore.hasNoMemberships()) {
        next({ path: "/no-organization" });
        return;
      }

      const commonPaths = ["/a/terms", "/a/user-profile", "/new/payments", "/no-organization"];

      // If the user is not going to a common path but the path starts with /a/ then need to check they have studio
      // access and if not take them to /f/home
      const { isStudioUser } = storeToRefs(appStore.userStore);
      if (!commonPaths.includes(to.path) && to.path.startsWith("/a/") && !isStudioUser.value) {
        next({ path: "/f/home" });
        return;
      }

      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
