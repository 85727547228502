import type { Ref } from "vue";
import type { DataAttribute, DataObject, Taxonomy } from "~/model";
import type { KeyboardShortcut } from "~/store/useKeyboard";
import { defineStore, storeToRefs } from "pinia";
import { debounce } from "vue-debounce";
import appStore from "~/store/index";
import { createKodexaFormulaResolver } from "~/store/useKodexaFormula";
import { log } from "~/utils/logger";

export function createDataAttributeHelper(initialDataAttribute: Ref<DataAttribute>, initialDataObject: Ref<DataObject>, currentViewId: string) {
  return defineStore(`data-attribute-helper-${initialDataAttribute.value.uuid}`, () => {
    const viewId = ref<string | undefined>(currentViewId);
    const keyboardStore = useKeyboard();

    const { taxonomies } = storeToRefs(appStore.projectStore);

    const formatters = computed(() => {
      const taxonomy = taxonomies.value.find((taxonomy: Taxonomy) => taxonomy.ref === initialDataObject.value.taxonomyRef);
      const useFormula = createKodexaFormulaResolver(ref(taxonomy), initialDataObject);
      const { formatters } = storeToRefs(useFormula);
      return formatters.value;
    });

    const style = computed(() => {
      const style = {} as any;
      formatters.value.get(initialDataAttribute.value.tag)?.forEach((formatter) => {
        if (formatter.type === "backgroundColor") {
          style["background-color"] = `${formatter.properties?.color} !important`;
        }
        if (formatter.type === "outlineColor") {
          style.border = `2px solid ${formatter.properties?.color}`;
        }
        if (formatter.type === "textColor") {
          style.color = `${formatter.properties?.color} !important`;
        }
      });
      return style;
    });

    const conditionalValidationIcon = computed(() => {
      const icon = {} as { icon: string | undefined; color: string | undefined };
      formatters.value.get(initialDataAttribute.value.tag)?.forEach((formatter) => {
        if (formatter.type === "icon") {
          icon.icon = formatter.properties?.icon as string | undefined;
          icon.color = formatter.properties?.color as string | undefined;
        }
      });
      return icon;
    });

    const attribute = ref(initialDataAttribute);
    const dataObject = ref(initialDataObject);
    const clearShortcut = {
      key: "control+e",
      description: `Clear the value for ${attribute.value.tag}`,
      callback: clearAttribute,
    } as KeyboardShortcut;

    const deleteAttributeShortcut = {
      key: "alt+e",
      description: "Delete the value in the active field",
      callback: deleteAttribute,
    } as KeyboardShortcut;

    const debouncedAddDataObject = debounce(() => {
      appStore.workspaceStore.addSiblingDataObjectByUuid(dataObject.value.uuid);
    }, 300);

    const addDataObjectShortcut = {
      key: "control+enter",
      description: "Add a new data group in the active field",
      callback: () => {
        debouncedAddDataObject();
      },
    } as KeyboardShortcut;

    keyboardStore.addShortcut(addDataObjectShortcut);

    const deleteDataObjectShortcut = {
      key: "control+m",
      description: "Delete the active data group",
      callback: deleteDataObject,
    } as KeyboardShortcut;

    const blurDataAttributeShortcut = {
      key: "alt",
      altKey: "alt",
      description: "Removes the focus on an input field",
      callback: blur,
    } as KeyboardShortcut;

    const isFocused = ref(false);

    function focus(clear = false) {
      log.info(`Focusing on attribute ${attribute.value?.uuid} [${attribute.value?.tagUuid}] in view ${viewId.value}, with clear ${clear}`);
      isFocused.value = !clear;
      if (attribute.value?.tagUuid) {
        const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
        log.info(`Pre-Focus on tag ${attribute.value.tagUuid} in workspace ${currentWorkspaceId.value}`);
        if (currentWorkspaceId.value && dataObject.value && attribute.value) {
          const useSidecar = createSidecar(currentWorkspaceId.value);
          log.info(`Focusing on tag ${attribute.value.tagUuid} in workspace ${currentWorkspaceId.value} - ${clear ? "unfocus" : "focus"}`);
          clear ? useSidecar.unfocusTag() : useSidecar.focusAttribute(attribute.value, dataObject.value);
          clear ? appStore.workspaceStore.setFocusedAttribute(undefined) : appStore.workspaceStore.setFocusedAttribute(attribute.value);
        } else {
          log.warn(`Unable to focus on tag ${attribute.value.tagUuid} in workspace ${currentWorkspaceId.value}`);
        }
      }
      if (viewId.value) {
        try {
          const dataFormViewer = createDataFormViewerStore(viewId.value);
          clear ? dataFormViewer.setFocusedAttributeUuid(undefined) : dataFormViewer.setFocusedAttributeUuid(attribute.value.uuid);
        } catch (e) {
          log.error(`Error focusing on attribute ${attribute.value.uuid} in view ${viewId.value}`, e);
        }
      }
    }

    watchDebounced(isFocused, (value) => {
      if (value) {
        keyboardStore.addShortcut(clearShortcut);
        keyboardStore.addShortcut(deleteAttributeShortcut);
        keyboardStore.addShortcut(addDataObjectShortcut);
        keyboardStore.addShortcut(deleteDataObjectShortcut);
        keyboardStore.addShortcut(blurDataAttributeShortcut);
      } else {
        keyboardStore.removeShortcut(clearShortcut);
        keyboardStore.removeShortcut(deleteAttributeShortcut);
        keyboardStore.removeShortcut(addDataObjectShortcut);
        keyboardStore.removeShortcut(deleteDataObjectShortcut);
        keyboardStore.removeShortcut(blurDataAttributeShortcut);
      }
    }, { immediate: true, debounce: 800 });

    function clearAttribute() {
      appStore.workspaceStore.clearAttribute(dataObject.value, attribute.value);
    }

    function deleteAttribute() {
      log.info(`Deleting attribute ${attribute.value.uuid} in data object ${dataObject.value.uuid}`);
      appStore.workspaceStore.deleteAttribute(dataObject.value, attribute.value);
    }

    function deleteDataObject() {
      appStore.workspaceStore.deleteDataObjectByUuid(dataObject.value.uuid);
    }

    function addDataObject() {
      appStore.workspaceStore.addSiblingDataObjectByUuid(dataObject.value.uuid);
    }

    function updated() {
      appStore.workspaceStore.addUpdatedAttributeUuid(dataObject.value, attribute.value.uuid);
    }

    function blur() {
      appStore.workspaceStore.setFocusTagUuid(undefined);
      log.info(`Blurring on attribute ${attribute.value.uuid} in view ${viewId.value}`);
      const { popupOpen } = storeToRefs(useKeyboard());
      const inputElement = document.getElementById(`kdx-attribute-${attribute.value.uuid}`); // Replace with your actual input element ID
      if (inputElement) {
        inputElement.blur();
      }
      if (!popupOpen.value) {
        focus(true);
      }
    }

    function setAttribute(dataAttribute: DataAttribute) {
      attribute.value = dataAttribute;
    }

    const errors = computed(() => {
      const attributeExceptions = attribute.value.dataExceptions || [];
      const dataObjectExceptionForAttribute = dataObject.value.dataExceptions?.find(exception => exception.path === attribute.value.path);
      const allExceptions = [...attributeExceptions, ...(dataObjectExceptionForAttribute ? [dataObjectExceptionForAttribute] : [])];
      return {
        [`kdx-attribute-${attribute.value.uuid}`]: allExceptions.filter(exception => exception.open) || [],
      };
    });

    const valid = computed(() => {
      return errors.value[`kdx-attribute-${attribute.value.uuid}`]?.length === 0 && (!dataObject.value.dataExceptions?.find(exception => exception.path === attribute.value.path && exception.open));
    });

    return {
      viewId,
      attribute,
      dataObject,
      focus,
      blur,
      clearAttribute,
      updated,
      deleteAttribute,
      deleteDataObject,
      addDataObject,
      setAttribute,
      style,
      conditionalValidationIcon,
      formatters,
      errors,
      valid,
    };
  })();
}
