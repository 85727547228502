<script lang="ts" setup>
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { getDocumentFamily } from "~/api/document-families/document-families";
import { getContentObjectImage } from "~/api/stores/stores";
import appStore from "~/store";
import { RefHelper } from "~/utils/ref-utils";

// Define a more specific type for Node
interface GraphNode {
  data: {
    documentFamilyId?: string;
    path?: string;
    raw?: {
      page_numbers?: number[];
    };
  };
}

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<GraphNode>,
    default: () => ({ data: {} }),
  },
});

const workspaceStore = appStore.workspaceStore;

const pageImages = ref<{ [key: number]: string }>({});
const showAllPages = ref(false);

function openPage(page) {
  if (!workspaceStore.sidecarPanelOpen) {
    workspaceStore.toggleSidecar();
  }
  nextTick(() => {
    const useSidecar = createSidecar(workspaceStore.currentWorkspaceId);
    if (props.node?.data.documentFamilyId) {
      useSidecar.focusPage(props.node.data.documentFamilyId, page);
    }
  });
}

function getVisiblePages() {
  if (!props.node?.data.raw?.page_numbers) {
    return [];
  }

  const pages = props.node.data.raw.page_numbers;

  if (pages.length <= 5 || showAllPages.value) {
    return pages;
  }

  // Return first 2 and last 2 pages
  return [pages[0], pages[1], "ellipsis", pages[pages.length - 2], pages[pages.length - 1]];
}

async function loadPageImage(page: number) {
  if (!props.node?.data.documentFamilyId || typeof page !== "number" || pageImages.value[page]) {
    return;
  }

  try {
    const documentFamily = await getDocumentFamily(props.node.data.documentFamilyId);
    const refHelper = new RefHelper(documentFamily.storeRef);
    const blob = await getContentObjectImage(
      refHelper.getOrgSlug(),
      refHelper.getSlug(),
      props.node.data.documentFamilyId,
      page,
      { dpi: 5 },
    );
    const arrayBuffer = await blob.arrayBuffer();
    // Fix typing issue with String.fromCharCode
    const uint8Array = new Uint8Array(arrayBuffer);
    const chars = Array.from(uint8Array).map(byte => String.fromCharCode(byte)).join("");
    pageImages.value[page] = btoa(chars);
  } catch (e) {
    console.error("Error loading page image", e);
  }
}

function loadVisiblePageImages() {
  const visiblePages = getVisiblePages();
  visiblePages.forEach((page) => {
    if (typeof page === "number") {
      loadPageImage(page);
    }
  });
}

function toggleShowAllPages() {
  showAllPages.value = !showAllPages.value;
  // Load images for newly visible pages
  nextTick(() => {
    loadVisiblePageImages();
  });
}

onMounted(() => {
  loadVisiblePageImages();
});
</script>

<template>
  <div class="page-preview-container py-4 justify-center">
    <div
      v-for="page in getVisiblePages()"
      :key="typeof page === 'number' ? page : 'ellipsis'"
      class="page-preview-wrapper px-2"
    >
      <div v-if="page === 'ellipsis'" class="page-preview ellipsis" @click="toggleShowAllPages">
        <span class="dots">...</span>
      </div>
      <div v-else class="page-preview" @click="openPage(page)">
        <KodexaFileIcon
          v-if="!pageImages[page]"
          :filename="props.node?.data.path || ''"
          class="size-12"
        />
        <img
          v-else
          :src="`data:image/png;base64,${pageImages[page]}`"
          width="50"
          alt="Page Preview"
          class="shadow"
        >
        <span class="page-number text-sm pt-0.5">Page {{ (typeof page === 'number' ? page + 1 : '') }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.page-preview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-preview {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
}

.dots {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 0.5;
}

.page-number {
  font-size: 0.8rem;
  margin-top: 4px;
}
</style>
