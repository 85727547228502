<script setup lang="ts">
import type { PropType } from "vue/dist/vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { Button } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import appStore from "~/store";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const useDataFormViewer = createDataFormViewerStore(props.viewId);

const {
  dataObjects,
} = storeToRefs(useDataFormViewer);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const tagMetadata = computed(() => {
  return tagMetadataMap.value.get(props.card.properties?.groupTaxon);
});

const scopedDataObjects = computed(() => {
  if (!tagMetadata.value) {
    return [];
  }

  let scopedDataObjects = tagMetadata.value
    ? dataObjects.value.filter((dataObject) => {
        return dataObject.path === tagMetadata.value.path;
      })
    : dataObjects.value;

  scopedDataObjects = props.parentTagMetadata
    ? scopedDataObjects.filter((dataObject) => {
        return dataObject.path === props.parentTagMetadata?.path;
      })
    : scopedDataObjects;

  if (props.parentDataObject) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      // We need to be able to handle children that haven't been saved yet
      return dataObject.parentId ? dataObject.parentId === props.parentDataObject?.id : dataObject.parent?.uuid === props.parentDataObject?.uuid;
    });
  }

  if (props.card?.properties?.filters) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      if (props.card?.properties?.filters) {
        for (const filter of props.card?.properties?.filters || []) {
          if (filter.type === "hasExceptionMessage") {
            const exception = dataObject.dataExceptions?.find((dataException) => {
              return dataException.message === filter.value;
            });
            return exception !== undefined;
          }
        }
      }
      return true;
    });
  }
  scopedDataObjects = scopedDataObjects.sort((a: DataObject, b: DataObject) => {
    if (a.sourceOrdering === undefined) {
      return -1;
    }
    if (b.sourceOrdering === undefined) {
      return 1;
    }
    // get last 4 characters of source ordering, then convert to number
    const sourceOrderingA: number = +a.sourceOrdering.slice(-4);
    const sourceOrderingB: number = +b.sourceOrdering.slice(-4);

    return sourceOrderingA - sourceOrderingB;
  });

  return scopedDataObjects;
});

const collapsed = ref(false);
const exceptionOpenFlag = ref(true);

function toggleCollapsed() {
  collapsed.value = !collapsed.value;
}

function addDataObject() {
  appStore.workspaceStore.addNewDataObject(tagMetadata.value, props.parentDataObject?.documentFamily, props.parentDataObject);
}

// I want to use the properties.rowSpan and work out the height in pixels
const rowHeight = computed(() => {
  if (!props.card.properties?.rowSpan) {
    return 24;
  }
  return props.card.properties?.rowSpan * 24;
});
</script>

<template>
  <div>
    <div
      v-if="card.properties?.showHeader"
      class="flex items-center justify-between mb-1"
    >
      <div class="flex-1">
        <div class="text-lg font-semibold leading-6 text-gray-900">
          {{ card.properties.title }}
        </div>
        <p v-if="card.properties?.subtitle" class="mt-1 text-sm text-gray-500 font-normal leading-relaxed">
          {{ card.properties.subtitle }}
        </p>
      </div>
      <div class="flex items-center">
        <Button
          v-if="!card.properties.hideAdd && tagMetadata"
          :disabled="!exceptionOpenFlag"
          class="rounded-md hover:bg-gray-100 transition-colors duration-200"
          fill-mode="flat"
          size="small"
          @click="addDataObject"
        >
          <MaterialDesignIcon
            v-tooltip="`Add new ${tagMetadata?.taxon.label}`"
            name="plus"
            class="text-blue-400 hover:text-blue-500 transition-colors duration-200"
            size="18"
            :tooltip="`Add new ${tagMetadata?.taxon.label}`"
          />
        </Button>
        <Button
          v-if="!card.properties.hideCollapse && tagMetadata"
          v-tooltip="collapsed ? `Expand ${tagMetadata.taxon.label} panel` : `Collapse ${tagMetadata.taxon.label} panel`"
          class="rounded-md hover:bg-gray-100 transition-colors duration-200"
          fill-mode="flat"
          size="small"
          @click="toggleCollapsed"
        >
          <MaterialDesignIcon
            :name="collapsed ? 'upArrow' : 'downArrow'"
            class="text-blue-400 hover:text-blue-500 transition-colors duration-200"
            size="18"
          />
        </Button>
      </div>
    </div>
    <KodexaDataObjectGrid
      v-if="!collapsed"
      :data-objects="scopedDataObjects"
      :height="rowHeight"
      :parent-data-object="parentDataObject"
      :group-taxon-metadata="tagMetadata"
      class="mt-1 px-1"
      :editable="true"
      :show-header="false"
      :view-id="viewId"
      :groupable="false"
    />
  </div>
</template>

<style scoped>

</style>
