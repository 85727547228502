/*
 * Copyright (C) 2025 Kodexa Inc - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import type { ContentNode, KddbDocument } from "~/components/document/document";
import type { AppStore } from "~/store";
import { Logger } from "tslog";
import { log } from "~/utils/logger";

export interface SpatialOptions {
  focusTagUuid: string | undefined;
  highlightOnlyFocus: boolean;
  highlightOnlyTagUuids: string[];
  showLines: boolean;
  showContentAreas: boolean;
  showImages: boolean;
  invertImages: boolean;
  showPageImages: boolean;
  showText: boolean;
  showWords: boolean;
  showTooltips: boolean;
  labeling: boolean;
  height: number;
  width: number;
  focusNodeUuid: string | undefined;
}

export interface SpatialContext {
  appStore: AppStore;
  options: SpatialOptions;
  height: number;
  width: number;
  textSpans: DocumentFragment;
  container: number[];
  scaleFactor: number;
  divs: Element[];
  selectedNode: ContentNode | undefined;
  currentTagReference: Element | undefined;
  focusedNode: SpatialNode | undefined;
  angle: number;
}

export interface SpatialNode {
  element: Element | undefined;
  node: ContentNode;
  nodeType: string | undefined;
  bbox: number[];
  originalBbox: number[];
  tags: string[];
  tagUuids: string[];
}

// @ts-expect-error implement the clear children function if it isn't there
if (typeof Element.prototype.clearChildren === "undefined") {
  Object.defineProperty(Element.prototype, "clearChildren", {
    configurable: true,
    enumerable: false,
    value() {
      while (this.firstChild) {
        this.removeChild(this.lastChild);
      }
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function buildRectNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {

}

function colourIsLight(r: number, g: number, b: number) {
  // Counting the perceptive luminance
  // human eye favors green color...
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return (a < 0.5);
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
}

export function addTagging(spatialNode: SpatialNode, classes: string[], newDiv: Element, spatialContext: SpatialContext, style = "") {
  let tagged = false;
  spatialNode.node.getTags(false).forEach((tag) => {
    const tagMeta = spatialContext.appStore.projectStore.getTagMetadata(tag);
    if (tagMeta && tagMeta.taxon && tagMeta.taxonomy && tagMeta.taxonomy.enabled && tagMeta.taxon.enabled) {
      if (spatialContext.appStore.workspaceStore.disabledTaxonomies.includes(tagMeta.taxonomy.ref)) {
        return;
      }

      const tagValue = spatialNode.node.getTag(tag)[0];
      if ((tagValue.confidence >= 0) || (tagValue.confidence === undefined || tagValue.confidence === null)) {
        classes.push("kodexa-tag-spatial-container");
        classes.push("tag-tooltip");

        if (spatialContext.options.focusTagUuid && spatialNode.tagUuids.includes(spatialContext.options.focusTagUuid)) {
          spatialContext.currentTagReference = newDiv;
          spatialContext.focusedNode = spatialNode;
        }

        classes.push(`tag-uuid-${tagValue.uuid}`);
        if (spatialContext.options.highlightOnlyTagUuids.length === 0 || (tagValue && spatialContext.options.highlightOnlyTagUuids.includes(tagValue.uuid))) {
          let opacity = spatialContext.options.showText ? "" : "70";

          // @ts-expect-error the color function is a bit out there
          const foreColor = colourIsLight(hexToRgb(tagMeta.color)) ? "black" : "white";
          const bgColor = tagMeta.taxon.color as string;

          if ((spatialContext.options.focusTagUuid && spatialNode.tagUuids.includes(spatialContext.options.focusTagUuid))) {
            classes.push("kodexa-tag-pulse");
          } else if (spatialContext.options.focusTagUuid) {
            opacity = "20";
          }
          style = `${style};background-color:${bgColor}${opacity};color:${foreColor};`;
          tagged = true;
        }
      } else {
        // skipping tag because it is not confident enough
      }
    }
  });
  if (spatialContext.options.focusNodeUuid && spatialNode.node.uuid === spatialContext.options.focusNodeUuid) {
    const color = "#FFDD005F";
    // @ts-expect-error the color function is a bit out there
    const foreColor = colourIsLight(hexToRgb(color)) ? "black" : "white";
    style = `${style};background-color:${color};color:${foreColor};`;
    tagged = true;
  }

  if (spatialContext.options.showText && !tagged) {
    style = `${style};opacity:1.0; background-color: white`;
  }
  newDiv.setAttribute("style", style);
  newDiv.setAttribute("class", classes.join(" "));
  spatialNode.element = newDiv;
}

export function buildLineNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {
  const newDivTag = document.createElement("div");

  newDivTag.id = `node-${spatialNode.node.uuid}`;
  // @ts-expect-error we add to the element
  newDivTag.spatialNode = spatialNode;
  newDivTag.setAttribute("nodeUuid", spatialNode.node.uuid);

  const height = (spatialNode.bbox[3] - spatialNode.bbox[1]);
  const top = spatialContext.height - spatialNode.bbox[3];

  let style = `white-space: nowrap; position:absolute; top:${top}px;left:${spatialNode.bbox[0]}px; display:block; opacity:1.0; width:${spatialNode.bbox[2] - spatialNode.bbox[0]}px; height: ${height}px`;

  if (spatialContext.options.showLines) {
    style = `${style};border-style: solid; border-width: 2px; border-color: #e703fc;`;
  }

  newDivTag.setAttribute("style", style);
  const classes = ["tooltip", "kodexa-text-node", "kodexa-selectable"];

  addTagging(spatialNode, classes, newDivTag, spatialContext, style);

  spatialContext.textSpans.insertBefore(newDivTag, spatialContext.textSpans.firstChild);
  spatialNode.element = newDivTag;
}

export function buildContentAreaNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {
  const newDivTag = document.createElement("div");

  newDivTag.id = `node-${spatialNode.node.uuid}`;
  // @ts-expect-error we add to the element
  newDivTag.spatialNode = spatialNode;
  newDivTag.setAttribute("nodeUuid", spatialNode.node.uuid);

  const height = spatialNode.bbox[3] - spatialNode.bbox[1];
  const top = spatialContext.height - spatialNode.bbox[3];

  let style = `white-space: nowrap; position:absolute; top:${top}px;left:${spatialNode.bbox[0]}px; display:block; opacity:1.0; width:${spatialNode.bbox[2] - spatialNode.bbox[0]}px; height: ${height}px`;

  const classes = ["tooltip", "kodexa-text-node", "kodexa-selectable"];
  if (spatialContext.options.showContentAreas) {
    style = `${style};border-style: solid; border-width: 2px; border-color: #0303fc;`;
  }
  addTagging(spatialNode, classes, newDivTag, spatialContext, style);
  spatialContext.textSpans.insertBefore(newDivTag, spatialContext.textSpans.firstChild);
  spatialNode.element = newDivTag;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function buildFigureLine(spatialContext: SpatialContext) {

}

export function buildImageNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {
  let skipImage = false;

  if (spatialNode.node.getFeature("spatial", "pageImage") !== null) {
    return;
  }
  if (spatialContext.options && !spatialContext.options.showImages && spatialNode.node.getFeature("spatial", "pageImage") === null) {
    skipImage = true;
  }
  if (spatialContext.options && !spatialContext.options.showPageImages && spatialNode.node.getFeature("spatial", "pageImage") !== null) {
    skipImage = true;
  }

  if (spatialNode.node.getContent() && !skipImage) {
    const newDivTag = document.createElement("div");
    const newImageTag = document.createElement("img");
    newImageTag.setAttribute("src", spatialNode.node.getContent());
    const height = spatialNode.bbox[3] - spatialNode.bbox[1];
    const top = spatialContext.height - spatialNode.bbox[3];
    const style = `white-space: nowrap; position:absolute; top:${top}px;left:${spatialNode.bbox[0]}px; display:block; `;
    newDivTag.setAttribute("style", style);
    newImageTag.setAttribute("width", `${spatialNode.bbox[2] - spatialNode.bbox[0]}px`);
    newImageTag.setAttribute("height", `${height}px`);

    if (spatialContext.options && spatialContext.options.invertImages) {
      newImageTag.style.filter = "invert(100%)";
    }

    newDivTag.appendChild(newImageTag);

    spatialContext.textSpans.insertBefore(newDivTag, spatialContext.textSpans.firstChild);
    spatialNode.element = newDivTag;
  }
}

export function buildTextNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {
  const newTextSpan = document.createElement("span");

  newTextSpan.id = `node-${spatialNode.node.uuid}`;
  // @ts-expect-error we are adding a property to the dom element
  newTextSpan.spatialNode = spatialNode;
  newTextSpan.setAttribute("nodeUuid", spatialNode.node.uuid);

  let opacity = "1.0";
  if (spatialContext.options && !spatialContext.options.showText) {
    opacity = "1.0";
  } else {
    newTextSpan.textContent = spatialNode.node.getContent();
  }

  const classes = ["tooltip", "kodexa-text-node", "kodexa-selectable"];
  const width = (spatialNode.bbox[2] - spatialNode.bbox[0]) + 1;
  const height = (spatialNode.bbox[3] - spatialNode.bbox[1]) + 1;

  let textSpanStyle = `white-space: nowrap;position:absolute; top:${(spatialContext.height - spatialNode.bbox[3]) - 1}px;left:${spatialNode.bbox[0]}px; width:${width}px;height:${height}px;opacity:${opacity}`;
  textSpanStyle = `${textSpanStyle};`;
  if (spatialContext.options.showWords) {
    textSpanStyle = `${textSpanStyle};border-style: solid; border-width: 2px; border-color: #7ffc03;`;
  }

  textSpanStyle = `${textSpanStyle};font-size: ${(spatialNode.bbox[3] - spatialNode.bbox[1]) * 0.8}px`;

  newTextSpan.setAttribute("style", textSpanStyle);

  addTagging(spatialNode, classes, newTextSpan, spatialContext, textSpanStyle);

  spatialContext.textSpans.appendChild(newTextSpan);
  spatialNode.element = newTextSpan;
}

export function buildSpatialNode(node: ContentNode): SpatialNode {
  const spatialNode: SpatialNode = {
    node,
    nodeType: node.nodeType,
    bbox: [],
    tags: [],
    tagUuids: [],
    originalBbox: [],
    element: undefined,
  };

  // If we get the page node we need to size everything into it

  node.getFeatures().forEach((feature) => {
    if (feature.featureType === "tag") {
      spatialNode.tags.push(feature.name);
      spatialNode.tagUuids.push(feature.value[0].uuid);
    }

    if (feature.featureType === "spatial" && feature.name === "bbox") {
      spatialNode.bbox = feature.value[0];
      spatialNode.originalBbox = feature.value[0];
    }
  });

  return spatialNode;
}

export function scaleSpatialNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {
  spatialNode.bbox = [
    (spatialNode.originalBbox[0] - spatialContext.container[0]) * spatialContext.scaleFactor,
    (spatialNode.originalBbox[1] - spatialContext.container[1]) * spatialContext.scaleFactor,
    (spatialNode.originalBbox[2] - spatialContext.container[0]) * spatialContext.scaleFactor,
    (spatialNode.originalBbox[3] - spatialContext.container[1]) * spatialContext.scaleFactor,
  ];
  return spatialNode;
}

export function renderSpatialNode(spatialNode: SpatialNode, spatialContext: SpatialContext) {
  // We only pick things up with a bounding box
  if (spatialNode.bbox) {
    if (spatialNode.nodeType === "text" || spatialNode.nodeType === "word") {
      buildTextNode(spatialNode, spatialContext);
    } else if (spatialNode.nodeType === "rect") {
      buildRectNode(spatialNode, spatialContext);
    } else if (spatialNode.nodeType === "image") {
      buildImageNode(spatialNode, spatialContext);
    } else if (spatialNode.nodeType === "figure-line") {
      buildFigureLine(spatialContext);
    } else if (spatialNode.nodeType === "curve") {
      buildFigureLine(spatialContext);
    } else if (spatialNode.nodeType === "line") {
      buildLineNode(spatialNode, spatialContext);
    } else if (spatialNode.nodeType === "content-area") {
      buildContentAreaNode(spatialNode, spatialContext);
    } else if (spatialNode.nodeType === "column") {
      buildContentAreaNode(spatialNode, spatialContext);
    }
  }
}

export class SpatialRenderEngine {
  private kdxaDocument: KddbDocument | undefined;
  private spatialContext: SpatialContext | undefined;
  private targetSpans: DocumentFragment | undefined;
  private nodeTypes: string[] = [];

  private logger = new Logger();

  setDocument(kdxaDocument: KddbDocument) {
    this.kdxaDocument = kdxaDocument;
  }

  attachToCanvas(textSpans: DocumentFragment) {
    this.targetSpans = textSpans;
  }

  render(nodeUuid: string, spatialOptions: SpatialOptions, appStore: AppStore): Promise<SpatialContext> {
    return new Promise((resolve) => {
      this.logger.info("Rendering spatial nodes");
      const textSpans = document.createDocumentFragment();
      this.spatialContext = {
        appStore,
        divs: [],
        options: spatialOptions,
        textSpans,
        container: [],
        width: spatialOptions.width ? spatialOptions.width : 0,
        height: spatialOptions.height ? spatialOptions.height : 0,
        scaleFactor: 1,
        selectedNode: undefined,
        currentTagReference: undefined,
        focusedNode: undefined,
        angle: 0,
      };

      if (!this.kdxaDocument) {
        log.warn("No document to render");
        return;
      }

      const startNode: ContentNode | undefined = nodeUuid ? this.kdxaDocument.findNodeByUUID(nodeUuid) : this.kdxaDocument.contentNode;

      if (!startNode || !this.spatialContext || !this.targetSpans) {
        return;
      }

      if (startNode.getFeature("page", "angle")) {
        this.spatialContext.angle = startNode.getFeature("page", "angle")?.value[0] as number;
      }
      const spatialNodes = this.buildSpatialNodes(startNode);

      this.logger.info(`Built ${spatialNodes.length} spatial nodes`);

      if (startNode.nodeType === "page") {
        this.spatialContext.container = spatialNodes[0].bbox;
      } else {
        this.spatialContext.container = this.creatingBoundingBox(spatialNodes);
      }

      this.spatialContext.scaleFactor = ((this.spatialContext.width - 1) / this.spatialContext.container[2]);
      this.spatialContext.options = spatialOptions;
      this.spatialContext.height = this.spatialContext.container[3] * this.spatialContext.scaleFactor;

      spatialNodes.filter(sn => spatialOptions.labeling || sn.tags.length > 0 || spatialOptions.showText).forEach((sn) => {
        if (this.spatialContext) {
          scaleSpatialNode(sn, this.spatialContext);
          renderSpatialNode(sn, this.spatialContext);
        }
      });

      this.logger.info("Build complete");

      if (this.targetSpans) {
        log.info("Replacing children");
        this.targetSpans.replaceChildren(textSpans);
      }

      if (spatialOptions.focusNodeUuid) {
        const focusNode = spatialNodes.find(sn => sn.node.uuid === spatialOptions.focusNodeUuid);
        if (focusNode) {
          this.spatialContext.focusedNode = focusNode;
        }
      }

      this.logger.info("Render complete");
      resolve(this.spatialContext);
    });
  }

  /**
   * Determine the bounding box around a node (and all its children)
   *
   * @param spatialNodes
   * @param container
   */
  creatingBoundingBox(spatialNodes: SpatialNode[], container = [1000, 0, 0, 0]) {
    for (let i = 0; i < spatialNodes.length; i++) {
      if (spatialNodes[i].bbox) {
        const bbox = spatialNodes[i].bbox;
        if (container[0] > bbox[0]) {
          container[0] = bbox[0];
        }
        if (container[0] < bbox[0]) {
          container[0] = bbox[0];
        }
        if (container[1] < bbox[1]) {
          container[1] = bbox[1];
        }
        if (container[2] < bbox[2]) {
          container[2] = bbox[2];
        }
        if (container[3] < bbox[3]) {
          container[3] = bbox[3];
        }
      }
    }
    return container;
  }

  buildSpatialNodes(node: ContentNode) {
    if (node.nodeType && !this.nodeTypes.includes(node.nodeType)) {
      this.nodeTypes.push(node.nodeType);
    }

    let spatialNodes: SpatialNode[] = [];
    spatialNodes.push(buildSpatialNode(node));

    if (!node) {
      return spatialNodes;
    }

    node.getChildren().forEach((child) => {
      spatialNodes = spatialNodes.concat(this.buildSpatialNodes(child));
    });
    return spatialNodes;
  }
}
