<script lang="ts" setup>
import type { PropType } from "vue";
import type { CreditTransaction, Organization } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { createCreditTransaction, useListCreditTransactions } from "~/api/credit-transactions/credit-transactions";
import { useGetAvailableCredit } from "~/api/organizations/organizations";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  currentOrganization: {
    type: Object as PropType<Organization>,
    required: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

// Setup our grid helpers
const gridHelper = createGridHelper(`credit-list-${props.currentOrganization?.id}`, {
  pageSize: 5,
  filter: "",
  sort: "createdOn:desc",
  query: "",
  page: 1,
}, `organization.id: '${props.currentOrganization.id}'`);

const { user } = storeToRefs(appStore.userStore);

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListCreditTransactions(gridQuery.value);

const {
  data: creditData,
  mutate,
  mutateAsync,
  reset,
} = useGetAvailableCredit();

// onMounted(async () => {
//   await mutateAsync({id: props.currentOrganization?.id});
// });

const loading = ref(false);

watch(() => props.currentOrganization, (newValue) => {
  if (props.modelValue && newValue?.id) {
    mutate({ id: newValue.id });
    gridHelper.setBaseFilter(`organization.id:'${newValue.id}'`);
    gridHelper.setPage(1);
  }
}, {
  immediate: true,
});

const credit = ref({ amount: "" });

const isSaveEnabled = computed(() => {
  return !!credit.value.amount; // Returns true if credit amount is not empty
});

function close() {
  credit.value.amount = ""; // Reset credit amount
  emit("update:modelValue", false);
}

async function addCredit() {
  const amount = Number.parseFloat(credit.value.amount); // Parse the amount as a float

  if (!amount) {
    return; // Exit function if amount is not a valid number
  }

  const newCredit = {
    organization: props.currentOrganization,
    amount, // Assign the parsed amount
    user: user.value,
  } as CreditTransaction;

  try {
    await updateHandler(createCreditTransaction(newCredit), "Added credit successfully");
  } catch (error) {
    console.error("An error occurred:", error);
  }

  refetch();
  mutate({ id: props.currentOrganization?.id });
  credit.value.amount = "";
  close();
}

const columns = [
  {
    title: "Transaction Date",
    field: "createdOn",
    format: "{0:G}",
  },
  {
    title: "Credit Amount",
    field: "amount",
    textAlign: "right",
    format: "{0:N2}",
  },
];
</script>

<template>
  <TransitionRoot :show="modelValue" as="template">
    <Dialog as="div" class="relative z-[99]" @close="close()">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <!-- Action buttons -->
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 dark:bg-gray-900 px-2 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Add Credits
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to add credits to your organization.
                          </p>
                        </div>
                        <div class="shrink-0 py-5">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              icon="cancel"
                              type="secondary"
                              size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              icon="content-save"
                              size="small"
                              :disabled="!isSaveEnabled"
                              @click="addCredit"
                            >
                              Add
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- Org name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            for="organization-name"
                          >Organization
                            name</label>
                        </div>
                        <div>
                          <p class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                            {{ props.currentOrganization.name }}
                          </p>
                        </div>
                      </div>

                      <!-- Org Slug -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              for="organization-slug"
                            >Slug</label>
                          </div>
                          <div>
                            <p class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {{ props.currentOrganization.slug }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- Add Credits -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            for="organization-credit"
                          >Credits</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaNumericInput
                            id="creditAmountInput"
                            v-model="credit.amount"
                            name="credit"
                          />
                        </div>
                      </div>

                      <!-- Credit Balance -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              for="credit-balance"
                            >Credit Balance</label>
                          </div>
                          <div v-if="creditData?.amount">
                            <p id="creditBalance" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {{ creditData.amount.toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- Credit Transactions -->
                      <div class="space-y-2 px-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            class="mb-4 block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            for="organization-credit"
                          >Credit History</label>
                        </div>

                        <div class="mx-16">
                          <Grid
                            class="creditHistory"
                            :data-items="data ? data.content : []"
                            :sortable="true"
                            :columns="columns"
                          >
                            <GridNoRecords>
                              <KodexaGridNoRecords
                                :is-loading="isLoading"
                                no-records-message="No credit transaction found"
                              />
                            </GridNoRecords>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.creditHistory {
  background-color: #F9FBFB;
  padding: 20px;
  border-radius: 10px;
  border-color: transparent;
}

:deep(.creditHistory thead tr) {
  background-color: #F9FBFB;
}

:deep(.creditHistory table tbody tr td) {
  background-color: #F9FBFB;
}

:deep(.k-grid td, .k-grid .k-table-td) {
  border-color: white;
  border-bottom: 1px solid rgb(75 75 75 / 5%);
  padding-top: 13px;
  padding-bottom: 13px;
}

:deep(.k-grid .k-table-th) {
  border-color: white;
}

:deep(.creditHistory td:nth-child(2)) {
  text-align: right; /* Align content of the second column to the right */
}

:deep(.creditHistory th:nth-child(2)) {
  justify-content: end;
}

:deep(.creditHistory th) {
  font-weight: bold;
}
</style>
