<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import router from "~/router/router";
import appStore from "~/store";

const {
  project,
  projectLoading,
  projectDirty,
} = storeToRefs(appStore.projectStore);

const availableTabs = computed(() => {
  return [
    {
      ref: "projectManage",
      name: "Manage",
      singleWidget: { type: "projectManage" },
    },
    {
      ref: "projectActivity",
      name: "Activity",
      singleWidget: {
        type: "projectActivity",
        properties: {
          style: { height: "calc(100vh - 190px)" },
        },
      },
    },
    {
      ref: "projectDataFlow",
      name: "Data Flow",
      singleWidget: {
        type: "projectDataflow",
        properties: {
          style: { height: "calc(100vh - 190px)" },
        },
      },
    },
    {
      ref: "projectConfiguration",
      name: "Metadata",
      singleWidget: {
        type: "projectMetadata",
        properties: {
          style: { height: "calc(100vh - 190px)" },
        },
      },
    },
    {
      ref: "projectTemplateCreate",
      name: "Create Template",
      singleWidget: { type: "projectTemplateCreate" },
    },
  ] as Tab[];
});

const selectedTab = ref(availableTabs.value[0]);

const saving = ref(false);

async function saveProjectManagement() {
  try {
    saving.value = true;
    appStore.projectStore.saveProjectManagement();
  } finally {
    saving.value = false;
  }
}

async function cancelAdmin() {
  await appStore.projectStore.reloadProject();
  // Send the user back to the project home
  await router.push({ path: `/a/o/${project.value.organization.id}/p/${project.value.id}/home` });
}
</script>

<template>
  <div v-if="project" class="bg-white dark:bg-gray-800">
    <header>
      <div>
        <header>
          <div v-if="!projectLoading">
            <KodexaTabStrip
              :items="availableTabs"
              :selected="selectedTab"
              @selected="selectedTab = $event"
            >
              <div class="float-right mt-4 pr-6">
                <KodexaButton
                  v-if="selectedTab !== 'projectEmailNotification' && selectedTab !== 'projectDocumentAssignment'"
                  class="mr-1"
                  :loading="saving"
                  :disabled="!projectDirty"
                  icon="content-save"
                  name="saveChanges"
                  size="small"
                  type="primary"
                  @click="saveProjectManagement"
                >
                  Save Changes
                </KodexaButton>
                <KodexaButton
                  v-if="selectedTab !== 'projectEmailNotification' && selectedTab !== 'projectDocumentAssignment'"
                  class="mr-1"
                  :disabled="saving"
                  icon="cancel"
                  name="saveChanges"
                  size="small"
                  type="secondary"
                  @click="cancelAdmin"
                >
                  Cancel
                </KodexaButton>
              </div>
            </KodexaTabStrip>
          </div>
        </header>
      </div>
    </header>
    <div v-if="!projectLoading">
      <KodexaDashboard
        v-if="selectedTab"
        :model-value="selectedTab"
        :setup-mode="false"
        entry-point="project"
      />
    </div>
  </div>
</template>
