<script lang="ts" setup>
import type { SplitterPaneProps } from "@progress/kendo-vue-layout";
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import type { Ref } from "vue";
import type { DataException, DataForm, DocumentFamily } from "~/model";
import type { DataFormViewer } from "~/store/useWorkspace";
import { Splitter } from "@progress/kendo-vue-layout";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { log } from "~/utils/logger";
import SidecarPanel from "~/views/studio/organizations/project/workspace/panels/sidecar-panel.vue";

const props = defineProps<{
  dataForm: DataForm;
  documentFamily: DocumentFamily;
}>();

defineEmits(["updateTitle"]);

const loading = ref(true);

const { currentTask } = storeToRefs(appStore.taskStore);
const dataFormRef: Ref<string | undefined> = ref(undefined);
const workspaceId: Ref<string | undefined> = ref(undefined);

watch(currentTask, async () => {
  // We need to get the template from the current task to determine the data form and workspace
  if (currentTask.value?.template) {
    dataFormRef.value = currentTask.value.template.metadata?.dataFormRef;
    workspaceId.value = currentTask.value.template.metadata?.workspaceId;
  }
}, { immediate: true, deep: true });

const {
  isLoaded,
} = storeToRefs(appStore.projectStore);

const {
  currentWorkspaceId,
} = storeToRefs(appStore.workspaceStore);

const allExceptions = ref([]) as Ref<DataException[]>;

const kioskView: Ref<undefined | DataFormViewer> = ref(undefined);

const panes: Ref<SplitterPaneProps[]> = ref([
  {
    size: undefined,
    min: "20%",
    collapsible: false,
    content: "form",
  },
  {
    size: "0%",
    min: "20%",
    collapsible: true,
    content: "sidecar",
  },
] as SplitterPaneProps[]);

function onSplitterChange(changeEvent: any) {
  if (changeEvent.newState) {
    panes.value = changeEvent.newState;
  }
}

watch([isLoaded, currentWorkspaceId], async () => {
  log.info(isLoaded.value ? "Loaded" : "Not loaded");
  if (!isLoaded.value) {
    log.info("Not loaded");
    return;
  }
  if (currentWorkspaceId.value) {
    log.info("Workspace Loaded");
    // make sure we remove any other document views
    if (props.documentFamily.id) {
      await appStore.workspaceStore.addDocumentFamily(props.documentFamily, false, true, true);
    }

    kioskView.value = appStore.workspaceStore.addDataForm(props.dataForm, props.documentFamily, true, true);
    const sidecar = createSidecar(currentWorkspaceId.value, true);
    nextTick(() => {
      sidecar.focusPage(props.documentFamily.id, 0);
    });

    loading.value = false;
    panes.value[1].size = "40%";
    appStore.workspaceStore.toggleSidecar();
  }
}, { immediate: true, deep: true });

/**
 * Add a way to handle data exceptions changed. We need to factor as well
 * exceptions that has open = false which means the exceptions were override
 * @param dataExceptions
 */
function handleDataExceptionChanged(dataExceptions: DataException[]) {
  // Remove the exceptions that has open = false
  allExceptions.value = dataExceptions.filter(exception => exception.open);
}
</script>

<template>
  <div>
    <div>
      <Splitter
        v-if="panes"
        :panes="panes"
        orientation="horizontal"
        style="width: 100%"
        @change="onSplitterChange"
      >
        <template #form>
          <div v-if="loading" class="mt-14 flex justify-center">
            <KodexaLoader />
          </div>
          <div v-else-if="!loading && !kioskView" class="mt-14 flex flex-col items-center text-center">
            <MaterialDesignIcon class="text-red-600" name="minusCircle" size="42" />
            <div>
              We have a problem...
            </div>
          </div>

          <div v-else-if="!(loading || !kioskView)" class="mx-auto" style="font-size: .9em">
            <KodexaDataFormView
              v-if="kioskView && kioskView.viewType === 'dataForm' && currentWorkspaceId"
              :params="{ api: { id: kioskView.id } } as IDockviewHeaderActionsProps"
              @data-exceptions-changed="handleDataExceptionChanged"
            />
          </div>
        </template>
        <template #sidecar>
          <SidecarPanel v-if="currentWorkspaceId" :kiosk-mode="true" />
          <div v-else class="mt-14 flex flex-col items-center text-center">
            <MaterialDesignIcon class="text-red-600" name="minusCircle" size="42" />
            <div>
              We have a problem...
            </div>
          </div>
        </template>
      </Splitter>
    </div>
    <KodexaKeyboardShortcuts />
  </div>
</template>

<style scoped>
.selectable {
  user-select: text !important;
}
</style>
