export const optionMetadata = {
  label: "Data Form",
  description: "Select a data form from the project",
  options: [
    {
      name: "formRef",
      label: "Form Reference",
      type: "string",
      description: "Reference to the data form",
    },
  ],
};
