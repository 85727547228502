export const optionMetadata = {
  label: "Taxon Lookup",
  description: "Search and select from taxonomy hierarchy",
  options: [
    {
      name: "taxonomyTypes",
      label: "Taxonomy Types",
      type: "array",
      description: "Types of taxonomies to include",
      default: ["CONTENT", "MODEL"],
    },
    {
      name: "showFullPath",
      label: "Show Full Path",
      type: "boolean",
      description: "Show full taxonomy path",
      default: true,
    },
  ],
};
