<script setup lang="ts">
import type { PropType } from "vue";
import type { ProductGroup } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { updateProductGroup } from "~/api/product-groups/product-groups";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  productGroup: {
    type: Object as PropType<ProductGroup>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetchProductGroup"]);

const localProductGroup = ref(JSON.parse(JSON.stringify(props.productGroup)) as typeof props.productGroup);

function close() {
  emit("update:modelValue", false);
}

async function saveProductGroup() {
  if (localProductGroup.value && localProductGroup.value.id) {
    await updateHandler(updateProductGroup(localProductGroup.value.id, localProductGroup.value), "Product Group successfully updated");
  }

  emit("refetchProductGroup");
  close();
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Product Group
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              type="secondary"
                              icon="cancel"
                              size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              type="primary"
                              icon="content-save"
                              size="small"
                              @click="saveProductGroup"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                        <!--                        <div class="flex h-7 items-center"> -->
                        <!--                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="close"> -->
                        <!--                            <span class="sr-only">Close panel</span> -->
                        <!--                            <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" /> -->
                        <!--                          </button> -->
                        <!--                        </div> -->
                      </div>
                    </div>
                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- Product Group Name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="edit-pg-name"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Product Group Name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput id="edit-pg-name" v-model="localProductGroup.name" name="edit-pg-name" />
                        </div>
                      </div>

                      <!-- Product Group Description -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="edit-pg-desc"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Description</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextArea id="edit-pg-desc" v-model="localProductGroup.description" rows="2" name="edit-pg-desc" />
                        </div>
                      </div>

                      <!-- Product Overview Markdown -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="edit-overview-markdown"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Overview
                            Markdown</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaMarkdownEditor v-model="localProductGroup.overviewMarkdown" name="edit-overview-markdown" />
                        </div>
                      </div>
                    </div>
                    <!-- Products -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          for="products"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Product(s)</label>
                      </div>
                      <div class="rounded bg-gray-50 dark:bg-gray-900 p-2 text-sm text-gray-700 sm:col-span-2">
                        {{ localProductGroup.products.map((product) => product.name).join(", ") }}
                        <!--                        <KodexaMultiselectInput :items="productGroup?.products ? productGroup.products : []" :disabled="true" value-field="id" /> -->
                      </div>
                    </div>
                    <!-- Status -->
                    <div class="mb-10 space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label for="isActive" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">is Active</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaCheckbox
                          name="isActive"
                        />
                        <div class="text-xs italic text-gray-400">
                          The Products associated to this Product Group will be unavailable.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
