<script lang="ts" setup>
import { ref } from "vue";

const currentNavigation = ref(undefined);

const defaultDashboards = computed(() => {
  return [
    {
      ref: "manageOrganizations",
      name: "Organizations",
      singleWidget: {
        type: "manageOrganizations",
      },
    },
    {
      ref: "users",
      name: "Users",
      singleWidget: {
        type: "manageUsers",
      },
    },
    {
      ref: "event",
      name: "Events",
      singleWidget: {
        type: "eventStream",
      },
    },
    {
      ref: "extensions",
      name: "Extension Packs",
      singleWidget: {
        type: "manageExtensionPacks",
      },
    },
    {
      ref: "productGroups",
      name: "Product Groups",
      singleWidget: {
        type: "manageProductGroups",
      },
    },
    {
      ref: "product",
      name: "Products",
      singleWidget: {
        type: "manageProduct",
      },
    },
    {
      ref: "cms",
      name: "Home Content",
      singleWidget: {
        type: "manageContent",
      },
    },
  ] as any[];
});

const orderedDashboards = computed(() => {
  return defaultDashboards.value;
});

watch(orderedDashboards, () => {
  // if the current navigation isn't in the ordered dashboards then make it undefined
  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value) {
    const found = orderedDashboards.value.find(dashboard => dashboard.ref === currentNavigation.value.ref);
    if (!found) {
      currentNavigation.value = undefined;
    }
  }

  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value === undefined) {
    currentNavigation.value = orderedDashboards.value[0];
  }
}, {
  immediate: true,
  deep: true,
});
</script>

<template>
  <div class="bg-white dark:bg-gray-800">
    <KodexaTabStrip :items="orderedDashboards" :selected="currentNavigation" @selected="currentNavigation = $event" />
    <div>
      <KodexaDashboard
        v-if="currentNavigation"
        :allow-delete="false"
        :model-value="currentNavigation"
        :setup-mode="false"
      />
    </div>
  </div>
</template>
