<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";
import type { ProcessingStepViewer } from "~/store/useWorkspace";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
  inWorkspace: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["addToWorkspace", "removeFromWorkspace", "open", "delete", "toggleLock", "rename", "details", "toggleGuidance"]);

const reactiveInWorkspace = computed(() => props.inWorkspace);
const reactiveDocumentFamily = computed(() => props.documentFamily);

interface NavItem {
  text?: string | undefined;
  icon?: string | undefined;
  name?: string | undefined;
  current?: boolean | undefined;
  items?: NavItem[] | undefined;
  mdiIcon?: string | undefined;
  render?: string | undefined;
  divider?: boolean | undefined;
  classes?: string[] | undefined;
  emit?: string | undefined;
  hidden?: boolean | undefined;
  disabled?: boolean | undefined;
  run?: () => void |
    undefined;
}

const { documentStores, allDataForms, project } = storeToRefs(appStore.projectStore);

const openExport = async function (format: string) {
  // we want to get the format for the document family and then show it
  // however we want to do it based on the document objects we have
  let output;
  let resourceRef: string | undefined;
  if (format === "XML") {
    output = await appStore.workspaceStore.getDataObjectsWithFormat(props.documentFamily, "xml");
    format = "xml";
  } else if (format === "XML (Friendly)") {
    output = await appStore.workspaceStore.getDataObjectsWithFormat(props.documentFamily, "xml(friendly)");
    format = "xml";
  } else if (format === "JSON") {
    output = await appStore.workspaceStore.getDataObjectsWithFormat(props.documentFamily, "json");
    format = "json";
  } else if (format === "External") {
    output = await appStore.workspaceStore.getExternalData(props.documentFamily.id);
    output = JSON.stringify(output, null, 2);
    resourceRef = `external:${props.documentFamily.id}`;
    format = "json";
  }
  if (output !== "") {
    appStore.workspaceStore.createTextViewer(`${props.documentFamily.path} (${format})`, output, undefined, format, resourceRef);
  } else {
    notify({
      title: "No Data",
      message: "There is no data to export",
      group: "error",
    });
  }
};

const viewDataExpanded = ref(false);
const viewFormExpanded = ref(false);
const showNewTaskPopover = ref(false);
const selectedTask = ref(undefined);

async function openProcessingSteps() {
  await appStore.workspaceStore.addDocumentFamily(props.documentFamily, true, false, false);
  const processStepView = {
    viewType: "processingStep",
    id: `processing-step-view-${props.documentFamily.id}`,
    documentFamilyId: props.documentFamily.id,
    title: `${props.documentFamily.path} (Explain)`,
  } as ProcessingStepViewer;
  appStore.workspaceStore.addView(processStepView);
}

const navigation = computed(() => {
  const finalNavigation = [
    {
      icon: "open",
      render: "itemRender",
      text: "Open",
      emit: "open",
    },
    {
      icon: "clipboard-text",
      render: "itemRender",
      text: "Create Task",
      run: () => {
        showNewTaskPopover.value = true;
      },
    },
    { divider: true },
  ];

  if (reactiveInWorkspace.value) {
    finalNavigation.push({
      icon: "minus",
      render: "itemRender",
      text: "Remove from data form",
      emit: "removeFromWorkspace",
    });
  } else {
    finalNavigation.push({
      icon: "plus",
      render: "itemRender",
      text: "Add to data form",
      emit: "addToWorkspace",
    });
  }

  finalNavigation.push({
    icon: reactiveDocumentFamily.value.guidance ? "minus" : "plus",
    render: "itemRender",
    text: `${reactiveDocumentFamily.value.guidance ? "Do not use as" : "Use as"} guidance`,
    emit: "toggleGuidance",
  });

  finalNavigation.push(...[
    { divider: true },
    {
      icon: "lock",
      render: "itemRender",
      text: props.documentFamily.locked ? "Unlock" : "Lock",
      emit: "toggleLock",
    },
    {
      icon: "graph",
      render: "itemRender",
      text: "View Explain Plan",
      run: () => {
        openProcessingSteps();
      },
    },
    { divider: true },
    {
      text: "View Data",
      render: "itemRender",
      icon: "code-json",
      run: () => {
        viewDataExpanded.value = !viewDataExpanded.value;
      },
      items: [
        {
          text: "XML (friendly)",
          icon: "xml",
          isData: true,
          run: () => {
            openExport("XML (Friendly)");
            viewDataExpanded.value = false;
          },
        },
        {
          text: "XML",
          isData: true,
          icon: "xml",
          run: () => {
            openExport("XML");
            viewDataExpanded.value = false;
          },
        },
        {
          text: "JSON",
          isData: true,
          icon: "code-json",
          run: () => {
            openExport("JSON");
            viewDataExpanded.value = false;
          },
        },
        {
          text: "External",
          isData: true,
          icon: "code-json",
          run: () => {
            openExport("External");
            viewDataExpanded.value = false;
          },
        },
      ],
    },
  ]);

  if (documentStores.value.length > 1) {
    finalNavigation.push({ divider: true } as NavItem);
    for (const documentStore of documentStores.value) {
      if (documentStore.ref === props.documentFamily.storeRef) {
        continue;
      }
      finalNavigation.push({
        text: `Copy to ${documentStore.name}`,
        render: "itemRender",
        icon: "content-duplicate",
        run: () => {
          appStore.projectStore.copyDocumentFamily(props.documentFamily, documentStore.ref);
        },
      } as NavItem);
    }
  }

  finalNavigation.push(...[
    { divider: true },
    {
      icon: "properties",
      text: "Details",
      render: "itemRender",
      emit: "details",
    },
    {
      icon: "download",
      render: "itemRender",
      text: "Download",
      emit: "download",
    },
    { divider: true },
    {
      icon: "delete",
      render: "itemRender",
      text: computed(() => {
        if (props.documentFamily.locked) {
          return "Delete (Locked)";
        }
        if (props.documentFamily.guidance) {
          return "Delete (Has Guidance)";
        }
        return "Delete";
      }),
      classes: ["text-red-400"],
      emit: "delete",
      disabled: props.documentFamily.locked || props.documentFamily.guidance,
    },
  ] as NavItem[]);

  return finalNavigation;
});

function openNav(item: NavItem) {
  if (item.emit && !item.disabled) {
    emit(item.emit);
  }
  if (item.run) {
    item.run();
  }
}

function handleDialogClose() {
  showNewTaskPopover.value = false;
  selectedTask.value = null;
}

function handleTaskUpdated() {
  selectedTask.value = null;
  showNewTaskPopover.value = false;
}

function refetch() {
  showNewTaskPopover.value = false;
}
</script>

<template>
  <div>
    <VDropdown :arrow-overflow="false">
      <MaterialDesignIcon name="THREEDOTMENU" class="size-5 pt-2 text-gray-500 dark:text-gray-400" aria-hidden="true" />
      <template #popper="{ hide }">
        <nav class="p-1 dark:bg-gray-900" aria-label="Sidebar">
          <div v-for="item in navigation" :key="item.name">
            <a
              v-if="!item.divider && !item.items" v-close-popper
              :class="[item.disabled ? 'cursor-not-allowed text-opacity-25 dark:text-opacity-25' : 'cursor-pointer text-opacity-100 dark:text-opacity-100']"
              class="flex select-none items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100"
              :aria-current="item.current && !item.disabled ? 'page' : undefined" @click="openNav(item) && hide()"
            >
              <MaterialDesignIcon
                v-if="item.icon" :name="item.icon" class="-ml-1 mr-2 shrink-0"
                :classes="item.classes ? item.classes : []" aria-hidden="true" size="16"
              />
              <span class="truncate">{{ item.text }}</span>
            </a>
            <template v-else-if="item.items" class="pl-2">
              <a
                :class="[item.disabled ? 'cursor-not-allowed text-opacity-25 dark:text-opacity-25' : 'cursor-pointer text-opacity-100 dark:text-opacity-100']"
                class="flex select-none items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100"
                :aria-current="item.current && !item.disabled ? 'page' : undefined" @click="openNav(item)"
              >
                <MaterialDesignIcon
                  v-if="item.icon" :name="item.icon" class="-ml-1 mr-2 shrink-0"
                  :classes="item.classes ? item.classes : []" aria-hidden="true" size="16"
                />
                <span class="truncate">{{ item.text }}</span>
              </a>
              <Transition name="slide-fade">
                <div v-show="viewDataExpanded">
                  <template v-for="subItem in item.items" :key="subItem.text">
                    <a
                      v-if="subItem.isData" v-close-popper
                      :class="[subItem.disabled ? 'cursor-not-allowed text-opacity-25 dark:text-opacity-25' : 'cursor-pointer text-opacity-100 dark:text-opacity-100']"
                      class="flex select-none items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 ml-2"
                      :aria-current="subItem.current && !subItem.disabled ? 'page' : undefined"
                      @click="openNav(subItem) && hide()"
                    >
                      <MaterialDesignIcon
                        v-if="subItem.icon" :name="subItem.icon" class="-ml-1 mr-2 shrink-0"
                        :classes="subItem.classes ? subItem.classes : []" aria-hidden="true" size="16"
                      />
                      <span class="truncate">{{ subItem.text }}</span>
                    </a>
                  </template>
                </div>
              </Transition>
              <Transition name="slide-fade">
                <div v-show="viewFormExpanded">
                  <template v-for="subItem in item.items" :key="subItem.text">
                    <a
                      v-if="subItem.isForm" v-close-popper
                      :class="[subItem.disabled ? 'cursor-not-allowed text-opacity-25 dark:text-opacity-25' : 'cursor-pointer text-opacity-100 dark:text-opacity-100']"
                      class="flex select-none items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 ml-2"
                      :aria-current="subItem.current && !subItem.disabled ? 'page' : undefined"
                      @click="openNav(subItem) && hide()"
                    >
                      <MaterialDesignIcon
                        v-if="subItem.icon" :name="subItem.icon" class="-ml-1 mr-2 shrink-0"
                        :classes="subItem.classes ? subItem.classes : []" aria-hidden="true" size="16"
                      />
                      <span class="truncate">{{ subItem.text }}</span>
                    </a>
                  </template>
                </div>
              </Transition>
            </template>
            <div v-else class="my-1 border-t border-gray-200 dark:border-gray-700" />
          </div>
        </nav>
      </template>
    </VDropdown>
    <NewTaskPopover
      v-model="showNewTaskPopover" :project="project" :document-families="[documentFamily]"
      :task-to-edit="selectedTask" @update:task-to-edit="(val) => selectedTask = val" @task-created="refetch"
      @task-updated="handleTaskUpdated" @update:model-value="handleDialogClose"
    />
  </div>
</template>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>
