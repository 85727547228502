<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const useDataFormViewer = createDataFormViewerStore(props.viewId);
</script>

<template>
  <div class="rounded-lg bg-white shadow px-2 py-1 mr-2 mb-1">
    <div class="w-100">
      <div
        v-if="card.properties?.showHeader"
        class="flex items-center justify-between border-b border-gray-200 w-full"
      >
        <div class="flex-1">
          <div class="text-lg font-semibold leading-6 text-gray-900">
            {{ card.properties.title }}
          </div>
          <p v-if="card.properties?.subtitle" class="mt-1 text-sm text-gray-500 font-normal leading-relaxed">
            {{ card.properties.subtitle }}
          </p>
        </div>
      </div>
      <div>
        <div class="mt-2">
          <KodexaRowLayout
            :rows="useDataFormViewer.buildDataFormViewer(card)" :view-id="viewId"
            :parent-tag-metadata="parentTagMetadata"
            :parent-data-object="parentDataObject"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
