// This exists due to issues with fixed headers and scrollIntoView

import { log } from "~/utils/logger";
/**
 * Scrolls an element into view, either within a parent container or in the window
 * @param element The element to scroll into view
 * @param parent The optional parent container to scroll within
 */
export function scrollIntoView(element: Element, parent: Element | undefined) {
  if (parent && element) {
    log.info("Scrolling parent");
    // Use getBoundingClientRect() to get positions instead of offsetTop
    const elementRect = element.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    // Calculate the relative position and scroll the parent
    const relativeTop = elementRect.top - parentRect.top;
    parent.scrollBy({
      top: relativeTop - parent.clientHeight / 2 + elementRect.height / 2,
      behavior: "smooth",
    });
  } else {
    if (element) {
      log.info("Scrolling window");
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
}

export function truncatedString(text: string, length: number) {
  if (text.length <= length) {
    return text;
  }
  const start = text.substring(0, length / 2);
  const end = text.substring(text.length - length / 2);
  return `${start}...${end}`;
}
