export const optionMetadata = {
  label: "Guidance Set",
  description: "Select a guidance set from the project",
  options: [
    {
      name: "ref",
      label: "Reference",
      type: "string",
      description: "Reference to the guidance set",
    },
    {
      name: "showDescription",
      label: "Show Description",
      type: "boolean",
      description: "Show guidance set description",
      default: true,
    },
  ],
};
