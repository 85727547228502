<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Button } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import { useGetMarketPlace } from "~/api/platform-overview/platform-overview";
import appStore from "~/store";

const props = defineProps({
  modelValue: Boolean,
});
const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);

const {
  project,
  documentStores,
  dataStores,
  modelStores,
  taxonomies,
  dataForms,
} = storeToRefs(appStore.projectStore);

const open = computed({
  get: () => {
    return props.modelValue;
  },
  set: () => {
    emit("update:modelValue", open.value);
  },
});

const gridHelper = createGridHelper("marketplace", {
  selectionType: "resources",
  template: true,
  pageSize: 15,
  page: 1,
  query: "",
  filter: "",
  sort: "",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  data,
  isLoading,
} = useGetMarketPlace(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

function setQuery(query: string) {
  gridHelper.setQuery(query);
  gridHelper.pageChangeHandler({ page: 1 });
}

function closeDialog() {
  emit("update:modelValue", false);
}

async function linkToProject(resource: any) {
  await appStore.projectStore.linkResource(resource);
  emit("update:modelValue", false);
}

function getSafeUrl(url: string) {
  return url ? url.replace("https://images.kodexa.com", "/assets") : "";
}

const computedQuery = computed({
  get() {
    return gridQuery.value.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[99]" @close="closeDialog">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 60%; height: 600px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="closeDialog"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div class="mt-3">
                  <div class="mt-2" style="width: 100%">
                    <KodexaToolbar>
                      <label for="query" class="sr-only">Query</label>
                      <input
                        id="query" v-model="computedQuery" type="text" name="query"
                        class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Filter..."
                      >
                    </KodexaToolbar>

                    <div class="border-1 border-gray-600 bg-gray-100" style="height: 650px; overflow-y: scroll">
                      <ul
                        v-if="data" role="list"
                        class="grid grid-cols-1 gap-3  p-2 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5"
                      >
                        <li
                          v-for="resource in data.content" :key="resource.ref"
                          class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white dark:bg-gray-800 text-center shadow"
                        >
                          <div class="flex flex-1 flex-col p-8">
                            <img
                              class="mx-auto h-20 w-20 shrink-0" :src="getSafeUrl(resource.imageUrl)" alt=""
                            >
                            <h3 class="mt-6 h-14 text-sm font-medium text-gray-900">
                              {{ resource.name }}
                            </h3>
                            <dl class="mt-1 flex grow flex-col">
                              <dd>
                                <span>{{ resource.description }}</span>
                              </dd>
                            </dl>

                            <Button class="text-theme-primary mt-2" fill-mode="flat" @click="linkToProject(resource)">
                              Link to project
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
