export const optionMetadata = {
  label: "Label",
  description: "Select a label from available project labels",
  options: [
    {
      name: "label",
      label: "Label",
      type: "string",
      description: "The label to use",
    },
    {
      name: "multiple",
      label: "Allow Multiple",
      type: "boolean",
      description: "Allow selecting multiple labels",
      default: false,
    },
  ],
};
