/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AddLogEntriesToExecution400,
  ContentObject,
  CreateExecution400,
  CreateExecutionBody,
  CreateExecutionParams,
  ExceptionResponse,
  Execution,
  ExecutionEvent,
  ExecutionLogEntry,
  GetExecutionContentObject400,
  GetExecutionEventById400,
  GetSession400,
  GetSessionExecution400,
  GetSessionExecutionLogs400,
  ListSessionExecutions400,
  ListSessionExecutionsParams,
  PageExecution,
  ProcessEvent400,
  Run400,
  RunBody,
  RunParams,
  Session,
  StatusDetails,
  ThrowableProblem,
  UpdateSessionExecutionStatus400,
  UploadContentObject400,
  UploadContentObjectBody,
  UploadContentObjectParams
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Update the status details on an execution
 */
export const updateSessionExecutionStatus = (
    sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    statusDetails: MaybeRef<StatusDetails>,
 options?: SecondParameter<typeof customAxios>,) => {
      sessionId = unref(sessionId);
executionId = unref(executionId);
statusDetails = unref(statusDetails);
      
      return customAxios<Execution>(
      {url: `/api/sessions/${sessionId}/executions/${executionId}/status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: statusDetails
    },
      options);
    }
  


export const getUpdateSessionExecutionStatusMutationOptions = <TError = ErrorType<UpdateSessionExecutionStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSessionExecutionStatus>>, TError,{sessionId: string;executionId: string;data: StatusDetails}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateSessionExecutionStatus>>, TError,{sessionId: string;executionId: string;data: StatusDetails}, TContext> => {
    
const mutationKey = ['updateSessionExecutionStatus'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSessionExecutionStatus>>, {sessionId: string;executionId: string;data: StatusDetails}> = (props) => {
          const {sessionId,executionId,data} = props ?? {};

          return  updateSessionExecutionStatus(sessionId,executionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSessionExecutionStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateSessionExecutionStatus>>>
    export type UpdateSessionExecutionStatusMutationBody = StatusDetails
    export type UpdateSessionExecutionStatusMutationError = ErrorType<UpdateSessionExecutionStatus400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateSessionExecutionStatus = <TError = ErrorType<UpdateSessionExecutionStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSessionExecutionStatus>>, TError,{sessionId: string;executionId: string;data: StatusDetails}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateSessionExecutionStatus>>,
        TError,
        {sessionId: string;executionId: string;data: StatusDetails},
        TContext
      > => {

      const mutationOptions = getUpdateSessionExecutionStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Upload a new content object to the execution
 */
export const uploadContentObject = (
    sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    uploadContentObjectBody: MaybeRef<UploadContentObjectBody>,
    params: MaybeRef<UploadContentObjectParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      sessionId = unref(sessionId);
executionId = unref(executionId);
uploadContentObjectBody = unref(uploadContentObjectBody);
params = unref(params);
      
      return customAxios<ContentObject>(
      {url: `/api/sessions/${sessionId}/executions/${executionId}/objects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadContentObjectBody,
        params: unref(params), signal
    },
      options);
    }
  


export const getUploadContentObjectMutationOptions = <TError = ErrorType<UploadContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadContentObject>>, TError,{sessionId: string;executionId: string;data: UploadContentObjectBody;params: UploadContentObjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadContentObject>>, TError,{sessionId: string;executionId: string;data: UploadContentObjectBody;params: UploadContentObjectParams}, TContext> => {
    
const mutationKey = ['uploadContentObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadContentObject>>, {sessionId: string;executionId: string;data: UploadContentObjectBody;params: UploadContentObjectParams}> = (props) => {
          const {sessionId,executionId,data,params} = props ?? {};

          return  uploadContentObject(sessionId,executionId,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadContentObjectMutationResult = NonNullable<Awaited<ReturnType<typeof uploadContentObject>>>
    export type UploadContentObjectMutationBody = UploadContentObjectBody
    export type UploadContentObjectMutationError = ErrorType<UploadContentObject400 | ThrowableProblem | ExceptionResponse>

    export const useUploadContentObject = <TError = ErrorType<UploadContentObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadContentObject>>, TError,{sessionId: string;executionId: string;data: UploadContentObjectBody;params: UploadContentObjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof uploadContentObject>>,
        TError,
        {sessionId: string;executionId: string;data: UploadContentObjectBody;params: UploadContentObjectParams},
        TContext
      > => {

      const mutationOptions = getUploadContentObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Pass, and process, a new event in the session
 */
export const processEvent = (
    sessionId: MaybeRef<string>,
    executionEvent: MaybeRef<ExecutionEvent>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      sessionId = unref(sessionId);
executionEvent = unref(executionEvent);
      
      return customAxios<Execution>(
      {url: `/api/sessions/${sessionId}/events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: executionEvent, signal
    },
      options);
    }
  


export const getProcessEventMutationOptions = <TError = ErrorType<ProcessEvent400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof processEvent>>, TError,{sessionId: string;data: ExecutionEvent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof processEvent>>, TError,{sessionId: string;data: ExecutionEvent}, TContext> => {
    
const mutationKey = ['processEvent'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof processEvent>>, {sessionId: string;data: ExecutionEvent}> = (props) => {
          const {sessionId,data} = props ?? {};

          return  processEvent(sessionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProcessEventMutationResult = NonNullable<Awaited<ReturnType<typeof processEvent>>>
    export type ProcessEventMutationBody = ExecutionEvent
    export type ProcessEventMutationError = ErrorType<ProcessEvent400 | ThrowableProblem | ExceptionResponse>

    export const useProcessEvent = <TError = ErrorType<ProcessEvent400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof processEvent>>, TError,{sessionId: string;data: ExecutionEvent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof processEvent>>,
        TError,
        {sessionId: string;data: ExecutionEvent},
        TContext
      > => {

      const mutationOptions = getProcessEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specified execution in the session
 */
export const getSessionExecution = (
    id: MaybeRef<string>,
    executionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
executionId = unref(executionId);
      
      return customAxios<Execution>(
      {url: `/api/sessions/${id}/executions/${executionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSessionExecutionQueryKey = (id: MaybeRef<string>,
    executionId: MaybeRef<string>,) => {
    return ['api','sessions',id,'executions',executionId] as const;
    }

    
export const getGetSessionExecutionQueryOptions = <TData = Awaited<ReturnType<typeof getSessionExecution>>, TError = ErrorType<GetSessionExecution400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>,
    executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionExecution>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetSessionExecutionQueryKey(id,executionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionExecution>>> = ({ signal }) => getSessionExecution(id,executionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(executionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSessionExecution>>, TError, TData> 
}

export type GetSessionExecutionQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionExecution>>>
export type GetSessionExecutionQueryError = ErrorType<GetSessionExecution400 | ThrowableProblem | ExceptionResponse>



export function useGetSessionExecution<TData = Awaited<ReturnType<typeof getSessionExecution>>, TError = ErrorType<GetSessionExecution400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>,
    executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionExecution>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetSessionExecutionQueryOptions(id,executionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



export const run = (
    id: MaybeRef<string>,
    executionId: MaybeRef<string>,
    runBody: MaybeRef<RunBody>,
    params?: MaybeRef<RunParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
executionId = unref(executionId);
runBody = unref(runBody);
params = unref(params);
      
      return customAxios<Execution>(
      {url: `/api/sessions/${id}/executions/${executionId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: runBody,
        params: unref(params), signal
    },
      options);
    }
  


export const getRunMutationOptions = <TError = ErrorType<Run400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof run>>, TError,{id: string;executionId: string;data: RunBody;params?: RunParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof run>>, TError,{id: string;executionId: string;data: RunBody;params?: RunParams}, TContext> => {
    
const mutationKey = ['run'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof run>>, {id: string;executionId: string;data: RunBody;params?: RunParams}> = (props) => {
          const {id,executionId,data,params} = props ?? {};

          return  run(id,executionId,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RunMutationResult = NonNullable<Awaited<ReturnType<typeof run>>>
    export type RunMutationBody = RunBody
    export type RunMutationError = ErrorType<Run400 | ThrowableProblem | ExceptionResponse>

    export const useRun = <TError = ErrorType<Run400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof run>>, TError,{id: string;executionId: string;data: RunBody;params?: RunParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof run>>,
        TError,
        {id: string;executionId: string;data: RunBody;params?: RunParams},
        TContext
      > => {

      const mutationOptions = getRunMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get logs for the specified execution in the session
 */
export const getSessionExecutionLogs = (
    id: MaybeRef<string>,
    executionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
executionId = unref(executionId);
      
      return customAxios<ExecutionLogEntry[]>(
      {url: `/api/sessions/${id}/executions/${executionId}/logs`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSessionExecutionLogsQueryKey = (id: MaybeRef<string>,
    executionId: MaybeRef<string>,) => {
    return ['api','sessions',id,'executions',executionId,'logs'] as const;
    }

    
export const getGetSessionExecutionLogsQueryOptions = <TData = Awaited<ReturnType<typeof getSessionExecutionLogs>>, TError = ErrorType<GetSessionExecutionLogs400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>,
    executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionExecutionLogs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetSessionExecutionLogsQueryKey(id,executionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionExecutionLogs>>> = ({ signal }) => getSessionExecutionLogs(id,executionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(executionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSessionExecutionLogs>>, TError, TData> 
}

export type GetSessionExecutionLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionExecutionLogs>>>
export type GetSessionExecutionLogsQueryError = ErrorType<GetSessionExecutionLogs400 | ThrowableProblem | ExceptionResponse>



export function useGetSessionExecutionLogs<TData = Awaited<ReturnType<typeof getSessionExecutionLogs>>, TError = ErrorType<GetSessionExecutionLogs400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>,
    executionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionExecutionLogs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetSessionExecutionLogsQueryOptions(id,executionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Add log entries to an execution
 */
export const addLogEntriesToExecution = (
    id: MaybeRef<string>,
    executionId: MaybeRef<string>,
    executionLogEntry: MaybeRef<ExecutionLogEntry[]>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
executionId = unref(executionId);
executionLogEntry = unref(executionLogEntry);
      
      return customAxios<void>(
      {url: `/api/sessions/${id}/executions/${executionId}/logs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: executionLogEntry, signal
    },
      options);
    }
  


export const getAddLogEntriesToExecutionMutationOptions = <TError = ErrorType<AddLogEntriesToExecution400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLogEntriesToExecution>>, TError,{id: string;executionId: string;data: ExecutionLogEntry[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addLogEntriesToExecution>>, TError,{id: string;executionId: string;data: ExecutionLogEntry[]}, TContext> => {
    
const mutationKey = ['addLogEntriesToExecution'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLogEntriesToExecution>>, {id: string;executionId: string;data: ExecutionLogEntry[]}> = (props) => {
          const {id,executionId,data} = props ?? {};

          return  addLogEntriesToExecution(id,executionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLogEntriesToExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof addLogEntriesToExecution>>>
    export type AddLogEntriesToExecutionMutationBody = ExecutionLogEntry[]
    export type AddLogEntriesToExecutionMutationError = ErrorType<AddLogEntriesToExecution400 | ThrowableProblem | ExceptionResponse>

    export const useAddLogEntriesToExecution = <TError = ErrorType<AddLogEntriesToExecution400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLogEntriesToExecution>>, TError,{id: string;executionId: string;data: ExecutionLogEntry[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof addLogEntriesToExecution>>,
        TError,
        {id: string;executionId: string;data: ExecutionLogEntry[]},
        TContext
      > => {

      const mutationOptions = getAddLogEntriesToExecutionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a new execution
 */
export const createExecution = (
    id: MaybeRef<string>,
    createExecutionBody: MaybeRef<CreateExecutionBody>,
    params?: MaybeRef<CreateExecutionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
createExecutionBody = unref(createExecutionBody);
params = unref(params);
      
      return customAxios<Execution>(
      {url: `/api/sessions/${id}/execute`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createExecutionBody,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateExecutionMutationOptions = <TError = ErrorType<CreateExecution400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExecution>>, TError,{id: string;data: CreateExecutionBody;params?: CreateExecutionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createExecution>>, TError,{id: string;data: CreateExecutionBody;params?: CreateExecutionParams}, TContext> => {
    
const mutationKey = ['createExecution'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createExecution>>, {id: string;data: CreateExecutionBody;params?: CreateExecutionParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  createExecution(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof createExecution>>>
    export type CreateExecutionMutationBody = CreateExecutionBody
    export type CreateExecutionMutationError = ErrorType<CreateExecution400 | ThrowableProblem | ExceptionResponse>

    export const useCreateExecution = <TError = ErrorType<CreateExecution400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExecution>>, TError,{id: string;data: CreateExecutionBody;params?: CreateExecutionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createExecution>>,
        TError,
        {id: string;data: CreateExecutionBody;params?: CreateExecutionParams},
        TContext
      > => {

      const mutationOptions = getCreateExecutionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific session
 */
export const getSession = (
    sessionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      sessionId = unref(sessionId);
      
      return customAxios<Session>(
      {url: `/api/sessions/${sessionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSessionQueryKey = (sessionId: MaybeRef<string>,) => {
    return ['api','sessions',sessionId] as const;
    }

    
export const getGetSessionQueryOptions = <TData = Awaited<ReturnType<typeof getSession>>, TError = ErrorType<GetSession400 | ThrowableProblem | ExceptionResponse>>(sessionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSession>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetSessionQueryKey(sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSession>>> = ({ signal }) => getSession(sessionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(sessionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSession>>, TError, TData> 
}

export type GetSessionQueryResult = NonNullable<Awaited<ReturnType<typeof getSession>>>
export type GetSessionQueryError = ErrorType<GetSession400 | ThrowableProblem | ExceptionResponse>



export function useGetSession<TData = Awaited<ReturnType<typeof getSession>>, TError = ErrorType<GetSession400 | ThrowableProblem | ExceptionResponse>>(
 sessionId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSession>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetSessionQueryOptions(sessionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the execution event details
 */
export const getExecutionEventById = (
    sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    executionEventId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      sessionId = unref(sessionId);
executionId = unref(executionId);
executionEventId = unref(executionEventId);
      
      return customAxios<ExecutionEvent>(
      {url: `/api/sessions/${sessionId}/executions/${executionId}/${executionEventId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExecutionEventByIdQueryKey = (sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    executionEventId: MaybeRef<string>,) => {
    return ['api','sessions',sessionId,'executions',executionId,executionEventId] as const;
    }

    
export const getGetExecutionEventByIdQueryOptions = <TData = Awaited<ReturnType<typeof getExecutionEventById>>, TError = ErrorType<GetExecutionEventById400 | ThrowableProblem | ExceptionResponse>>(sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    executionEventId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionEventById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExecutionEventByIdQueryKey(sessionId,executionId,executionEventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionEventById>>> = ({ signal }) => getExecutionEventById(sessionId,executionId,executionEventId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(sessionId) && unref(executionId) && unref(executionEventId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecutionEventById>>, TError, TData> 
}

export type GetExecutionEventByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionEventById>>>
export type GetExecutionEventByIdQueryError = ErrorType<GetExecutionEventById400 | ThrowableProblem | ExceptionResponse>



export function useGetExecutionEventById<TData = Awaited<ReturnType<typeof getExecutionEventById>>, TError = ErrorType<GetExecutionEventById400 | ThrowableProblem | ExceptionResponse>>(
 sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    executionEventId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionEventById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetExecutionEventByIdQueryOptions(sessionId,executionId,executionEventId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Download the content object's content (binary)
 */
export const getExecutionContentObject = (
    sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    objectId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      sessionId = unref(sessionId);
executionId = unref(executionId);
objectId = unref(objectId);
      
      return customAxios<Blob>(
      {url: `/api/sessions/${sessionId}/executions/${executionId}/objects/${objectId}`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetExecutionContentObjectQueryKey = (sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    objectId: MaybeRef<string>,) => {
    return ['api','sessions',sessionId,'executions',executionId,'objects',objectId] as const;
    }

    
export const getGetExecutionContentObjectQueryOptions = <TData = Awaited<ReturnType<typeof getExecutionContentObject>>, TError = ErrorType<GetExecutionContentObject400 | ThrowableProblem | ExceptionResponse>>(sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    objectId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionContentObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExecutionContentObjectQueryKey(sessionId,executionId,objectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionContentObject>>> = ({ signal }) => getExecutionContentObject(sessionId,executionId,objectId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(sessionId) && unref(executionId) && unref(objectId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecutionContentObject>>, TError, TData> 
}

export type GetExecutionContentObjectQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionContentObject>>>
export type GetExecutionContentObjectQueryError = ErrorType<GetExecutionContentObject400 | ThrowableProblem | ExceptionResponse>



export function useGetExecutionContentObject<TData = Awaited<ReturnType<typeof getExecutionContentObject>>, TError = ErrorType<GetExecutionContentObject400 | ThrowableProblem | ExceptionResponse>>(
 sessionId: MaybeRef<string>,
    executionId: MaybeRef<string>,
    objectId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionContentObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetExecutionContentObjectQueryOptions(sessionId,executionId,objectId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Gets paginated list of executions in the session
 */
export const listSessionExecutions = (
    id: MaybeRef<string>,
    params: MaybeRef<ListSessionExecutionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<PageExecution>(
      {url: `/api/sessions/${id}/executions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListSessionExecutionsQueryKey = (id: MaybeRef<string>,
    params: MaybeRef<ListSessionExecutionsParams>,) => {
    return ['api','sessions',id,'executions', ...(params ? [params]: [])] as const;
    }

    
export const getListSessionExecutionsQueryOptions = <TData = Awaited<ReturnType<typeof listSessionExecutions>>, TError = ErrorType<ListSessionExecutions400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>,
    params: MaybeRef<ListSessionExecutionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSessionExecutions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListSessionExecutionsQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSessionExecutions>>> = ({ signal }) => listSessionExecutions(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listSessionExecutions>>, TError, TData> 
}

export type ListSessionExecutionsQueryResult = NonNullable<Awaited<ReturnType<typeof listSessionExecutions>>>
export type ListSessionExecutionsQueryError = ErrorType<ListSessionExecutions400 | ThrowableProblem | ExceptionResponse>



export function useListSessionExecutions<TData = Awaited<ReturnType<typeof listSessionExecutions>>, TError = ErrorType<ListSessionExecutions400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>,
    params: MaybeRef<ListSessionExecutionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSessionExecutions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListSessionExecutionsQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



