<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { AttributeTaxonOverrideOptions } from "~/components/dataForm/attribute-taxon-override-options";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { offset, shift, useFloating } from "@floating-ui/vue";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDataAttributeMetadata from "~/components/dataObject/kodexa-data-attribute-metadata.vue";
import KodexaDataAttributePreview from "~/components/dataObject/kodexa-data-attribute-preview.vue";
import KodexaAttributeAutocomplete from "~/components/dataObject/types/kodexa-attribute-autocomplete.vue";
import KodexaAttributeBoolean from "~/components/dataObject/types/kodexa-attribute-boolean.vue";
import KodexaAttributeCurrency from "~/components/dataObject/types/kodexa-attribute-currency.vue";
import KodexaAttributeDateTime from "~/components/dataObject/types/kodexa-attribute-date-time.vue";
import KodexaAttributeDate from "~/components/dataObject/types/kodexa-attribute-date.vue";
import KodexaAttributeFormula from "~/components/dataObject/types/kodexa-attribute-formula.vue";
import KodexaAttributeMultiselect from "~/components/dataObject/types/kodexa-attribute-multiselect.vue";
import KodexaAttributeNumber from "~/components/dataObject/types/kodexa-attribute-number.vue";
import KodexaAttributeSelect from "~/components/dataObject/types/kodexa-attribute-select.vue";
import KodexaAttributeString from "~/components/dataObject/types/kodexa-attribute-string.vue";
import appStore from "~/store";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  editorOptions: {
    type: Object as PropType<AttributeEditorOptions>,
    required: false,
    default: () => {
      return {} as AttributeEditorOptions;
    },
  },
  taxonOverrideOptions: {
    type: Object as PropType<AttributeTaxonOverrideOptions>,
    required: false,
    default: () => {
      return {} as AttributeTaxonOverrideOptions;
    },
  },
  placeholderAttribute: {
    type: Boolean,
    required: false,
    default: false,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const { user } = storeToRefs(appStore.userStore);
const { dataObjects } = storeToRefs(appStore.workspaceStore);

const dataFormViewer = createDataFormViewerStore(props.viewId);
const { focusedAttributeUuid } = storeToRefs(dataFormViewer);

const attributeTypes = {
  STRING: KodexaAttributeString,
  DATE: KodexaAttributeDate,
  CURRENCY: KodexaAttributeCurrency,
  BOOLEAN: KodexaAttributeBoolean,
  DATE_TIME: KodexaAttributeDateTime,
  NUMBER: KodexaAttributeNumber,
  SELECTION: KodexaAttributeSelect,
  AUTOCOMPLETE: KodexaAttributeAutocomplete,
  MULTISELECT: KodexaAttributeMultiselect,
  DERIVED: KodexaAttributeString,
  EXPLANATION: KodexaAttributeString,
};

function deleteAttribute(attribute: any) {
  appStore.workspaceStore.deleteAttribute(props.dataObject, attribute);
}

const dataExceptions = computed(() => {
  const dataExceptions: DataException[] = [];
  if (!props.attribute?.dataExceptions) {
    return dataExceptions;
  }
  for (const dataException of props.attribute?.dataExceptions) {
    if (dataException.open) {
      dataExceptions.push(dataException);
    }
  }

  // we also need to find any exceptions on the data object that have
  // the attribute path that we don't already have
  for (const dataException of props.dataObject?.dataExceptions || []) {
    if (dataException.open && dataException.path === props.attribute?.path) {
      const found = dataExceptions.find(e => e.uuid === dataException.uuid);
      if (!found) {
        dataExceptions.push(dataException);
      }
    }
  }
  return dataExceptions;
});

function updateAttribute(attribute: DataAttribute) {
  attribute.ownerUri = `user://${user.value.id}/${user.value.email}`;
  emit("update", attribute);
}

const evalExpression = computed(() => {
  if (!props.taxonOverrideOptions?.taxonExpressions) {
    return false;
  }

  for (const taxonExpressions of props.taxonOverrideOptions?.taxonExpressions) {
    if (taxonExpressions?.override) {
      return taxonExpressions;
    }
    if (taxonExpressions.fromParentDataObject) {
      // Get the parentDataObject to be evaluated
      const parentDataObject: DataObject = dataObjects.value.get(props.dataObject?.parent?.uuid);
      const parentDataObjectExpression: Partial<DataObject> = taxonExpressions.fromParentDataObject.dataObject;
      const parentDataAttributeExpression: Partial<DataAttribute> = taxonExpressions.fromParentDataObject.attribute;

      if (!parentDataObject || !parentDataObject.attributes) {
        continue;
      }

      const parentObjectRes = isMatching(parentDataObjectExpression, parentDataObject);
      if (!parentObjectRes) {
        continue;
      }

      let parentAttrRes = false;
      for (const attribute of parentDataObject.attributes) {
        if (isMatching(parentDataAttributeExpression, attribute)) {
          parentAttrRes = true;
          break;
        }
      }
      if (parentAttrRes) {
        return taxonExpressions;
      }
    }
  }

  function isMatching(partialObject: Partial<DataObject | DataAttribute>, wholeObject: DataObject | DataAttribute) {
    for (const key of Object.keys(partialObject)) {
      if (!partialObject[key] || !wholeObject[key]) {
        return false;
      }
      if (partialObject[key].toLowerCase() !== wholeObject[key].toLowerCase()) {
        return false;
      }
    }
    return true;
  }

  return false;
});

const finalTagMetaData = markRaw({ ...props.tagMetadata, taxon: { ...props.tagMetadata?.taxon } });
const attrType = shallowRef(attributeTypes[finalTagMetaData?.taxon?.taxonType]);

watchDebounced(evalExpression, () => {
  if (props.taxonOverrideOptions) {
    if (evalExpression.value) {
      for (const key of Object.keys(evalExpression.value.taxonProperties)) {
        finalTagMetaData.taxon[key] = evalExpression.value.taxonProperties[key];
      }
    } else {
      finalTagMetaData.taxon = { ...props.tagMetadata?.taxon };
    }
  }

  const taxonType = finalTagMetaData.taxon.taxonType || "STRING";
  attrType.value = attributeTypes[taxonType];
}, { immediate: true, debounce: 1000 });

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  middleware: [
    offset(10),
    shift({ padding: 8 }),
  ],
});

function showMetadata(attribute: DataAttribute) {
  // We will use the confirm dialog to show the metadata
  const dialog = createConfirmDialog(KodexaDataAttributeMetadata, {
    attribute,
  } as any);
  dialog.reveal();
}

watch(focusedAttributeUuid, () => {
  if (focusedAttributeUuid.value === props.attribute.uuid) {
    // Next tick we want to find the input element and focus it
    nextTick(() => {
      const input = document.querySelector(`[data-attr-uuid="${props.attribute.uuid}"] input`);
      if (input) {
        (input as HTMLElement).focus();
      }
    });
  }
});
</script>

<template>
  <div class="flex" :data-attr-uuid="attribute.uuid" :data-object-uuid="dataObject.uuid" :data-path="attribute.path">
    <div v-if="attribute.tagUuid && attribute.ownerUri?.startsWith('user')" v-tooltip="`Edited Value`" class="h-8 w-1 bg-blue-500" />
    <div v-else-if="tagMetadata.taxon.valuePath === 'DERIVED'" v-tooltip="`Derived`" class="h-8 w-1 bg-orange-400" />
    <div v-else-if="tagMetadata.taxon.valuePath === 'FORMULA'" v-tooltip="`Formula`" class="h-8 w-1 bg-gray-50 dark:bg-gray-9000" />
    <div v-else-if="!attribute.tagUuid" v-tooltip="`Not from Document`" class="h-8 w-1 bg-yellow-500" />

    <div v-else class="h-8 w-1" />
    <Component
      :is="attrType"
      v-if="tagMetadata.taxon.valuePath !== 'FORMULA'"
      :key="`${attribute.uuid}`"
      ref="reference"
      :style="editorOptions.hideAttributeMenu ? 'width: 95%;' : 'width: calc(100% - 25px);'"
      :view-id="viewId" :tag-metadata="finalTagMetaData" :data-object="dataObject"
      :attribute="attribute"
      :data-exceptions="dataExceptions"
      :editor-options="editorOptions"
      :editable="editable"
      @update="updateAttribute"
      @delete-data-object="emit('deleteDataObject', $event)"
      @add-data-object="emit('addDataObject', $event)"
    />
    <KodexaAttributeFormula
      v-else
      ref="reference"
      :key="`formula-${attribute.uuid}`"
      style="width: 95%;"
      :view-id="viewId" :tag-metadata="finalTagMetaData" :data-object="dataObject"
      :attribute="attribute"
      :data-exceptions="dataExceptions"
      @update="updateAttribute"
      @delete-data-object="emit('deleteDataObject', $event)"
      @add-data-object="emit('addDataObject', $event)"
    />
    <KodexaDataAttributeMenu
      v-if="tagMetadata.taxon.valuePath !== 'FORMULA' && !editorOptions.hideAttributeMenu"
      class="-mt-1"
      :data-object="props.dataObject"
      :view-id="viewId"
      :attribute="attribute"
      :placeholder="placeholderAttribute"
      :tag-metadata="tagMetadata"
      :editable="editable"
      @delete-attribute="deleteAttribute(attribute)"
      @show-metadata="showMetadata(attribute)"
    />
    <div
      v-if="focusedAttributeUuid === attribute.uuid && ((!editorOptions?.hideExceptionPopup && dataExceptions.length > 0) || editorOptions?.showPreview)"
      ref="floating"
      :style="floatingStyles"
      class="absolute left-0 top-0 z-50"
    >
      <div class="bg-white dark:bg-gray-800 shadow-lg rounded-xl border border-gray-200 dark:border-gray-700 max-w-[600px] break-words">
        <div class="p-4 px-6">
          <KodexaDataAttributePreview
            v-if="!placeholderAttribute && editorOptions?.showPreview"
            :data-object="dataObject"
            :view-id="viewId"
            :attribute="attribute"
          />
          <div v-for="exception in dataExceptions" :key="exception.uuid" class="space-y-3">
            <KodexaDataException :key="exception.uuid" :exception="exception" :attribute="attribute" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
