<script lang="ts" setup>
import type { Store } from "pinia";
import type { PropType } from "vue";
import type { DataFlowNode } from "~/model";
import type { Tab } from "~/utils/tab";
import { storeToRefs } from "pinia";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "step-deleted"]);

const { modelStores } = storeToRefs(appStore.projectStore);

const modelStore = computed(() => {
  return modelStores.value.find((store: Store) => props.node && store.ref === props.node.id?.split("//")[1]);
});

const currentNavigation = ref({ ref: "general", name: "General", icon: "cog" });

const tabs = [
  { ref: "general", name: "General" },
  { ref: "options", name: "Options" },
] as Tab[];
</script>

<template>
  <div>
    <div v-if="modelStore" class="flex h-full flex-col bg-white dark:bg-gray-800">
      <div>
        <KodexaTabStrip :selected="currentNavigation" :items="tabs" :small="true" @selected="currentNavigation = $event" />
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextInput
              v-model="modelStore.name"
              name="name"
              type="text"
              autocomplete="modelStore-name"
              label="Name"
            />
          </div>
          <div class="mt-4 mb-4 text-gray-600">
            <KodexaClipboardable :content="modelStore.ref" :show-content="false" message="Copy Model Reference" />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextArea
              v-model="modelStore.description" name="modelStore-description" label="Description"
              :rows="9"
            />
          </div>
          <KodexaResourceDelete :resource="modelStore" label="Model" @deleted="emit('step-deleted')" />
        </div>
      </div>
    </div>>
  </div>
</template>

<style scoped>

</style>
