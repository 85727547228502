<script lang="ts" setup>
import type { Dashboard } from "~/model";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { useDashboardsView } from "~/store/useDashboardsView";

const dashboardsView = useDashboardsView();

const {
  dashboards,
  orderedDashboards,
  dashboardsDirty,
  showTabs,
  currentNavigation,
} = storeToRefs(dashboardsView);

const route = useRoute();
const { user, platformAdmin } = storeToRefs(appStore.userStore);

watch(orderedDashboards, () => {
  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value === undefined) {
    currentNavigation.value = orderedDashboards.value[0];
  }
}, {
  immediate: true,
  deep: true,
});

const setupMode = ref(false);

function addDashboard() {
  const newDashboard = {
    type: "dashboard",
    version: "1.0.0",
    name: `${project.value.name} Dashboard`,
    orgSlug: `${project.value.organization.slug}`,
    slug: `${project.value.id}-dashboard-${uuidv4()}`,
    description: `${project.value.name} Dashboard`,
    widgets: [],
  } as Dashboard;

  currentNavigation.value = newDashboard;
  appStore.projectStore.newDashboard(newDashboard);
}

watch(setupMode, () => {
  if (!setupMode.value) {
    // We need to update the dashboard
    appStore.projectStore.updateProjectDashboard(currentNavigation.value);
  }
});

async function deleteDashboard(dashboard: any) {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const {
    isCanceled,
  } = await deleteConfirmDialog.reveal({
    // title: `Delete dashboard ${dashboard.name} ?`,
    message: "Are you sure you want to delete this dashboard?",
    confirmText: "Delete Dashboard",
    confirmIcon: "delete",
  });
  if (!isCanceled) {
    await appStore.projectStore.deleteProjectDashboard(dashboard);
  }
}

async function saveDashboard(dashboard: Dashboard) {
  await appStore.projectStore.updateProjectDashboard(dashboard);
  notify({
    group: "generic",
    title: "Updated dashboard",
  }, 500);
}

function moveLeft(dashboard: any) {
  appStore.projectStore.updateDashboardOrder(dashboard, -1);
}

function moveRight(dashboard: any) {
  appStore.projectStore.updateDashboardOrder(dashboard, 1);
}

const savingChanges = ref(false);

async function saveDashboards() {
  if (await saveAllChange()) {
    savingChanges.value = true;
    await appStore.projectStore.saveDashboards();
    savingChanges.value = false;
  }
}

async function saveAllChange() {
  savingChanges.value = true;
  const res = await appStore.projectStore.saveAllChanges();
  savingChanges.value = false;
  return res;
}

function updateUrlWithTab(tab: any) {
  const tabName = tab.name.toLowerCase();
  window.history.replaceState({}, "", `?tab=${tabName}`);
}

watch([route, orderedDashboards], () => {
  if (orderedDashboards.value && orderedDashboards.value.length > 0) {
    const projectTab = route.query.tab as string;
    const tab = orderedDashboards.value.find((item) => {
      const tabName = item.name.toLowerCase();
      return tabName === projectTab && projectTab !== undefined;
    });
    if (tab) {
      dashboardsView.setCurrentNavigation(tab);
    } else {
      dashboardsView.setCurrentNavigation(orderedDashboards.value[0]);
    }
    updateUrlWithTab(currentNavigation.value);
  }
}, { immediate: true });
</script>

<template>
  <div class="bg-white dark:bg-gray-800">
    <header v-if="showTabs">
      <div class="float-right mt-4 pr-2">
        <slot name="header" />
        <KodexaButton
          v-if="setupMode"
          v-tooltip="'Add New Dashboard'"
          class="mr-1"
          icon="plus" name="addDashboard" size="small"
          type="secondary"
          @click="addDashboard"
        >
          Add New Dashboard
        </KodexaButton>

        <KodexaButton
          v-if="setupMode"
          :disabled="!dashboardsDirty"
          :loading="savingChanges"
          class="mr-1"
          icon="content-save" name="saveDashboards" size="small" type="primary"
          @click="saveDashboards"
        >
          Save Customization
        </KodexaButton>
        <KodexaButton
          v-if="setupMode"
          class="mr-1"
          icon="cancel" name="saveChanges" size="small" type="secondary"
          @click="setupMode = !setupMode"
        >
          Cancel
        </KodexaButton>
      </div>
      <div class="border-b border-gray-200 dark:border-gray-700">
        <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
          <li
            v-for="(item, idx) in orderedDashboards" :key="item.ref"
            class="mr-2"
            @click="currentNavigation = item; updateUrlWithTab(item); updateUrlWithTab(item)"
          >
            <a
              :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
              class="text-md"
            >
              <MaterialDesignIcon
                v-if="item?.icon" :name="item.icon" class="text-theme-primary mr-3"
                size="18"
              />
              {{ item?.name }}
              <MaterialDesignIcon
                v-if="setupMode && idx > 0" v-tooltip="`Move left`" class="text-theme-primary ml-3"
                name="chevron-left"
                size="18" @click="moveLeft(item)"
              />
              <MaterialDesignIcon
                v-if="setupMode && idx < dashboards.length" v-tooltip="`Move Right`"
                class="text-theme-primary ml-3" name="chevron-right" size="18"
                @click="moveRight(item)"
              />
            </a>
          </li>
        </ul>
      </div>
    </header>

    <div>
      <KodexaDashboard
        v-if="currentNavigation"
        :model-value="currentNavigation"
        :setup-mode="setupMode"
        entry-point="project"
        style="height: calc(100vh - 10rem)"
        @delete-dashboard="deleteDashboard(currentNavigation)"
        @save-dashboard="saveDashboard"
      />
    </div>
  </div>
</template>
