export const componentMetadata = {
  label: "Data Element Panel",
  description: "Present a data element panel for editing",
  supportsChildren: true,
  defaultWidth: 12,
  defaultHeight: 4,
  options: [
    {
      label: "Show Header",
      name: "showHeader",
      type: "boolean",
      description: "Whether to show the header",
      required: false,
      default: true,
    },
    {
      label: "Title",
      name: "title",
      type: "string",
      description: "Header title to display",
      required: false,
      showIf: "this.showHeader === true",
    },
    {
      label: "Subtitle",
      name: "subtitle",
      type: "string",
      description: "Subtitle to display",
      required: false,
      showIf: "this.showHeader === true",
    },
    {
      label: "Group Taxon",
      name: "groupTaxon",
      type: "groupTaxon",
      description: "Taxon group to use",
      required: true,
    },
    {
      label: "Use Tabs",
      name: "useTabs",
      type: "boolean",
      description: "Use tabs for display of multiple objects",
      required: false,
      default: false,
    },
    {
      label: "Hide Add",
      name: "hideAdd",
      type: "boolean",
      description: "Hide the add button",
      required: false,
      default: false,
    },
    {
      label: "Hide Exceptions",
      name: "hideExceptions",
      type: "boolean",
      description: "Hide exceptions display",
      required: false,
      default: false,
    },
    {
      label: "Exception Sticky",
      name: "exceptionSticky",
      type: "boolean",
      description: "Make exceptions stick to top",
      required: false,
      default: false,
    },
    {
      label: "Is Jump On",
      name: "isJumpOn",
      type: "boolean",
      description: "Enable jumping to this section",
      required: false,
      default: false,
    },
    {
      label: "Override Exception",
      name: "overrideException",
      type: "boolean",
      description: "Allow exception override",
      required: false,
      default: false,
    },
    {
      name: "dataMovement",
      type: "object",
      groupOptions: [
        {
          label: "Show Move",
          name: "showMove",
          type: "boolean",
          description: "Show move options",
          required: false,
          default: true,
        },
        {
          label: "Move to another Data Object",
          name: "diffDataObjectMovement",
          type: "object",
          groupOptions: [
            {
              label: "String Value",
              name: "stringValue",
              type: "string",
              description: "Value identifier for the data object",
              required: false,
            },
            {
              label: "Identifier Properties",
              name: "identifierProps",
              type: "object",
              groupOptions: [
                {
                  label: "Group Taxon Path",
                  name: "groupTaxonPath",
                  type: "taxon",
                  properties: {
                    groupOnly: true,
                  },
                },
                {
                  label: "Taxon Attribute",
                  name: "taxonAttribute",
                  type: "taxon",
                },
              ],
            },
            {
              label: "Destination Paths",
              name: "destinationPaths",
              type: "taxon",
              properties: {
                groupOnly: true,
              },
            },
            {
              label: "Destination Taxon Path",
              name: "destinationTaxonPath",
              type: "list",
              listType: "object",
              groupOptions: [
                {
                  label: "Attribute Path",
                  name: "attributePath",
                  type: "taxon",
                },
                {
                  label: "Destination Path",
                  name: "destinationPath",
                  type: "taxon",
                },
              ],
            },
          ],
        },
        {
          label: "Move in same Data Object",
          name: "sameDataObjectMovement",
          type: "object",
          groupOptions: [
            {
              label: "Identifier Properties",
              name: "identifierProps",
              type: "object",
              groupOptions: [
                {
                  label: "Group Taxon Path",
                  name: "groupTaxonPath",
                  type: "taxon",
                  properties: {
                    groupOnly: true,
                  },
                },
                {
                  label: "Taxon Attribute",
                  name: "taxonAttribute",
                  type: "taxon",
                },
              ],
            },
            {
              label: "Destination Paths",
              name: "destinationPaths",
              type: "string",
              default: "Sample",
            },
          ],
        },
      ],
    },
    {
      label: "Auto Add When Empty",
      name: "isEmptyAutoAdd",
      type: "boolean",
      description: "Automatically add new item when empty",
      required: false,
      default: false,
    },
    {
      label: "Background Color",
      name: "backgroundColor",
      type: "string",
      description: "Background color for the panel",
      required: false,
      default: "#ffffff",
    },
  ],
};
