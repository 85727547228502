import type { UseProjectStoreType } from "./useProject";
import { useAccount } from "./useAccount";
import { useOrganization } from "./useOrganization";
import { usePlatform } from "./usePlatform";
import { useProject } from "./useProject";

export interface AppStore {
  projectStore: UseProjectStoreType;
  organizationStore: any;
  platformStore: any;
  workspaceStore: any;
  validators: any;
  taskStore: any;
  userStore: any;
  cacheStore: any;
  isLoggedIn: () => boolean;
}

const appStore = {} as AppStore;

/**
 * App Level Store
 */
export function registerStore() {
  appStore.projectStore = useProject();
  appStore.organizationStore = useOrganization();
  appStore.platformStore = usePlatform();
  appStore.userStore = useAccount();
  appStore.workspaceStore = useWorkspace();
  appStore.validators = useValidators();
  appStore.cacheStore = useCache();
  appStore.taskStore = useTask();

  appStore.isLoggedIn = function isLoggedIn(): boolean {
    return !!appStore.userStore.isLoggedIn();
  };
}

export default appStore;
