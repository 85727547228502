/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateCreditTransaction400,
  CreditTransaction,
  DeleteCreditTransaction400,
  ExceptionResponse,
  GetChangeSequenceForCreditTransaction400,
  GetCreditTransaction400,
  ListCreditTransactions400,
  ListCreditTransactionsParams,
  PageCreditTransaction,
  ThrowableProblem,
  UpdateCreditTransaction400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getCreditTransaction = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<CreditTransaction>(
      {url: `/api/creditTransactions/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCreditTransactionQueryKey = (id: MaybeRef<string>,) => {
    return ['api','creditTransactions',id] as const;
    }

    
export const getGetCreditTransactionQueryOptions = <TData = Awaited<ReturnType<typeof getCreditTransaction>>, TError = ErrorType<GetCreditTransaction400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCreditTransaction>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetCreditTransactionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditTransaction>>> = ({ signal }) => getCreditTransaction(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCreditTransaction>>, TError, TData> 
}

export type GetCreditTransactionQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditTransaction>>>
export type GetCreditTransactionQueryError = ErrorType<GetCreditTransaction400 | ThrowableProblem | ExceptionResponse>



export function useGetCreditTransaction<TData = Awaited<ReturnType<typeof getCreditTransaction>>, TError = ErrorType<GetCreditTransaction400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCreditTransaction>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetCreditTransactionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateCreditTransaction = (
    id: MaybeRef<string>,
    creditTransaction: MaybeRef<CreditTransaction>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
creditTransaction = unref(creditTransaction);
      
      return customAxios<CreditTransaction>(
      {url: `/api/creditTransactions/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: creditTransaction
    },
      options);
    }
  


export const getUpdateCreditTransactionMutationOptions = <TError = ErrorType<UpdateCreditTransaction400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditTransaction>>, TError,{id: string;data: CreditTransaction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCreditTransaction>>, TError,{id: string;data: CreditTransaction}, TContext> => {
    
const mutationKey = ['updateCreditTransaction'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCreditTransaction>>, {id: string;data: CreditTransaction}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCreditTransaction(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCreditTransactionMutationResult = NonNullable<Awaited<ReturnType<typeof updateCreditTransaction>>>
    export type UpdateCreditTransactionMutationBody = CreditTransaction
    export type UpdateCreditTransactionMutationError = ErrorType<UpdateCreditTransaction400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateCreditTransaction = <TError = ErrorType<UpdateCreditTransaction400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditTransaction>>, TError,{id: string;data: CreditTransaction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateCreditTransaction>>,
        TError,
        {id: string;data: CreditTransaction},
        TContext
      > => {

      const mutationOptions = getUpdateCreditTransactionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteCreditTransaction = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/creditTransactions/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteCreditTransactionMutationOptions = <TError = ErrorType<DeleteCreditTransaction400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCreditTransaction>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCreditTransaction>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteCreditTransaction'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCreditTransaction>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteCreditTransaction(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCreditTransactionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCreditTransaction>>>
    
    export type DeleteCreditTransactionMutationError = ErrorType<DeleteCreditTransaction400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteCreditTransaction = <TError = ErrorType<DeleteCreditTransaction400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCreditTransaction>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteCreditTransaction>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteCreditTransactionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listCreditTransactions = (
    params?: MaybeRef<ListCreditTransactionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageCreditTransaction>(
      {url: `/api/creditTransactions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListCreditTransactionsQueryKey = (params?: MaybeRef<ListCreditTransactionsParams>,) => {
    return ['api','creditTransactions', ...(params ? [params]: [])] as const;
    }

    
export const getListCreditTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof listCreditTransactions>>, TError = ErrorType<ListCreditTransactions400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListCreditTransactionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCreditTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListCreditTransactionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCreditTransactions>>> = ({ signal }) => listCreditTransactions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCreditTransactions>>, TError, TData> 
}

export type ListCreditTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof listCreditTransactions>>>
export type ListCreditTransactionsQueryError = ErrorType<ListCreditTransactions400 | ThrowableProblem | ExceptionResponse>



export function useListCreditTransactions<TData = Awaited<ReturnType<typeof listCreditTransactions>>, TError = ErrorType<ListCreditTransactions400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListCreditTransactionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCreditTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListCreditTransactionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createCreditTransaction = (
    creditTransaction: MaybeRef<CreditTransaction>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      creditTransaction = unref(creditTransaction);
      
      return customAxios<CreditTransaction>(
      {url: `/api/creditTransactions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: creditTransaction, signal
    },
      options);
    }
  


export const getCreateCreditTransactionMutationOptions = <TError = ErrorType<CreateCreditTransaction400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCreditTransaction>>, TError,{data: CreditTransaction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createCreditTransaction>>, TError,{data: CreditTransaction}, TContext> => {
    
const mutationKey = ['createCreditTransaction'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCreditTransaction>>, {data: CreditTransaction}> = (props) => {
          const {data} = props ?? {};

          return  createCreditTransaction(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCreditTransactionMutationResult = NonNullable<Awaited<ReturnType<typeof createCreditTransaction>>>
    export type CreateCreditTransactionMutationBody = CreditTransaction
    export type CreateCreditTransactionMutationError = ErrorType<CreateCreditTransaction400 | ThrowableProblem | ExceptionResponse>

    export const useCreateCreditTransaction = <TError = ErrorType<CreateCreditTransaction400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCreditTransaction>>, TError,{data: CreditTransaction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createCreditTransaction>>,
        TError,
        {data: CreditTransaction},
        TContext
      > => {

      const mutationOptions = getCreateCreditTransactionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForCreditTransaction = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/creditTransactions/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForCreditTransactionQueryKey = (id: MaybeRef<string>,) => {
    return ['api','creditTransactions',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForCreditTransactionQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>, TError = ErrorType<GetChangeSequenceForCreditTransaction400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForCreditTransactionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>> = ({ signal }) => getChangeSequenceForCreditTransaction(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>, TError, TData> 
}

export type GetChangeSequenceForCreditTransactionQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>>
export type GetChangeSequenceForCreditTransactionQueryError = ErrorType<GetChangeSequenceForCreditTransaction400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForCreditTransaction<TData = Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>, TError = ErrorType<GetChangeSequenceForCreditTransaction400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCreditTransaction>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForCreditTransactionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



