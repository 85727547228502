<script setup lang="ts">
import { Editor } from "@progress/kendo-vue-editor";
import { defaultMarkdownParser, defaultMarkdownSerializer } from "prosemirror-markdown";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  tools: {
    type: Array,
    required: false,
    default: () => {
      return ["Bold", "Italic", "Link", "Unlink", "InsertImage", "FormatBlock", "Undo", "Redo", "OrderedList", "UnorderedList"];
    },
  },
});

const emit = defineEmits(["update:modelValue"]);

const localValue = computed(() => {
  return defaultMarkdownParser.parse(props.modelValue);
});

function onChange(event) {
  const content = defaultMarkdownSerializer.serialize(event.value);
  emit("update:modelValue", content);
}
</script>

<template>
  <Editor
    :resizable="true"
    :value="localValue"
    :tools="tools"
    :content-style="{
      height: '900px',
      fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
    }"
    @change="onChange"
  />
</template>

<style scoped>
:deep(.k-editor) {
  @apply dark:bg-gray-800 dark:border-gray-700;
}

:deep(.k-editor-content) {
  @apply dark:bg-gray-800 dark:text-white font-sans;
}

:deep(.k-toolbar) {
  @apply dark:bg-gray-700 dark:border-gray-600 bg-white border-b-0 dark:border-b-0;
}

:deep(.k-button) {
  @apply dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600;
}

:deep(.k-dropdown) {
  @apply dark:bg-gray-700 dark:border-gray-600 dark:text-white;
}

:deep(.k-dropdown-wrap) {
  @apply dark:bg-gray-700 dark:border-gray-600 dark:text-white;
}
</style>
