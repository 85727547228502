<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  isHidden,
  type,
  localValue,
} = useConfigurationBase(props, emit, true);
</script>

<template>
  <div>
    <ConfigurationOptionValue
      v-if="item && (item.listType === undefined && type !== 'object')" v-show="!isHidden"
      v-model="localValue"
      class="mb-0 mt-2" :item="item" :content-object="contentObject" :errors="errors"
      :assistant="assistant"
    />
    <ConfigurationOptionList
      v-else-if="item.listType !== undefined && item.listType !== null" v-show="!isHidden"
      v-model="localValue"
      class="mb-0 mt-2" :item="item" :content-object="contentObject"
      :errors="errors"
      :assistant="assistant"
    />
    <ConfigurationOptionGroup
      v-else-if="type === 'object'"
      v-show="!isHidden"
      v-model="localValue" :item="item" :content-object="contentObject"
      :assistant="assistant"
      :errors="errors"
    />
  </div>
</template>

<style>

</style>
