<script setup lang="ts">
import type { PropType } from "vue";
import type { Option, PossibleValue } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    required: false,
  },
});

const emit = defineEmits(["update:modelValue"]);
const crypto = window.crypto;

const {
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div>
    <KodexaDropDown
      v-model="localValue"
      :name="`${item.name}-${crypto.randomUUID()}`" :label="item.label || item.name"
      :items="item.possibleValues as PossibleValue[]"
      :text-field="item.properties?.textField as string || 'label'"
      :value-field="item.properties?.valueField as string || 'value'"
    />
  </div>
</template>
