<script lang="ts" setup>
import type { StripeEmbeddedCheckout } from "@stripe/stripe-js/";
import type { Ref } from "vue";
import type { CheckoutRequest, LineItem, Organization, Product } from "~/model";
import { loadStripe } from "@stripe/stripe-js/";
import { storeToRefs } from "pinia";
import { listProducts } from "~/api/product-overview/product-overview";
import { prepareCheckout } from "~/api/stripe-overview/stripe-overview";
import KodexaButton from "~/components/inputs/kodexa-button.vue";
import appStore from "~/store";
import { useIntercom } from "~/store/useIntercom";
import { log } from "~/utils/logger";

const { user } = storeToRefs(appStore.userStore);

function generateOrganizationName(email: string): string {
  // Extract the part before the @ symbol
  const name = email.split("@")[0];

  // Capitalize the first letter of the name
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

  // Construct the organization name
  return `${capitalizedName} (${email}) Organization`;
}

const organization = ref({
  name: generateOrganizationName(user.value.email),
  description: "A place where we can work together",
} as Partial<Organization>);// Create a Checkout Session as soon as the page loads

const route = useRoute();
useIntercom().trackEvent("payment-started");

const timerId = ref(setTimeout(() => {
  useIntercom().show();
}, 90000));

// Cleanup the timer when component unmounts
onBeforeUnmount(() => {
  if (timerId.value) {
    clearTimeout(timerId.value);
  }
});
const sessionId = route.query.sessionId;
const checkout: Ref<StripeEmbeddedCheckout | null> = ref(null);
const availableProducts: Ref<Product[] | null> = ref(null);

onMounted(async () => {
  listProducts({ filter: "active:true and parent is null", sort: "order:asc" }).then((response) => {
    availableProducts.value = response.content;
  });
});

const loadingCheckout = ref(false);
const instance = getCurrentInstance();
const stripeKey = instance?.appContext.config.globalProperties.stripeKey;

async function buyProduct(product: Product) {
  loadingCheckout.value = true;
  const updatedLineItems = [{ productId: product.id, quantity: 1 }];
  const stripe = await loadStripe(stripeKey);
  const checkoutRequest = {
    newOrganization: organization.value,
    lineItems: updatedLineItems as LineItem[],
    sessionId,
    subscription: true,
  } as CheckoutRequest;

  try {
    const response = await prepareCheckout(checkoutRequest);
    const clientSecret = response.clientSecret;
    if (response.metadata) {
      organization.value.name = response.metadata.organizationName;
      organization.value.description = response.metadata.organizationDescription;
    }

    if (stripe) {
      checkout.value = await stripe.initEmbeddedCheckout({
        clientSecret,
      });
      // Mount Checkout
      checkout.value.mount("#stripe-checkout");
    } else {
      log.error("Stripe is not loaded, unable to create checkout");
    }
  } catch (e) {
    console.error(e);
  }
  loadingCheckout.value = false;
}

function getFormattedPrice(price: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(price);
}
</script>

<template>
  <div>
    <div class="mr-10 mt-5 flex justify-end">
      <KodexaButton type="secondary" size="small" @click="appStore.userStore.logout()">
        Logout
      </KodexaButton>
    </div>
    <!-- Pricing section -->
    <div v-if="availableProducts && !checkout && !loadingCheckout">
      <div class="mx-auto max-w-7xl px-6  lg:px-8">
        <div class="mx-auto max-w-4xl text-center">
          <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Choose an affordable plan that’s
          packed with the features you need to make document automation a breeze.
        </p>
      </div>
      <div class="mt-4 flex items-center justify-center gap-x-6 font-lg font-bold text-gray-900">
        <p>What do you want to call your organization?</p>
      </div>
      <div class="mt-1 flex items-center justify-center gap-x-6">
        <KodexaTextInput
          v-model="organization.name" class="w-96" name="organizationName"
          style="font-size:2em !important;"
          placeholder="Choose a name for your organization" required="true"
        />
      </div>
      <div>
        <div class="isolate mx-auto mt-10 px-20 grid grid-cols-3 gap-8">
          <div
            v-for="product in availableProducts" :key="product.id"
            class="rounded-3xl p-8" :class="[product.promoted ? 'ring-2 ring-blue-600' : 'ring-1 ring-gray-200']"
          >
            <h1
              :id="product.id" style="font-size:1.5em;"
              class="text-lg font-semibold leading-8" :class="[product.promoted ? 'text-blue-600' : 'text-gray-900']"
            >
              {{ product.name }}
            </h1>
            <p class="mt-4 text-sm leading-6 text-gray-600 min-h-16">
              {{ product.description }}
            </p>
            <p class="mt-6 flex items-baseline gap-x-1">
              <span class="text-4xl font-bold tracking-tight text-gray-900">{{
                getFormattedPrice(product.price)
              }}</span>
              <span class="text-sm font-semibold leading-6 text-gray-600">{{ product.priceSuffix }}</span>
            </p>
            <a
              :aria-describedby="product.id" class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" :class="[product.promoted ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-500' : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300']"
              @click="buyProduct(product)"
            >Buy
              plan</a>
            <div role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
              <KodexaMarkdown :content="product.overviewMarkdown" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div id="stripe-checkout" class="mx-auto max-w-2xl" />
    </div>
  </div>
</template>

<style scoped>
button[disabled] {
  background-color: gray-300 !important;
  color: white !important;
}

button[disabled]:hover {
  background-color: #b5b8bc !important;
}
</style>
