<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { storeToRefs } from "pinia";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dateValue = computed({
  get() {
    if (props.attribute.dateValue) {
      return new Date(props.attribute.dateValue);
    } else {
      return undefined;
    }
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      dateValue: value,
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

function focus() {
  if (props.editable) {
    dataViewHelper.focus();
    emit("focus");
  }
}

const { style, conditionalValidationIcon, valid } = storeToRefs(dataViewHelper);

const formattedDate = computed(() => {
  if (dateValue.value) {
    return dateValue.value.toLocaleDateString();
  }
  return "";
});

const textInputOptions = {
  enterSubmit: true,
  tabSubmit: true,
  openMenu: "toggle",
  format: dateFormat.value,
  selectOnFocus: true,
  escClose: true,
};

const dateFormat = computed(() => {
  if (props.tagMetadata?.taxon?.typeFeatures?.normalizeDate) {
    return props.tagMetadata.taxon.typeFeatures.dateFormat as string;
  } else {
    return "M/d/yyyy h:mm a";
  }
});
</script>

<template>
  <div class="flex items-center" style="width: 200px" @click="focus">
    <template v-if="props.editable">
      <VueDatePicker
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="dateValue"
        model-type="date"
        time-picker-inline
        :text-input="textInputOptions"
        :name="`kdx-attribute-${props.attribute.uuid}`"
        :style="style"
        class="kodexa-input"
        rounded="medium"
        :class="{ 'invalid-date': !valid }"
        :disabled="props.dataObject?.documentFamily.locked === true"
        :valid="valid"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      />
    </template>
    <template v-else>
      <KodexaNonEditableValue
        v-if="!editable"
        :style="style" :conditional-validation-icon="conditionalValidationIcon"
        :string-value="formattedDate"
      />
    </template>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.invalid-date :deep(.dp__input) {
  border-color: red !important;
}
</style>
