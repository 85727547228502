<script lang="ts" setup>
import type { ProcessingEntity, ProcessingGroup } from "~/model";
import type { Tab } from "~/utils/tab";
import appStore from "~/store";

const props = defineProps<{
  processingEntity: ProcessingEntity;
  processingGroup: ProcessingGroup;
}>();

const emit = defineEmits<{
  "update:processingEntity": [value: ProcessingEntity];
}>();

// Create a reference to store the working copy
const localProcessingEntity = ref(props.processingEntity);

// Watch for changes in props.processingEntity
watch(() => props.processingEntity, (newValue) => {
  localProcessingEntity.value = newValue;
}, { deep: true });

// Watch for changes in localProcessingEntity
watch(localProcessingEntity, (newValue) => {
  appStore.projectStore.addProcessingEntityToUpdate(newValue);
  emit("update:processingEntity", newValue);
}, { deep: true });

const tabs = [{
  ref: "Overview",
  name: "Overview",
}, {
  ref: "Configuration",
  name: "Configuration",
}, {
  ref: "Properties",
  name: "Properties",
}];

const currentTab: Ref<Tab> = ref(tabs[0]);

function selectTab(tab: Tab) {
  currentTab.value = tab;
}

function deleteEntity() {

}
</script>

<template>
  <div class="mx-1">
    <KodexaTabStrip :items="tabs" :selected="currentTab" @selected="selectTab" />
    <div v-if="currentTab.ref === 'Overview'" class="p-4 bg-white rounded-lg shadow-md" style="height: calc(100vh - 15rem);">
      <KodexaTextInput v-model="localProcessingEntity.name" name="name" label="Name" />
      <KodexaTextArea v-model="localProcessingEntity.description" name="description" label="Description" :rows="8" />
      <div class="bg-white dark:bg-gray-800 shadow sm:rounded-lg mt-4">
        <div class="px-4 py-5 sm:p-6">
          <div class="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 class="text-base font-semibold leading-6 text-gray-900">
                Delete Entity
              </h3>
              <div class="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Deleting an entity will remove it, it will also remove its relationship to all documents
                </p>
                <KodexaButton id="deleteEntity" icon="delete" type="danger" class="mt-7" @click="deleteEntity()">
                  Delete Entity
                </KodexaButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentTab.ref === 'Configuration'" class="p-4 bg-white rounded-lg shadow-md" style="height: calc(100vh - 15rem);">
      <ConfigurationOption v-for="option in processingGroup.entityOptions" :key="option.uuid" v-model="localProcessingEntity.properties" :item="option" />
    </div>
  </div>
</template>

<style scoped>

</style>
