/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AddParticipants400,
  Channel,
  ChannelParticipant,
  CreateChannel400,
  DeleteChannel400,
  DeleteMessages400,
  ExceptionResponse,
  GetChangeSequenceForChannel400,
  GetChannel400,
  ListChannels400,
  ListChannelsParams,
  PageChannel,
  RemoveParticipants400,
  ThrowableProblem,
  UpdateChannel400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getChannel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Channel>(
      {url: `/api/channels/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChannelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','channels',id] as const;
    }

    
export const getGetChannelQueryOptions = <TData = Awaited<ReturnType<typeof getChannel>>, TError = ErrorType<GetChannel400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChannel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChannelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChannel>>> = ({ signal }) => getChannel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChannel>>, TError, TData> 
}

export type GetChannelQueryResult = NonNullable<Awaited<ReturnType<typeof getChannel>>>
export type GetChannelQueryError = ErrorType<GetChannel400 | ThrowableProblem | ExceptionResponse>



export function useGetChannel<TData = Awaited<ReturnType<typeof getChannel>>, TError = ErrorType<GetChannel400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChannel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChannelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateChannel = (
    id: MaybeRef<string>,
    channel: MaybeRef<Channel>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
channel = unref(channel);
      
      return customAxios<Channel>(
      {url: `/api/channels/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: channel
    },
      options);
    }
  


export const getUpdateChannelMutationOptions = <TError = ErrorType<UpdateChannel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateChannel>>, TError,{id: string;data: Channel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateChannel>>, TError,{id: string;data: Channel}, TContext> => {
    
const mutationKey = ['updateChannel'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateChannel>>, {id: string;data: Channel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateChannel(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateChannelMutationResult = NonNullable<Awaited<ReturnType<typeof updateChannel>>>
    export type UpdateChannelMutationBody = Channel
    export type UpdateChannelMutationError = ErrorType<UpdateChannel400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateChannel = <TError = ErrorType<UpdateChannel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateChannel>>, TError,{id: string;data: Channel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateChannel>>,
        TError,
        {id: string;data: Channel},
        TContext
      > => {

      const mutationOptions = getUpdateChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteChannel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/channels/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteChannelMutationOptions = <TError = ErrorType<DeleteChannel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteChannel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteChannel>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteChannel'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteChannel>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteChannel(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteChannelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteChannel>>>
    
    export type DeleteChannelMutationError = ErrorType<DeleteChannel400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteChannel = <TError = ErrorType<DeleteChannel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteChannel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteChannel>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Add participant to a channel
 */
export const addParticipants = (
    id: MaybeRef<string>,
    channelParticipant: MaybeRef<ChannelParticipant>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
channelParticipant = unref(channelParticipant);
      
      return customAxios<void>(
      {url: `/api/channels/${id}/participants`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: channelParticipant
    },
      options);
    }
  


export const getAddParticipantsMutationOptions = <TError = ErrorType<AddParticipants400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addParticipants>>, TError,{id: string;data: ChannelParticipant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addParticipants>>, TError,{id: string;data: ChannelParticipant}, TContext> => {
    
const mutationKey = ['addParticipants'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addParticipants>>, {id: string;data: ChannelParticipant}> = (props) => {
          const {id,data} = props ?? {};

          return  addParticipants(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddParticipantsMutationResult = NonNullable<Awaited<ReturnType<typeof addParticipants>>>
    export type AddParticipantsMutationBody = ChannelParticipant
    export type AddParticipantsMutationError = ErrorType<AddParticipants400 | ThrowableProblem | ExceptionResponse>

    export const useAddParticipants = <TError = ErrorType<AddParticipants400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addParticipants>>, TError,{id: string;data: ChannelParticipant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof addParticipants>>,
        TError,
        {id: string;data: ChannelParticipant},
        TContext
      > => {

      const mutationOptions = getAddParticipantsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listChannels = (
    params?: MaybeRef<ListChannelsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageChannel>(
      {url: `/api/channels`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListChannelsQueryKey = (params?: MaybeRef<ListChannelsParams>,) => {
    return ['api','channels', ...(params ? [params]: [])] as const;
    }

    
export const getListChannelsQueryOptions = <TData = Awaited<ReturnType<typeof listChannels>>, TError = ErrorType<ListChannels400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListChannelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListChannelsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listChannels>>> = ({ signal }) => listChannels(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData> 
}

export type ListChannelsQueryResult = NonNullable<Awaited<ReturnType<typeof listChannels>>>
export type ListChannelsQueryError = ErrorType<ListChannels400 | ThrowableProblem | ExceptionResponse>



export function useListChannels<TData = Awaited<ReturnType<typeof listChannels>>, TError = ErrorType<ListChannels400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListChannelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listChannels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListChannelsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createChannel = (
    channel: MaybeRef<Channel>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      channel = unref(channel);
      
      return customAxios<Channel>(
      {url: `/api/channels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: channel, signal
    },
      options);
    }
  


export const getCreateChannelMutationOptions = <TError = ErrorType<CreateChannel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createChannel>>, TError,{data: Channel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createChannel>>, TError,{data: Channel}, TContext> => {
    
const mutationKey = ['createChannel'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createChannel>>, {data: Channel}> = (props) => {
          const {data} = props ?? {};

          return  createChannel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateChannelMutationResult = NonNullable<Awaited<ReturnType<typeof createChannel>>>
    export type CreateChannelMutationBody = Channel
    export type CreateChannelMutationError = ErrorType<CreateChannel400 | ThrowableProblem | ExceptionResponse>

    export const useCreateChannel = <TError = ErrorType<CreateChannel400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createChannel>>, TError,{data: Channel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createChannel>>,
        TError,
        {data: Channel},
        TContext
      > => {

      const mutationOptions = getCreateChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForChannel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/channels/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForChannelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','channels',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForChannelQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForChannel>>, TError = ErrorType<GetChangeSequenceForChannel400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForChannel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForChannelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForChannel>>> = ({ signal }) => getChangeSequenceForChannel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForChannel>>, TError, TData> 
}

export type GetChangeSequenceForChannelQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForChannel>>>
export type GetChangeSequenceForChannelQueryError = ErrorType<GetChangeSequenceForChannel400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForChannel<TData = Awaited<ReturnType<typeof getChangeSequenceForChannel>>, TError = ErrorType<GetChangeSequenceForChannel400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForChannel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForChannelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Remove participant from a channel
 */
export const removeParticipants = (
    id: MaybeRef<string>,
    participantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
participantId = unref(participantId);
      
      return customAxios<void>(
      {url: `/api/channels/${id}/participants/${participantId}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveParticipantsMutationOptions = <TError = ErrorType<RemoveParticipants400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeParticipants>>, TError,{id: string;participantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeParticipants>>, TError,{id: string;participantId: string}, TContext> => {
    
const mutationKey = ['removeParticipants'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeParticipants>>, {id: string;participantId: string}> = (props) => {
          const {id,participantId} = props ?? {};

          return  removeParticipants(id,participantId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveParticipantsMutationResult = NonNullable<Awaited<ReturnType<typeof removeParticipants>>>
    
    export type RemoveParticipantsMutationError = ErrorType<RemoveParticipants400 | ThrowableProblem | ExceptionResponse>

    export const useRemoveParticipants = <TError = ErrorType<RemoveParticipants400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeParticipants>>, TError,{id: string;participantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof removeParticipants>>,
        TError,
        {id: string;participantId: string},
        TContext
      > => {

      const mutationOptions = getRemoveParticipantsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete all messages in a channel
 */
export const deleteMessages = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/channels/${id}/messages`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteMessagesMutationOptions = <TError = ErrorType<DeleteMessages400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMessages>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMessages>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteMessages'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMessages>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteMessages(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMessagesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMessages>>>
    
    export type DeleteMessagesMutationError = ErrorType<DeleteMessages400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteMessages = <TError = ErrorType<DeleteMessages400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMessages>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteMessages>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteMessagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    