<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget } from "~/model";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import KodexaMarkdown from "~/components/kodexa-markdown.vue";
import appStore from "~/store";
import emitter from "~/utils/event-bus";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const componentMetadata = {
  label: "Workflow Welcome",
  description: "Displays welcome information for the workflow dashboard",
  dashboard: true,
  options: [
    {
      label: "Welcome Title",
      key: "welcomeTitle",
      type: "string",
      required: false,
      description: "Custom title for the welcome section",
    },
    {
      label: "Welcome Message",
      key: "welcomeMessage",
      type: "string",
      required: false,
      description: "Custom welcome message content",
    },
  ],
};

defineExpose({
  componentMetadata,
});

const { platformConfiguration } = storeToRefs(appStore.platformStore);

const welcomeContent = computed(() => {
  return props.widget?.properties?.welcomeMessage || platformConfiguration.value?.welcomeMarkdown;
});

function openOrgs() {
  emitter.emit("app:open-organization-popup");
}
</script>

<template>
  <div class="relative isolate overflow-hidden bg-white dark:bg-gray-800 h-full">
    <svg class="absolute inset-0 -z-10 size-full stroke-gray-200 dark:stroke-gray-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
      <defs>
        <pattern id="welcome-pattern" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
          <path d="M.5 200V.5H200" fill="none" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" stroke-width="0" fill="url(#welcome-pattern)" />
    </svg>
    <div class="mx-auto max-w-7xl px-6 pb-12 pt-7 sm:pb-16 lg:flex lg:px-8 lg:py-20 h-full">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:shrink-0 lg:pt-8">
        <KodexaMarkdown v-if="welcomeContent" :content="welcomeContent" />

        <KodexaAnimatedText class="mt-4 cursor-pointer" @click="openOrgs">
          <div class="text-gray-700">
            Choose your organization
          </div>
        </KodexaAnimatedText>
      </div>
      <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
          <div class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
            <img src="/src/images/kodexa-task.png" alt="Workflow preview" width="2432" height="1442" class="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.prose :deep(h2) {
  @apply text-gray-900 dark:text-white text-2xl font-semibold mt-8 mb-4;
}

.prose :deep(ul) {
  @apply list-disc pl-6 mt-4 space-y-2 text-gray-600 dark:text-gray-400;
}

.prose :deep(li) {
  @apply text-base;
}
</style>
