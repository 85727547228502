import type { GridItem } from "~/components/dataForm/data-form-editor/editor-panel";
import type { Card } from "~/model";
import { startCase } from "lodash";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { availableCards } from "~/components/dataForm/cards/cards";
import appStore from "~/store/index";
import { log } from "~/utils/logger";

export interface Panel extends Card {
  x: number;
  y: number;
  w: number;
  h: number;
  static?: boolean;
  children: Panel[];
  parentId: string | null;
}

export function createDataFormEditorStore(viewId: string) {
  return defineStore(`dataFormEditor-${viewId}`, () => {
    const panels = ref<Panel[]>([]);
    const selectedPanelId = ref<string | null>(null);

    const selectedPanel = computed(() =>
      selectedPanelId.value ? findPanelById(selectedPanelId.value, panels.value) : undefined,
    );

    const layout = computed({
      get: () => {
        return panels.value.map(panel => ({
          i: panel.id,
          x: panel.x,
          y: panel.y,
          w: panel.w,
          h: panel.h,
        }));
      },
      set: (newLayout: GridItem[]) => {
        updateLayout(newLayout);
      },
    });

    function findPanelById(id: string, panelList: Panel[]): Panel | undefined {
      for (const panel of panelList) {
        if (panel.id === id) {
          return panel;
        }
        if (panel.children.length > 0) {
          const found = findPanelById(id, panel.children);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    }

    function convertCardToPanel(card: Card, parentId: string | null = null): Panel {
      // Lets get the parent so we can work out the x and y based on the children that exist
      const parentPanel = parentId ? findPanelById(parentId, panels.value) : null;
      if (parentPanel) {
        const nextX = parentPanel.children.length > 0 ? parentPanel.children[parentPanel.children.length - 1].x + 4 : 0;
        return {
          ...card,
          id: card.id || uuidv4(),
          x: card.properties?.col || nextX,
          y: card.properties?.row || 0,
          w: card.properties?.colSpan || 12,
          h: card.properties?.rowSpan || 6,
          static: false,
          children: card.children ? card.children.map(c => convertCardToPanel(c, card.id)) : [],
          parentId,
          type: card.type || "panel",
          title: card.properties?.label || startCase(card.type) || "New Panel",
          properties: {
            ...card.properties,
            backgroundColor: "#ffffff",
          },
        };
      } else {
        return {
          ...card,
          id: card.id || uuidv4(),
          x: card.properties?.col || 0,
          y: card.properties?.row || 0,
          w: card.properties?.colSpan || 12,
          h: card.properties?.rowSpan || 6,
          static: false,
          children: card.children ? card.children.map(c => convertCardToPanel(c, card.id)) : [],
          parentId,
          type: card.type || "panel",
          title: card.properties?.label || startCase(card.type) || "New Panel",
          properties: {
            ...card.properties,
            backgroundColor: "#ffffff",
          },
        };
      }
    }

    function addPanel(card?: Card, parentId: string | null = null, cardType: string = "panel") {
      if (card) {
        const newPanel = convertCardToPanel(card, parentId);
        if (parentId) {
          const parentPanel = findPanelById(parentId, panels.value);
          if (parentPanel) {
            parentPanel.children.push(newPanel);
          }
        } else {
          panels.value.push(newPanel);
        }
      } else {
        // Get the card metadata
        const card = availableCards.filter(availableCard => availableCard?.type === cardType)[0];

        if (!card) {
          log.error(`Card type ${cardType} not found`);
          return;
        }

        const newPanel: Panel = {
          id: uuidv4(),
          type: cardType,
          title: startCase(cardType),
          children: [],
          parentId,
          x: 0,
          y: parentId ? 0 : panels.value.length * 4,
          w: card.defaultWidth || 12,
          h: card.defaultHeight || 4,
          static: false,
          properties: {
            backgroundColor: "#ffffff",
            title: startCase(cardType),
          },
        };

        if (parentId) {
          const parentPanel = findPanelById(parentId, panels.value);
          if (parentPanel) {
            parentPanel.children.push(newPanel);
          }
        } else {
          panels.value.push(newPanel);
        }
      }
      syncDataForm();
    }

    function updateChildrenLayout(parentId: string, newLayout: GridItem[]) {
      log.info("Updating children layout");
      const parentPanel = findPanelById(parentId, panels.value);
      if (parentPanel) {
        newLayout.forEach((item) => {
          const childPanel = parentPanel.children.find(p => p.id === item.i);
          if (childPanel) {
            childPanel.x = item.x;
            childPanel.y = item.y;
            childPanel.w = item.w;
            childPanel.h = item.h;
            // Update card properties
            childPanel.properties = {
              ...childPanel.properties,
              col: item.x,
              row: item.y,
              colSpan: item.w,
              rowSpan: item.h,
            };
          }
        });
        updateLayout(newLayout);
      }
    }

    function updatePanelProperties(id: string, properties: Record<string, any>) {
      const panel = findPanelById(id, panels.value);
      if (panel) {
        panel.properties = {
          ...panel.properties,
          ...properties,
        };
        syncDataForm();
      }
    }

    function updateLayout(newLayout: GridItem[]) {
      newLayout.forEach((item) => {
        const panel = panels.value.find(p => p.id === item.i);
        if (panel) {
          panel.x = item.x;
          panel.y = item.y;
          panel.w = item.w;
          panel.h = item.h;
          // Update card properties
          panel.properties = {
            ...panel.properties,
            col: item.x,
            row: item.y,
            colSpan: item.w,
            rowSpan: item.h,
          };
        }
      });
      syncDataForm();
    }

    function syncDataForm() {
      const cards = convertToCards();

      // Get the data form based on the viewId
      const view = appStore.workspaceStore.views.find(v => v.id === viewId);

      // Get the form ref
      const formRef = view?.dataFormRef;
      const { dataForms } = appStore.projectStore;
      if (formRef) {
        const dataForm = dataForms.find(df => df.ref === formRef);
        if (dataForm) {
          dataForm.cards = cards;
          appStore.projectStore.updateDataFormCards(dataForm.ref, cards);
        }
      }
    }

    function deletePanel(id: string) {
      function findAndDeletePanel(panels: Panel[]): boolean {
        for (let i = 0; i < panels.length; i++) {
          if (panels[i].id === id) {
            panels.splice(i, 1);
            if (selectedPanelId.value === id) {
              selectedPanelId.value = null;
            }
            return true;
          }
          if (panels[i].children.length > 0) {
            if (findAndDeletePanel(panels[i].children)) {
              return true;
            }
          }
        }
        return false;
      }

      findAndDeletePanel(panels.value);
      syncDataForm();
    }

    function selectPanel(id: string | null) {
      selectedPanelId.value = id;
    }

    function convertToCards(): Card[] {
      function panelToCard(panel: Panel): Card {
        return {
          id: panel.id,
          type: panel.type,
          properties: {
            ...panel.properties,
            col: panel.x,
            row: panel.y,
            colSpan: panel.w,
            rowSpan: panel.h,
          },
          children: panel.children.map(panelToCard),
        };
      }

      return panels.value.map(panelToCard);
    }

    function processLayout(components: Card[]): Card[] {
      const processed: Card[] = [...components];
      const grid: { [key: string]: boolean }[][] = []; // 2D array to track occupied cells

      // Initialize grid with enough rows and columns
      function ensureGridSpace(row: number, col: number) {
        while (grid.length <= row) {
          grid.push([]);
        }
        while (grid[row].length <= col) {
          grid[row].push(false);
        }
      }

      // Check if space is available
      function isSpaceAvailable(row: number, col: number, width: number, height: number): boolean {
        for (let r = row; r < row + height; r++) {
          for (let c = col; c < col + width; c++) {
            ensureGridSpace(r, c);
            if (grid[r][c]) {
              return false;
            }
          }
        }
        return true;
      }

      // Find next available position
      function findNextPosition(width: number, height: number): { row: number; col: number } {
        let row = 0;
        while (true) {
          for (let col = 0; col <= 12 - width; col++) {
            if (isSpaceAvailable(row, col, width, height)) {
              return { row, col };
            }
          }
          row++;
        }
      }

      // Mark space as occupied
      function occupySpace(row: number, col: number, width: number, height: number) {
        for (let r = row; r < row + height; r++) {
          for (let c = col; c < col + width; c++) {
            ensureGridSpace(r, c);
            grid[r][c] = true;
          }
        }
      }

      // Process each component
      processed.forEach((component) => {
        const width = component.properties.colSpan || 12;
        const height = Math.max(2, component.properties.rowSpan || 2);

        const { row, col } = findNextPosition(width, height);

        // Update component position
        component.properties.row = row;
        component.properties.col = col;
        component.properties.rowSpan = height;

        // Mark space as occupied
        occupySpace(row, col, width, height);

        // Process children recursively
        if (Array.isArray(component.children)) {
          component.children = processLayout(component.children);
        }
      });

      return processed;
    }

    function initialize(cards: Card[]) {
      cards.forEach((card) => {
        addPanel(card);
      });
    }

    function autoLayout(cards: Card[]) {
      panels.value = [];
      processLayout(cards).forEach((card) => {
        addPanel(card);
      });
    }

    return {
      panels,
      selectedPanel,
      layout,
      initialize,
      addPanel,
      updateLayout,
      deletePanel,
      selectPanel,
      updatePanelProperties,
      updateChildrenLayout,
      autoLayout,
    };
  });
}
