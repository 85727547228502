<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListProjects } from "~/api/projects/projects";
import KodexaProjectCard from "~/components/kodexa-project-card.vue";
import router from "~/router/router";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const {
  currentOrganization,
} = storeToRefs(appStore.organizationStore);

const widget = ref(props.widget);

const gridHelper = createGridHelper(`projects-list ${currentOrganization.value?.id}`, {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProjects(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = computed(() => {
  const baseColumns = [
    { title: "Name", field: "name", cell: "name", width: "500px" },
    { title: "Status", field: "status.status", cell: "status" },
    { title: "Owner", field: "owner.firstName", cell: "owner" },
    { title: "Created", field: "createdOn", cell: "created" },
  ];

  if (!currentOrganization.value) {
    baseColumns.splice(1, 0, { title: "Organization", field: "organization.name", cell: "organization" });
  }

  return baseColumns;
});

watch(currentOrganization, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`organization.id:'${newValue.id}'`);
    gridHelper.setPage(1);
  } else {
    gridHelper.setBaseFilter("");
    gridHelper.setPage(1);
  }
}, {
  immediate: true,
});

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

const el = ref(null);

const gridStyle = computed(() => {
  const rowSpan = widget.value?.properties?.defaultPosition.rowSpan || 6;
  const height = (rowSpan * 140) - 130;
  return {
    rowSpan: `${rowSpan}`,
    height: `${height}px`,
  };
});

function gotoOrg(org) {
  const prefix = router.currentRoute.value.path.startsWith("/f/") ? "/f/" : "/a/";
  router.push(`${prefix}o/${org.id}/home`);
}
</script>

<template>
  <div>
    <div class="mx-4">
      <KodexaToolbar class="mx-1">
        <label for="query" class="sr-only">Query</label>
        <KodexaTextInput
          id="filterProjects"
          v-model="computedQuery" :show-clear="true" type="text" name="filterProjects"
          class="-ml-1 block w-96 rounded-md border-gray-300 dark:border-gray-600 shadow-sm sm:text-sm dark:bg-gray-700 dark:text-gray-200" placeholder="Filter projects"
        />
        <Button
          class="rounded-md bg-white dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-900  dark:text-gray-50  shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"
          :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
        />
        <KodexaArticle class="ml-2 mt-2" article-id="8729166" text="Learn more about projects" :slide="false" />
        <ToolbarSpacer />
      </KodexaToolbar>
      <Grid
        style="height: calc(100vh - 208px)"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        scrollable="scrollable"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :take="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No projects found"
          />
        </GridNoRecords>
        <template #organization="{ props }">
          <td>
            <div class="open-org-link cursor-pointer dark:text-blue-400" @click="gotoOrg(props.dataItem.organization)">
              {{ props.dataItem.organization.name }}
            </div>
          </td>
        </template>
        <template #name="{ props }">
          <td>
            <KodexaProjectCard :project="props.dataItem" @updated="refetch()" />
          </td>
        </template>
        <template #status="{ props }">
          <td>
            <KodexaProjectStatus :project="props.dataItem" @updated="refetch" />
          </td>
        </template>
        <template #owner="{ props }">
          <td>
            <KodexaProjectOwner :project="props.dataItem" @updated="refetch" />
          </td>
        </template>
        <template #created="{ props }">
          <td>
            <KodexaDateTimeView :date="props.dataItem.createdOn" :ago="true" />
          </td>
        </template>
      </Grid>
    </div>
  </div>
</template>

<style scoped>
.open-org-link {
  color: #007bff; /* Change to your preferred link color */
  text-decoration: underline;
  cursor: pointer;
}

.open-org-link:hover {
  color: #0056b3; /* Darker shade for hover effect */
}

@media (prefers-color-scheme: dark) {
  .open-org-link {
    color: #60a5fa; /* Light blue for dark mode */
  }

  .open-org-link:hover {
    color: #93c5fd; /* Lighter blue for dark mode hover */
  }
}
</style>
