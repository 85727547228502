<script setup lang="ts">
import type { Ref } from "vue";
import type { Option } from "~/model";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { createDataFormEditorStore } from "~/store/useDataFormEditorStore";
import { availableCards } from "../cards/cards";

interface LayoutInfo {
  row: number;
  col: number;
  colSpan: number;
  rowSpan: number;
}

interface CardProperties {
  isCheckbox?: boolean;
  onCheckValue?: string;
  isMaskedText?: boolean;
  maskPattern?: string;
  maskDateFormat?: string;
  sourceDateFormat?: string;
  [key: string]: any;
}

const props = defineProps<{
  viewId: string;
}>();

const store = createDataFormEditorStore(props.viewId)();
const { selectedPanel } = storeToRefs(store);

const layoutInfo = computed<LayoutInfo | null>(() => {
  if (!selectedPanel.value) {
    return null;
  }

  const { properties } = selectedPanel.value;
  return {
    row: properties?.row || 0,
    col: properties?.col || 0,
    colSpan: properties?.colSpan || 12,
    rowSpan: properties?.rowSpan || 1,
  };
});

const cardOptions = computed(() => {
  if (!selectedPanel.value) {
    return [] as Option[];
  }

  const cardType = selectedPanel.value.type;
  const cardComponent = availableCards.find(c => c.type === cardType);

  return cardComponent?.options as Option[] || [];
}) as Ref<Option[]>;

const cardProperties = computed({
  get: () => {
    const props = selectedPanel.value?.properties || {};
    // Return the properties directly without flattening
    return props;
  },
  set: (value: CardProperties) => {
    if (!selectedPanel.value?.id) {
      return;
    }

    // No need to destructure and reconstruct the nested objects
    store.updatePanelProperties(selectedPanel.value.id, value);
  },
});

const isLayoutExpanded = ref(false);
</script>

<template>
  <div style="height: calc(100vh - 9rem); overflow: scroll;">
    <div v-if="selectedPanel" class="px-4 space-y-2">
      <section class="space-y-2">
        <ConfigurationOption
          v-for="cardItem in cardOptions"
          :key="cardItem.name"
          v-model="cardProperties"
          :item="cardItem"
          class="transition-all duration-200 ease-in-out"
        />
      </section>
      <section
        v-if="layoutInfo"
        class="bg-gray-50 rounded-lg border border-gray-200 shadow-sm p-4"
      >
        <div class="flex justify-between items-center mb-3 cursor-pointer" @click="isLayoutExpanded = !isLayoutExpanded">
          <h3 class="text-sm font-semibold text-gray-700">
            Layout Information
          </h3>
          <svg
            class="w-5 h-5 text-gray-500 transition-transform duration-200"
            :class="{ 'rotate-180': !isLayoutExpanded }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </div>
        <div v-show="isLayoutExpanded" class="grid grid-cols-2 gap-3">
          <div
            v-for="(value, key) in layoutInfo"
            :key="key"
            class="flex justify-between items-center p-3 bg-white rounded-md border border-gray-200 hover:border-gray-300 transition-colors"
          >
            <span class="text-sm text-gray-600 font-medium capitalize">{{ key }}:</span>
            <span class="font-medium text-gray-800">{{ value }}</span>
          </div>
        </div>
      </section>
    </div>
    <template v-else>
      <div class="text-center mt-12">
        <svg
          class="mx-auto size-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.5 14.5L19 18M10 15.5C6.41015 15.5 3.5 12.5899 3.5 9C3.5 5.41015 6.41015 2.5 10 2.5C13.5899 2.5 16.5 5.41015 16.5 9C16.5 12.5899 13.5899 15.5 10 15.5ZM10 6V12M7 9H13"
          />
        </svg>
        <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-100">
          No Selected Card
        </h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Select a card to view its properties
        </p>
      </div>
    </template>
  </div>
</template>
