<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";

const {
  activeView,
} = storeToRefs(appStore.workspaceStore);
</script>

<template>
  <div>
    <div v-if="activeView?.id">
      <EditorDataFormProperties :view-id="activeView.id" />
    </div>
  </div>
</template>

<style scoped>
/* Add necessary styles here */
</style>
