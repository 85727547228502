/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListAuditRevisions400,
  ListAuditRevisionsParams,
  PageAuditRevision,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Get all audit revisions
 */
export const listAuditRevisions = (
    params: MaybeRef<ListAuditRevisionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAuditRevision>(
      {url: `/api/auditRevisions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAuditRevisionsQueryKey = (params: MaybeRef<ListAuditRevisionsParams>,) => {
    return ['api','auditRevisions', ...(params ? [params]: [])] as const;
    }

    
export const getListAuditRevisionsQueryOptions = <TData = Awaited<ReturnType<typeof listAuditRevisions>>, TError = ErrorType<ListAuditRevisions400 | ThrowableProblem | ExceptionResponse>>(params: MaybeRef<ListAuditRevisionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAuditRevisions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAuditRevisionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAuditRevisions>>> = ({ signal }) => listAuditRevisions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAuditRevisions>>, TError, TData> 
}

export type ListAuditRevisionsQueryResult = NonNullable<Awaited<ReturnType<typeof listAuditRevisions>>>
export type ListAuditRevisionsQueryError = ErrorType<ListAuditRevisions400 | ThrowableProblem | ExceptionResponse>


/**
 * @summary Get all audit revisions
 */

export function useListAuditRevisions<TData = Awaited<ReturnType<typeof listAuditRevisions>>, TError = ErrorType<ListAuditRevisions400 | ThrowableProblem | ExceptionResponse>>(
 params: MaybeRef<ListAuditRevisionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAuditRevisions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAuditRevisionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



