<script setup lang="ts">
import type { ProcessingGroup } from "~/model";
import KodexaProcessingGroup from "~/components/processingGroups/kodexa-processing-group.vue";

const selectedGroup: Ref<ProcessingGroup | undefined> = ref(undefined);

function selectProcessingGroup(group: ProcessingGroup) {
  selectedGroup.value = group;
}
</script>

<template>
  <div class="mx-4 my-6">
    <div class="flex gap-6">
      <div class="w-1/3 p-4">
        <KodexaProcessingGroups @select="selectProcessingGroup" />
      </div>
      <div class="w-2/3 p-4">
        <KodexaProcessingGroup v-if="selectedGroup" :key="selectedGroup.uuid" :processing-group="selectedGroup" />
      </div>
    </div>
  </div>
</template>
