<script lang="ts" setup>
import { storeToRefs } from "pinia";
import KodexaButton from "~/components/inputs/kodexa-button.vue";
import appStore from "~/store";

const { user } = storeToRefs(appStore.userStore);
</script>

<template>
  <div class="relative min-h-screen bg-gradient-to-b from-white to-gray-50">
    <div class="absolute top-5 right-10">
      <KodexaButton type="secondary" size="small" class="hover:shadow-lg transition-shadow duration-300" @click="appStore.userStore.logout()">
        Logout
      </KodexaButton>
    </div>
    <div class="flex items-center justify-center min-h-screen">
      <div class="mx-auto max-w-2xl px-6 py-12 sm:py-16 lg:px-8">
        <div class="text-center space-y-8">
          <h2 class="h-12 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-500">
            No Organization Access
          </h2>
          <p class="text-lg leading-8 text-gray-600 max-w-xl mx-auto">
            Hi <span class="font-medium text-indigo-600">{{ user?.firstName }}</span>, you currently don't have access to any organizations in Kodexa.
            Please contact your administrator to request access or to be added to an organization.
          </p>
          <div class="w-24 h-1 bg-indigo-500 mx-auto rounded-full opacity-75" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
