export const optionMetadata = {
  label: "Taxon Dropdown Item",
  description: "A dropdown item component for taxon selection",
  validators: [],
  defaultValue: null,
  metadata: {
    supportsList: false,
    supportsGroup: false,
  },
};
