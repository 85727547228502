<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";

const { project } = storeToRefs(appStore.projectStore);

const localProject = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

function updateOptions(newOptions: any) {
  if (localProject.value) {
    localProject.value = {
      ...localProject.value,
      options: newOptions,
    };
  }
}
</script>

<template>
  <OptionsBuilder
    :options="localProject.options || []"
    @update:options="updateOptions"
  />
</template>
