<script setup lang="ts">
import type { PropType } from "vue";
import type { Task } from "~/model";
import { storeToRefs } from "pinia";
import { updateTaskTagsForTask } from "~/api/tasks/tasks";
import KodexaTaskTagify from "~/components/kodexa-task-tagify.vue";
import appStore from "~/store";

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
});

const emit = defineEmits(["updated"]);

const {
  taskTags,
} = storeToRefs(appStore.projectStore);

const taskTagList = computed(() => {
  return JSON.parse(JSON.stringify(props.task.tags)) ?? [];
});

function addTag(tag) {
  taskTagList.value.push(tag);
  updateTaskTagsForTask(props.task.id as string, taskTagList.value).then((e) => {
    emit("updated");
  });
}

function removeTag(tag) {
  updateTaskTagsForTask(props.task.id as string, taskTagList.value.filter(t => t.name !== tag),
  ).then(() => {
    emit("updated");
  });
}
</script>

<template>
  <div v-if="task" style="width: 100%">
    <KodexaTaskTagify
      :tags="taskTagList" :available-tags="taskTags" @add-tag="addTag" @remove-tag="removeTag"
    />
  </div>
</template>

<style scoped>

</style>
