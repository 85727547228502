<script lang="ts" setup>
import type { PropType } from "vue";
import type { DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";

defineProps({
  rows: {
    type: Array as PropType<any[]>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentDataObjectGroups: {
    type: Map as PropType<Map<string, DataObject>>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard", "deleteDataObject", "addDataObject", "moveDataObject"]);

onMounted(() => {
  nextTick(() => {
    // TODO replace me with something proper
    const els = document.querySelector(".kodexa-data-view-form-input");
    // @ts-expect-error this is horrible
    els?.focus();
  });
});

function orderedRow(row: any[]) {
  return row.sort((a, b) => {
    // We want to order by properties.col
    return a.properties.col - b.properties.col;
  });
}
</script>

<template>
  <div class="w-100">
    <div v-for="row in rows" :key="row.id" class="flex flex-wrap" :data-object-attributes="parentDataObject?.attributes[0]">
      <div
        v-for="rowCard in orderedRow(row.cards)" :key="rowCard.id"
        class="mr-1"
        :style="{ width: `${(parseInt(rowCard.colSpan) / 12) * 100}%` }"
      >
        <KodexaFormCard
          style="width: 100%"
          :card="rowCard"
          :parent-tag-metadata="parentTagMetadata"
          :view-id="viewId"
          :parent-data-object="parentDataObject"
          :parent-data-object-groups="parentDataObjectGroups"
          :jump-on="rowCard.type === 'dataAttributeEditor' && rowCard.properties?.isJumpOn ? 'true' : 'false'"
          @delete-data-object="emit('deleteDataObject', $event)"
          @add-data-object="emit('addDataObject', $event)"
          @move-data-object="emit('moveDataObject', $event)"
        />
      </div>
    </div>
  </div>
</template>
