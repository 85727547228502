<script setup lang="ts">
import type { PropType } from "vue";
import type { TaskTemplate, TemplateDataForm } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import * as yup from "yup";
import { useCreateTaskTemplate, useDeleteTaskTemplate, useUpdateTaskTemplate } from "~/api/task-templates/task-templates";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

// Props and Emits
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  template: {
    type: Object as PropType<TaskTemplate>,
    default: undefined,
    required: false,
  },
});

const emit = defineEmits<{
  "update:modelValue": [value: boolean];
  "templateDeleted": [id: string];
  "refetchTemplates": [];
}>();

// Store
const { project, dataForms, workspaces } = storeToRefs(appStore.projectStore);

// Mutations
const createTemplateMutation = useCreateTaskTemplate();
const updateTemplateMutation = useUpdateTaskTemplate();
const deleteTemplateMutation = useDeleteTaskTemplate();

// Computed properties
const isEditMode = computed(() => !!props.template);
const modalTitle = computed(() => isEditMode.value ? "Update Task Template" : "New Task Template");
const submitButtonText = computed(() => isEditMode.value ? "Save Changes" : "Create");
const submitButtonIcon = computed(() => isEditMode.value ? "content-save" : "plus");

// Create a reactive reference for the initial values
const localTemplate = ref(props.template
  ? JSON.parse(JSON.stringify(props.template))
  : {
      name: "",
      description: "",
      project: project.value,
      metadata: {},
    });

const validationSchema = yup.object({
  name: yup.string().required("Template name is required"),
});

const properties = computed(() => {
  return localTemplate.value.metadata?.properties || [];
});

const errors = ref({});

watch(localTemplate, async (newValue) => {
  try {
    await validationSchema.validate(newValue, { abortEarly: false });
    errors.value = {};
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const newErrors = {};
      err.inner.forEach((error) => {
        if (error.path) {
          newErrors[error.path] = error.message;
        }
      });
      errors.value = newErrors;
    }
  }
}, { deep: true });

async function onSubmit() {
  try {
    await validationSchema.validate(localTemplate.value);
    if (isEditMode.value) {
      await updateTemplateMutation.mutateAsync({
        id: localTemplate.value.id as string,
        data: localTemplate.value as TaskTemplate,
      });
      await updateHandler(Promise.resolve(), "Template updated successfully");
    } else {
      await createTemplateMutation.mutateAsync({
        data: localTemplate.value as TaskTemplate,
      });
      await updateHandler(Promise.resolve(), "Template created successfully");
    }
    emit("refetchTemplates");
    close();
  } catch (error) {
    console.error(`Error ${isEditMode.value ? "updating" : "creating"} template:`, error);
    await updateHandler(Promise.reject(error), `Failed to ${isEditMode.value ? "update" : "create"} template`);
  }
}

function close() {
  emit("update:modelValue", false);
}

function addForm() {
  if (!localTemplate.value.metadata) {
    localTemplate.value.metadata = {};
  }
  if (!localTemplate.value.metadata.forms) {
    localTemplate.value.metadata.forms = [];
  }
  localTemplate.value.metadata.forms.push({
    name: "",
    dataFormRef: "",
  });
}

function removeForm(form: TemplateDataForm) {
  if (localTemplate.value.metadata?.forms) {
    localTemplate.value.metadata.forms = localTemplate.value.metadata.forms.filter(f => f !== form);
  }
}

async function deleteTemplate() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    name: `Delete template ${localTemplate.value.name}?`,
    message: "This action cannot be undone.",
  });

  if (!isCanceled) {
    try {
      await deleteTemplateMutation.mutateAsync({
        id: localTemplate.value.id as string,
      });
      emit("refetchTemplates");
      await updateHandler(Promise.resolve(), "Template deleted successfully");
      emit("templateDeleted", localTemplate.value.id);
      close();
    } catch (error) {
      console.error("Error deleting template:", error);
      await updateHandler(Promise.reject(error), "Failed to delete template");
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <form
                  class="flex h-full flex-col divide-y divide-gray-200 bg-white dark:bg-gray-800 shadow-xl"
                  @submit="onSubmit"
                >
                  <!-- Header -->
                  <div class="space-y-4 bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <div class="space-y-1">
                        <DialogTitle class="text-base font-semibold text-gray-900 dark:text-gray-100">
                          {{ modalTitle }}
                        </DialogTitle>
                        <p class="text-sm text-gray-500 dark:text-gray-400">
                          {{ isEditMode ? 'Change the template details and choose Save Changes' : 'Get started by filling in the information below to create a new task template.' }}
                        </p>
                      </div>
                      <div class="flex space-x-3">
                        <KodexaButton
                          :id="isEditMode ? 'cancelEditTemplate' : 'cancelNewTemplate'"
                          icon="cancel"
                          type="secondary"
                          size="small"
                          @click="close"
                        >
                          Cancel
                        </KodexaButton>
                        <KodexaButton
                          :id="isEditMode ? 'updateTemplate' : 'createNewTemplate'"
                          :icon="submitButtonIcon"
                          type="primary"
                          size="small"
                          :loading="isEditMode ? updateTemplateMutation.isLoading : createTemplateMutation.isLoading"
                          @click="onSubmit"
                        >
                          {{ submitButtonText }}
                        </KodexaButton>
                      </div>
                    </div>
                  </div>

                  <!-- Form -->
                  <div class="flex-1 overflow-y-auto">
                    <div class="space-y-6 p-4 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-gray-700 sm:p-6">
                      <!-- Name -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="name"
                          class="block text-sm font-medium text-gray-900 dark:text-gray-100 sm:mt-1.5"
                        >
                          Template Name
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="name"
                            v-model="localTemplate.name"
                            name="name"
                            :errors="errors"
                          />
                        </div>
                      </div>

                      <!-- Description -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 dark:text-gray-100 sm:mt-1.5"
                        >
                          Description
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextArea
                            id="description"
                            v-model="localTemplate.description"
                            name="description"
                            :rows="4"
                            :errors="errors"
                          />
                        </div>
                      </div>

                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="hideDefaultButtons"
                          class="block text-sm font-medium text-gray-900 dark:text-gray-100 sm:mt-1.5"
                        >
                          Hide Default Buttons
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaCheckbox
                            id="hideDefaultButtons"
                            v-model="properties.hideDefaultButtons"
                            name="hideDefaultButtons"
                            :errors="errors"
                          />
                        </div>
                      </div>

                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="defaultToDataForm"
                          class="block text-sm font-medium text-gray-900 dark:text-gray-100 sm:mt-1.5"
                        >
                          Default to Data Form
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaCheckbox
                            id="defaultToDataForm"
                            v-model="properties.defaultToDataForm"
                            name="defaultToDataForm"
                            :errors="errors"
                          />
                        </div>
                      </div>

                      <!-- Forms Section -->
                      <div class="space-y-4 mt-1">
                        <div class="flex justify-between items-center">
                          <h3 class="text-sm font-medium text-gray-900 dark:text-gray-100">
                            Data Forms
                          </h3>
                          <KodexaButton icon="plus" size="small" @click="addForm">
                            Add Form
                          </KodexaButton>
                        </div>
                        <div class="space-y-4">
                          <div
                            v-for="(templateForm, index) in localTemplate.metadata?.forms"
                            :key="index"
                            class="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg"
                          >
                            <ProjectTaskTemplateForm
                              :template-form="templateForm"
                              :data-forms="dataForms"
                              @remove="removeForm(templateForm)"
                            />
                          </div>
                        </div>
                      </div>

                      <!-- Workspace -->
                      <div v-if="localTemplate.metadata" class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="workspace"
                          class="block text-sm font-medium text-gray-900 dark:text-gray-100 sm:mt-1.5"
                        >
                          Workspace
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaDropDown
                            v-model="localTemplate.metadata.workspaceId"
                            name="workspace"
                            :items="workspaces"
                            text-field="name"
                            value-field="id"
                            placeholder="Select workspace..."
                            :is-object="false"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Delete Section (Edit Mode Only) -->
                    <div v-if="isEditMode" class="m-6 space-y-4 rounded-lg border-2 border-red-200 bg-white dark:bg-gray-800 p-4">
                      <h3 class="text-base font-semibold text-red-600 dark:text-red-400">
                        Delete Task Template
                      </h3>
                      <div class="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-y-0">
                        <p class="text-gray-900 dark:text-gray-100">
                          Are you sure you want to delete this task template? This cannot be undone.
                        </p>
                        <KodexaButton
                          id="deleteTemplate"
                          type="danger"
                          :loading="deleteTemplateMutation.isLoading"
                          @click="deleteTemplate"
                        >
                          Delete
                        </KodexaButton>
                      </div>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
