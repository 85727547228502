<script lang="ts" setup>
import type { DataAttribute } from "~/model";
import { storeToRefs } from "pinia";
import { getAttributeValueByTaxonType } from "~/components/util/attribute-utils";
import { DebugInfoNode } from "~/formula/formula";
import appStore from "~/store";
import { log } from "~/utils/logger";

defineProps({
  node: {
    type: DebugInfoNode,
    required: true,
  },
});

function gotoAttribute(attribute: DataAttribute) {
  if (attribute.tagUuid) {
    const { currentWorkspaceId, dataObjects } = storeToRefs(appStore.workspaceStore);
    const dataObject = Array.from(dataObjects.value.values()).find(d => d.id === attribute.dataObject.id);

    log.info(`Goto attribute on tag ${attribute.tagUuid} in workspace ${currentWorkspaceId.value}`);
    if (currentWorkspaceId.value && dataObject && attribute.value) {
      const useSidebar = createSidecar(currentWorkspaceId.value);
      useSidebar.focusAttribute(attribute, dataObject);
      appStore.workspaceStore.setFocusedAttribute(attribute);
    } else {
      log.warn(`Unable to focus on tag ${attribute.tagUuid} in workspace ${currentWorkspaceId.value}`);
    }
  }
}

function formatAttributes(attributes: DataAttribute[] | DataAttribute) {
  if (!Array.isArray(attributes)) {
    return [{
      attribute: attributes,
      value: formatType(attributes),
    }];
  }

  return attributes.map((attribute) => {
    return {
      attribute,
      value: formatType(attribute),
    };
  });
}

function formatType(attribute: DataAttribute | any) {
  // if it is not a data attribute just return it
  if (!attribute || !attribute.typeAtCreation) {
    return attribute;
  }

  const value = getAttributeValueByTaxonType(attribute);
  switch (attribute.typeAtCreation) {
    case "NUMBER":
      return new Intl.NumberFormat().format(value);
    case "CURRENCY":
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);
    case "DATE":
      return new Date(value).toLocaleDateString();
    default:
      return value;
  }
}

const ruleLabels = {
  SubContext: "-",
  AddContext: "+",
  DivContext: "/",
  FormulaContext: "formula",
  StringLiteralContext: "string",
  ArgumentListContext: "arguments",
  ArrayAccessContext: "array access",
  ArrayLiteralContext: "array",
  PositiveNumberContext: "positive number",
  PositiveOrNegativeNumberContext: "number",
  NegativeNumberContext: "negative number",
  MulContext: "*",
  LessThanContext: "<",
  LessThanEqualContext: "=<",
  GreaterThanContext: ">",
  GreaterThanEqualContext: ">=",
  EqualContext: "=",
  UnaryMinus: "unary minus",
  LogicalNot: "logical not",
  TrueLiteral: "true",
  FalseLiteral: "false",
  LogicalAnd: "logical and",
  LogicalOr: "logical or",
  NotEqualContext: "not equal",
  Parens: "parentheses",
  Not: "!",
  AttributePropertyContext: "attribute property",
  AttributeReferenceContext: "attribute reference",
  // Add other rules here
};

function getRuleLabel(rule) {
  return ruleLabels[rule] || "";
}

function shouldDisplayRule(rule, index, length) {
  // get all the keys from ruleLabels and see if the rule is in there
  return Object.keys(ruleLabels).includes(rule) && index < length - 1;
}
</script>

<template>
  <span class="flex-auto">
    <span v-if="node.debugInfo.rule === 'AttributeReferenceContext'" class="m-1 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
      {{ node.debugInfo.taxon?.label }}
      <span
        v-if="node.debugInfo.result"
      ><span
        v-for="(value, index) in
          formatAttributes(node.debugInfo.result)" :key="`${value}${index}`"
        class="mx-1 inline-flex items-center rounded-md bg-gray-50 dark:bg-gray-900 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
      >{{ value.value }} <MaterialDesignIcon v-if="value.attribute.typeAtCreation" v-tooltip="`Navigate to value`" name="tag" size="12" class="ml-2 text-gray-300" @click="gotoAttribute(value.attribute)" /><MaterialDesignIcon v-else v-tooltip="`Result of Formula`" name="sigma" size="12" class="ml-2 text-gray-300" /></span>
      </span>
    </span>
    <span v-if="node.debugInfo.rule !== 'FunctionContext' && node.children && node.children.length">
      <span v-for="(child, index) in node.children" :key="index">
        <KodexaFormulaAstNode :node="child" />
        <span v-if="shouldDisplayRule(node.debugInfo.rule, index, node.children.length)" class="mx-2">
          {{ getRuleLabel(node.debugInfo.rule) }}
        </span>
      </span>
    </span>
    <span v-if="node.debugInfo.rule === 'FunctionContext' && node.children && node.children.length" class="m-1 inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-600 ring-1 ring-inset ring-orange-500/10">
      {{ node.debugInfo.function }}(
      <span v-for="(child, index) in node.children" :key="index">
        <KodexaFormulaAstNode :node="child" />
        <span v-if="index < node.children.length - 1" class="mx-2">
          ,
        </span>
      </span>
      )
    </span>
  </span>
</template>
