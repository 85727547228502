<script>
export default {
  name: "TaxonWithPropertiesOption",

  components: {
    ConfigurationOption: () => import("~/components/options/configuration-option-value.vue"),
  },
  props: {
    item: Object,
    value: Object,
    taxonomies: Array,
  },

  data() {
    return {
      selectedTaxon: null,
      availableTaxons: [],
      currentTab: 0,
    };
  },

  computed: {
    properties: {
      get() {
        let properties = this.value[this.item.name] && this.value[this.item.name] !== null ? this.value[this.item.name].options : {};

        if (!properties) {
          properties = {};
        }
        return properties;
      },
      set(value) {
        const updatedValue = { ...this.value };
        if (value && this.taxon) {
          updatedValue[this.item.name] = {
            tag: this.taxon.path,
            options: value,
          };
        } else {
          delete updatedValue[this.item.name];
        }
        this.$emit("input", updatedValue);
      },
    },
    taxon: {
      get() {
        if (this.taxonomies) {
          this.rebuild();
        }

        const currentPath = this.value[this.item.name] ? this.value[this.item.name].tag : null;

        if (currentPath) {
          const taxon = this.availableTaxons.find(t => t.path === currentPath);
          if (taxon) {
            return taxon;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      set(value) {
        const updatedValue = { ...this.value };
        if (value !== null) {
          updatedValue[this.item.name] = {
            tag: value.path,
            options: this.properties,
          };
        } else {
          updatedValue[this.item.name] = null;
        }
        this.$emit("input", updatedValue);
      },
    },
  },
  watch: {
    item() {
      if (this.taxonomies) {
        this.rebuild();
      }
    },
    taxonomies: {
      deep: true,
      immediate: true,
      handler() {
        this.rebuild();
      },
    },
  },
  methods: {
    rebuild() {
      this.availableTaxons = [];
      this.selectedTaxon = null;
      this.taxonomies.forEach((taxonomy) => {
        if (taxonomy.taxonomyType === "CONTENT" || taxonomy.taxonomyType === "MODEL") {
          this.buildAvailable(taxonomy.taxons, "", taxonomy.name);
        }
      });
    },
    clear() {
      this.taxon = null;
    },
    buildAvailable(taxons, path, labelPath) {
      taxons.forEach((taxon) => {
        if (taxon.valuePath !== "METADATA") {
          taxon.fullName = `${labelPath} / ${taxon.label}`;
          if (taxon.path === this.value) {
            this.selectedTaxon = taxon;
          }
          if (!taxon.group) {
            this.availableTaxons.push(taxon);
          }
        }

        if (taxon.children) {
          this.buildAvailable(taxon.children, taxon.path, taxon.fullName);
        }
      });
    },
  },
};
</script>

<template>
  <VCard v-if="item" style="padding: 5px" outlined>
    <VAutocomplete
      v-model="taxon" :hint="item.description" :items="availableTaxons"
      :label="item.label"
      chips
      item-text="fullName"
      item-value="path"
      clearable
      persistent-hint
      return-object @click.clear="clear"
    >
      <template #item="{ item }">
        <VChip color="green" style="color:white" label small>
          {{ item.fullName }}
        </VChip>
      </template>
      <template #selection="{ item }">
        <VChip color="green" style="color:white" label small>
          {{ item.fullName }}
        </VChip>
      </template>
    </VAutocomplete>

    <div v-if="taxon !== null">
      <VToolbar elevation="0" style="margin-left: 10px">
        <VBtn v-for="(item, index) in additionalTaxonOptions" :key="index" elevation="0" icon small>
          <VTooltip bottom>
            <template #activator="{ on, attrs }">
              <VIcon
                v-bind="attrs" small :color="currentTab === index ? 'blue' : 'default'" @click="currentTab = index"
                v-on="on"
              >
                {{ item.type === 'assistant' ? 'mdi-robot-outline' : 'mdi-head-snowflake-outline' }}
              </VIcon>
            </template>
            <span>{{ item.name }}</span>
          </VTooltip>
        </VBtn>
      </VToolbar>
      <VTabs v-model="currentTab" vertical height="30px">
        <VTabItem v-for="(selectedAdditionalTaxonOptions, index) in additionalTaxonOptions" :key="index">
          <div v-for="option in selectedAdditionalTaxonOptions.options" :key="option.name">
            <ConfigurationOption
              v-model="properties" :content-taxonomies="taxonomies"
              :item="option"
            />
          </div>
        </VTabItem>
      </VTabs>
    </div>
  </VCard>
</template>

<style>

</style>
