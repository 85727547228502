<script setup lang='ts'>
import { cn } from "~/lib/utils";

interface AnimatedGradientText {
  class?: string;
}

const props = defineProps<AnimatedGradientText>();

const className = cn(
  "group relative flex max-w-fit flex-row items-center rounded-2xl bg-white/40 px-8 py-4 text-lg shadow-[inset_0_-8px_10px_#8fdfff1f] backdrop-blur-sm transition-shadow duration-500 ease-out [--bg-size:300%] hover:shadow-[inset_0_-5px_10px_#8fdfff3f] dark:bg-black/40 bg-clip-text text-transparent",
  props.class,
);
</script>

<template>
  <div :class="className">
    <div
      class="absolute inset-0 block h-full w-full animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] p-[3px] ![mask-composite:subtract] [border-radius:inherit] [mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)]"
    />

    <slot />
  </div>
</template>
