<script setup lang="ts">
import type { PropType } from "vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import type { Option } from "~/model";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    required: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const {
  isShown,
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div v-if="isShown">
    <KodexaArticle
      :article-id="item.properties.articleId"
      :text="item.properties.text"
      :slide="item.properties.slide"
    />
  </div>
</template>
