export const optionMetadata = {
  label: "Boolean Input",
  description: "Toggle switch or checkbox input",
  options: [
    {
      name: "label",
      label: "Label",
      type: "string",
      description: "Label to display next to the checkbox",
    },
    {
      name: "falseLabel",
      label: "False Label",
      type: "string",
      description: "Optional label to display when value is false",
    },
  ],
};
