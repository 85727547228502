<script lang="ts" setup>
import type { ComputedRef } from "vue";
import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import Loading from "vue-loading-overlay";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaAbout from "~/components/about/kodexa-about.vue";
import KodexaThemeToggle from "~/components/kodexa-theme-toggle.vue";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import logo from "~/images/kodexa-large.png";
import router from "~/router/router";
import appStore from "~/store";
import { useIntercom } from "~/store/useIntercom";
import { handleError } from "~/utils/error-handler";
import emitter from "~/utils/event-bus";
import "vue-loading-overlay/dist/css/index.css";

appStore.platformStore.loadConfiguration();
const { user, platformAdmin } = storeToRefs(appStore.userStore);

useIntercom().trackEvent("app-started");
const {
  platformErrors,
  loadingEvents,
  platformConfiguration,
  isStudio,
  urlPrefix,
  hideLoader,
} = storeToRefs(appStore.platformStore);

const { project } = storeToRefs(appStore.projectStore);

const { currentOrganization, creditStatus, mutateStatus } = storeToRefs(appStore.organizationStore);

const previousCreditStatus = ref(null);
const creditCountStatus = computed(() => {
  if (mutateStatus.value === "pending") {
    return previousCreditStatus.value;
  }
  previousCreditStatus.value = creditStatus.value;
  return creditStatus.value;
});

interface UserNavigation {
  name: string;
  icon?: string;
  onClick?: () => void;
}

const userNavigation: ComputedRef<UserNavigation[]> = computed(() => {
  // Start with basic options that should always be present
  const options = [
    {
      name: "Profile",
      icon: "account",
      onClick: () => {
        router.push(isStudio.value ? `${urlPrefix.value}/profile` : "${urlPrefix}/profile");
      },
    },
  ] as UserNavigation[];

  if (isStudio.value) {
    options.push(
      {
        name: "-divider-",
      },
      {
        name: !isStudio.value ? "Switch to Studio" : "Switch to Workflow",
        icon: isStudio.value ? "checkbox-marked-circle-auto-outline" : "pencil-ruler",
        onClick: async () => {
          const currentPath = router.currentRoute.value.path;
          const targetPath = isStudio.value
            ? currentPath.replace("/a/", "/f/")
            : currentPath.replace("/f/", "/a/");
          await router.push(targetPath);
        },
      },
    );
  }

  // Only add these options in workflow mode
  if (platformAdmin.value) {
    options.push(
      {
        name: "-divider-",
      },
      {
        name: "Platform Administration",
        icon: "shield-crown",
        onClick: () => {
          router.push("/a/admin");
        },
      },
      {
        name: `Toggle Developer Tools ${user.value?.showDeveloperTools ? " ☑" : ""}`,
        icon: "code-tags",
        onClick: () => {
          appStore.userStore.toggleDeveloperTools();
        },
      },
    );
  }

  if (isStudio.value) {
    if (user.value.showDeveloperTools) {
      options.push(
        {
          name: "-divider-",
        },
        {
          name: "Visit Developer Portal",
          icon: "web",
          onClick: () => {
            window.open("https://developer.kodexa.ai", "_blank");
          },
        },
      );
      options.push(
        {
          name: "-divider-",
        },
        {
          name: "Test Throwing Exception",
          icon: "error",
          onClick: () => {
            handleError(new Error("Test Exception"));
          },
        },
      );
    }

    if (currentOrganization.value) {
      options.push(
        {
          name: "-divider-",
        },
        {
          name: "Manage Organization",
          icon: "office-building",
          onClick: () => {
            router.push(`${urlPrefix.value}/o/${currentOrganization.value.id}/admin`);
          },
        },
      );
    }

    if (project.value) {
      options.push(
        {
          name: "-divider-",
        },
        {
          name: "Manage Project",
          icon: "folder-settings",
          onClick: () => {
            router.push(`${urlPrefix.value}/o/${project.value.organization.id}/p/${project.value.id}/admin`);
          },
        },
      );
    }
  }

  // Always add these options at the end
  options.push(
    {
      name: "-divider-",
    },
    {
      name: "About Kodexa",
      icon: "information",
      onClick: () => {
        const dialog = createConfirmDialog(KodexaAbout);
        dialog.reveal();
      },
    },
    {
      name: "Sign out",
      icon: "logout",
      onClick: () => {
        appStore.userStore.logout().then(() => {
          notify({
            group: "generic",
            title: "Logged out",
            text: "Successfully logged out",
          }, 3000);
        });
      },
    },
  );

  return options;
});

watchDebounced(() => platformErrors, () => {
  if (platformErrors.value) {
    notify({
      group: "error",
      title: "Error",
      text: platformErrors.value,
    }, 3000);
  }
});

const isLoadingEvents = computed(() => loadingEvents.value.length > 0 && !hideLoader.value);

function switchContext() {
  const currentPath = router.currentRoute.value.path;

  const { isStudioUser } = storeToRefs(appStore.userStore);

  if (isStudioUser.value) {
    appStore.platformStore.toggleStudio();

    const newPath = !isStudio.value
      ? currentPath.replace("/f/", "/a/")
      : currentPath.replace("/a/", "/f/");
    router.push(newPath);
  }
}

const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

const openOrganizationPopup = ref(false);

onMounted(() => {
  emitter.on("app:open-organization-popup", () => {
    openOrganizationPopup.value = true;
  });
});

const closeBanner = ref(true);

function close() {
  closeBanner.value = false;
}

function showNews() {
  appStore.platformStore.markNewsRead();
  useIntercom().showArticle(platformConfiguration.value.changeLogArticleId);
}
</script>

<template>
  <div class="min-h-full">
    <Loading
      v-model:active="isLoadingEvents"
      :can-cancel="false"
      :is-full-page="true"
      class="z-[49]"
      loader="spinner"
    >
      <div class="flex h-full w-96 flex-col rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl dark:shadow-gray-900">
        <div class="flex items-center justify-center">
          <div class="cool-loader mt-20 mb-10">
            <div class="box box-1">
              <div class="side-left" />
              <div class="side-right" />
              <div class="side-top" />
            </div>
            <div class="box box-2">
              <div class="side-left" />
              <div class="side-right" />
              <div class="side-top" />
            </div>
            <div class="box box-3">
              <div class="side-left" />
              <div class="side-right" />
              <div class="side-top" />
            </div>
            <div class="box box-4">
              <div class="side-left" />
              <div class="side-right" />
              <div class="side-top" />
            </div>
          </div>
        </div>
        <div v-for="loadingEvent in loadingEvents" :key="loadingEvent.id" class="mt-2">
          <div class="flex items-center justify-center text-lg text-gray-900 dark:text-gray-100">
            <span>{{ loadingEvent.title }}</span>
          </div>
          <div class="text-gray-500 dark:text-gray-400 mt-1 flex h-8 items-center justify-center text-sm">
            <span>{{ loadingEvent.progressMessage }}</span>
          </div>
          <div class="mt-4 flex items-center justify-center">
            <div v-if="loadingEvent.progressPercent !== undefined" class="h-2.5 w-full rounded-full bg-gray-50 dark:bg-gray-900">
              <div
                :style="{ width: `${loadingEvent.progressPercent}%` }"
                class="h-2.5 rounded-full bg-gradient-to-r from-green-500 to-blue-500 dark:from-green-600 dark:to-blue-600"
              />
            </div>
          </div>
        </div>
      </div>
    </Loading>
    <Disclosure id="appNav" as="nav" class="border-b border-gray-200 bg-white dark:bg-gray-800">
      <div class="px-4">
        <div class="flex h-16 justify-between">
          <div class="flex grow-0">
            <RouterLink id="homeNav" class="flex shrink-0 items-center" :to="`${urlPrefix}/home`">
              <img
                :src="logo"
                alt="Kodexa" class="block h-[50px] w-[160px] mt-2 mr-1"
              >
            </RouterLink>
            <div class="flex grow-0 items-start header-container px-2" style="margin-top: 12px">
              <div
                class="px-2 py-1 mt-1 rounded-md uppercase font-semibold cursor-pointer relative text-white shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300 bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:from-blue-500 hover:via-blue-600 hover:to-blue-700 animate-gradient-x"
                @click="switchContext()"
              >
                <div class="w-26 h-7 relative flex items-center justify-center overflow-hidden">
                  <Transition
                    enter-active-class="transition duration-500 transform absolute inset-0"
                    enter-from-class="translate-y-full opacity-0 scale-95 rotate-12"
                    enter-to-class="translate-y-0 opacity-100 scale-100 rotate-0"
                    leave-active-class="transition duration-500 transform absolute inset-0"
                    leave-from-class="translate-y-0 opacity-100 scale-100 rotate-0"
                    leave-to-class="-translate-y-full opacity-0 scale-95 -rotate-12"
                    mode="out-in"
                  >
                    <div :key="isStudio" class="px-2 text-center font-bold tracking-wider text-shadow-glow animate-pulse-subtle">
                      {{ isStudio ? "Studio" : "Workflow" }}
                    </div>
                  </Transition>
                </div>
              </div>

              <div class="flex organization-container ml-6" style="margin-top: 8px">
                <RouterLink v-if="currentOrganization" :to="`${urlPrefix}/o/${currentOrganization.id}/home`">
                  <div id="organizationNameNav" class="text-theme-primary dark:text-gray-100 text-lg organization-name">
                    {{ currentOrganization?.name }}
                  </div>
                </RouterLink>
              </div>
              <MaterialDesignIcon
                id="organizationSelector"
                v-tooltip="`Change Organization`" class="text-theme-primary dark:text-gray-100 ml-1 mt-1" name="menu-down"
                size="24" @click="openOrganizationPopup = true"
              />
              <div v-if="project && currentOrganization" class="ml-2 mr-3 mt-2 text-blue-900 dark:text-gray-100">
                |
              </div>
              <RouterLink
                v-if="project && currentOrganization"
                id="projectNameNav"
                :to="`${urlPrefix}/o/${currentOrganization.id}/p/${project?.id}/home`" class="flex"
              >
                <MaterialDesignIcon
                  v-tooltip="`Project Home`" class="text-theme-primary dark:text-gray-100 mr-3 mt-1" name="briefcase"
                  size="24"
                />
                <div class="text-theme-primary dark:text-gray-100 mt-2 text-lg project-name">
                  {{ project?.name }}
                </div>
              </RouterLink>
            </div>
          </div>
          <div class="flex grow-0 items-center justify-end">
            <div v-if="currentWorkspaceId && currentOrganization && project && platformAdmin">
              <RouterLink :to="`/sidecar/${currentOrganization.id}/${project.id}/${currentWorkspaceId}`" target="_blank" class="flex items-center gap-1 text-sm hover:text-primary-600 dark:hover:text-primary-400">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                  <polyline points="15 3 21 3 21 9" />
                  <line x1="10" y1="14" x2="21" y2="3" />
                </svg>
              </RouterLink>
            </div>
            <div v-if="platformConfiguration?.changeLogArticleId?.length > 0" class="flex items-center justify-center">
              <svg
                v-tooltip="`Learn about the latest updates`"
                class="text-theme-primary dark:text-gray-100 mr-3 w-6 h-6 cursor-pointer"
                viewBox="0 0 24 24"
                @click="showNews"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="8"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                >
                  <animate
                    attributeName="r"
                    values="8;11;8"
                    dur="1.5s"
                    repeatCount="indefinite"
                    calcMode="spline"
                    keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
                  />
                  <animate
                    attributeName="opacity"
                    values="0.8;0.2;0.8"
                    dur="1.5s"
                    repeatCount="indefinite"
                    calcMode="spline"
                    keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
                  />
                  <animate
                    attributeName="stroke-width"
                    values="2;3;2"
                    dur="1.5s"
                    repeatCount="indefinite"
                    calcMode="spline"
                    keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="12"
                  r="4"
                  fill="currentColor"
                >
                  <animate
                    attributeName="r"
                    values="4;4.5;4"
                    dur="1.5s"
                    repeatCount="indefinite"
                    calcMode="spline"
                    keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  fill="currentColor"
                  opacity="0.1"
                >
                  <animate
                    attributeName="opacity"
                    values="0.1;0.2;0.1"
                    dur="1.5s"
                    repeatCount="indefinite"
                    calcMode="spline"
                    keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
                  />
                </circle>
              </svg>
            </div>
            <div>
              <KodexaThemeToggle />
            </div>
            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  id="userMenu"
                  class="focus:outline-non flex max-w-xs items-center rounded-full bg-white dark:bg-gray-800 text-sm"
                >
                  <span class="sr-only">Open user menu</span>
                  <KodexaUserAvatar :user="user" object-type="user" size="12" />
                </MenuButton>
              </div>
              <Transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 mt-2 w-60 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg z-[200]"
                >
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a
                      v-if="item.name !== '-divider-'"
                      class="px-4 py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer flex items-center"
                      :class="[active ? 'bg-gray-100 dark:bg-gray-700' : '']"
                      style="white-space: nowrap"
                      @click="item.onClick"
                    >
                      <MaterialDesignIcon
                        v-if="item.icon"
                        :name="item.icon"
                        class="mr-2"
                        size="18"
                      />
                      {{ item.name }}
                    </a>
                    <hr v-else class="my-1 border-gray-200 dark:border-gray-600">
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </Disclosure>
    <div v-if="creditCountStatus" id="creditBanner">
      <div v-if="creditCountStatus.status === 'RED'" class="flex justify-between items-center h-7 red-notif-banner">
        <div id="creditBannerRedMessage" class="flex justify-center items-center w-full">
          Your organization does not have AI credits.
          <KodexaArticle
            class="school-icon ml-2 pt-0.5" article-id="9165324" text="Learn more about AI credits" size="21"
            :slide="true"
          />
        </div>
      </div>
      <div
        v-if="closeBanner && creditCountStatus.status === 'AMBER'"
        class="flex justify-between items-center h-7 amber-notif-banner bg-yellow-500 text-white"
      >
        <div id="creditBannerAmberMessage" class="flex justify-center items-center w-full">
          Your organization is running low on AI credits.
          <KodexaArticle
            class="school-icon ml-2 pt-0.5" article-id="9165324" text="Learn more about AI credits"
            :slide="true" size="21" icon-color="white"
          />
        </div>
        <button id="creditClosePanel" type="button" class="text-gray-400 hover:text-gray-500" @click="close">
          <span class="sr-only">Close panel</span>
          <MaterialDesignIcon name="close" class="size-6" size="20" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div>
      <main>
        <RouterView />
        <KodexaCommandPalette />
        <KodexaKeyboardShortcuts />
        <KodexaOrganizationsPopup v-model="openOrganizationPopup" />
      </main>
    </div>
  </div>
</template>

<style>
/* From Uiverse.io by csozidev */
/* 3D tower loader made by: csozi | Website: www.csozi.hu*/

.cool-loader {
  scale: 3;
  height: 50px;
  width: 40px;
}

.box {
  position: relative;
  opacity: 0;
  left: 10px;
}

.side-left {
  position: absolute;
  background-color: #286cb5;
  width: 19px;
  height: 5px;
  transform: skew(0deg, -25deg);
  top: 14px;
  left: 10px;
}

.side-right {
  position: absolute;
  background-color: #2f85e0;
  width: 19px;
  height: 5px;
  transform: skew(0deg, 25deg);
  top: 14px;
  left: -9px;
}

.side-top {
  position: absolute;
  background-color: #5fa8f5;
  width: 20px;
  height: 20px;
  rotate: 45deg;
  transform: skew(-20deg, -20deg);
}

.box-1 {
  animation: from-left 4s infinite;
}

.box-2 {
  animation: from-right 4s infinite;
  animation-delay: 1s;
}

.box-3 {
  animation: from-left 4s infinite;
  animation-delay: 2s;
}

.box-4 {
  animation: from-right 4s infinite;
  animation-delay: 3s;
}

@keyframes from-left {
  0% {
    z-index: 20;
    opacity: 0;
    translate: -20px -6px;
  }

  20% {
    z-index: 10;
    opacity: 1;
    translate: 0px 0px;
  }

  40% {
    z-index: 9;
    translate: 0px 4px;
  }

  60% {
    z-index: 8;
    translate: 0px 8px;
  }

  80% {
    z-index: 7;
    opacity: 1;
    translate: 0px 12px;
  }

  100% {
    z-index: 5;
    translate: 0px 30px;
    opacity: 0;
  }
}

@keyframes from-right {
  0% {
    z-index: 20;
    opacity: 0;
    translate: 20px -6px;
  }

  20% {
    z-index: 10;
    opacity: 1;
    translate: 0px 0px;
  }

  40% {
    z-index: 9;
    translate: 0px 4px;
  }

  60% {
    z-index: 8;
    translate: 0px 8px;
  }

  80% {
    z-index: 7;
    opacity: 1;
    translate: 0px 12px;
  }

  100% {
    z-index: 5;
    translate: 0px 30px;
    opacity: 0;
  }
}

.intercom-launcher {
  opacity: 0.3 !important;
}

.intercom-launcher:hover {
  opacity: 1 !important;
}

:host .intercom-launcher {
  opacity: 0.3;
}

:host .intercom-launcher:hover {
  opacity: 1;
}

.red-notif-banner {
  background-color: #f44336;
  color: white;
  text-align: center;
  padding: 12px;
  font-size: 13px;
  font-weight: 500;
}

.amber-notif-banner {
  text-align: center;
  padding: 12px;
  font-size: 13px;
  font-weight: 500;
}

.school-icon {
  font-size: 12px !important;
  font-weight: normal;
}

.header-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.organization-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.organization-name,
.project-name {
  white-space: nowrap;
  overflow: hidden;
  max-width: max-content; /* Adjust the width as needed */
}

#organizationSelector {
  cursor: pointer;
}

@media (max-width: 768px) {
  .organization-name,
  .project-name {
    max-width: 100px; /* Adjust for smaller screens */
  }
}
</style>
