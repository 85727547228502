export const optionMetadata = {
  label: "Cloud Embedding",
  description: "Select a cloud embedding model",
  options: [
    {
      name: "modelId",
      label: "Model",
      type: "string",
      description: "Cloud embedding model to use",
    },
    {
      name: "showDescription",
      label: "Show Description",
      type: "boolean",
      description: "Show model description as hint",
      default: true,
    },
  ],
};
