<script setup lang="ts">
import { computed, ref, useSlots } from "vue";

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: "primary",
    validation(value: string) {
      return ["primary", "secondary", "warning", "danger", "gray", "cta"].includes(value) || "must be one of the following: 'primary', 'secondary', 'warning', 'danger', 'gray', 'cta'";
    },
  },
  size: {
    type: String,
    required: false,
    default: "default",
    validation(value: string) {
      return ["small", "default", "large"].includes(value) || "must be one of the following: 'small', 'default', 'large'";
    },
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: undefined,
  },
  width: {
    type: String,
    required: false,
    default: "%",
  },
  fakecta: {
    type: Boolean,
    required: false,
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["click"]);

const slots = useSlots();
const isSlotEmpty = computed(() => !slots.default || slots.default().length === 0);

const sizeClasses = computed(() => {
  const classes: string[] = [];
  if (props.size !== "small") {
    classes.push("mt-1");
    if (!isSlotEmpty.value) {
      classes.push("mr-1");
    }
  } else {
    if (!isSlotEmpty.value) {
      classes.push("mr-1");
    }
  }

  if (props.icon) {
    classes.push("mt-3");
  }

  return classes;
});

const buttonStyle = computed(() => {
  const style: Record<string, string> = { width: props.width };
  if (props.color) {
    style.backgroundColor = props.color;
  }
  return style;
});

const fakeLoading = ref(false);

function doClick() {
  fakeLoading.value = true;
  setTimeout(() => {
    fakeLoading.value = false;
  }, 250);

  emit("click");
}
</script>

<template>
  <button
    type="button" :disabled="disabled || loading || fakeLoading" class="disabled:bg-gray-300 dark:disabled:bg-gray-600 disabled:hover:bg-gray-300 dark:disabled:hover:bg-gray-600 whitespace-nowrap"
    :style="buttonStyle"
    :class="[`kodexa-button-${type}-${size}`]" @click="doClick"
  >
    <span class="flex-col items-center justify-center">
      <MaterialDesignIcon
        v-if="!loading && icon" :name="icon" :size="size === 'small' ? '12' : '16'" :class="size === 'isSlotEmpty' ? ['mt-3'] : ['mt-3', 'mr-1']"
      />
      <MaterialDesignIcon
        v-if="loading" name="running" :size="size === 'small' ? '12' : '16'" :class="sizeClasses" :spin="true"
      />
      <slot />
    </span>
  </button>
</template>

<style scoped>
.kodexa-button-primary-small {
  background-color: #1359a5;
  @apply rounded py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-primary-default {
  background-color: #1359a5;
  @apply py-2 px-4 rounded text-sm font-semibold text-white
}

.kodexa-button-primary-default:not(:disabled):hover {
  background-color: #0f4c8c;
  @apply py-2 px-4 rounded text-sm font-semibold text-white
}

.kodexa-button-primary-large {
  background-color: #1359a5;
  @apply rounded-md py-3.5 px-5 text-sm font-semibold text-white
}

.kodexa-button-primary-large:not(:disabled):hover {
  background-color: #0f4c8c;
  @apply rounded-md py-3.5 px-5 text-sm font-semibold text-white
}

.kodexa-button-secondary-small {
  @apply rounded bg-white dark:bg-gray-800 py-1 px-2 text-xs font-semibold text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-small:disabled {
  @apply rounded bg-white dark:bg-gray-800 py-1 px-2 text-xs font-semibold text-gray-400 dark:text-gray-400 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-default:not(:disabled):hover {
  @apply bg-white dark:bg-gray-800 py-2 px-4 rounded text-sm font-semibold text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-default {
  @apply bg-white dark:bg-gray-800 py-2 px-4 rounded text-sm font-semibold text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-default:disabled {
  @apply bg-white dark:bg-gray-800 py-2 px-4 rounded text-sm font-semibold text-gray-400 dark:text-gray-400 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-large:not(:disabled):hover {
  @apply rounded-md bg-white dark:bg-gray-800 py-3.5 px-5 text-sm font-semibold text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-large {
  @apply rounded-md bg-white dark:bg-gray-800 py-3.5 px-5 text-sm font-semibold text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300
}

.kodexa-button-secondary-large:disabled {
  @apply rounded-md bg-white dark:bg-gray-800 py-3.5 px-5 text-sm font-semibold text-gray-400 dark:text-gray-400 ring-1 ring-inset ring-gray-300
}

.kodexa-button-warning-small:not(:disabled):hover {
  @apply rounded bg-yellow-500 py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-warning-small {
  @apply rounded bg-yellow-600 py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-warning-default:not(:disabled):hover {
  @apply rounded-md bg-yellow-500 py-2.5 px-3.5 text-sm font-semibold text-white
}

.kodexa-button-warning-default {
  @apply rounded-md bg-yellow-600 py-2.5 px-3.5 text-sm font-semibold text-white
}

.kodexa-button-warning-large:not(:disabled):hover {
  @apply rounded-md bg-yellow-500 py-2.5 px-3.5 text-sm font-semibold text-white
}

.kodexa-button-warning-large {
  @apply rounded-md bg-yellow-600 py-2.5 px-3.5 text-sm font-semibold text-white
}

.kodexa-button-danger-small:not(:disabled):hover {
  @apply rounded-md bg-red-500 py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-danger-small {
  @apply rounded-md bg-red-600 py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-danger-default:not(:disabled):hover {
  @apply py-2 px-4 rounded bg-red-500 text-sm font-semibold text-white
}

.kodexa-button-danger-default {
  @apply bg-red-600 py-2 px-4 rounded text-sm font-semibold text-white
}

.kodexa-button-danger-large:not(:disabled):hover {
  @apply bg-red-600 py-2 px-4 rounded text-sm font-semibold text-white
}

.kodexa-button-danger-large {
  @apply bg-red-600 py-2 px-4 rounded text-sm font-semibold text-white
}

.kodexa-button-gray-small {
  @apply rounded bg-gray-600 py-1 px-2 text-xs font-semibold text-white ring-1 ring-inset ring-gray-300
}

.kodexa-button-gray-default {
  @apply rounded-md bg-gray-600 py-2.5 px-3.5 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300
}

.kodexa-button-gray-large {
  @apply rounded-md bg-gray-600 py-3.5 px-5 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300
}

.kodexa-button-cta-small {
  @apply rounded bg-green-600 py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-cta-small:not(:disabled):hover {
  @apply rounded bg-green-500 py-1 px-2 text-xs font-semibold text-white
}

.kodexa-button-cta-default {
  @apply rounded bg-green-600 py-2 px-4 text-sm font-semibold text-white
}

.kodexa-button-cta-default:not(:disabled):hover {
  @apply rounded-md bg-green-500 py-2 px-4 text-sm font-semibold text-white
}

.kodexa-button-cta-large {
  @apply rounded-md bg-green-600 py-3.5 px-5 text-sm font-semibold text-white
}

.kodexa-button-cta-large:not(:disabled):hover {
  @apply rounded-md bg-green-500 py-3.5 px-5 text-sm font-semibold text-white
}
</style>
