/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateProcessingEntity400,
  DeleteProcessingEntity400,
  ExceptionResponse,
  GetChangeSequenceForProcessingEntity400,
  GetProcessingEntity400,
  ListProcessingEntities400,
  ListProcessingEntitiesParams,
  PageProcessingEntity,
  ProcessingEntity,
  ThrowableProblem,
  UpdateProcessingEntity400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getProcessingEntity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProcessingEntity>(
      {url: `/api/processingEntities/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProcessingEntityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','processingEntities',id] as const;
    }

    
export const getGetProcessingEntityQueryOptions = <TData = Awaited<ReturnType<typeof getProcessingEntity>>, TError = ErrorType<GetProcessingEntity400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProcessingEntity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProcessingEntityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcessingEntity>>> = ({ signal }) => getProcessingEntity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProcessingEntity>>, TError, TData> 
}

export type GetProcessingEntityQueryResult = NonNullable<Awaited<ReturnType<typeof getProcessingEntity>>>
export type GetProcessingEntityQueryError = ErrorType<GetProcessingEntity400 | ThrowableProblem | ExceptionResponse>



export function useGetProcessingEntity<TData = Awaited<ReturnType<typeof getProcessingEntity>>, TError = ErrorType<GetProcessingEntity400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProcessingEntity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProcessingEntityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateProcessingEntity = (
    id: MaybeRef<string>,
    processingEntity: MaybeRef<ProcessingEntity>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
processingEntity = unref(processingEntity);
      
      return customAxios<ProcessingEntity>(
      {url: `/api/processingEntities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: processingEntity
    },
      options);
    }
  


export const getUpdateProcessingEntityMutationOptions = <TError = ErrorType<UpdateProcessingEntity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProcessingEntity>>, TError,{id: string;data: ProcessingEntity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProcessingEntity>>, TError,{id: string;data: ProcessingEntity}, TContext> => {
    
const mutationKey = ['updateProcessingEntity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProcessingEntity>>, {id: string;data: ProcessingEntity}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProcessingEntity(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProcessingEntityMutationResult = NonNullable<Awaited<ReturnType<typeof updateProcessingEntity>>>
    export type UpdateProcessingEntityMutationBody = ProcessingEntity
    export type UpdateProcessingEntityMutationError = ErrorType<UpdateProcessingEntity400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateProcessingEntity = <TError = ErrorType<UpdateProcessingEntity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProcessingEntity>>, TError,{id: string;data: ProcessingEntity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateProcessingEntity>>,
        TError,
        {id: string;data: ProcessingEntity},
        TContext
      > => {

      const mutationOptions = getUpdateProcessingEntityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProcessingEntity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/processingEntities/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProcessingEntityMutationOptions = <TError = ErrorType<DeleteProcessingEntity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProcessingEntity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProcessingEntity>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteProcessingEntity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProcessingEntity>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProcessingEntity(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProcessingEntityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProcessingEntity>>>
    
    export type DeleteProcessingEntityMutationError = ErrorType<DeleteProcessingEntity400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteProcessingEntity = <TError = ErrorType<DeleteProcessingEntity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProcessingEntity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteProcessingEntity>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteProcessingEntityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProcessingEntities = (
    params?: MaybeRef<ListProcessingEntitiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProcessingEntity>(
      {url: `/api/processingEntities`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProcessingEntitiesQueryKey = (params?: MaybeRef<ListProcessingEntitiesParams>,) => {
    return ['api','processingEntities', ...(params ? [params]: [])] as const;
    }

    
export const getListProcessingEntitiesQueryOptions = <TData = Awaited<ReturnType<typeof listProcessingEntities>>, TError = ErrorType<ListProcessingEntities400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListProcessingEntitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProcessingEntities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProcessingEntitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProcessingEntities>>> = ({ signal }) => listProcessingEntities(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProcessingEntities>>, TError, TData> 
}

export type ListProcessingEntitiesQueryResult = NonNullable<Awaited<ReturnType<typeof listProcessingEntities>>>
export type ListProcessingEntitiesQueryError = ErrorType<ListProcessingEntities400 | ThrowableProblem | ExceptionResponse>



export function useListProcessingEntities<TData = Awaited<ReturnType<typeof listProcessingEntities>>, TError = ErrorType<ListProcessingEntities400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListProcessingEntitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProcessingEntities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProcessingEntitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProcessingEntity = (
    processingEntity: MaybeRef<ProcessingEntity>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      processingEntity = unref(processingEntity);
      
      return customAxios<ProcessingEntity>(
      {url: `/api/processingEntities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: processingEntity, signal
    },
      options);
    }
  


export const getCreateProcessingEntityMutationOptions = <TError = ErrorType<CreateProcessingEntity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProcessingEntity>>, TError,{data: ProcessingEntity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProcessingEntity>>, TError,{data: ProcessingEntity}, TContext> => {
    
const mutationKey = ['createProcessingEntity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProcessingEntity>>, {data: ProcessingEntity}> = (props) => {
          const {data} = props ?? {};

          return  createProcessingEntity(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProcessingEntityMutationResult = NonNullable<Awaited<ReturnType<typeof createProcessingEntity>>>
    export type CreateProcessingEntityMutationBody = ProcessingEntity
    export type CreateProcessingEntityMutationError = ErrorType<CreateProcessingEntity400 | ThrowableProblem | ExceptionResponse>

    export const useCreateProcessingEntity = <TError = ErrorType<CreateProcessingEntity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProcessingEntity>>, TError,{data: ProcessingEntity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createProcessingEntity>>,
        TError,
        {data: ProcessingEntity},
        TContext
      > => {

      const mutationOptions = getCreateProcessingEntityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProcessingEntity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/processingEntities/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProcessingEntityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','processingEntities',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProcessingEntityQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>, TError = ErrorType<GetChangeSequenceForProcessingEntity400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProcessingEntityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>> = ({ signal }) => getChangeSequenceForProcessingEntity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>, TError, TData> 
}

export type GetChangeSequenceForProcessingEntityQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>>
export type GetChangeSequenceForProcessingEntityQueryError = ErrorType<GetChangeSequenceForProcessingEntity400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForProcessingEntity<TData = Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>, TError = ErrorType<GetChangeSequenceForProcessingEntity400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProcessingEntity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForProcessingEntityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



