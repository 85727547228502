<script lang="ts" setup>
import type { DataFormAction, TaskStatus } from "~/model";
import { storeToRefs } from "pinia";
import { getProjectTaskStatuses } from "~/api/projects/projects";
import appStore from "~/store";

const props = defineProps<{
  dataFormAction: DataFormAction;
}>();

const emit = defineEmits<{
  "update:dataFormAction": [value: DataFormAction];
  "remove": [];
}>();

const taskStatuses = ref([] as TaskStatus[]);

const { project } = storeToRefs(appStore.projectStore);

watch(() => project.value?.id, async (newProjectId) => {
  if (newProjectId) {
    taskStatuses.value = await getProjectTaskStatuses(newProjectId);
  }
}, { immediate: true });

const localValue = computed({
  get: () => {
    const value = props.dataFormAction;
    value.properties = value.properties || {};
    return value;
  },
  set: (value: DataFormAction) => {
    emit("update:dataFormAction", value);
  },

});

const options = computed(() => {
  return [
    { type: "list", listType: "object", name: "attributes", label: "Set Attributes", groupOptions: [
      { type: "taxon", name: "taxon", label: "Taxon" },
      { type: "string", name: "valueType", label: "Value Type", possibleValues: [{ label: "Metadata", value: "metadata" }, { label: "String", value: "string" }, { label: "Boolean", value: "boolean" }] },
      { type: "string", name: "metadataKey", label: "Metadata Key", showIf: "this.valueType === 'metadata'", possibleValues: [{ label: "Current User Email", value: "currentUserEmail" }] },
      { type: "string", name: "value", label: "Value", showIf: "this.valueType === 'string'" },
      { type: "boolean", name: "booleanValue", label: "Value", showIf: "this.valueType === 'boolean'" },
    ] },
  ];
});
</script>

<template>
  <div>
    <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
      <label
        for="statusId"
        class="block text-sm font-medium text-gray-900 sm:mt-1.5"
      >
        Task Status
      </label>
      <div class="sm:col-span-2">
        <KodexaDropDown
          v-model="localValue.properties.statusId"
          name="statusId"
          :items="taskStatuses"
          text-field="label"
          value-field="id"
          placeholder="Select status..."
          :is-object="false"
        />
      </div>
      <label
        for="label"
        class="block text-sm font-medium text-gray-900 sm:mt-1.5"
      >
        Label
      </label>
      <div class="sm:col-span-2">
        <KodexaTextInput v-model="localValue.label" name="label" placeholder="Enter label..." />
      </div>
      <label
        for="label"
        class="block text-sm font-medium text-gray-900 sm:mt-1.5"
      >
        Icon
      </label>
      <div class="sm:col-span-2">
        <KodexaTextInput v-model="localValue.properties.icon" name="icon" placeholder="Enter icon..." />
      </div>
      <label
        for="label"
        class="block text-sm font-medium text-gray-900 sm:mt-1.5"
      >
        Color
      </label>
      <div class="sm:col-span-2">
        <KodexaColorSelector v-model="localValue.properties.color" name="color" />
      </div>
      <KodexaCheckbox name="automaticallyTakeNextTask" label="Automatically take next task" v-model="localValue.properties.automaticallyTakeNextTask" />
      <div class="sm:col-span-2">
        <ConfigurationOption v-for="option in options" :key="option.name" v-model="localValue.properties" :item="option" />
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <KodexaButton
        icon="delete" size="small" type="danger"
        @click="emit('remove')"
      >
        Remove Action
      </KodexaButton>
    </div>
  </div>
</template>

<style scoped>

</style>
