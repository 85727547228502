<script lang="ts" setup>
import type { DockviewApi, DockviewReadyEvent } from "dockview-vue";
import { useDark } from "@vueuse/core";
import { DockviewVue } from "dockview-vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const dockviewApi: Ref<DockviewApi | undefined> = ref(undefined);

const {
  workspaceSidebar,
} = storeToRefs(appStore.workspaceStore);

function syncPanels() {
  workspaceSidebar.value.forEach((workspacePanel) => {
    if (!dockviewApi.value) {
      return;
    }

    if (dockviewApi.value.panels.find(panel => panel.id === workspacePanel.id)) {
      return;
    }
    const newPanel = { ...workspacePanel, inactive: dockviewApi.value?.activePanel, tabComponent: "PanelTab" };
    if (newPanel.params) {
      newPanel.params.title = newPanel.title;
      newPanel.params.icon = newPanel.id;
    } else {
      newPanel.params = { title: newPanel.title, icon: newPanel.id };
    }
    dockviewApi.value?.addPanel(newPanel);
  });
}

function dockReady(api: DockviewReadyEvent) {
  dockviewApi.value = api.api;
  syncPanels();
}

const isDark = useDark({
  selector: "html",
  attribute: "class",
  valueDark: "dark",
  valueLight: "light",
});

watch(workspaceSidebar, () => {
  syncPanels();
}, { immediate: true, deep: true });
const { currentSidebar } = storeToRefs(appStore.platformStore);
watch(currentSidebar, () => {
  if (currentSidebar.value) {
    // set focus
    dockviewApi.value?.panels.forEach((panel) => {
      if (panel.id === currentSidebar.value) {
        panel.focus();
      }
    });
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <DockviewVue
      :class="!isDark ? 'dockview-theme-light' : 'dockview-theme-abyss'" style="height: calc(100vh - 7rem)"
      right-header-actions-component="RightPanelAction"
      watermark-component="WorkspacePanelsEmpty" @ready="dockReady"
    />
  </div>
</template>

<style scoped>

</style>
