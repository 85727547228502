export const optionMetadata = {
  label: "Alert",
  description: "Display an alert message",
  options: [
    {
      name: "markdown",
      label: "Message Content",
      type: "text",
      description: "Markdown content to display in the alert",
    },
    {
      name: "type",
      label: "Alert Type",
      type: "select",
      description: "Style of the alert",
      options: [
        { value: "info", label: "Information" },
        { value: "warning", label: "Warning" },
        { value: "error", label: "Error" },
        { value: "success", label: "Success" },
      ],
    },
  ],
};
