<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";
import { availableOptionTypes } from "../options/options";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    required: false,
    type: Object as PropType<any>,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: Object,
  contentObject: Object as PropType<ContentObject | undefined>,
  assistant: Object as PropType<Assistant | undefined>,
});

const emit = defineEmits(["update:modelValue"]);

const {
  documentStores,
  dataStores,
  modelStores,
  contentTaxonomies,
  guidance,
} = storeToRefs(appStore.projectStore);

const {
  isShown,
  type,
  localValue,
} = useConfigurationBase(props, emit, true);

const TYPE_MAPPINGS = {
  code: ["python", "javascript", "script", "code", "simpleExpression"],
  componentLookup: ["tableStore", "documentStore", "modelStore", "taxonomy", "guidance"],
  text: ["regex", "password", "string"],
  number: ["number"],
  boolean: ["boolean"],
  taxon: ["groupTaxon"],
};

function getOptionComponent(type: string) {
  // Handle string and regex cases with possible values
  if ((type === "string" || type === "regex")) {
    return (Array.isArray(props.item.possibleValues) && props.item.possibleValues.length > 0) ? availableOptionTypes.find(opt => opt?.type === "select")?.implementation() : availableOptionTypes.find(opt => opt?.type === "text")?.implementation();
  }

  // Find matching type from mappings
  for (const [targetType, sourceTypes] of Object.entries(TYPE_MAPPINGS)) {
    if (sourceTypes.includes(type)) {
      return availableOptionTypes.find(opt => opt?.type === targetType)?.implementation();
    }
  }

  // Default to direct type match
  const optionDef = availableOptionTypes.find(opt => opt?.type === type);

  if (!optionDef) {
    console.warn(`Unknown option type: ${type}`);
    return null;
  }

  return optionDef.implementation();
}

const commonProps = computed(() => ({
  modelValue: localValue,
  item: props.item,
  contentObject: props.contentObject,
  disabled: props.disabled,
  type: type.value,
  assistant: props.assistant,
}));

const ADDITIONAL_PROPS_MAP = {
  document: () => ({ stores: documentStores.value }),
  tableStore: () => ({ components: dataStores.value }),
  documentStore: () => ({ components: documentStores.value }),
  modelStore: () => ({ components: modelStores.value }),
  taxonomy: () => ({ components: contentTaxonomies.value }),
  guidance: () => ({ components: Array.from(guidance.value.values()) }),
  groupTaxon: () => ({ onlyGroups: true }),
  simpleExpression: () => ({ height: "100px" }),
  password: () => ({ password: true }),
};

const CODE_TYPES = ["python", "javascript", "script", "code"];

const additionalProps = computed(() => {
  const currentType = type.value;

  // Handle code-related types
  if (CODE_TYPES.includes(currentType)) {
    return { language: "python" };
  }

  // Get props from mapping or return empty object
  return ADDITIONAL_PROPS_MAP[currentType]?.() || {};
});
</script>

<template>
  <Component
    :is="getOptionComponent(type)"
    v-if="isShown"
    v-bind="{ ...commonProps, ...additionalProps }"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<style scoped>
</style>
