/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateTaskActivity400,
  DeleteTaskActivity400,
  ExceptionResponse,
  GetChangeSequenceForTaskActivity400,
  GetTaskActivity400,
  ListTaskActivities400,
  ListTaskActivitiesParams,
  PageTaskActivity,
  TaskActivity,
  ThrowableProblem,
  UpdateTaskActivity400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getTaskActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<TaskActivity>(
      {url: `/api/taskActivities/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskActivities',id] as const;
    }

    
export const getGetTaskActivityQueryOptions = <TData = Awaited<ReturnType<typeof getTaskActivity>>, TError = ErrorType<GetTaskActivity400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskActivity>>> = ({ signal }) => getTaskActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskActivity>>, TError, TData> 
}

export type GetTaskActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskActivity>>>
export type GetTaskActivityQueryError = ErrorType<GetTaskActivity400 | ThrowableProblem | ExceptionResponse>



export function useGetTaskActivity<TData = Awaited<ReturnType<typeof getTaskActivity>>, TError = ErrorType<GetTaskActivity400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTaskActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateTaskActivity = (
    id: MaybeRef<string>,
    taskActivity: MaybeRef<NonReadonly<TaskActivity>>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taskActivity = unref(taskActivity);
      
      return customAxios<TaskActivity>(
      {url: `/api/taskActivities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taskActivity
    },
      options);
    }
  


export const getUpdateTaskActivityMutationOptions = <TError = ErrorType<UpdateTaskActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskActivity>>, TError,{id: string;data: NonReadonly<TaskActivity>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskActivity>>, TError,{id: string;data: NonReadonly<TaskActivity>}, TContext> => {
    
const mutationKey = ['updateTaskActivity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskActivity>>, {id: string;data: NonReadonly<TaskActivity>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTaskActivity(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskActivityMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskActivity>>>
    export type UpdateTaskActivityMutationBody = NonReadonly<TaskActivity>
    export type UpdateTaskActivityMutationError = ErrorType<UpdateTaskActivity400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateTaskActivity = <TError = ErrorType<UpdateTaskActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskActivity>>, TError,{id: string;data: NonReadonly<TaskActivity>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTaskActivity>>,
        TError,
        {id: string;data: NonReadonly<TaskActivity>},
        TContext
      > => {

      const mutationOptions = getUpdateTaskActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTaskActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/taskActivities/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskActivityMutationOptions = <TError = ErrorType<DeleteTaskActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskActivity>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteTaskActivity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskActivity>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTaskActivity(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskActivityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskActivity>>>
    
    export type DeleteTaskActivityMutationError = ErrorType<DeleteTaskActivity400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteTaskActivity = <TError = ErrorType<DeleteTaskActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteTaskActivity>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteTaskActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTaskActivities = (
    params?: MaybeRef<ListTaskActivitiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTaskActivity>(
      {url: `/api/taskActivities`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTaskActivitiesQueryKey = (params?: MaybeRef<ListTaskActivitiesParams>,) => {
    return ['api','taskActivities', ...(params ? [params]: [])] as const;
    }

    
export const getListTaskActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof listTaskActivities>>, TError = ErrorType<ListTaskActivities400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListTaskActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTaskActivitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTaskActivities>>> = ({ signal }) => listTaskActivities(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTaskActivities>>, TError, TData> 
}

export type ListTaskActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof listTaskActivities>>>
export type ListTaskActivitiesQueryError = ErrorType<ListTaskActivities400 | ThrowableProblem | ExceptionResponse>



export function useListTaskActivities<TData = Awaited<ReturnType<typeof listTaskActivities>>, TError = ErrorType<ListTaskActivities400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListTaskActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTaskActivitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTaskActivity = (
    taskActivity: MaybeRef<NonReadonly<TaskActivity>>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      taskActivity = unref(taskActivity);
      
      return customAxios<TaskActivity>(
      {url: `/api/taskActivities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskActivity, signal
    },
      options);
    }
  


export const getCreateTaskActivityMutationOptions = <TError = ErrorType<CreateTaskActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskActivity>>, TError,{data: NonReadonly<TaskActivity>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTaskActivity>>, TError,{data: NonReadonly<TaskActivity>}, TContext> => {
    
const mutationKey = ['createTaskActivity'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTaskActivity>>, {data: NonReadonly<TaskActivity>}> = (props) => {
          const {data} = props ?? {};

          return  createTaskActivity(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTaskActivityMutationResult = NonNullable<Awaited<ReturnType<typeof createTaskActivity>>>
    export type CreateTaskActivityMutationBody = NonReadonly<TaskActivity>
    export type CreateTaskActivityMutationError = ErrorType<CreateTaskActivity400 | ThrowableProblem | ExceptionResponse>

    export const useCreateTaskActivity = <TError = ErrorType<CreateTaskActivity400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskActivity>>, TError,{data: NonReadonly<TaskActivity>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTaskActivity>>,
        TError,
        {data: NonReadonly<TaskActivity>},
        TContext
      > => {

      const mutationOptions = getCreateTaskActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTaskActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/taskActivities/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTaskActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskActivities',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTaskActivityQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError = ErrorType<GetChangeSequenceForTaskActivity400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTaskActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>> = ({ signal }) => getChangeSequenceForTaskActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError, TData> 
}

export type GetChangeSequenceForTaskActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>>
export type GetChangeSequenceForTaskActivityQueryError = ErrorType<GetChangeSequenceForTaskActivity400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForTaskActivity<TData = Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError = ErrorType<GetChangeSequenceForTaskActivity400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForTaskActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



