<script lang="ts" setup>
import type { Node } from "@vue-flow/core";
import type { PropType } from "vue";
import { Handle, Position } from "@vue-flow/core";
import { computed, onMounted, ref, watch } from "vue";
import { useListCloudModels } from "~/api/cloud-models/cloud-models";
import KodexaGraphDefault from "~/components/explainPlan/presentations/kodexa-graph-default.vue";
import KodexaGraphGuidance from "~/components/explainPlan/presentations/kodexa-graph-guidance.vue";
import KodexaGraphJson from "~/components/explainPlan/presentations/kodexa-graph-json.vue";
import KodexaGraphMessage from "~/components/explainPlan/presentations/kodexa-graph-message.vue";
import KodexaGraphMetadata from "~/components/explainPlan/presentations/kodexa-graph-metadata.vue";
import KodexaGraphPagenumbers from "~/components/explainPlan/presentations/kodexa-graph-pagenumbers.vue";
import KodexaGraphPrompt from "~/components/explainPlan/presentations/kodexa-graph-prompt.vue";
import KodexaGraphResponse from "~/components/explainPlan/presentations/kodexa-graph-response.vue";
import KodexaGraphThinkingResponse from "~/components/explainPlan/presentations/kodexa-graph-thinking-response.vue";
import { getColorByBgColor } from "~/utils/colors";

const props = defineProps({
  node: {
    type: Object as PropType<Node>,
    required: true,
  },
  showRaw: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["selected", "selectedStep", "selected-step", "zoom-to-node"]);

const presentationComponents = {
  default: KodexaGraphDefault,
  prompt: KodexaGraphPrompt,
  schema: KodexaGraphJson,
  response: KodexaGraphResponse,
  llm_response: KodexaGraphResponse,
  llm_thinking_response: KodexaGraphThinkingResponse,
  page_numbers: KodexaGraphPagenumbers,
  final_llm_response: KodexaGraphJson,
  external_data: KodexaGraphJson,
  metadata: KodexaGraphMetadata,
  guidance: KodexaGraphGuidance,
};

const data = computed(() => {
  // Filter the label out of the node data
  const { label, ...rest } = props.node?.data.clean;

  // Make sure that all the values in rest are strings
  for (const key in rest) {
    if (typeof rest[key] === "undefined" || rest[key] === null) {
      continue;
    }

    if (!(key in presentationComponents) && !props.showRaw) {
      delete rest[key];
      continue;
    }

    if (typeof rest[key] !== "string") {
      rest[key] = JSON.stringify(rest[key]);
    }
  }
  return rest;
});

const imageUrlsWithNames = ref<Array<{ url: string; name: string }>>([]);
const cloudModelLoading = ref(true);

const { data: cloudModels } = useListCloudModels();

const modelIds = computed(() => {
  const ids = props.node?.data?.clean?.model_id || props.node?.data?.raw?.model_id;
  return Array.isArray(ids) ? ids : ids ? [ids] : [];
});

watch([modelIds, cloudModels], ([newModelIds, newCloudModels]) => {
  if (newModelIds.length > 0 && newCloudModels) {
    fetchImageUrls(newModelIds);
  } else {
    imageUrlsWithNames.value = [];
  }
});

onMounted(() => {
  if (modelIds.value.length > 0 && cloudModels.value) {
    fetchImageUrls(modelIds.value);
  }
});

function fetchImageUrls(ids: string[]) {
  cloudModelLoading.value = true;
  try {
    if (cloudModels.value) {
      imageUrlsWithNames.value = ids.map((id) => {
        const model = cloudModels.value.content?.find(model => model.modelId === id);
        if (model) {
          return {
            url: model.imageUrl || "",
            name: model.name || "Unknown Model",
          };
        } else {
          return null;
        }
      }).filter(item => item !== null && item.url !== "");
    } else {
      imageUrlsWithNames.value = [];
    }
  } catch (error) {
    imageUrlsWithNames.value = [];
  } finally {
    cloudModelLoading.value = false;
  }
}

const nodeColor = computed(() => props.node.data?.raw?.color || "#23ABDC");
const textColor = computed(() => getColorByBgColor(nodeColor.value));

const nonEmptyComponents = computed(() => {
  return Object.fromEntries(
    Object.entries(data.value).filter(([key, value]) => {
      const presentationComponent = presentationComponents[props.node?.data?.presentationMetadata?.key || key];
      return value !== null && value !== undefined && value !== ""
        && key !== "page_numbers"
        && presentationComponent
        && typeof presentationComponent === "object";
    }),
  );
});

const hasNonEmptyComponents = computed(() => {
  return Object.keys(nonEmptyComponents.value).length > 0;
});

function handleHeaderClick() {
  emit("zoom-to-node", props.node);
}
</script>

<template>
  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
    <li :key="node.id" class="overflow-hidden rounded-xl border border-gray-200 w-full" style="width: 380px">
      <div class="p-5 component-header cursor-pointer flex" @click="handleHeaderClick">
        <div
          class="w-2"
          :style="{ backgroundColor: node.data?.raw?.color || '#23ABDC' }"
        />
        <div class="flex-grow pl-4">
          <div class="flex gap-x-4 justify-between">
            <div class="text-sm font-medium leading-6 text-gray-900">
              <h2 class="break-words" style="overflow-wrap: break-word; word-wrap: break-word;">
                {{ node?.data.clean.label }}
              </h2>
              <p
                v-if="node?.data.clean.subtitle"
                class="truncate text-sm break-words font-light"
                style="overflow-wrap: break-word; word-wrap: break-word;"
              >
                {{ node?.data.clean.subtitle }}
              </p>
            </div>
            <div v-if="imageUrlsWithNames.length > 0" class="flex justify-end space-x-2 mb-2 mt-1">
              <div
                v-for="(imageData, index) in imageUrlsWithNames"
                :key="index"
                class="w-8 h-8 p-0.5 bg-white dark:bg-gray-800 rounded-full flex border-gray-100 items-center justify-center"
                style="border: 1px solid #E7E7E7;"
              >
                <img
                  v-tooltip="`${imageData.name}`"
                  class="h-auto"
                  :src="imageData.url"
                  :alt="`${node?.data?.clean?.label || 'Model'} Image ${index + 1}`"
                  style="position: relative; z-index: 1;"
                  @click="$emit('click')"
                >
              </div>
            </div>
          </div>
          <KodexaGraphMessage v-if="node.data?.raw && 'message' in node.data?.raw" :node="node" />
          <div class="mt-2 flex justify-start space-x-2">
            <div v-if="node.data?.raw && 'status' in node.data?.raw">
              <span
                class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium"
                :class="[
                  node.data.raw.status === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700',
                ]"
              >
                <svg class="h-1.5 w-1.5" :class="node.data.raw.status === 'success' ? 'fill-green-500' : 'fill-red-500'" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx="3" cy="3" r="3" />
                </svg>
                {{ node.data.raw.status === 'success' ? 'Success' : 'Failed' }}
              </span>
            </div>
            <div v-if="node.data?.raw && 'guidance' in node.data?.raw">
              <span
                class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
              >
                <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx="3" cy="3" r="3" />
                </svg>
                Guidance Applied
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div v-if="hasNonEmptyComponents" class="flex space-x-2 justify-end">
          <div v-for="(value, key) in nonEmptyComponents" :key="key">
            <div
              v-if="presentationComponents[props.node?.data?.presentationMetadata?.key || key]"
              class="flex justify-between mx-1 py-3"
            >
              <dd class="flex items-start gap-x-2">
                <Component
                  :is="presentationComponents[props.node?.data?.presentationMetadata?.key || key]"
                  :key-name="key"
                  :node="props.node"
                  :node-color="nodeColor"
                  :text-color="textColor"
                />
              </dd>
            </div>
          </div>
        </div>
        <KodexaGraphPagenumbers
          v-if="node.data?.raw && 'page_numbers' in node.data?.raw"
          key-name="page_numbers"
          :node="node"
        />
        <KodexaGraphMetadata
          key-name="metadata"
          :node="node"
          :node-color="nodeColor"
          :text-color="textColor"
          class="justify-center"
        />
      </div>
    </li>
  </ul>
  <Handle id="consumes" type="target" :position="Position.Left" />
  <Handle id="publishes" type="source" :position="Position.Right" />
</template>

<style scoped>
:deep(.open-schema a), :deep(.open-prompt a), :deep(.open-response a) {
  margin-right: 0 !important;
}
</style>
