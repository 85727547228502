<script setup lang="ts">
import type { ProjectTag } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { createProjectTag } from "~/api/project-tag/project-tag";
import appStore from "~/store";
import { handleError } from "~/utils/error-handler";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetchProjectTag"]);

const newProjectTag = ref({
  name: "",
  color: "",
  organization: appStore.organizationStore.currentOrganization,
} as ProjectTag);
const { currentOrganization } = storeToRefs(appStore.organizationStore);

function close() {
  emit("update:modelValue", false);
}

async function createNewProjectTag() {
  try {
    await createProjectTag(newProjectTag.value);
  } catch (e) {
    await handleError(e);
    console.error(e);
  }
  notify({
    group: "generic",
    title: "Success",
    text: "Added New Project Tag successfully",
  }, 3000);
  emit("refetchProjectTag");
  close();
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Project Tag
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create a new project tag.
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton id="cancelNewProjectTag" icon="cancel" type="secondary" size="small" @click="close">
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createNewProjectTag" icon="plus" type="primary" size="small"
                              @click="createNewProjectTag "
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- Project Tag Name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="projectTagName"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="projectTagName" v-model="newProjectTag.name"
                            name="projectTagName"
                          />
                        </div>
                      </div>

                      <!-- Project Tag Color -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="projectTagColor"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Color</label>
                        </div>
                        <div class="sm:col-span-2">
                          <ColorPicker id="projectTagColor" v-model="newProjectTag.color" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
