<script lang="ts" setup>
import type { Dashboard } from "~/model";
import { useDashboardsView } from "~/store/useDashboardsView";

const dashboardsView = useDashboardsView();

dashboardsView.initialize([
  {
    orgSlug: "kodexa",
    slug: "workflow-dashboard",
    version: "1.0.0",
    ref: "kodexa/workflow-dashboard",
    name: "Workflow Dashboard",
    description: "A dashboard for Workflow",
    type: "dashboard",
    widgets: [
      {
        id: "welcome-1",
        type: "workflowWelcomeWidget",
        name: "Welcome",
        properties: {
          defaultPosition: { col: 0, row: 0, colSpan: 12, rowSpan: 20, order: 1 },
          style: {
            height: "calc(100vh - 4rem)",
          },
        },
      },
      // {
      //   id: "chart-1",
      //   type: "documentCountByOrganizationCard",
      //   name: "Tasks",
      //   properties: {
      //     defaultPosition: { col: 0, row: 4, colSpan: 12, rowSpan: 7, order: 2 },
      //     style: {
      //       height: "calc(100vh - 210px)",
      //     },
      //   },
      // },
    ],
  } as Dashboard,
] as Dashboard[], {}, false);
</script>

<template>
  <div>
    <KodexaDashboardsView />
  </div>
</template>
