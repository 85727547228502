<script lang="ts" setup>
import type { PropType } from "vue";
import type { Assistant, CustomEvent } from "~/model";
import type { DocumentViewer, ExecutionViewer } from "~/store/useWorkspace";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  assistant: {
    type: Object as PropType<Assistant>,
    required: true,
  },
  eventType: {
    type: Object as PropType<CustomEvent>,
    required: true,
  },
  buttonClass: {
    type: Array,
    default: () => [],
  },
  height: {
    type: String,
    default: "500px",
  },
  isPanel: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["started"]);
const { projectDirty, project } = storeToRefs(appStore.projectStore);
const { isDirty } = storeToRefs(appStore.workspaceStore);

const assistantCopy = ref();

const localAssistant = computed({
  get() {
    if (assistantCopy.value !== undefined) {
      return assistantCopy.value;
    } else {
      return props.assistant;
    }
  },
  set(value) {
    assistantCopy.value = value;
  },
});

const loading = ref(false);
const { activeView } = storeToRefs(appStore.workspaceStore);
const lastError = ref();
const executions = ref();

const create = async function () {
  // Check if the request is coming from the panel, if so we need to show save all changes popup
  if (props.isPanel && (projectDirty.value || isDirty.value)) {
    if (!await appStore.projectStore.saveAllChanges(true)) {
      return;
    };
  }
  // We need to get the current view, and then we need to create a
  // new execution from it and thus create a new document view

  loading.value = true;

  if (!activeView.value || !props.eventType?.contentObject) {
    log.info("Executing without active view or content object");

    const newUuid = uuidv4();
    const newExecutionViewer = {
      ephemeral: true,
      id: newUuid,
      executionId: newUuid,
      viewType: "execution",
      title: props.eventType.label || props.eventType.name,
      isSidecar: false,
    } as ExecutionViewer;
    const executionStore = createExecutionStore(newExecutionViewer.id);
    executionStore.executeAssistantEvent(project.value, localAssistant.value, undefined, props.eventType.name, props.assistant.testOptions, undefined).then(() => {
      appStore.workspaceStore.addView(newExecutionViewer);
      executions.value = storeToRefs(executionStore);
      running.value = true;
      loading.value = false;
      emit("started");
    }).catch((error) => {
      log.error(error);
      lastError.value = error;
      loading.value = false;
    });
  } else {
    log.info("Executing active view or content object");

    const documentViewerStore = createDocumentViewerStore(activeView.value.id);
    const {
      documentViewer,
      kddbDocument,
    } = storeToRefs(documentViewerStore);

    // We want to duplicate the view and then create an execution and link
    // it to the view - then add the view to the workspace

    const newDocumentViewer = JSON.parse(JSON.stringify(documentViewer.value)) as DocumentViewer;
    newDocumentViewer.ephemeral = true;
    newDocumentViewer.id = uuidv4();
    newDocumentViewer.executionId = newDocumentViewer.id;
    const executionStore = createExecutionStore(newDocumentViewer.id);
    const contentObject = documentViewerStore?.getLatestContentObject();

    if (contentObject) {
      log.info("Using content object");
      executionStore.executeAssistantEvent(project.value, localAssistant.value, kddbDocument.value, props.eventType.name, props.assistant.testOptions, contentObject.id).then(() => {
        // We need to update the document viewer
        appStore.workspaceStore.addView(newDocumentViewer);
        executions.value = storeToRefs(executionStore);
        running.value = true;
        loading.value = false;
        emit("started");
      }).catch((error) => {
        log.error(error);
        lastError.value = error;
        loading.value = false;
      });
    } else {
      log.info("No content object available");
      executionStore.executeAssistantEvent(project.value, localAssistant.value, undefined, props.eventType.name, props.assistant.testOptions, undefined).then(() => {
        // We need to update the document viewer
        executions.value = storeToRefs(executionStore);
        appStore.workspaceStore.addView(newDocumentViewer);

        running.value = true;
        loading.value = false;
        emit("started");
      }).catch((error) => {
        log.error(error);
        lastError.value = error;
        loading.value = false;
      });
    }
  }
};

const running = ref(false);

watch(() => localAssistant.value.testOptions, () => {
  appStore.projectStore.updateAssistantTestOptions(localAssistant.value);
}, {
  deep: true,
});
</script>

<template>
  <div>
    <div>
      <p class="mb-2 mt-1 text-sm">
        The following are options for the training, and testing, of the
        assistant
      </p>
      <div v-if="lastError">
        <KodexaAlert :message="lastError" />
      </div>
      <div class="overflow-scroll px-2" :style="{ height }">
        <div
          v-for="option in localAssistant.definition.eventTypes.find(evtType => evtType.name === eventType.name).options"
          :key="option.name"
        >
          <ConfigurationOption
            v-model="localAssistant.testOptions"
            :assistant="localAssistant"
            :item="option"
          />
        </div>
      </div>
      <div
        class="sm:flex sm:flex-row-reverse"
        :class="buttonClass"
      >
        <KodexaButton
          id="sendToAssistant"
          :loading="loading"
          icon="play"
          @click="create"
        >
          Send to Assistant
        </KodexaButton>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
