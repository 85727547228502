<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { ColorSchemeDesign, TaxonNavigationOption } from "~/components/dataForm/taxon-navigation-option";
import type { Card, DataAttribute, DataException, DataObject } from "~/model";
import type { KeyboardShortcut } from "~/store/useKeyboard";

import type { TagMetadata } from "~/store/useProject";
import { Button } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { getUniqueExceptions } from "~/utils/data-exceptions";
import { log } from "~/utils/logger";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const componentUuid = ref(crypto.randomUUID ? crypto.randomUUID() : Math.random().toString(36).substring(2, 15));

const useDataFormViewer = createDataFormViewerStore(props.viewId);

const {
  dataObjects,
  validatedExceptionUuids,
} = storeToRefs(useDataFormViewer);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

function setupStickyElements() {
  nextTick(() => {
    // Use a unique selector that includes the component instance ID
    const stickyElements = document.querySelectorAll(`.sticky-${componentUuid.value}`);

    stickyElements.forEach((element) => {
      // Get the element's original position relative to the viewport

      // Add the current scroll position to get the absolute position
      const absoluteTop = window.scrollY;

      // Set the top value to the original position
      element.style.top = `${absoluteTop}px`;
    });
  });
}

// ALT+x shortcut key to jump or scroll to section/block with [jump-on=true]
// and then 500ms will focus to its next input field
const keyboardStore = useKeyboard();
let tick: any = 0;
function doScrollTo(isBack = false) {
  log.info("Jumping to the next section");
  const visibleJumpElements = Array.from(document.querySelectorAll("[jump-on=true]")).filter((el) => {
    const rect = el.getBoundingClientRect();
    return rect.width > 0 && rect.height > 0 && window.getComputedStyle(el).display !== "none";
  });

  if (visibleJumpElements.length === 0) {
    return;
  }

  const scrollablePane = document.querySelector(".data-form-scroll");
  if (!scrollablePane) {
    return;
  }

  const currentScrollTop = scrollablePane.scrollTop;
  const scrollHeight = scrollablePane.scrollHeight;
  const clientHeight = scrollablePane.clientHeight;

  let currentIndex = visibleJumpElements.findIndex(el => el.offsetTop > currentScrollTop);
  if (currentIndex === -1) {
    currentIndex = 0;
  }

  // Check if we're at the bottom of the scroll
  const isAtBottom = currentScrollTop + clientHeight >= scrollHeight - 10; // 10px threshold

  // Determine the next index
  if (isBack) {
    currentIndex = (currentIndex - 1 + visibleJumpElements.length) % visibleJumpElements.length;
  } else if (isAtBottom) {
    // If at the bottom, go back to the first element (Invoice information)
    currentIndex = 0;
  } else {
    currentIndex = (currentIndex + 1) % visibleJumpElements.length;
  }

  const nextJumpTo = visibleJumpElements[currentIndex];

  if (nextJumpTo) {
    const dy = nextJumpTo.offsetTop - 64;
    scrollablePane.scroll({ top: dy, behavior: "smooth" });

    clearTimeout(tick);
    tick = setTimeout(() => {
      const input = nextJumpTo.querySelector("input");
      if (input) {
        input.focus();
      }
    }, 500);
  }
}

const scrollShortcut = {
  key: "alt+shift+n",
  altKey: "alt+shift+˜",
  description: "Scrolls to the next section",
  callback: () => {
    doScrollTo();
  },
} as KeyboardShortcut;
keyboardStore.addShortcut(scrollShortcut);

function scrollToException(isBack = false) {
  const visibleExceptionElements = Array.from(document.querySelectorAll("[exception-scroll=true]")).filter((el) => {
    const rect = el.getBoundingClientRect();
    return rect.width > 0 && rect.height > 0 && window.getComputedStyle(el).display !== "none";
  });

  if (visibleExceptionElements.length === 0) {
    return;
  }

  const scrollablePane = document.querySelector(".data-form-scroll");
  if (!scrollablePane) {
    return;
  }

  const currentScrollTop = scrollablePane.scrollTop;
  const scrollHeight = scrollablePane.scrollHeight;
  const clientHeight = scrollablePane.clientHeight;

  // Find the first exception element that's below the current scroll position
  let currentIndex = visibleExceptionElements.findIndex(el => el.offsetTop > currentScrollTop);
  if (currentIndex === -1) {
    currentIndex = 0;
  }

  // Check if we're at the bottom of the scroll
  const isAtBottom = currentScrollTop + clientHeight >= scrollHeight - 10; // 10px threshold

  // Determine the next index
  if (isBack) {
    currentIndex = (currentIndex - 1 + visibleExceptionElements.length) % visibleExceptionElements.length;
  } else if (isAtBottom) {
    // If at the bottom, go back to the first element
    currentIndex = 0;
  } else {
    currentIndex = (currentIndex + 1) % visibleExceptionElements.length;
  }

  const nextException = visibleExceptionElements[currentIndex];
  if (nextException) {
    const dy = nextException.offsetTop - 64;
    scrollablePane.scroll({ top: dy, behavior: "smooth" });
  }
}
const scrollExceptionShortcut = {
  key: "alt+shift+e",
  altKey: "alt+shift+´",
  description: "Scrolls to the next exception",
  callback: () => {
    scrollToException();
  },
} as KeyboardShortcut;
keyboardStore.addShortcut(scrollExceptionShortcut);

const tagMetadata = computed(() => {
  return tagMetadataMap.value.get(props.card.properties?.groupTaxon);
});

const exceptionOpenFlag = ref(true);

const scopedDataObjects = computed(() => {
  if (!tagMetadata.value) {
    return [];
  }

  let scopedDataObjects = tagMetadata.value
    ? dataObjects.value.filter((dataObject) => {
        return dataObject.path === tagMetadata.value.path;
      })
    : dataObjects.value;

  scopedDataObjects = props.parentTagMetadata
    ? scopedDataObjects.filter((dataObject) => {
        return dataObject.path === props.parentTagMetadata?.path;
      })
    : scopedDataObjects;

  if (props.parentDataObject) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      // We need to be able to handle children that haven't been saved yet
      return dataObject.parentId ? dataObject.parentId === props.parentDataObject?.id : dataObject.parent?.uuid === props.parentDataObject?.uuid;
    });
  }

  if (props.card?.properties?.filters) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      if (props.card?.properties?.filters) {
        for (const filter of props.card?.properties?.filters || []) {
          if (filter.type === "hasExceptionMessage") {
            const exception = dataObject.dataExceptions?.find((dataException) => {
              return dataException.message === filter.value;
            });
            return exception !== undefined;
          }
        }
      }
      return true;
    });
  }
  scopedDataObjects = scopedDataObjects.sort((a: DataObject, b: DataObject) => {
    if (a.sourceOrdering === undefined) {
      return -1;
    }
    if (b.sourceOrdering === undefined) {
      return 1;
    }
    // get last 4 characters of source ordering, then convert to number
    const sourceOrderingA: number = +a.sourceOrdering.slice(-4);
    const sourceOrderingB: number = +b.sourceOrdering.slice(-4);

    return sourceOrderingA - sourceOrderingB;
  });

  return scopedDataObjects;
});

const tabs = computed(() => {
  if (!props.card?.properties?.useTabs) {
    return [];
  }

  return scopedDataObjects.value.map((dataObject, idx) => {
    return {
      ref: dataObject.uuid,
      name: `#${idx + 1}`,
    } as Tab;
  });
});

const currentTab = ref(tabs.value.length > 0 ? tabs.value[0] : undefined);

function setTab(tab: Tab) {
  currentTab.value = tab;
}

const finalScopedObjects = computed(() => {
  if (props.card?.properties?.useTabs) {
    return scopedDataObjects.value.filter((dataObject) => {
      return dataObject.uuid === currentTab.value?.ref;
    });
  } else {
    return scopedDataObjects.value;
  }
});

/**
 * Create a computed properties to show the possible destination movement for a
 * dataObject
 */
const parentDataObjectGroups = computed(() => {
  const parentDataObjectGroups: Map<string, DataObject> = new Map<string, DataObject>();
  if (!props.card?.properties?.dataMovement?.showMove) {
    return parentDataObjectGroups;
  }

  const dataMovementProps = props.card?.properties?.dataMovement;
  for (const dataObject of dataObjects.value) {
    if ((dataObject.path !== dataMovementProps?.sameDataObjectMovement?.identifierProps?.groupTaxonPath
      && dataObject.path !== dataMovementProps?.diffDataObjectMovement?.identifierProps?.groupTaxonPath) || !dataObject.attributes) {
      continue;
    }
    // Get the attribute taxon that will be used as a key
    const attributeIdentifier: DataAttribute | undefined = dataObject.attributes.find((attribute) => {
      return attribute.path === dataMovementProps.sameDataObjectMovement?.identifierProps?.taxonAttribute
        || attribute.path === dataMovementProps.diffDataObjectMovement?.identifierProps?.taxonAttribute;
    });
    if (dataObject.path === dataMovementProps?.diffDataObjectMovement?.identifierProps?.groupTaxonPath) {
      if (attributeIdentifier?.stringValue) {
        if (parentDataObjectGroups.has(attributeIdentifier?.stringValue)) {
          continue;
        }
        parentDataObjectGroups.set(attributeIdentifier.stringValue, dataObject);
      } else if (dataMovementProps.diffDataObjectMovement?.stringValue) {
        parentDataObjectGroups.set(dataMovementProps.diffDataObjectMovement.stringValue, dataObject);
      }
    } else {
      if (attributeIdentifier?.stringValue) {
        if (parentDataObjectGroups.has(attributeIdentifier?.stringValue)) {
          continue;
        }
        parentDataObjectGroups.set(attributeIdentifier.stringValue, dataObject);
      }
    }
  }

  return parentDataObjectGroups;
});

function getNavigations(dataObject: DataObject) {
  const taxonNavigation = props.card?.properties?.taxonNavigation as TaxonNavigationOption;
  // Find all childDataObject
  const allChildDataObject = appStore.workspaceStore.findChildDataObject(dataObject);
  const filteredChildDataObject: DataObject[] = allChildDataObject.filter(dataObject => dataObject.path === taxonNavigation.childGroupTaxonPath);
  const navList = [] as ColorSchemeDesign[];
  for (const childDataObject of filteredChildDataObject) {
    childDataObject.attributes?.forEach((attribute) => {
      if (attribute.path === taxonNavigation.taxonPath) {
        const colorSchemeValue = taxonNavigation.colorSchemeDesign.find((value) => {
          return value.value.toLowerCase() === attribute.value?.toLowerCase();
        });
        if (attribute.id) {
          navList.push({
            value: attribute.stringValue ? attribute.stringValue : "No Navigation",
            color: colorSchemeValue?.color,
            uuid: attribute.uuid,
          } as ColorSchemeDesign);
        } else {
          navList.unshift({
            value: attribute.stringValue ? attribute.stringValue : "No Navigation",
            color: colorSchemeValue?.color,
            uuid: attribute.uuid,
          } as ColorSchemeDesign);
        }
      }
    });
  }
  return navList;
}

const openDataExceptions = computed(() => {
  const allUnresolvedDataExceptions: DataException[] = [];

  function getOpenDataException(dataExceptions) {
    for (const dataException of dataExceptions) {
      if (dataException.open) {
        allUnresolvedDataExceptions.push(dataException);
      }
    }
  }

  for (const dataObject of finalScopedObjects.value) {
    if (dataObject.dataExceptions) {
      getOpenDataException(dataObject.dataExceptions);
    }
    if (dataObject.attributes) {
      for (const dataAttribute of dataObject?.attributes) {
        if (dataAttribute.dataExceptions) {
          getOpenDataException(dataAttribute.dataExceptions);
        }
      }
    }
  }
  return allUnresolvedDataExceptions;
});

function buildDataFormViewer(card: Card) {
  return useDataFormViewer.buildDataFormViewer(card);
}

async function moveDataObject(eventHandler) {
  const destinationDataObject = eventHandler.destinationDataObject;
  const dataObject = eventHandler.dataObject;
  // We will be reassigning the parentDataObject
  if (props.card?.properties?.dataMovement?.diffDataObjectMovement?.identifierProps?.groupTaxonPath === eventHandler.destinationDataObject.path) {
    //   Create new data Object
    const destinationTaxonPath = props.card?.properties?.dataMovement?.diffDataObjectMovement?.destinationTaxonPath;
    const tagMetadata = tagMetadataMap.value.get(props.card?.properties?.dataMovement?.diffDataObjectMovement?.destinationPaths);
    const newDataObject = await appStore.workspaceStore.addNewDataObject(tagMetadata, eventHandler.destinationDataObject.documentFamily, eventHandler.destinationDataObject);
    for (const attribute of dataObject.attributes) {
      const currentTaxonPath = destinationTaxonPath.find((value) => {
        return value.attributePath === attribute.path;
      });
      const taxonTagMetadata = tagMetadataMap.value.get(currentTaxonPath?.destinationPath);
      const newAttribute = useDataFormViewer.addAttribute(taxonTagMetadata, newDataObject);
      newAttribute.value = attribute.value;
      newAttribute.stringValue = attribute.stringValue;
      newAttribute.dateValue = attribute.dateValue;
      newAttribute.booleanValue = attribute.booleanValue;
      newAttribute.decimalValue = attribute.decimalValue;
    }
    deleteDataObject(eventHandler.dataObject);
  } else {
    const updatedPartialDataObject: Partial<DataObject> = {
      parentId: destinationDataObject.id,
      parent: destinationDataObject,
    };
    appStore.workspaceStore.updateDataObject(dataObject, updatedPartialDataObject);
  }
}
function addDataObject() {
  appStore.workspaceStore.addNewDataObject(tagMetadata.value, props.parentDataObject?.documentFamily, props.parentDataObject);
}

const collapsed = ref(false);

function toggleCollapsed() {
  collapsed.value = !collapsed.value;
}

function deleteDataObject(dataObject: DataObject) {
  appStore.workspaceStore.deleteDataObjectByUuid(dataObject.uuid);
}

function navigationScroll(attributeUuid) {
  const element = document.querySelector(`[data-key-attribute="${attributeUuid}"]`);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
}

onMounted(() => {
  if (!finalScopedObjects.value.length) {
    if (props.card?.properties?.isEmptyAutoAdd) {
      addDataObject();
    }
  }

  // Setup sticky elements
  setupStickyElements();

  // Also set up a resize listener to recalculate if needed
  window.addEventListener("resize", setupStickyElements);
});

onBeforeUnmount(() => {
  keyboardStore.removeShortcut(scrollShortcut);
  keyboardStore.removeShortcut(scrollExceptionShortcut);
  clearTimeout(tick);

  // Remove resize listener
  window.removeEventListener("resize", setupStickyElements);
});
</script>

<template>
  <div>
    <div class="w-100 mt-1">
      <div
        v-if="card.properties?.showHeader"
        class="flex items-center justify-between mb-1"
        :class="{ [`sticky-${componentUuid}`]: card.properties?.exceptionSticky }"
      >
        <div class="flex-1">
          <div class="text-lg font-semibold leading-6 text-gray-900">
            {{ card.properties.title }}
          </div>
          <p v-if="card.properties?.subtitle" class="mt-1 text-sm text-gray-500 font-normal leading-relaxed">
            {{ card.properties.subtitle }}
          </p>
        </div>
        <div class="flex items-center">
          <Button
            v-if="!card.properties.hideAdd && tagMetadata"
            :disabled="!exceptionOpenFlag"
            class="rounded-md hover:bg-gray-100 transition-colors duration-200"
            fill-mode="flat"
            size="small"
            @click="addDataObject"
          >
            <MaterialDesignIcon
              v-tooltip="`Add new ${tagMetadata?.taxon.label}`"
              name="plus"
              class="text-blue-400 hover:text-blue-500 transition-colors duration-200"
              size="18"
              :tooltip="`Add new ${tagMetadata?.taxon.label}`"
            />
          </Button>
          <Button
            v-if="!card.properties.hideCollapse && tagMetadata"
            v-tooltip="collapsed ? `Expand ${tagMetadata.taxon.label} panel` : `Collapse ${tagMetadata.taxon.label} panel`"
            class="rounded-md hover:bg-gray-100 transition-colors duration-200"
            fill-mode="flat"
            size="small"
            @click="toggleCollapsed"
          >
            <MaterialDesignIcon
              :name="collapsed ? 'upArrow' : 'downArrow'"
              class="text-blue-400 hover:text-blue-500 transition-colors duration-200"
              size="18"
            />
          </Button>
        </div>
      </div>
      <div v-if="props.card?.properties?.overrideException">
        <div class="rounded-md bg-blue-50 p-4">
          <div class="flex items-start">
            <div class="shrink-0">
              <MaterialDesignIcon name="information" class="mr-1 text-blue-400" size="28" />
            </div>
            <div class="ml-3 flex-1">
              <div class="flex items-center justify-between">
                <!-- Flex container for vertical alignment and edge alignment -->
                <p class="text-sm text-blue-700">
                  If all data in this section is correct, click the button to confirm.
                </p>
                <button
                  type="button"
                  class="rounded-md px-2 py-1.5 text-sm font-medium text-white transition-colors duration-300 ease-in-out focus:outline-none focus:ring focus:ring-blue-300"
                  :class="{
                    'bg-green-500 hover:bg-green-600': !exceptionOpenFlag && openDataExceptions.length > 0,
                    'bg-red-500 hover:bg-red-600': exceptionOpenFlag && openDataExceptions.length > 0,
                    'cursor-not-allowed bg-red-500 opacity-50 hover:bg-red-600': exceptionOpenFlag && openDataExceptions.length === 0,
                    'cursor-not-allowed bg-green-500 opacity-50 hover:bg-green-600': !exceptionOpenFlag && openDataExceptions.length === 0,

                  }"
                  :disabled="openDataExceptions.length === 0"
                  @click="overrideException"
                >
                  {{ exceptionOpenFlag ? "Override Exception" : "Exception Overridden" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!collapsed && tagMetadata">
        <div v-if="card.properties?.useTabs">
          <KodexaTabStrip :items="tabs" :selected="currentTab" :small="true" @selected="setTab" />
        </div>
        <div v-if="finalScopedObjects.length === 0">
          <div class="child-taxon mb-2 bg-white rounded-lg shadow-sm border border-gray-200 px-2 py-1">
            <div class="text-center">
              <MaterialDesignIcon name="information" class="mr-1 text-blue-400" size="28" />
              <p class="text-sm">
                No {{ tagMetadata.taxon.label }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-for="dataObject in finalScopedObjects" :key="dataObject.uuid"
          class="child-taxon mb-1 bg-white rounded-lg shadow-sm border border-gray-200 px-2 py-1"
        >
          <div class="text-right" style="float:right">
            <MaterialDesignIcon
              v-if="!card.properties?.hideAdd" v-tooltip="`Delete ${tagMetadata.taxon.label}`"
              :tooltip="`Delete ${tagMetadata.taxon.label}`"
              name="delete" :class="card.properties?.deleteIcon
                ? card.properties?.deleteIcon
                : 'text-red-600 hover:text-red-700 cursor-pointer'" size="20" @click="deleteDataObject(dataObject)"
            />
          </div>
          <div v-if="props.card?.properties?.taxonNavigation" class="flex flex-wrap">
            <div
              v-for="item in getNavigations(dataObject)"
              :key="item.value"
              class="mb-4"
            >
              <button
                class="mr-4 flex h-10 items-center justify-center rounded-md bg-white dark:bg-gray-800 px-4 py-2 transition duration-300"
                :style="{ backgroundColor: item.color, minWidth: '140px !important' }"
                @click="navigationScroll(item.uuid)"
              >
                <div class="text-gray-800">
                  {{ item.value }}
                </div>
              </button>
            </div>
          </div>

          <div v-if="card.properties && !card.properties.hideExceptions" :class="{ [`sticky-${componentUuid}`]: card.properties?.exceptionSticky }" class="mb-4">
            <div
              v-for="exception in getUniqueExceptions(dataObject)"
              :key="exception.uuid"
              exception-scroll="true"
              class="exception-container"
            >
              <KodexaDataExceptionMini :exception="exception" :validated-exception-uuids="validatedExceptionUuids" :view-id="viewId" />
            </div>
          </div>

          <KodexaRowLayout
            :rows="buildDataFormViewer(card)" :view-id="viewId"
            :parent-tag-metadata="parentTagMetadata"
            :parent-data-object="dataObject"
            :parent-data-object-groups="parentDataObjectGroups"
            @delete-data-object="deleteDataObject"
            @add-data-object="addDataObject"
            @move-data-object="moveDataObject"
          />
        </div>
        <div class="height: 200px" />
      </div>
      <div v-else-if="!collapsed" class="child-taxon mb-1 bg-white rounded-lg shadow-sm border border-gray-200 px-2 py-1 mr-2">
        <KodexaRowLayout
          :rows="buildDataFormViewer(card)" :view-id="viewId"
          :parent-tag-metadata="parentTagMetadata"
          :parent-data-object="parentDataObject"
          @delete-data-object="deleteDataObject"
          @add-data-object="addDataObject"
          @move-data-object="moveDataObject"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.exception-container {
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 100px);
}

[class^="sticky-"] {
   position: sticky;
   z-index: 40;
   transition: none;
}

[child-taxon]:not([child-taxon=false]) {
  background-color: var(--bg-color-basis);
}
</style>
