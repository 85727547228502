<script lang="ts" setup>
import type { PropType } from "vue";
import type { Task, TaskStatus } from "~/model";
import { removeTaskStatus, updateTaskStatus } from "~/api/tasks/tasks";
import { getColorByBgColor } from "~/utils/colors";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
  saveValue: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["updated"]);

const editing = ref(false);

const selectedTaskStatus = ref<TaskStatus | undefined>(props.task?.status);

watch(() => props.task?.status, (newValue) => {
  selectedTaskStatus.value = newValue;
}, { immediate: true });

async function saveChanges() {
  await updateHandler(updateTaskStatus(props.task.id as string, selectedTaskStatus.value as TaskStatus), "Status updated");
}

async function updated(taskStatus) {
  selectedTaskStatus.value = taskStatus;
  // if we have save value flag, we will be saving the value to the server,
  // otherwise we will just emit the updated event
  if (props.saveValue) {
    await saveChanges();
  }
  editing.value = false;
  emit("updated", selectedTaskStatus.value);
}

async function clear() {
  selectedTaskStatus.value = undefined;
  editing.value = false;

  if (props.saveValue) {
    await updateHandler(removeTaskStatus(props.task.id as string), "Status cleared");
  }
  emit("updated", undefined);
}
</script>

<template>
  <div>
    <div v-if="editing">
      <KodexaTaskStatusDropdown
        :key="task.id"
        v-model="selectedTaskStatus" :project="task.project" @updated="updated" @clear="clear"
      />
    </div>
    <div
      v-else-if="selectedTaskStatus" id="currentTaskStatus"
      style="height: 87px; display: flex; align-items: center;"
      @click="editing = true"
    >
      <span :style="{ 'background-color': selectedTaskStatus.color, 'color': getColorByBgColor(selectedTaskStatus.color) }" class="text-md inline-flex items-center rounded-md p-2 font-medium">{{ selectedTaskStatus.label }}</span>
    </div>
    <div
      v-else id="editTaskStatus" style="height: 87px; display: flex; align-items: center;"
      @click="editing = true"
    >
      <div>-</div>
    </div>
  </div>
</template>
