/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateProcessingGroup400,
  DeleteProcessingGroup400,
  ExceptionResponse,
  GetChangeSequenceForProcessingGroup400,
  GetProcessingGroup400,
  ListProcessingGroups400,
  ListProcessingGroupsParams,
  PageProcessingGroup,
  ProcessingGroup,
  ThrowableProblem,
  UpdateProcessingGroup400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getProcessingGroup = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProcessingGroup>(
      {url: `/api/processingGroups/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProcessingGroupQueryKey = (id: MaybeRef<string>,) => {
    return ['api','processingGroups',id] as const;
    }

    
export const getGetProcessingGroupQueryOptions = <TData = Awaited<ReturnType<typeof getProcessingGroup>>, TError = ErrorType<GetProcessingGroup400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProcessingGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProcessingGroupQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcessingGroup>>> = ({ signal }) => getProcessingGroup(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProcessingGroup>>, TError, TData> 
}

export type GetProcessingGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getProcessingGroup>>>
export type GetProcessingGroupQueryError = ErrorType<GetProcessingGroup400 | ThrowableProblem | ExceptionResponse>



export function useGetProcessingGroup<TData = Awaited<ReturnType<typeof getProcessingGroup>>, TError = ErrorType<GetProcessingGroup400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProcessingGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProcessingGroupQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateProcessingGroup = (
    id: MaybeRef<string>,
    processingGroup: MaybeRef<ProcessingGroup>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
processingGroup = unref(processingGroup);
      
      return customAxios<ProcessingGroup>(
      {url: `/api/processingGroups/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: processingGroup
    },
      options);
    }
  


export const getUpdateProcessingGroupMutationOptions = <TError = ErrorType<UpdateProcessingGroup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProcessingGroup>>, TError,{id: string;data: ProcessingGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProcessingGroup>>, TError,{id: string;data: ProcessingGroup}, TContext> => {
    
const mutationKey = ['updateProcessingGroup'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProcessingGroup>>, {id: string;data: ProcessingGroup}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProcessingGroup(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProcessingGroupMutationResult = NonNullable<Awaited<ReturnType<typeof updateProcessingGroup>>>
    export type UpdateProcessingGroupMutationBody = ProcessingGroup
    export type UpdateProcessingGroupMutationError = ErrorType<UpdateProcessingGroup400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateProcessingGroup = <TError = ErrorType<UpdateProcessingGroup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProcessingGroup>>, TError,{id: string;data: ProcessingGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateProcessingGroup>>,
        TError,
        {id: string;data: ProcessingGroup},
        TContext
      > => {

      const mutationOptions = getUpdateProcessingGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProcessingGroup = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/processingGroups/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProcessingGroupMutationOptions = <TError = ErrorType<DeleteProcessingGroup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProcessingGroup>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProcessingGroup>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteProcessingGroup'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProcessingGroup>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProcessingGroup(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProcessingGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProcessingGroup>>>
    
    export type DeleteProcessingGroupMutationError = ErrorType<DeleteProcessingGroup400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteProcessingGroup = <TError = ErrorType<DeleteProcessingGroup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProcessingGroup>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteProcessingGroup>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteProcessingGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProcessingGroups = (
    params?: MaybeRef<ListProcessingGroupsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProcessingGroup>(
      {url: `/api/processingGroups`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProcessingGroupsQueryKey = (params?: MaybeRef<ListProcessingGroupsParams>,) => {
    return ['api','processingGroups', ...(params ? [params]: [])] as const;
    }

    
export const getListProcessingGroupsQueryOptions = <TData = Awaited<ReturnType<typeof listProcessingGroups>>, TError = ErrorType<ListProcessingGroups400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListProcessingGroupsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProcessingGroups>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProcessingGroupsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProcessingGroups>>> = ({ signal }) => listProcessingGroups(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProcessingGroups>>, TError, TData> 
}

export type ListProcessingGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof listProcessingGroups>>>
export type ListProcessingGroupsQueryError = ErrorType<ListProcessingGroups400 | ThrowableProblem | ExceptionResponse>



export function useListProcessingGroups<TData = Awaited<ReturnType<typeof listProcessingGroups>>, TError = ErrorType<ListProcessingGroups400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListProcessingGroupsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProcessingGroups>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProcessingGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProcessingGroup = (
    processingGroup: MaybeRef<ProcessingGroup>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      processingGroup = unref(processingGroup);
      
      return customAxios<ProcessingGroup>(
      {url: `/api/processingGroups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: processingGroup, signal
    },
      options);
    }
  


export const getCreateProcessingGroupMutationOptions = <TError = ErrorType<CreateProcessingGroup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProcessingGroup>>, TError,{data: ProcessingGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProcessingGroup>>, TError,{data: ProcessingGroup}, TContext> => {
    
const mutationKey = ['createProcessingGroup'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProcessingGroup>>, {data: ProcessingGroup}> = (props) => {
          const {data} = props ?? {};

          return  createProcessingGroup(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProcessingGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createProcessingGroup>>>
    export type CreateProcessingGroupMutationBody = ProcessingGroup
    export type CreateProcessingGroupMutationError = ErrorType<CreateProcessingGroup400 | ThrowableProblem | ExceptionResponse>

    export const useCreateProcessingGroup = <TError = ErrorType<CreateProcessingGroup400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProcessingGroup>>, TError,{data: ProcessingGroup}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createProcessingGroup>>,
        TError,
        {data: ProcessingGroup},
        TContext
      > => {

      const mutationOptions = getCreateProcessingGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProcessingGroup = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/processingGroups/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProcessingGroupQueryKey = (id: MaybeRef<string>,) => {
    return ['api','processingGroups',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProcessingGroupQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>, TError = ErrorType<GetChangeSequenceForProcessingGroup400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProcessingGroupQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>> = ({ signal }) => getChangeSequenceForProcessingGroup(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>, TError, TData> 
}

export type GetChangeSequenceForProcessingGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>>
export type GetChangeSequenceForProcessingGroupQueryError = ErrorType<GetChangeSequenceForProcessingGroup400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForProcessingGroup<TData = Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>, TError = ErrorType<GetChangeSequenceForProcessingGroup400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProcessingGroup>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForProcessingGroupQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



