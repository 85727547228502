<script setup lang="ts">
import type { PropType } from "vue";
import type { Project, ProjectStatus, TaskStatus } from "~/model";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { getProjectTaskStatuses } from "~/api/projects/projects";
import { getColorByBgColor } from "~/utils/colors";

const props = defineProps({
  modelValue: {
    type: Object as PropType<ProjectStatus | undefined>,
    required: false,
  },
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

const emit = defineEmits(["updated", "clear"]);

const taskStatuses = ref<TaskStatus[]>([]);

const lastProjectId = ref<string | undefined>(undefined);

watch(
  () => props.project.id,
  (newProjectId) => {
    if (lastProjectId.value !== newProjectId && newProjectId) {
      getProjectTaskStatuses(newProjectId).then((statuses) => {
        taskStatuses.value = statuses;
        lastProjectId.value = newProjectId;
      });
    }
  },
  { immediate: true },
);

const localSelectedStatus = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("updated", value);
  },
});

function selectItem(dataItem: any) {
  localSelectedStatus.value = dataItem;
}
</script>

<template>
  <div class="flex items-center space-x-4 rounded">
    <DropDownList
      id="projectStatusDropdown"
      v-model="localSelectedStatus"
      :data-items="taskStatuses || []"
      value-field="id"
      text-field="label"
      :filterable="false"
      :value-primitive="false"
      fill-mode="flat"
      class="grow"
      item-render="itemRender"
      value-render="valueRender"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <template #itemRender="{ props }">
        <div
          v-if="props.dataItem" class="kodexa-input mx-2 mt-1 cursor-pointer py-0.5"
          @click="selectItem(props.dataItem, props)"
        >
          <div style="width: 100%">
            <span :style="{ 'background-color': props.dataItem.color, 'color': getColorByBgColor(props.dataItem.color) }" class="text-sm inline-flex items-center rounded-md px-2 py-1 font-medium">{{ props.dataItem.label }}</span>
          </div>
        </div>
      </template>
      <template #valueRender="{ props }">
        <div v-if="props.value" id="currentStatusDropdown" style="width: 100%" class="py-1">
          <span :style="{ 'background-color': props.value.color, 'color': getColorByBgColor(props.value.color) }" class="text-md inline-flex items-center rounded-md px-2 py-1 font-medium">{{ props.value.label }}</span>
        </div>
        <div v-else id="statusDropdown" class="py-1 w-full">
          <span class="text-gray-400">Select status...</span>
        </div>
      </template>
    </DropDownList>
    <MaterialDesignIcon id="closeProjectStatus" name="close" @click="emit('clear')" />
  </div>
</template>
