// Component metadata definition
export const componentMetadata = {
  label: "Group",
  description: "A visual representation of a group of data elements",
  supportsChildren: true,
  defaultWidth: 12,
  defaultHeight: 4,
  options: [
    {
      label: "Show Header",
      name: "showHeader",
      type: "boolean",
      description: "Whether to show the header",
      required: false,
      default: true,
    },
    {
      label: "Title",
      name: "title",
      type: "string",
      description: "Header title to display",
      required: false,
      showIf: "this.showHeader === true",
    },
    {
      label: "Subtitle",
      name: "subtitle",
      type: "string",
      description: "Subtitle to display",
      required: false,
      showIf: "this.showHeader === true",
    },
  ],
};
