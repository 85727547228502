import type { DeploymentOptions, ExtensionPack, PlatformConfiguration, PlatformOverview } from "~/model";
import ls from "localstorage-slim";
import { defineStore, getActivePinia, storeToRefs } from "pinia";
import { customAxios } from "~/api/custom-axios";
import { deleteVersionExtensionPack } from "~/api/extension-packs/extension-packs";
import { getConfiguration, getPlatformOverview, updateConfiguration } from "~/api/platform-overview/platform-overview";
import router from "~/router/router";
import appStore from "~/store/index";
import { log } from "~/utils/logger";
import { RefHelper } from "~/utils/ref-utils";

export interface HeaderItem {
  title: string;
  subtitle: string;
  breadcrumbs?: Array<{ name: string; link: string }>;
}

export interface LoadingEvent {
  id: string;
  title: string;
  subtitle?: string;
  progress?: number;
  progressMax?: number;
  progressMessage?: string;
  progressPercent?: number;
}

export const usePlatform = defineStore("platform", () => {
  const platformConfiguration = ref<PlatformConfiguration | undefined>();
  const platformOverview = ref<PlatformOverview | undefined>();
  const currentSidebar = ref<string>("unset");
  const currentHeading = ref<HeaderItem>({
    title: "Loading...",
    subtitle: "Please wait",
  });

  const isStudio = ref<boolean>(false);

  function toggleStudio() {
    isStudio.value = !isStudio.value;
  }

  const urlPrefix = computed(() => (isStudio.value ? "/a" : "/f"));

  watch(isStudio, (value) => {
    const currentPath = router.currentRoute.value.path;
    const newPath = value
      ? currentPath.replace(/^\/f/, "/a")
      : currentPath.replace(/^\/a/, "/f");

    router.push(newPath);
  });

  // We need to watch the route to make sure we know if we are in studio or not
  watch(router.currentRoute, (route) => {
    isStudio.value = route.path.startsWith("/a");
  }, { immediate: true });

  const platformErrors = ref<Array<any>>([]);
  const loadingEvents = ref<Array<LoadingEvent>>([]);
  const hideLoader = ref<boolean>(false);
  const newsRead = ref<boolean>(true);

  function addLoadingEvent(event: LoadingEvent) {
    // Ignore the add if we have a loading event with the same id
    if (loadingEvents.value.find(e => e.id === event.id)) {
      return;
    }

    if (event.progress !== undefined) {
      event.progressPercent = (event.progress / (event.progressMax === undefined ? 10 : event.progressMax)) * 100;
    }
    loadingEvents.value.push(event);
  }

  function removeLoadingEvent(event: LoadingEvent) {
    const index = loadingEvents.value.indexOf(event);
    if (index > -1) {
      loadingEvents.value.splice(index, 1);
    }
  }

  function incrementLoadingProgress(eventId: string, progress = 1, progressMax: number | undefined = undefined, progressMessage = undefined) {
    const event = loadingEvents.value.find(e => e.id === eventId) as LoadingEvent;
    if (event) {
      event.progress = (event.progress === undefined ? 0 : event.progress) + progress;
      if (progressMax) {
        event.progressMax = (event.progressMax === undefined ? 10 : event.progressMax) + progressMax;
      }
      event.progressPercent = (event.progress / (event.progressMax === undefined ? 10 : event.progressMax)) * 100;

      if (event.progressPercent > 100) {
        event.progressPercent = 100;
      }
    }
    if (progressMessage !== undefined) {
      event.progressMessage = progressMessage;
    } else {
      event.progressMessage = undefined;
    }
    // Replace the event in the array
    const index = loadingEvents.value.indexOf(event);
    if (index > -1) {
      loadingEvents.value[index] = event;
    }
  }

  function loadConfiguration() {
    log.info("Loading platform configuration");

    if (platformConfiguration.value === undefined) {
      getConfiguration().then((response) => {
        platformConfiguration.value = response;
        // Lets see if we have news markdown in local storage
        const changeLogArticleId = ls.get("changeLogArticleId");
        if (changeLogArticleId) {
          if (changeLogArticleId !== platformConfiguration.value.changeLogArticleId) {
            ls.set("news", platformConfiguration.value.changeLogArticleId);
            ls.set("newsRead", false);
          }
        }

        newsRead.value = ls.get("newsRead") === true;
      });
    }
  }

  function loadPlatformOverview() {
    getPlatformOverview().then((response) => {
      log.info("Loaded platform overview");
      platformOverview.value = response;
    });
  }

  function setCurrentSidebar(sidebarId: string) {
    currentSidebar.value = sidebarId;
  }

  function setCurrentHeading(heading: HeaderItem) {
    currentHeading.value = heading;
  }

  const sidebarNavigation = computed(() => {
    // We want to try and work out the sidebar, this is probably could be better
    // we start looking for the workspace,  then the project, if not those then
    // we look in the route (the theory here is that only the project and workspace
    // are dynamic

    const {
      projectSidebar,
      project,
    } = storeToRefs(appStore.projectStore);
    const {
      workspaceSidebar,
      currentWorkspaceId,
    } = storeToRefs(appStore.workspaceStore);

    function initializeSide(navSide: any) {
      if (currentSidebar.value === "unset") {
        return;
      }

      const foundSidebar = navSide.value ? navSide.value.find((item: any) => item.id === currentSidebar.value) : null;
      if (!foundSidebar && navSide.value && navSide.value.length > 0) {
        currentSidebar.value = navSide.value[0].id;
      }
    }

    if (currentWorkspaceId.value) {
      initializeSide(workspaceSidebar);
      return workspaceSidebar;
    } else if (project.value) {
      initializeSide(projectSidebar);
      return projectSidebar;
    } else if (router.currentRoute.value.meta.sidebar) {
      const navSide = ref(router.currentRoute.value.meta.sidebar as []);
      initializeSide(navSide);
      return ref(navSide);
    }
    return [];
  });

  const captureException = (error: any) => {
    platformErrors.value.push(error);
  };

  const clearLoadingEvents = () => {
    loadingEvents.value = [];
  };

  async function deployExtensionPack(extensionPack: ExtensionPack, deploymentOptions: DeploymentOptions) {
    await customAxios({
      method: "PUT",
      url: `/api/extensionPacks/${extensionPack.orgSlug}/${extensionPack.slug}/${extensionPack.version}/deploy`,
      data: deploymentOptions,
    });
  }

  async function undeployExtensionPack(extensionPack: ExtensionPack) {
    await customAxios({
      method: "PUT",
      url: `/api/extensionPacks/${extensionPack.orgSlug}/${extensionPack.slug}/${extensionPack.version}/undeploy`,
    });
  }

  async function cancelRunningExtensionPack(extensionPack: ExtensionPack) {
    await customAxios({
      method: "PUT",
      url: `/api/extensionPacks/${extensionPack.orgSlug}/${extensionPack.slug}/${extensionPack.version}/cancelAll`,
    });
  }

  async function deleteExtensionPack(extensionPackage: ExtensionPack) {
    await deleteVersionExtensionPack(extensionPackage.orgSlug, extensionPackage.slug, extensionPackage.version);
  }

  async function getModel(modelRef: string) {
    const refHelper = new RefHelper(modelRef);
    return await customAxios({
      method: "GET",
      url: `/api/stores/${refHelper.getOrgSlug()}/${refHelper.getSlug()}/${refHelper.getVersion()}`,
    });
  }

  async function saveConfiguration(config: PlatformConfiguration) {
    platformConfiguration.value = await updateConfiguration(config);
  }

  function markNewsRead() {
    newsRead.value = true;
    ls.set("newsRead", true);
  }

  function destroyPiniaStores() {
    // Get a list of all the pinia stores that exist and then
    // pick out the views and destroy them
    const pinia = getActivePinia();

    // Access all stores
    const stores = Object.entries(pinia?.state.value);

    // Iterate over each store
    stores.forEach(([key]) => {
      const keywords = [
        "data-flow",
        "channel",
        "data-attribute-helper",
        "dataForm",
        "document-search",
        "documentViewer",
        "execution-store",
        "formula-store",
        "formula-service",
      ];

      // Check if any keyword is a substring of the input
      if (keywords.some(keyword => key.includes(keyword))) {
        // Destroy the store
        log.info(`Destroying store: ${key}`);
        delete pinia?.state.value[key];
        // Check if the store has been initialized
        if (pinia?._s.has(key)) {
          // Initialize the store if not already initialized
          return pinia._s.get(key).$dispose();
        }
        log.info(`Store destroyed: ${key}`);
      }
    });

    // Destroy the project store
    delete pinia?.state.value.project;
    appStore.cacheStore.clearCache();
    delete pinia?.state.value.workspace;
  }

  function hideLoad() {
    hideLoader.value = true;
  }

  function showLoad() {
    hideLoader.value = false;
  }

  return {
    loadPlatformOverview,
    loadConfiguration,
    platformConfiguration,
    sidebarNavigation,
    currentSidebar,
    setCurrentSidebar,
    currentHeading,
    setCurrentHeading,
    platformOverview,
    captureException,
    addLoadingEvent,
    removeLoadingEvent,
    loadingEvents,
    incrementLoadingProgress,
    clearLoadingEvents,
    deployExtensionPack,
    undeployExtensionPack,
    deleteExtensionPack,
    getModel,
    cancelRunningExtensionPack,
    saveConfiguration,
    newsRead,
    markNewsRead,
    destroyPiniaStores,
    urlPrefix,
    isStudio,
    toggleStudio,
    hideLoader,
    hideLoad,
    showLoad,
  };
});
