/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateDataObject400,
  DataObject,
  DeleteDataObject400,
  ExceptionResponse,
  GetChangeSequenceForDataObject400,
  GetDataObject400,
  ListDataObjects400,
  ListDataObjectsParams,
  PageDataObject,
  ThrowableProblem,
  UpdateDataObject400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getDataObject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<DataObject>(
      {url: `/api/dataObjects/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDataObjectQueryKey = (id: MaybeRef<string>,) => {
    return ['api','dataObjects',id] as const;
    }

    
export const getGetDataObjectQueryOptions = <TData = Awaited<ReturnType<typeof getDataObject>>, TError = ErrorType<GetDataObject400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataObjectQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataObject>>> = ({ signal }) => getDataObject(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataObject>>, TError, TData> 
}

export type GetDataObjectQueryResult = NonNullable<Awaited<ReturnType<typeof getDataObject>>>
export type GetDataObjectQueryError = ErrorType<GetDataObject400 | ThrowableProblem | ExceptionResponse>



export function useGetDataObject<TData = Awaited<ReturnType<typeof getDataObject>>, TError = ErrorType<GetDataObject400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetDataObjectQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateDataObject = (
    id: MaybeRef<string>,
    dataObject: MaybeRef<DataObject>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
dataObject = unref(dataObject);
      
      return customAxios<DataObject>(
      {url: `/api/dataObjects/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataObject
    },
      options);
    }
  


export const getUpdateDataObjectMutationOptions = <TError = ErrorType<UpdateDataObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObject>>, TError,{id: string;data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataObject>>, TError,{id: string;data: DataObject}, TContext> => {
    
const mutationKey = ['updateDataObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataObject>>, {id: string;data: DataObject}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDataObject(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDataObjectMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataObject>>>
    export type UpdateDataObjectMutationBody = DataObject
    export type UpdateDataObjectMutationError = ErrorType<UpdateDataObject400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateDataObject = <TError = ErrorType<UpdateDataObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObject>>, TError,{id: string;data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateDataObject>>,
        TError,
        {id: string;data: DataObject},
        TContext
      > => {

      const mutationOptions = getUpdateDataObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteDataObject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/dataObjects/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDataObjectMutationOptions = <TError = ErrorType<DeleteDataObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObject>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataObject>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteDataObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataObject>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDataObject(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDataObjectMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataObject>>>
    
    export type DeleteDataObjectMutationError = ErrorType<DeleteDataObject400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteDataObject = <TError = ErrorType<DeleteDataObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObject>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteDataObject>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDataObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listDataObjects = (
    params?: MaybeRef<ListDataObjectsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageDataObject>(
      {url: `/api/dataObjects`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListDataObjectsQueryKey = (params?: MaybeRef<ListDataObjectsParams>,) => {
    return ['api','dataObjects', ...(params ? [params]: [])] as const;
    }

    
export const getListDataObjectsQueryOptions = <TData = Awaited<ReturnType<typeof listDataObjects>>, TError = ErrorType<ListDataObjects400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListDataObjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDataObjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListDataObjectsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDataObjects>>> = ({ signal }) => listDataObjects(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDataObjects>>, TError, TData> 
}

export type ListDataObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof listDataObjects>>>
export type ListDataObjectsQueryError = ErrorType<ListDataObjects400 | ThrowableProblem | ExceptionResponse>



export function useListDataObjects<TData = Awaited<ReturnType<typeof listDataObjects>>, TError = ErrorType<ListDataObjects400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListDataObjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDataObjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListDataObjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createDataObject = (
    dataObject: MaybeRef<DataObject>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      dataObject = unref(dataObject);
      
      return customAxios<DataObject>(
      {url: `/api/dataObjects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataObject, signal
    },
      options);
    }
  


export const getCreateDataObjectMutationOptions = <TError = ErrorType<CreateDataObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObject>>, TError,{data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDataObject>>, TError,{data: DataObject}, TContext> => {
    
const mutationKey = ['createDataObject'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDataObject>>, {data: DataObject}> = (props) => {
          const {data} = props ?? {};

          return  createDataObject(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateDataObjectMutationResult = NonNullable<Awaited<ReturnType<typeof createDataObject>>>
    export type CreateDataObjectMutationBody = DataObject
    export type CreateDataObjectMutationError = ErrorType<CreateDataObject400 | ThrowableProblem | ExceptionResponse>

    export const useCreateDataObject = <TError = ErrorType<CreateDataObject400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObject>>, TError,{data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createDataObject>>,
        TError,
        {data: DataObject},
        TContext
      > => {

      const mutationOptions = getCreateDataObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForDataObject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/dataObjects/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForDataObjectQueryKey = (id: MaybeRef<string>,) => {
    return ['api','dataObjects',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForDataObjectQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForDataObject>>, TError = ErrorType<GetChangeSequenceForDataObject400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForDataObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForDataObjectQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForDataObject>>> = ({ signal }) => getChangeSequenceForDataObject(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForDataObject>>, TError, TData> 
}

export type GetChangeSequenceForDataObjectQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForDataObject>>>
export type GetChangeSequenceForDataObjectQueryError = ErrorType<GetChangeSequenceForDataObject400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForDataObject<TData = Awaited<ReturnType<typeof getChangeSequenceForDataObject>>, TError = ErrorType<GetChangeSequenceForDataObject400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForDataObject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForDataObjectQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



