<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  documentFamilies: {
    type: Array as PropType<DocumentFamily[]>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "clearSelection"]);

function close() {
  emit("update:modelValue", false);
  emit("clearSelection");
}
</script>

<template>
  <TransitionRoot :show="props.modelValue" as="template" appear>
    <Dialog as="div" class="relative z-[99]" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            style="width: 950px; height: 560px"
            class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-4"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                @click="emit('update:modelValue', false)"
              >
                <span class="sr-only">Close</span>
                <MaterialDesignIcon name="close" class="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
            <DialogTitle class="flex text-lg font-medium leading-4 text-gray-900">
              <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex sm:space-x-5">
                  <div class="shrink-0" />
                  <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                      Copy Documents
                    </p>
                    <p class="text-sm font-medium text-gray-400">
                      Copy documents from one document store to another
                    </p>
                  </div>
                </div>
              </div>
            </DialogTitle>
            <div>
              <div class="h-96" />
              <div
                class="mr-1 mt-12 sm:flex sm:flex-row-reverse"
              >
                <KodexaButton icon="copy" width="180px">
                  Perform Copy
                </KodexaButton>
                <KodexaButton
                  class="mr-2" width="120px"
                  icon="cancel" type="secondary"
                  @click="close"
                >
                  Cancel
                </KodexaButton>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
