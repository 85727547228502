<script lang="ts" setup>
</script>

<template>
  <div class="flex flex-wrap items-center gap-2 py-1 min-h-[3rem] sticky top-0 z-10">
    <slot />
  </div>
</template>

<style scoped>

</style>
