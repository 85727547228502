export const optionMetadata = {
  label: "Number Input",
  description: "Numeric input field with optional constraints",
  options: [
    {
      name: "min",
      label: "Minimum Value",
      type: "number",
      description: "Minimum allowed value",
    },
    {
      name: "max",
      label: "Maximum Value",
      type: "number",
      description: "Maximum allowed value",
    },
    {
      name: "step",
      label: "Step Value",
      type: "number",
      description: "Step increment/decrement value",
      default: 1,
    },
  ],
};
