/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateProjectStatus400,
  DeleteProjectStatus400,
  ExceptionResponse,
  GetChangeSequenceForProjectStatus400,
  GetProjectStatus400,
  ListProjectStatuses400,
  ListProjectStatusesParams,
  PageProjectStatus,
  ProjectStatus,
  ThrowableProblem,
  UpdateProjectStatus400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getProjectStatus = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectStatus>(
      {url: `/api/projectStatuses/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectStatusQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projectStatuses',id] as const;
    }

    
export const getGetProjectStatusQueryOptions = <TData = Awaited<ReturnType<typeof getProjectStatus>>, TError = ErrorType<GetProjectStatus400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectStatusQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectStatus>>> = ({ signal }) => getProjectStatus(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectStatus>>, TError, TData> 
}

export type GetProjectStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectStatus>>>
export type GetProjectStatusQueryError = ErrorType<GetProjectStatus400 | ThrowableProblem | ExceptionResponse>



export function useGetProjectStatus<TData = Awaited<ReturnType<typeof getProjectStatus>>, TError = ErrorType<GetProjectStatus400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProjectStatusQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateProjectStatus = (
    id: MaybeRef<string>,
    projectStatus: MaybeRef<ProjectStatus>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectStatus = unref(projectStatus);
      
      return customAxios<ProjectStatus>(
      {url: `/api/projectStatuses/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectStatus
    },
      options);
    }
  


export const getUpdateProjectStatusMutationOptions = <TError = ErrorType<UpdateProjectStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectStatus>>, TError,{id: string;data: ProjectStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectStatus>>, TError,{id: string;data: ProjectStatus}, TContext> => {
    
const mutationKey = ['updateProjectStatus'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectStatus>>, {id: string;data: ProjectStatus}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProjectStatus(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectStatus>>>
    export type UpdateProjectStatusMutationBody = ProjectStatus
    export type UpdateProjectStatusMutationError = ErrorType<UpdateProjectStatus400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateProjectStatus = <TError = ErrorType<UpdateProjectStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectStatus>>, TError,{id: string;data: ProjectStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateProjectStatus>>,
        TError,
        {id: string;data: ProjectStatus},
        TContext
      > => {

      const mutationOptions = getUpdateProjectStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProjectStatus = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/projectStatuses/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProjectStatusMutationOptions = <TError = ErrorType<DeleteProjectStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectStatus>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectStatus>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteProjectStatus'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectStatus>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProjectStatus(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectStatusMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectStatus>>>
    
    export type DeleteProjectStatusMutationError = ErrorType<DeleteProjectStatus400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteProjectStatus = <TError = ErrorType<DeleteProjectStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectStatus>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteProjectStatus>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProjectStatuses = (
    params?: MaybeRef<ListProjectStatusesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProjectStatus>(
      {url: `/api/projectStatuses`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProjectStatusesQueryKey = (params?: MaybeRef<ListProjectStatusesParams>,) => {
    return ['api','projectStatuses', ...(params ? [params]: [])] as const;
    }

    
export const getListProjectStatusesQueryOptions = <TData = Awaited<ReturnType<typeof listProjectStatuses>>, TError = ErrorType<ListProjectStatuses400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListProjectStatusesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectStatuses>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProjectStatusesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectStatuses>>> = ({ signal }) => listProjectStatuses(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProjectStatuses>>, TError, TData> 
}

export type ListProjectStatusesQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectStatuses>>>
export type ListProjectStatusesQueryError = ErrorType<ListProjectStatuses400 | ThrowableProblem | ExceptionResponse>



export function useListProjectStatuses<TData = Awaited<ReturnType<typeof listProjectStatuses>>, TError = ErrorType<ListProjectStatuses400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListProjectStatusesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectStatuses>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProjectStatusesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProjectStatus = (
    projectStatus: MaybeRef<ProjectStatus>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      projectStatus = unref(projectStatus);
      
      return customAxios<ProjectStatus>(
      {url: `/api/projectStatuses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectStatus, signal
    },
      options);
    }
  


export const getCreateProjectStatusMutationOptions = <TError = ErrorType<CreateProjectStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectStatus>>, TError,{data: ProjectStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProjectStatus>>, TError,{data: ProjectStatus}, TContext> => {
    
const mutationKey = ['createProjectStatus'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectStatus>>, {data: ProjectStatus}> = (props) => {
          const {data} = props ?? {};

          return  createProjectStatus(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProjectStatusMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectStatus>>>
    export type CreateProjectStatusMutationBody = ProjectStatus
    export type CreateProjectStatusMutationError = ErrorType<CreateProjectStatus400 | ThrowableProblem | ExceptionResponse>

    export const useCreateProjectStatus = <TError = ErrorType<CreateProjectStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectStatus>>, TError,{data: ProjectStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createProjectStatus>>,
        TError,
        {data: ProjectStatus},
        TContext
      > => {

      const mutationOptions = getCreateProjectStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProjectStatus = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/projectStatuses/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProjectStatusQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projectStatuses',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProjectStatusQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>, TError = ErrorType<GetChangeSequenceForProjectStatus400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProjectStatusQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>> = ({ signal }) => getChangeSequenceForProjectStatus(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>, TError, TData> 
}

export type GetChangeSequenceForProjectStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>>
export type GetChangeSequenceForProjectStatusQueryError = ErrorType<GetChangeSequenceForProjectStatus400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForProjectStatus<TData = Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>, TError = ErrorType<GetChangeSequenceForProjectStatus400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProjectStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForProjectStatusQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



