<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget, Membership, Project, ProjectStatus } from "~/model";
import { ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { RefHelper } from "~/utils/ref-utils";

defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: true,
  },
});

const {
  project,
  firstWorkspace,
} = storeToRefs(appStore.projectStore);

const openingWorkspace = ref(false);

async function openWorkspace() {
  await appStore.workspaceStore.openWorkspace(firstWorkspace.value);
  openingWorkspace.value = false;
}

const localProject: Ref<Project | undefined> = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

function updateProjectStatus(projectStatus: ProjectStatus) {
  if (localProject.value) {
    localProject.value.status = projectStatus;
    appStore.projectStore.updateCurrentProject(localProject.value);
  }
}

function updateProjectMember(member: Membership) {
  if (localProject.value) {
    localProject.value.owner = member?.user ? member.user : undefined;
    appStore.projectStore.updateCurrentProject(localProject.value);
  }
}

const optionsProperties = computed({
  get: () => {
    return localProject.value?.options?.properties || {};
  },
  set: (newValue) => {
    if (localProject.value) {
      if (!localProject.value.options) {
        localProject.value.options = {};
      }
      localProject.value.options.properties = newValue;
      appStore.projectStore.updateCurrentProject(localProject.value);
    }
  },
});
</script>

<template>
  <div>
    <div>
      <div v-if="localProject" class="mx-2">
        <KodexaHeader
          title="Project Overview" sub-title="Overview information for your project" :sidebar="false"
          class="ml-2"
        />
        <KodexaArticle class="ml-4 mt-7" article-id="8729166" text="Learn more about projects" :slide="false" />
        <KodexaToolbar
          v-if="firstWorkspace && !widget.properties?.hideOpenWorkspace"
        >
          <ToolbarSpacer />
          <KodexaButton
            id="openProjectWorkspace" :loading="openingWorkspace" icon="open" title="New Project"
            @click="openWorkspace"
          >
            <span class="ml-1">Open Workspace</span>
          </KodexaButton>
        </KodexaToolbar>
        <div class="flex">
          <!-- Left Panel -->
          <div class="w-1/2 pr-4">
            <dl>
              <div class="mt-4 px-4 py-2">
                <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                  Name
                </dt>
                <KodexaClipboardable
                  id="projectName" class="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-300"
                  :content="project.name"
                />
              </div>
              <div class="px-4 py-2">
                <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                  Description
                </dt>
                <dd id="projectDescription" class="clipboardable mt-1 text-sm leading-6 text-gray-700 dark:text-gray-300">
                  {{ project.description }}
                </dd>
              </div>
              <div v-if="project.templateArticleId" class="px-4 py-2">
                <KodexaArticle
                  :article-id="project.templateArticleId"
                  text="This project was created from a template, click here to learn how to use it"
                  :slide="false"
                />
              </div>
              <div class="px-4 py-1">
                <dt class="text-sm font-medium text-gray-900 dark:text-gray-100">
                  Status
                </dt>
                <dd class="my-2 text-sm text-gray-700 dark:text-gray-300">
                  <KodexaProjectStatus :project="localProject" :save-value="false" @updated="updateProjectStatus" />
                </dd>
              </div>
              <div class="px-4 py-1">
                <dt class="text-sm font-medium text-gray-900 dark:text-gray-100">
                  Owner
                </dt>
                <dd class="my-1 text-sm text-gray-700 dark:text-gray-300">
                  <KodexaProjectOwner :project="localProject" :save-value="false" @updated="updateProjectMember" />
                </dd>
              </div>
              <div class="px-4 py-1">
                <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                  Notes
                </dt>
                <dd class="my-1 text-sm leading-6 text-gray-700 dark:text-gray-300">
                  <KodexaProjectNotes />
                </dd>
              </div>
            </dl>
          </div>
          <!-- Right Panel -->
          <div class="w-1/2 pl-4 -mt-10">
            <dl>
              <div v-if="localProject.options?.options" class="px-4">
                <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                  Options
                </dt>
              </div>
              <div v-for="option in localProject.options?.options || []" :key="option.name" class="mb-2 px-4">
                <ConfigurationOption v-model="optionsProperties" :item="option" />
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
