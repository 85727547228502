export const optionMetadata = {
  label: "Code Editor",
  description: "Code editor with syntax highlighting",
  options: [
    {
      name: "language",
      label: "Language",
      type: "string",
      description: "Programming language for syntax highlighting",
      default: "python",
    },
    {
      name: "height",
      label: "Editor Height",
      type: "string",
      description: "Height of the editor",
      default: "300px",
    },
  ],
};
