<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useMagicKeys } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useKeyboard } from "~/store/useKeyboard";
import { log } from "~/utils/logger";

const keyboardStore = useKeyboard();
const keys = useMagicKeys();
const { current } = useMagicKeys();

const {
  availableShortcuts,
  shortcuts,
  popupOpen,
} = storeToRefs(keyboardStore);

const shortcutKey = keys["meta+/"] || keys["control+/"];

watch(shortcutKey, (v) => {
  if (v) {
    keyboardStore.togglePopup();
  }
});

watch(current, (currentKeys: Set<string>) => {
  if (currentKeys) {
    const currentKeyPress = Array.from(currentKeys).join("+");
    if (shortcuts.value.has(currentKeyPress)) {
      log.info(`Executing shortcut [${currentKeyPress}]`);
      shortcuts.value.get(currentKeyPress)?.callback();
    }
  }
});

function close() {
  keyboardStore.closePopup();
}
</script>

<template>
  <div>
    <TransitionRoot :show="popupOpen" as="template" appear>
      <Dialog as="div" class="relative z-[99]" @close="close">
        <TransitionChild
          as="template"
          enter="ease-out duration-200"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-150"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 p-4 sm:p-6 md:p-20">
          <TransitionChild
            as="template"
            enter="ease-out duration-200"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-150"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="mx-auto max-w-xl overflow-hidden rounded-xl bg-white dark:bg-gray-800 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
              tabindex="-1"
            >
              <div class="my-2" style="max-height: 700px; overflow-y: auto;">
                <p class="mb-3 ml-7 mt-2 text-sm">
                  Available shortcut keys
                </p>
                <div v-for="shortcut in availableShortcuts" :key="shortcut.key" class="p-1">
                  <div class="flex flex-wrap items-center px-4 text-xs text-gray-700">
                    <template v-for="(keyPart, idx) in shortcut.key.split('+')" :key="keyPart">
                      <template v-if="idx > 0">
                        +
                      </template>
                      <kbd
                        class="mx-1 flex items-center justify-center rounded border bg-white dark:bg-gray-800 p-1 font-semibold sm:mx-2"
                      >{{ keyPart }}</kbd>
                    </template>

                    <span class="ml-5">{{ shortcut.description }}</span>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<style scoped>

</style>
