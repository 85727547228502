import type { Auth0Plugin } from "@auth0/auth0-vue";
import type { RumInitConfiguration } from "@datadog/browser-rum";
import type { FloatingVueConfig } from "floating-vue/dist/config";
import { createAuth0 } from "@auth0/auth0-vue";
import { datadogRum } from "@datadog/browser-rum";
import { createGtm } from "@gtm-support/vue-gtm";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { CronLight } from "@vue-js-cron/light";
import VueDatePicker from "@vuepic/vue-datepicker";
import { useLocalStorage } from "@vueuse/core";
import { MotionPlugin } from "@vueuse/motion";
import FloatingVue from "floating-vue";
import pythonWorker from "monaco-editor/esm/vs/basic-languages/python/python.js?worker";
import xmlWorker from "monaco-editor/esm/vs/basic-languages/xml/xml.js?worker";
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import Notifications from "notiwind";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { registerSW } from "virtual:pwa-register";
import { createApp } from "vue";
import { vueDebounce } from "vue-debounce";

import VueGtag from "vue-gtag";
import { createI18n } from "vue-i18n";
import VueTippy from "vue-tippy";
import * as ConfirmDialog from "vuejs-confirm-dialog";
import App from "~//App.vue";
import { log } from "~/utils/logger";
import router from "./router/router";
import { registerStore } from "./store";
import "@vuepic/vue-datepicker/dist/main.css";

import "floating-vue/dist/style.css";
import "~/styles/main.css";
import "~/styles/tailwind.css";
import "@mdi/font/css/materialdesignicons.css";
import "highlight.js/styles/github-dark.css";
import "quill/dist/quill.snow.css";
import "@vue-js-cron/light/dist/light.css";

self.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === "json") {
      return new jsonWorker();
    }
    if (label === "xml") {
      return new xmlWorker();
    }
    if (label === "python" || label === "python") {
      return new pythonWorker();
    }
    return new editorWorker();
  },
};

const intervalMS = 60 * 1000;

interface UIConfig {
  gTagId?: string;
  gId?: string;
  domain?: string;
  clientId?: string;
  audience?: string;
  callbackUrl?: string;
  chartUiVersion?: string;
  platformVersion?: string;
  stripeKey?: string;
  applicationId?: string;
  clientToken?: string;
  site?: string;
  service: string;
  env: string;
  version?: string;
  sessionSampleRate?: string;
  sessionReplaySampleRate?: string;
  trackResources?: string;
  trackLongTasks?: string;
  trackUserInteractions?: string;
}

registerSW({
  onRegisteredSW(swUrl: string, r: ServiceWorkerRegistration) {
    r && setInterval(async () => {
      if (!(!r.installing && navigator)) {
        return;
      }

      if (("connection" in navigator) && !navigator.onLine) {
        return;
      }

      const resp = await fetch(swUrl, {
        cache: "no-store",
        headers: {
          "cache": "no-store",
          "cache-control": "no-cache",
        },
      });

      if (resp?.status === 200) {
        await r.update();
      }
    }, intervalMS);
  },
});

const app = createApp({
  render: () => h(App),
});

const messages = Object.fromEntries(
  Object.entries(
    import.meta.glob<{ default: any }>("./locales/*.json", { eager: true }),
  )
    .map(([key, value]) => {
      return [key.slice(10, -5), value.default];
    }),
);

app.use(createI18n({
  legacy: false,
  locale: unref(useLocalStorage("locale", "en-US")),
  messages,
}));
app.use(MotionPlugin);

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

app.use(pinia);
app.use(Notifications);
app.use(VueTippy);
app.use(VueQueryPlugin);
app.component("CronLight", CronLight);

const floatingConfig: FloatingVueConfig = {
  // Disable popper components
  disabled: false,
  // Default position offset along main axis (px)
  distance: 5,
  // Default position offset along cross axis (px)
  skidding: 0,
  // Default container where the tooltip will be appended
  container: "body",
  // Element used to compute position and size boundaries
  boundary: undefined,
  // Skip delay & CSS transitions when another popper is shown, so that the popper appear to instanly move to the new position.
  instantMove: false,
  // Auto destroy tooltip DOM nodes (ms)
  disposeTimeout: 5000,
  // Triggers on the popper itself
  popperTriggers: [],
  // Positioning strategy
  strategy: "absolute",
  // Prevent overflow
  preventOverflow: true,
  // Flip to the opposite placement if needed
  flip: true,
  // Shift on the cross axis to prevent the popper from overflowing
  shift: true,
  // Overflow padding (px)
  overflowPadding: 0,
  // Arrow padding (px)
  arrowPadding: 0,
  // Compute arrow overflow (useful to hide it)
  arrowOverflow: true,
  // Themes
  themes: {
    tooltip: {
      // Default tooltip placement relative to target element
      placement: "top",
      // Default events that trigger the tooltip
      triggers: ["hover", "focus", "touch"],
      // Close tooltip on click on tooltip target
      hideTriggers: (events: any) => {
        return [...events, "click"];
      },
      // Delay (ms)
      delay: {
        show: 200,
        hide: 0,
      },
      // Update popper on content resize
      handleResize: false,
      // Enable HTML content in directive
      html: false,
      // Displayed when tooltip content is loading
      loadingContent: "...",
    },
    dropdown: {
      // Default dropdown placement relative to target element
      placement: "bottom",
      // Default events that trigger the dropdown
      triggers: ["click"],
      // Delay (ms)
      delay: 0,
      // Update popper on content resize
      handleResize: true,
      // Hide on click outside
      autoHide: true,
    },
    menu: {
      $extend: "dropdown",
      triggers: ["hover", "focus"],
      popperTriggers: ["hover", "focus"],
      delay: {
        show: 20,
        hide: 100,
      },
    },
  },
};

app.directive("debounce", vueDebounce({ lock: true }));
app.component("VueDatePicker", VueDatePicker);

// Register a global custom directive called `v-focus`
app.directive("focus", {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    nextTick(() => {
      el.focus();
    }).catch((e) => {
      log.error("Error is autofocus", e);
    });
  },
});

app.use(FloatingVue, floatingConfig);
app.use(ConfirmDialog);
app.use(VueQueryPlugin);

registerStore();
const uiConfigUrl = "/ui-config";
export let auth0: Auth0Plugin | undefined;
fetch(uiConfigUrl).then(async (response) => {
  let uiConfig = {} as UIConfig;
  try {
    uiConfig = await response.json();
  } catch (e) {
    log.error("Error fetching UI Config");
    log.error(e);
  }

  app.use(router);

  if (!uiConfig) {
    log.warn("No UI Config found");
  } else {
    console.log("Initialize ui config", uiConfig);
    app.config.globalProperties.platformVersion = uiConfig.platformVersion;
    app.config.globalProperties.chartUiVersion = uiConfig.chartUiVersion;

    if (uiConfig.stripeKey) {
      log.info(`Initializing Stripe with shared key ${uiConfig.stripeKey}`);
    } else {
      log.warn("No Stripe Key found");
      uiConfig.stripeKey = "pk_test_51OfR4HCOAMAChgrL2yl7L0iHb0CxiMtQi3fjBygYrtje3oEYSLmOqSqBEkj1V9Q8MjicqMztOjkOEX45QcdWxzW300BWn0fbAl";
    }
    app.config.globalProperties.stripeKey = uiConfig.stripeKey;
  }

  const version = import.meta.env.VITE_APP_VERSION || "0.0.0";

  if (uiConfig.applicationId && uiConfig.clientToken && uiConfig.site) {
    log.info(`Initializing Datadog RUM ${uiConfig.env}`);
    const ddConfig = {
      applicationId: uiConfig.applicationId,
      clientToken: uiConfig.clientToken,
      service: uiConfig.service,
      env: uiConfig.env,
      version,
      site: uiConfig.site,
      sessionSampleRate: uiConfig.sessionSampleRate ? Number.parseInt(uiConfig.sessionSampleRate) : 10,
      sessionReplaySampleRate: uiConfig.sessionReplaySampleRate ? Number.parseInt(uiConfig.sessionReplaySampleRate) : 10,
      trackResources: uiConfig.trackResources ? uiConfig.trackResources === "true" : true,
      trackLongTasks: uiConfig.trackLongTasks ? uiConfig.trackLongTasks === "true" : true,
      trackUserInteractions: uiConfig.trackUserInteractions ? uiConfig.trackUserInteractions === "true" : true,
    } as RumInitConfiguration;
    console.log("Datadog RUM Config", ddConfig);
    datadogRum.init(ddConfig);
  } else {
    log.warn("No Datadog RUM Config found");
  }

  if (uiConfig.gTagId) {
    log.info(`Initializing Google Tags ${uiConfig.gTagId}`);

    try {
      app.use(
        createGtm({
          id: uiConfig.gTagId,
          vueRouter: router,
        }),
      );
    } catch (e) {
      log.error("Error initializing Google Tags");
      log.error(e);
    }
  } else {
    log.warn("No Google Tags ID found");
  }

  if (uiConfig.gId) {
    log.info(`Initializing Google Analytics ${uiConfig.gId}`);
    app.use(VueGtag, {
      config: { id: uiConfig.gId },
      router,
    });
  } else {
    log.warn("No Google Analytics ID found");
  }

  // We need to determine if we have the auth0 config
  // from the uiConfig and if not try and get it from VITE_ env

  if (!uiConfig.domain || !uiConfig.clientId || !uiConfig.audience || !uiConfig.callbackUrl) {
    log.warn("No Auth0 UI Config found, using VITE_ env variables");
    uiConfig.domain = import.meta.env.VITE_AUTH0_DOMAIN;
    uiConfig.clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
    uiConfig.audience = import.meta.env.VITE_AUTH0_AUDIENCE;

    // If we are running in a render environment we need to use the external URL
    uiConfig.callbackUrl = import.meta.env.RENDER_EXTERNAL_URL ? `${import.meta.env.RENDER_EXTERNAL_URL}/a/callback` : import.meta.env.VITE_AUTH0_CALLBACK_URL;
  }

  auth0 = createAuth0({
    domain: uiConfig.domain,
    clientId: uiConfig.clientId,
    authorizationParams: {
      audience: uiConfig.audience,
      redirect_uri: uiConfig.callbackUrl,
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  });
  if (auth0.isAuthenticated) {
    app.use(auth0);
  }
  app.mount("#app");
});
