<script lang="ts" setup>
import type { PageViewport } from "pdfjs-dist";
import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist/types/web/pdf_viewer";
import type { PropType } from "vue";
import { watchDebounced } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";
import { computed, ref, toRaw } from "vue";
import { log } from "~/utils/logger";
import { createLoadingTask } from "./loading-task";
import "pdfjs-dist/web/pdf_viewer.css";

const props = defineProps({
  src: {
    type: Object as PropType<Uint8Array>,
    required: false,
  },
  page: {
    type: Number,
    default: 1,
  },
  scale: {
    type: Number,
    default: null,
  },
});

const pdfWrapperRef = ref<HTMLElement | null>(null);
const parentWrapperRef = ref<HTMLElement | null>(null);

let thePDF: PDFDocumentProxy | null = null;
const numberOfPages = ref<number>(0);
const pageNumber = computed(() => props.page || 1);

async function scaleCanvas(pdfWrapperEl: HTMLElement, intialisedViewport: PageViewport, page: PDFPageProxy, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
  if (!pdfWrapperEl) {
    return;
  }

  const pdfWrapperElStyles = window.getComputedStyle(pdfWrapperEl);
  const pdfWrapperElWidth = Number.parseFloat(pdfWrapperElStyles.width);

  let scale = props.scale ? props.scale : pdfWrapperElWidth / intialisedViewport.width;
  scale = Math.abs(scale);

  const viewport = page.getViewport({ scale });

  const devicePixelRatio = window.devicePixelRatio || 1;
  const backingStoreRatio = (
    context.webkitBackingStorePixelRatio
    || context.mozBackingStorePixelRatio
    || context.msBackingStorePixelRatio
    || context.oBackingStorePixelRatio
    || context.backingStorePixelRatio || 1
  );

  const ratio = devicePixelRatio / backingStoreRatio;

  if (devicePixelRatio !== backingStoreRatio) {
    canvas.width = props.scale ? (viewport.width * ratio) : (pdfWrapperElWidth * ratio);
    canvas.height = viewport.height * ratio;
    canvas.style.width = props.scale ? "" : "100%";
    canvas.style.height = `${viewport.height}px`;
  } else {
    canvas.width = props.scale ? viewport.width : pdfWrapperElWidth;
    canvas.height = viewport.height;
    canvas.style.width = "";
    canvas.style.height = "";
  }

  context.scale(ratio, ratio);
  try {
    await page.render({ canvasContext: context, viewport }).promise;
    log.info("Completed Render");
  } catch (error) {
    log.error("Render Error: ", error);
  }
}

async function renderPage(page: PDFPageProxy) {
  const pdfWrapperEl = pdfWrapperRef.value as HTMLElement;

  const pageWrapper = document.createElement("div");
  pageWrapper.classList.add("vue-pdf__wrapper");
  const divUuid = uuidv4();
  pageWrapper.id = `vue-pdf-${divUuid}`;

  const canvas = document.createElement("canvas");
  pageWrapper.appendChild(canvas);
  pdfWrapperEl?.replaceChildren(pageWrapper);

  const initViewport = page.getViewport({ scale: 1 });
  const context = canvas.getContext("2d");
  if (context) {
    await scaleCanvas(pdfWrapperEl, initViewport, page, canvas, context);
  } else {
    log.error("Failed to get 2D context");
  }
}

const resizeTracker = ref(0);

useResizeObserver(pdfWrapperRef, () => {
  resizeTracker.value += 1;
});

function initPdfWorker() {
  if (props.src) {
    log.info("initializing pdf worker");
    const srcCopy = new Uint8Array(props.src.byteLength);
    srcCopy.set(props.src);
    const loadingTask = createLoadingTask(srcCopy);
    loadingTask.promise.then(async (pdf: PDFDocumentProxy) => {
      if (thePDF) {
        await thePDF.destroy();
      }
      thePDF = pdf;
      numberOfPages.value = pdf.numPages;
      if (pageNumber.value <= numberOfPages.value) {
        const page = await thePDF.getPage(pageNumber.value);
        await renderPage(page);
      }
    }).catch((error: any) => {
      log.error(error);
    });
  }
}

const renderActive = ref(false);
const renderPending = ref(false);

async function combinedWatcher(newPageNumber: number) {
  if (renderActive.value) {
    renderPending.value = true;
    return;
  }

  try {
    renderActive.value = true;
    if (newPageNumber <= numberOfPages.value && thePDF) {
      const page = await toRaw(thePDF).getPage(newPageNumber);
      await renderPage(page);
    } else if (props.src) {
      initPdfWorker();
    }
  } finally {
    renderActive.value = false;
    if (renderPending.value) {
      renderPending.value = false;
      await combinedWatcher(pageNumber.value);
    }
  }
}

watchDebounced(
  [pageNumber, () => props.src, resizeTracker],
  async () => {
    await combinedWatcher(pageNumber.value);
  },
  {
    deep: true,
    immediate: true,
    debounce: 500,
    maxWait: 1000,
  },
);
</script>

<template>
  <div ref="parentWrapperRef" class="vue-pdf-main">
    <div ref="pdfWrapperRef" class="vue-pdf" />
  </div>
</template>

<style>
.vue-pdf__wrapper {
  position: relative;
}
</style>
