<script lang="ts" setup>
import type { PropType } from "vue";
import type { DataFlowNode, Store } from "~/model";
import type { Tab } from "~/utils/tab";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import KodexaCheckbox from "~/components/inputs/kodexa-checkbox.vue";
import KodexaTextArea from "~/components/inputs/kodexa-text-area.vue";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["step-deleted"]);

const { documentStores } = storeToRefs(appStore.projectStore);

const documentStore = computed(() => {
  const store = documentStores.value.find((store: Store) => props.node && store.ref === props.node.id?.split("//")[1]);
  if (!store?.metadata) {
    store.metadata = {
      type: "document",
    };
  }
  return store;
});

watch(
  () => documentStore.value,
  (newValue) => {
    appStore.projectStore.addToDocumentStoresToUpdate(newValue);
  },
  { deep: true },
);

const currentNavigation = ref({ ref: "general", name: "General" });
const tabs = [
  { ref: "general", name: "General" },
  { ref: "email", name: "Email" },
] as Tab[];

const { platformOverview } = storeToRefs(appStore.platformStore);

const emailAddress = computed(() => {
  if (documentStore.value) {
    return `${platformOverview.value.environment}-${documentStore.value._id}@${platformOverview.value.defaultEmailDomain}`;
  }
  return "";
});

const storePurposes = [
  { text: "Operation", id: "OPERATIONAL" },
  { text: "Training", id: "TRAINING" },
  { text: "Testing", id: "TESTING" },
];
</script>

<template>
  <div>
    <div v-if="documentStore" class="flex h-full flex-col bg-white dark:bg-gray-800">
      <div>
        <KodexaTabStrip :selected="currentNavigation" :items="tabs" :small="true" @selected="currentNavigation = $event" />
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextInput
              v-model="documentStore.name"
              name="name"
              type="text"
              autocomplete="documentStore-name"
              label="Name"
            />
          </div>
          <div class="mt-4 mb-4 text-gray-600">
            <KodexaClipboardable
              :content="documentStore.ref"
              :show-content="false"
              message="Copy Store Reference"
              name="ref"
              color="black"
              label="Ref"
            />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextArea
              v-model="documentStore.description"
              name="documentStore-description"
              label="Description"
              :rows="9"
            />
          </div>
          <div class="col-span-6 mb-2 sm:col-span-3">
            <KodexaDropDown
              v-model="documentStore.storePurpose"
              label="Store Purpose"
              name="storePurpose" :items="storePurposes"
            />
            <KodexaCheckbox
              v-model="documentStore.deleteProtection"
              class="mt-2"
              name="deleteProtection"
              label="Delete Protection"
              hint="Set the store with delete protection"
            />

            <KodexaCheckbox
              v-model="documentStore.highQualityPreview"
              name="highQualityPreview"
              label="High Quality Preview"
              hint="Use high-quality previews for this store"
            />
          </div>
          <KodexaResourceDelete :resource="documentStore" label="Document Store" @deleted="emit('step-deleted')" />
        </div>
        <div v-if="currentNavigation?.ref === 'email'" class="mx-2 mt-3">
          <div class="col-span-6 sm:col-span-3">
            <KodexaCheckbox
              v-model="documentStore.metadata.emailAvailable"
              name="enabled"
              label="Active"
              hint="This will activate the email notification"
            />
          </div>
          <div v-if="documentStore.metadata.emailAvailable" class="mx-2 mt-7">
            <div>
              You can email attachments to <KodexaClipboardable
                :content="emailAddress"
                name="email"
                color="blue"
                class="mt-4"
              /> to upload them to the store.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
