export const optionMetadata = {
  label: "Workspace",
  description: "Select a workspace from available workspaces",
  options: [
    {
      name: "workspaceId",
      label: "Workspace ID",
      type: "string",
      description: "ID of the selected workspace",
    },
    {
      name: "showDescription",
      label: "Show Description",
      type: "boolean",
      description: "Show workspace description",
      default: true,
    },
  ],
};
