<script setup lang="ts">
import type { AssignNextRequest, DataForm, DataFormAction, DocumentFamily, TaskDocumentFamily, TaskStatus } from "~/model";
import { storeToRefs } from "pinia";
import { ref, watchEffect } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { getProjectTaskStatuses } from "~/api/projects/projects";
import { assignNextTask } from "~/api/tasks/tasks";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import TaskManage from "~/components/tasks/task-manage.vue";
import router from "~/router/router";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

// Store and Route
const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
const { allDataForms, project } = storeToRefs(appStore.projectStore);
const { currentOrganization } = storeToRefs(appStore.organizationStore);
// Task State
const { hasChanges, currentTask, taskDocumentFamilies } = storeToRefs(appStore.taskStore);

const currentTab = ref(undefined);

// Initialize the first tab
watchEffect(() => {
  if (!currentTab.value) {
    currentTab.value = {
      ref: "taskManage",
      name: "Manage",
      singleWidget: {
        type: "taskManage",
      },
    };
  }
});

const dataForm: Ref<DataForm | undefined> = ref(undefined);
const documentFamilyMap = ref(new Map<string, DocumentFamily>());

const orderedTabs = ref([
  {
    ref: "taskManage",
    name: "Overview",
    singleWidget: {
      type: "taskManage",
    },
  },
]);

async function assignNextAvailable() {
  const request: AssignNextRequest = {
    releaseInMinutes: 10,
    filter: `project.id:'${project.value.id}' and status.statusType:'TODO' and assignee is null`,
  };
  const task = await updateHandler(assignNextTask(request), "Task assigned successfully");

  // We will navigate to the task
  if (task) {
    router.push({
      path: `/f/o/${currentOrganization.value?.id}/p/${task.project?.id}/t/${task.id}`,
    });
  } else {
    const noTasksDialog = createConfirmDialog(KodexaConfirm);
    await noTasksDialog.reveal({
      icon: "information-outline",
      title: "No Available Tasks",
      message: "There are no more available tasks to work on.",
      confirmText: "OK",
      confirmIcon: "check",
      showCancel: false,
    });
    await router.push({
      path: `/f/o/${currentOrganization.value?.id}/p/${project.value?.id}/home`,
    });
  }
}

watchEffect(async () => {
  if (!project.value && currentTask.value?.project.id) {
    await appStore.projectStore.loadProject(currentTask.value?.project.id);
    return;
  }

  // Reset to default tab
  orderedTabs.value = [
    {
      ref: "taskManage",
      name: "Overview",
      singleWidget: {
        type: "taskManage",
      },
    },
  ];

  // We want to grab the data form
  if (!currentTask.value?.template?.metadata?.forms) {
    return;
  }

  for (const form of currentTask.value.template.metadata.forms) {
    if (!currentTask.value?.template?.metadata?.workspaceId) {
      // no workspace id so we need to show an error tab
      orderedTabs.value.push({
        ref: "taskError",
        name: "Error - No Workspace",
        singleWidget: {
          type: "taskError",
        },
      });
      continue;
    }

    if (!currentWorkspaceId.value) {
      await appStore.workspaceStore.loadWorkspace(currentTask.value?.template?.metadata?.workspaceId);
    }

    // Lets load the document families into the workspace
    taskDocumentFamilies.value.forEach((taskDocumentFamily: TaskDocumentFamily) => {
      appStore.workspaceStore.addDocumentFamily(taskDocumentFamily.documentFamily);
    });

    dataForm.value = allDataForms.value?.find(availableForm => availableForm.ref === form.dataFormRef);

    // If the entry point to the data form is a document family, we want to create a tab for each document family
    if (dataForm.value && taskDocumentFamilies.value && dataForm.value.entrypoints?.includes("documentFamily")) {
      taskDocumentFamilies.value.forEach((taskDocumentFamily: TaskDocumentFamily) => {
        const tabId = `taskKiosk-${taskDocumentFamily.documentFamily?.id}-${dataForm.value?.ref}`;
        // Check if tab with this ref already exists before adding
        if (!orderedTabs.value.some(tab => tab.ref === tabId)) {
          orderedTabs.value.push({
            ref: tabId,
            name: `${dataForm.value?.name} - ${taskDocumentFamily.documentFamily?.path}`,
            singleWidget: {
              type: "taskKiosk",
            },
          });
          documentFamilyMap.value.set(tabId, taskDocumentFamily.documentFamily);
        }
      });
    } else if (dataForm.value) {
      // if it isn't a document family then we just want to add it once
      const tabId = `taskDataForm-${dataForm.value.ref}`;
      // Check if tab with this ref already exists before adding
      if (!orderedTabs.value.some(tab => tab.ref === tabId)) {
        orderedTabs.value.push({
          ref: tabId,
          name: dataForm.value.name,
          singleWidget: {
            type: "taskDataForm",
          },
        });
      }
    }
  }

  if (currentTask.value?.template?.metadata?.properties?.defaultToDataForm && orderedTabs.value.length > 1) {
    currentTab.value = orderedTabs.value[1];
  }
});

const taskStatuses = ref([] as TaskStatus[]);

watch(
  () => project.value?.id,
  async (newProjectId) => {
    if (newProjectId) {
      taskStatuses.value = await getProjectTaskStatuses(newProjectId);
    }
  },
  { immediate: true },
);

async function runAction(action: DataFormAction) {
  // if properties.attributes is set then we want to update the attributes of the task
  if (action.properties?.attributes) {
    await appStore.taskStore.updateTaskAttributes(action.properties.attributes);
  }

  // if properties.statusId is set then we want to update the status of the task
  if (action.properties?.statusId) {
    const status = taskStatuses.value.find(status => status.id === action.properties?.statusId);
    if (status) {
      await appStore.taskStore.updateTaskStatus(status);
    }
  }

  // We will save the changes to the task and return to the project tasks view
  await appStore.workspaceStore.saveWorkspaceObjects();
  await appStore.taskStore.saveChanges();

  if (action.properties?.automaticallyTakeNextTask) {
    await assignNextAvailable();
  }

  await router.push({
    path: `/f/o/${currentOrganization.value?.id}/p/${project.value?.id}/home`,
  });
}

function leaveTask() {
  router.push({
    path: `/f/o/${currentOrganization.value?.id}/p/${project.value?.id}/home`,
  });
}
</script>

<template>
  <div style="height: calc(100vh - 4rem)">
    <!-- Tabs Navigation -->
    <KodexaTabStrip
      :items="orderedTabs" :selected="currentTab" @selected="val => {
        currentTab = val
      } "
    >
      <div class="flex flex-col gap-4 mt-3 mr-4">
        <!-- Save and Cancel Buttons -->
        <div class="flex gap-2">
          <template v-for="form in currentTask.template.metadata.forms" :key="form.dataFormRef">
            <template v-for="action in form.actions" :key="action.label">
              <KodexaButton
                size="small"
                type="primary"
                :icon="action.properties.icon"
                :color="action.properties.color"
                @click="runAction(action)"
              >
                {{ action.label }}
              </KodexaButton>
            </template>
          </template>
          <KodexaButton
            v-if="!currentTask?.template?.metadata?.properties?.hideDefaultButtons"
            type="primary"
            size="small"
            icon="content-save"
            :disabled="!hasChanges"
            @click="appStore.taskStore.saveChanges()"
          >
            Save
          </KodexaButton>
          <KodexaButton
            v-if="!currentTask?.template?.metadata?.properties?.hideDefaultButtons"
            type="secondary"
            size="small"
            icon="cancel"
            :disabled="!hasChanges"
            @click="leaveTask()"
          >
            Cancel
          </KodexaButton>
        </div>
      </div>
    </KodexaTabStrip>

    <!-- Tab Content -->
    <div>
      <div v-if="currentTab?.ref === 'taskManage' && currentTask">
        <TaskManage
          class="mt-2 px-2"
        />
      </div>
      <div v-else-if="currentTab?.ref.startsWith('taskKiosk') && dataForm">
        <TaskKiosk :data-form="dataForm" :document-family="documentFamilyMap.get(currentTab.ref)" />
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
