<script setup lang="ts">
import type { Team } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListTeams } from "~/api/teams/teams";
import NewTeam from "~/components/dashboard/widgets/organization/teams/new-team.vue";
import appStore from "~/store";

const { currentSidebar } = storeToRefs(appStore.platformStore);

const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`teams-${currentOrganization.value?.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `organization.id: '${currentOrganization.value.id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = useListTeams(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

function setQuery(query: string) {
  gridHelper.setQuery(query);
  gridHelper.pageChangeHandler({ page: 1 });
}

const columns = [
  {
    title: "Name",
    field: "name",
    cell: "name",
    width: "500px",
  },
];
const showCreateTeam = ref(false);
const showEditTeam = ref(false);
const selectedTeam = ref<Team>();
const query = ref("");

function openNew() {
  showCreateTeam.value = true;
}

watch(currentSidebar, (newValue) => {
  if (newValue === undefined || newValue === "unset") {
    appStore.platformStore.setCurrentSidebar("teams");
  }
}, {
  immediate: true,
});

function editTeam(team: Team) {
  selectedTeam.value = team;
  showEditTeam.value = true;
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <KodexaToolbar>
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <KodexaTextInput
          id="query" v-debounce:300ms="setQuery" type="text" name="query" :value="query"
          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Query..."
        />
        <ToolbarSpacer />
        <KodexaButton id="createNewTeam" icon="plus" @click="openNew">
          New Team
        </KodexaButton>
      </KodexaToolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No teams found"
          />
        </GridNoRecords>
        <template #actions="{ props }">
          <KodexaButton
            id="editTeam"
            size="small"
            @click="editTeam(props.dataItem)"
          />
        </template>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <KodexaAvatar :object-instance="props.dataItem" object-type="team" size="w-16" />

              <div class="text-lg font-bold text-gray-900 cursor-pointer flex-0" @click="editTeam(props.dataItem)">
                {{ props.dataItem?.name }}
              </div>
              <div class="text-gray-700 text-xs">
                {{ props.dataItem.description }}
              </div>
            </div>
          </td>
        </template>
      </Grid>
      <NewTeam v-model="showCreateTeam" />
      <EditTeam v-if="selectedTeam" v-model="showEditTeam" :team="selectedTeam" />
    </div>
  </div>
</template>
