export const optionMetadata = {
  label: "Taxon",
  description: "Select a taxonomy classification",
  options: [
    {
      name: "onlyGroups",
      label: "Only Groups",
      type: "boolean",
      description: "Show only group taxons",
      default: false,
    },
    {
      name: "allowEmpty",
      label: "Allow Empty",
      type: "boolean",
      description: "Allow empty selection",
      default: true,
    },
    {
      name: "filterable",
      label: "Filterable",
      type: "boolean",
      description: "Enable filtering",
      default: true,
    },
  ],
};
