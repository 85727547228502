export const optionMetadata = {
  label: "Select",
  description: "Dropdown selection from a list of options",
  options: [
    {
      name: "items",
      label: "Items",
      type: "array",
      description: "List of selectable items",
    },
    {
      name: "multiple",
      label: "Multiple Selection",
      type: "boolean",
      description: "Allow multiple items to be selected",
      default: false,
    },
    {
      name: "textField",
      label: "Text Field",
      type: "string",
      description: "Property to display as item text",
      default: "text",
    },
    {
      name: "valueField",
      label: "Value Field",
      type: "string",
      description: "Property to use as item value",
      default: "value",
    },
  ],
};
