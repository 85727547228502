export const componentMetadata = {
  label: "Data Element",
  description: "Present a data element for editing",
  supportsChildren: false,
  defaultWidth: 3,
  defaultHeight: 2,
  options: [
    {
      label: "Label",
      name: "attributeLabel",
      type: "string",
      required: false,
    },
    {
      label: "Data Element",
      name: "taxon",
      type: "taxon",
      required: true,
    },
    { label: "Editor Options", name: "editorOptions", type: "object", groupOptions: [
      {
        label: "Hide Attribute Menu",
        name: "hideAttributeMenu",
        type: "boolean",
        description: "Hide the attribute menu",
        required: false,
        default: false,
      },
      {
        label: "Enable Checkbox",
        name: "isCheckbox",
        type: "boolean",
        description: "Enable checkbox input type",
        required: false,
        default: false,
      },
      {
        label: "On Check Value",
        name: "onCheckValue",
        type: "string",
        description: "Value when checkbox is checked",
        required: false,
        default: "None",
        showIf: "this.isCheckbox === true",
      },
      {
        label: "Source Date Format",
        name: "sourceDateFormat",
        type: "string",
        description: "Format for storing dates (e.g. yyyy-mm-dd)",
        required: false,
        default: "yyyy-mm-dd",
        showIf: "this.isMaskedText === true",
      },
    ] },

  ],
};
