<script lang="ts" setup>
import type { ProcessingEntity, ProcessingGroup } from "~/model";
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { createProcessingEntity, useListProcessingEntities } from "~/api/processing-entity/processing-entity";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps<{
  processingGroup: ProcessingGroup;
}>();

const emit = defineEmits<{
  select: [processingEntity: ProcessingEntity];
}>();

// Setup our grid helpers
const gridHelper = createGridHelper(`project-processing-entities-${props.processingGroup.id}`, {
  page: 1,
  pageSize: 10,
  filter: `processingGroup.id:'${props.processingGroup.id}'`,
  query: "",
  sort: "",
}, undefined, [{
  field: "name",
  dir: "asc",
}]);

const {
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  data,
  refetch,
} = useListProcessingEntities(gridQuery);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const rawQuery = ref("");

const processingEntitys = computed(() => {
  return data.value?.content || [];
});

function onSelect(processingEntity: ProcessingEntity) {
  emit("select", processingEntity);
}

async function addEntity() {
  const newEntity = {
    name: "New Entity",
    description: "",
    processingGroup: props.processingGroup,
  } as ProcessingEntity;
  await updateHandler(
    createProcessingEntity(newEntity),
    "Entity created successfully",
  );
  refetch();
}
</script>

<template>
  <div>
    <Combobox>
      <div class="bg-gray-50 dark:bg-gray-900 flex justify-between items-center">
        <KodexaTextInput
          v-model="rawQuery"
          name="filter"
          placeholder="Filter..."
          class="flex-grow mr-2"
        />
        <KodexaButton class="flex-shrink-0" size="small" @click="addEntity">
          Add Entity
        </KodexaButton>
      </div>
      <p>
        <KodexaArticle class="ml-1 mt-2" article-id="9136710" text="Learn more about processing entities" :slide="false" />
      </p>
      <div style="height: calc(100vh - 17rem); overflow: auto">
        <ComboboxOptions
          v-if="processingEntitys && processingEntitys.length > 0" static
          class="mx-1 scroll-py-10 scroll-pb-2 space-y-4 p-1 pb-2"
          style="height: calc(100vh - 12rem);"
        >
          <li v-if="processingEntitys.length > 0">
            <ul class="-mx-4 text-sm text-gray-700">
              <ComboboxOption
                v-for="processingEntity in processingEntitys" :key="processingEntitys.uuid" v-slot="{ active }"
                as="template"
              >
                <li
                  class="flex cursor-default select-none rounded-xl p-3" :class="[active && 'bg-gray-100']"
                  @click="onSelect(processingEntity)"
                >
                  <div class="ml-4 flex-auto">
                    <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ processingEntity.name }}
                    </p>
                  </div>
                </li>
              </ComboboxOption>
            </ul>
          </li>
        </ComboboxOptions>
      </div>

      <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
        <MaterialDesignIcon name="help" lass="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
        <p class="mt-4 font-semibold text-gray-900">
          Help with Labels
        </p>
        <p class="mt-2 text-gray-500">
          Use this tool to quickly search for users and projects across our entire platform. You can also use
          the search modifiers found in the footer below to limit the results to just users or projects.
        </p>
      </div>

      <div
        v-if="rawQuery !== '' && rawQuery !== '?' && filteredTagInstances.length === 0"
        class="px-6 py-14 text-center text-sm sm:px-14"
      >
        <MaterialDesignIcon name="alertBox" class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
        <p class="mt-4 font-semibold text-gray-900">
          No labels found
        </p>
        <p class="mt-2 text-gray-500">
          We couldn’t find anything with that term. Please try again.
        </p>
      </div>
    </Combobox>
  </div>
</template>

<style scoped>

</style>
