/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */

export type DataAttributeTypeAtCreation = typeof DataAttributeTypeAtCreation[keyof typeof DataAttributeTypeAtCreation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataAttributeTypeAtCreation = {
  STRING: 'STRING',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  CURRENCY: 'CURRENCY',
  URL: 'URL',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  PHONE_NUMBER: 'PHONE_NUMBER',
  SELECTION: 'SELECTION',
  PERCENTAGE: 'PERCENTAGE',
  FORMULA: 'FORMULA',
  DERIVED: 'DERIVED',
  SECTION: 'SECTION',
} as const;
