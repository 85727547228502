<script setup lang="ts">
import type { PropType } from "vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import type { Option } from "~/model";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const {
  isShown,
  localValue,
} = useConfigurationBase(props, emit);

const alertType = computed(() => props.item.properties?.type || "info");
</script>

<template>
  <div v-if="isShown" :class="`rounded-md bg-${alertType}-50 p-4`">
    <div class="flex">
      <div class="flex-shrink-0">
        <MaterialDesignIcon
          :name="`${alertType}-circle`"
          :class="`h-5 w-5 text-${alertType}-400`"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p :class="`text-sm text-${alertType}-700`">
          <KodexaMarkdown :content="localValue" />
        </p>
      </div>
    </div>
  </div>
</template>
