<script setup lang="ts">
import type { ProcessingGroup } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useCreateProcessingGroup } from "~/api/processing-group/processing-group";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

// Props and Emits
const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: boolean];
  "refetch-groups": [];
}>();

// Store
const { project } = storeToRefs(appStore.projectStore);

const createGroupMutation = useCreateProcessingGroup();

const newGroup = ref<Partial<ProcessingGroup>>({
  name: "",
  description: "",
  project: project.value,
});

const requiredFieldsError = ref<Record<string, string>>({});
const requiredFields = ["name", "description"] as const;

// Methods
function close() {
  newGroup.value = {
    name: "",
    description: "",
    project: project.value,
  };
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

function clearError(field: string) {
  if (requiredFieldsError.value[field]) {
    delete requiredFieldsError.value[field];
    requiredFieldsError.value = { ...requiredFieldsError.value };
  }
}

function validateFields() {
  requiredFields.forEach((field) => {
    if (!newGroup.value[field as keyof ProcessingGroup]) {
      requiredFieldsError.value[field] = "This field is required";
    } else {
      delete requiredFieldsError.value[field];
    }
  });

  return Object.keys(requiredFieldsError.value).length === 0;
}

async function createNewGroup() {
  if (!validateFields()) {
    return;
  }

  try {
    await createGroupMutation.mutateAsync({
      data: newGroup.value as ProcessingGroup,
    });

    await updateHandler(
      Promise.resolve(),
      "Processing Group created successfully",
    );

    emit("refetch-groups");
    close();
  } catch (error) {
    console.error("Error creating processing group:", error);
    await updateHandler(
      Promise.reject(error),
      "Failed to create processing group",
    );
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <!-- Header -->
                  <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                          New Processing Group
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Get started by filling in the information below to create a new processing group.
                        </p>
                      </div>
                      <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                        <div class="flex justify-end space-x-3">
                          <KodexaButton
                            id="cancelNewGroup"
                            icon="cancel"
                            type="secondary"
                            size="small"
                            @click="close"
                          >
                            Cancel
                          </KodexaButton>
                          <KodexaButton
                            id="createNewGroup"
                            icon="plus"
                            type="primary"
                            size="small"
                            :loading="createGroupMutation.isLoading"
                            @click="createNewGroup"
                          >
                            Create
                          </KodexaButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Form -->
                  <div class="flex-1 overflow-y-auto">
                    <div class="space-y-6 p-4 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:p-6">
                      <!-- Name -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="name"
                          class="block text-sm font-medium text-gray-900 sm:mt-1.5"
                        >
                          Group Name
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="name"
                            v-model="newGroup.name"
                            name="name"
                            :errors="requiredFieldsError"
                            @input="clearError('name')"
                          />
                        </div>
                      </div>

                      <!-- Description -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 sm:mt-1.5"
                        >
                          Description
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextArea
                            id="description"
                            v-model="newGroup.description"
                            name="description"
                            :rows="4"
                            :errors="requiredFieldsError"
                            @input="clearError('description')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
