/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateProductSubscription400,
  DeleteProductSubscription400,
  ExceptionResponse,
  GetChangeSequenceForProductSubscription400,
  GetProductSubscription400,
  ListProductSubscriptions400,
  ListProductSubscriptionsParams,
  PageProductSubscription,
  ProductSubscription,
  ThrowableProblem,
  UpdateProductSubscription400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getProductSubscription = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProductSubscription>(
      {url: `/api/productSubscriptions/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProductSubscriptionQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productSubscriptions',id] as const;
    }

    
export const getGetProductSubscriptionQueryOptions = <TData = Awaited<ReturnType<typeof getProductSubscription>>, TError = ErrorType<GetProductSubscription400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductSubscription>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProductSubscriptionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductSubscription>>> = ({ signal }) => getProductSubscription(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductSubscription>>, TError, TData> 
}

export type GetProductSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getProductSubscription>>>
export type GetProductSubscriptionQueryError = ErrorType<GetProductSubscription400 | ThrowableProblem | ExceptionResponse>



export function useGetProductSubscription<TData = Awaited<ReturnType<typeof getProductSubscription>>, TError = ErrorType<GetProductSubscription400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductSubscription>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetProductSubscriptionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateProductSubscription = (
    id: MaybeRef<string>,
    productSubscription: MaybeRef<ProductSubscription>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
productSubscription = unref(productSubscription);
      
      return customAxios<ProductSubscription>(
      {url: `/api/productSubscriptions/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productSubscription
    },
      options);
    }
  


export const getUpdateProductSubscriptionMutationOptions = <TError = ErrorType<UpdateProductSubscription400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductSubscription>>, TError,{id: string;data: ProductSubscription}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProductSubscription>>, TError,{id: string;data: ProductSubscription}, TContext> => {
    
const mutationKey = ['updateProductSubscription'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProductSubscription>>, {id: string;data: ProductSubscription}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProductSubscription(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProductSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof updateProductSubscription>>>
    export type UpdateProductSubscriptionMutationBody = ProductSubscription
    export type UpdateProductSubscriptionMutationError = ErrorType<UpdateProductSubscription400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateProductSubscription = <TError = ErrorType<UpdateProductSubscription400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductSubscription>>, TError,{id: string;data: ProductSubscription}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateProductSubscription>>,
        TError,
        {id: string;data: ProductSubscription},
        TContext
      > => {

      const mutationOptions = getUpdateProductSubscriptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProductSubscription = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/productSubscriptions/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProductSubscriptionMutationOptions = <TError = ErrorType<DeleteProductSubscription400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductSubscription>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProductSubscription>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteProductSubscription'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductSubscription>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProductSubscription(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProductSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductSubscription>>>
    
    export type DeleteProductSubscriptionMutationError = ErrorType<DeleteProductSubscription400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteProductSubscription = <TError = ErrorType<DeleteProductSubscription400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductSubscription>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteProductSubscription>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteProductSubscriptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProductSubscriptions = (
    params?: MaybeRef<ListProductSubscriptionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProductSubscription>(
      {url: `/api/productSubscriptions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProductSubscriptionsQueryKey = (params?: MaybeRef<ListProductSubscriptionsParams>,) => {
    return ['api','productSubscriptions', ...(params ? [params]: [])] as const;
    }

    
export const getListProductSubscriptionsQueryOptions = <TData = Awaited<ReturnType<typeof listProductSubscriptions>>, TError = ErrorType<ListProductSubscriptions400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListProductSubscriptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductSubscriptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProductSubscriptionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProductSubscriptions>>> = ({ signal }) => listProductSubscriptions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProductSubscriptions>>, TError, TData> 
}

export type ListProductSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof listProductSubscriptions>>>
export type ListProductSubscriptionsQueryError = ErrorType<ListProductSubscriptions400 | ThrowableProblem | ExceptionResponse>



export function useListProductSubscriptions<TData = Awaited<ReturnType<typeof listProductSubscriptions>>, TError = ErrorType<ListProductSubscriptions400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListProductSubscriptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductSubscriptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListProductSubscriptionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProductSubscription = (
    productSubscription: MaybeRef<ProductSubscription>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      productSubscription = unref(productSubscription);
      
      return customAxios<ProductSubscription>(
      {url: `/api/productSubscriptions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productSubscription, signal
    },
      options);
    }
  


export const getCreateProductSubscriptionMutationOptions = <TError = ErrorType<CreateProductSubscription400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductSubscription>>, TError,{data: ProductSubscription}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProductSubscription>>, TError,{data: ProductSubscription}, TContext> => {
    
const mutationKey = ['createProductSubscription'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductSubscription>>, {data: ProductSubscription}> = (props) => {
          const {data} = props ?? {};

          return  createProductSubscription(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProductSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof createProductSubscription>>>
    export type CreateProductSubscriptionMutationBody = ProductSubscription
    export type CreateProductSubscriptionMutationError = ErrorType<CreateProductSubscription400 | ThrowableProblem | ExceptionResponse>

    export const useCreateProductSubscription = <TError = ErrorType<CreateProductSubscription400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductSubscription>>, TError,{data: ProductSubscription}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createProductSubscription>>,
        TError,
        {data: ProductSubscription},
        TContext
      > => {

      const mutationOptions = getCreateProductSubscriptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProductSubscription = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/productSubscriptions/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProductSubscriptionQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productSubscriptions',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProductSubscriptionQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>, TError = ErrorType<GetChangeSequenceForProductSubscription400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProductSubscriptionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>> = ({ signal }) => getChangeSequenceForProductSubscription(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>, TError, TData> 
}

export type GetChangeSequenceForProductSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>>
export type GetChangeSequenceForProductSubscriptionQueryError = ErrorType<GetChangeSequenceForProductSubscription400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForProductSubscription<TData = Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>, TError = ErrorType<GetChangeSequenceForProductSubscription400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductSubscription>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForProductSubscriptionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



