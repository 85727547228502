<script setup lang="ts">
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import { GridLayout } from "grid-layout-plus";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import EditorDataFormPanel from "./data-form-editor/editor-data-form-panel.vue";
import EditorDataFormToolbar from "./data-form-editor/editor-data-form-toolbar.vue";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});

const viewId = props.params.api.id.replace("view-", "") as string;
const dataFormEditorStore = createDataFormEditorStore(viewId)();
const { layout, panels, selectedPanel } = storeToRefs(dataFormEditorStore);
const isReady = ref(false);

// Match the 12-column layout from kodexa-data-form-view
const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };
const useDataFormViewer = createDataFormViewerStore(viewId);
const { dataForm } = storeToRefs(useDataFormViewer);

function handleLayoutUpdate(newLayout: any) {
  dataFormEditorStore.updateLayout(newLayout);
}

function handleOutsideClick(e: MouseEvent) {
  if ((e.target as HTMLElement).classList.contains("grid-container")) {
    dataFormEditorStore.selectPanel(null);
  }
}

onMounted(() => {
  if (dataForm.value?.cards) {
    dataFormEditorStore.initialize(dataForm.value.cards);
  }
  isReady.value = true;
});

const localLayout = computed({
  get: () => layout.value,
  set: value => handleLayoutUpdate(value),
});

function updateLayout(e) {
  dataFormEditorStore.updateLayout(e);
}
</script>

<template>
  <div class="w-full h-full relative overflow-hidden">
    <EditorDataFormToolbar :view-id="viewId" :data-form="dataForm" />
    <div
      class="absolute inset-x-0 bottom-0 px-5 overflow-auto grid-container"
      style="top: 60px; padding-top: 0; padding-bottom: 20px;"
      @click="handleOutsideClick"
    >
      <GridLayout
        v-model:layout="localLayout"
        :cols="cols"
        :row-height="70"
        :margin="[5, 5]"
        :min-h="2"
        use-css-transforms
        :vertical-compact="false"
        @layout-updated="updateLayout"
      >
        <template #item="{ item }">
          <EditorDataFormPanel
            :panel="panels.find(p => p.id === item.i)!"
            :is-selected="selectedPanel?.id === item.i"
            :view-id="viewId"
            @select="dataFormEditorStore.selectPanel"
            @delete="dataFormEditorStore.deletePanel"
          />
        </template>
      </GridLayout>
    </div>
  </div>
</template>

<style scoped>
.empty-state {
  @apply flex flex-col items-center justify-center h-full gap-4;
}

.add-button {
  @apply px-6 py-3 bg-[#68B984] text-white border-none rounded cursor-pointer font-medium transition-colors;
}

.add-button:hover {
  @apply bg-[#5aa873];
}
</style>
