/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  Assistant,
  AssistantMemory,
  CreateAssistant400,
  DeleteAssistant400,
  ExceptionResponse,
  GetAssistant400,
  GetAssistantMemory400,
  GetChangeSequenceForAssistant400,
  ListAssistants400,
  ListAssistantsParams,
  PageAssistant,
  ThrowableProblem,
  UpdateAssistant400,
  UpdateAssistantMemory400
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getAssistant = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Assistant>(
      {url: `/api/assistants/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assistants',id] as const;
    }

    
export const getGetAssistantQueryOptions = <TData = Awaited<ReturnType<typeof getAssistant>>, TError = ErrorType<GetAssistant400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistant>>> = ({ signal }) => getAssistant(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistant>>, TError, TData> 
}

export type GetAssistantQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistant>>>
export type GetAssistantQueryError = ErrorType<GetAssistant400 | ThrowableProblem | ExceptionResponse>



export function useGetAssistant<TData = Awaited<ReturnType<typeof getAssistant>>, TError = ErrorType<GetAssistant400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAssistantQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateAssistant = (
    id: MaybeRef<string>,
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/assistants/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getUpdateAssistantMutationOptions = <TError = ErrorType<UpdateAssistant400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistant>>, TError,{id: string;data: Assistant}, TContext> => {
    
const mutationKey = ['updateAssistant'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistant>>, {id: string;data: Assistant}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAssistant(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistant>>>
    export type UpdateAssistantMutationBody = Assistant
    export type UpdateAssistantMutationError = ErrorType<UpdateAssistant400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateAssistant = <TError = ErrorType<UpdateAssistant400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateAssistant>>,
        TError,
        {id: string;data: Assistant},
        TContext
      > => {

      const mutationOptions = getUpdateAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteAssistant = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/assistants/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssistantMutationOptions = <TError = ErrorType<DeleteAssistant400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistant>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssistant>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteAssistant'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssistant>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteAssistant(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssistant>>>
    
    export type DeleteAssistantMutationError = ErrorType<DeleteAssistant400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteAssistant = <TError = ErrorType<DeleteAssistant400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistant>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteAssistant>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get an assistant memory key
 */
export const getAssistantMemory = (
    id: MaybeRef<string>,
    key: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
key = unref(key);
      
      return customAxios<AssistantMemory>(
      {url: `/api/assistants/${id}/memory/${key}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantMemoryQueryKey = (id: MaybeRef<string>,
    key: MaybeRef<string>,) => {
    return ['api','assistants',id,'memory',key] as const;
    }

    
export const getGetAssistantMemoryQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantMemory>>, TError = ErrorType<GetAssistantMemory400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>,
    key: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantMemoryQueryKey(id,key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantMemory>>> = ({ signal }) => getAssistantMemory(id,key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(key))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantMemory>>, TError, TData> 
}

export type GetAssistantMemoryQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantMemory>>>
export type GetAssistantMemoryQueryError = ErrorType<GetAssistantMemory400 | ThrowableProblem | ExceptionResponse>



export function useGetAssistantMemory<TData = Awaited<ReturnType<typeof getAssistantMemory>>, TError = ErrorType<GetAssistantMemory400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>,
    key: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAssistantMemoryQueryOptions(id,key,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create or update a key memory for the assistant
 */
export const updateAssistantMemory = (
    id: MaybeRef<string>,
    key: MaybeRef<string>,
    assistantMemory: MaybeRef<AssistantMemory>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
key = unref(key);
assistantMemory = unref(assistantMemory);
      
      return customAxios<AssistantMemory>(
      {url: `/api/assistants/${id}/memory/${key}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantMemory
    },
      options);
    }
  


export const getUpdateAssistantMemoryMutationOptions = <TError = ErrorType<UpdateAssistantMemory400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantMemory>>, TError,{id: string;key: string;data: AssistantMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantMemory>>, TError,{id: string;key: string;data: AssistantMemory}, TContext> => {
    
const mutationKey = ['updateAssistantMemory'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantMemory>>, {id: string;key: string;data: AssistantMemory}> = (props) => {
          const {id,key,data} = props ?? {};

          return  updateAssistantMemory(id,key,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantMemoryMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantMemory>>>
    export type UpdateAssistantMemoryMutationBody = AssistantMemory
    export type UpdateAssistantMemoryMutationError = ErrorType<UpdateAssistantMemory400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateAssistantMemory = <TError = ErrorType<UpdateAssistantMemory400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantMemory>>, TError,{id: string;key: string;data: AssistantMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateAssistantMemory>>,
        TError,
        {id: string;key: string;data: AssistantMemory},
        TContext
      > => {

      const mutationOptions = getUpdateAssistantMemoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listAssistants = (
    params?: MaybeRef<ListAssistantsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAssistant>(
      {url: `/api/assistants`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantsQueryKey = (params?: MaybeRef<ListAssistantsParams>,) => {
    return ['api','assistants', ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantsQueryOptions = <TData = Awaited<ReturnType<typeof listAssistants>>, TError = ErrorType<ListAssistants400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListAssistantsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistants>>> = ({ signal }) => listAssistants(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistants>>, TError, TData> 
}

export type ListAssistantsQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistants>>>
export type ListAssistantsQueryError = ErrorType<ListAssistants400 | ThrowableProblem | ExceptionResponse>



export function useListAssistants<TData = Awaited<ReturnType<typeof listAssistants>>, TError = ErrorType<ListAssistants400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListAssistantsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAssistantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createAssistant = (
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/assistants`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistant, signal
    },
      options);
    }
  


export const getCreateAssistantMutationOptions = <TError = ErrorType<CreateAssistant400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistant>>, TError,{data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createAssistant>>, TError,{data: Assistant}, TContext> => {
    
const mutationKey = ['createAssistant'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssistant>>, {data: Assistant}> = (props) => {
          const {data} = props ?? {};

          return  createAssistant(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof createAssistant>>>
    export type CreateAssistantMutationBody = Assistant
    export type CreateAssistantMutationError = ErrorType<CreateAssistant400 | ThrowableProblem | ExceptionResponse>

    export const useCreateAssistant = <TError = ErrorType<CreateAssistant400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistant>>, TError,{data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createAssistant>>,
        TError,
        {data: Assistant},
        TContext
      > => {

      const mutationOptions = getCreateAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForAssistant = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/assistants/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForAssistantQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assistants',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForAssistantQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError = ErrorType<GetChangeSequenceForAssistant400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForAssistantQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>> = ({ signal }) => getChangeSequenceForAssistant(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError, TData> 
}

export type GetChangeSequenceForAssistantQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>>
export type GetChangeSequenceForAssistantQueryError = ErrorType<GetChangeSequenceForAssistant400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForAssistant<TData = Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError = ErrorType<GetChangeSequenceForAssistant400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForAssistantQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



