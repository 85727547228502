<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { AttributeEditorOptions } from "../attribute-editor-options";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import { parseLocalDate } from "~/utils/date";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  maskedTextOptions: {
    type: Object as PropType<AttributeEditorOptions>,
    required: false,
    default: () => {
      return {} as AttributeEditorOptions;
    },
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dateFormat = computed(() => {
  if (props.tagMetadata?.taxon?.typeFeatures?.normalizeDate) {
    return props.tagMetadata.taxon.typeFeatures.dateFormat as string;
  } else {
    return "MM/dd/yyyy";
  }
});

const dateValue = computed({
  get() {
    return props.attribute?.dateValue;
  },
  set(value) {
    if (!value) {
      emit("update", {
        ...props.attribute,
        dateValue: null,
      });
      return;
    }

    if (value !== props.attribute?.dateValue) {
      emit("update", {
        ...props.attribute,
        dateValue: value,
      });
    }
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const { style, conditionalValidationIcon, valid } = storeToRefs(dataViewHelper);

const displayDateValue = computed(() => {
  if (dateValue.value) {
    if (typeof dateValue.value === "string") {
      return dateValue.value;
    } else if (dateValue.value instanceof Date) {
      return DateTime.fromJSDate(dateValue.value).toFormat(dateFormat.value);
    }
  }
  return "";
});

const textInputOptions = {
  enterSubmit: true,
  tabSubmit: true,
  openMenu: "toggle",
  format: dateFormat.value,
  selectOnFocus: true,
  escClose: true,
};
</script>

<template>
  <div style="width: 700px" @click="focus">
    <template v-if="!editable">
      <KodexaNonEditableValue
        v-if="!editable"
        :style="style" :conditional-validation-icon="conditionalValidationIcon"
        :string-value="displayDateValue"
      />
    </template>
    <template v-else>
      <VueDatePicker
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="dateValue"
        model-type="iso"
        utc="preserve"
        auto-apply
        hide-input-icon
        :enable-time-picker="false"
        :text-input="textInputOptions"
        :name="`kdx-attribute-${props.attribute.uuid}`"
        class="kodexa-input"
        :class="{ 'invalid-date': !valid }"
        :format="dateFormat"
        :clearable="false"
        :disabled="props.dataObject?.documentFamily.locked === true"
        :valid="valid"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      >
        <template #prefix>
          <MaterialDesignIcon
            v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon"
            :color="conditionalValidationIcon.color"
          />
        </template>
      </VueDatePicker>
    </template>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.invalid-date :deep(.dp__input) {
  border-color: red !important;
}
</style>
