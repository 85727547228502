<script setup lang="ts">
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import { storeToRefs } from "pinia";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import { createExecutionStore } from "~/store/useExecutionStore";
import { useIntercom } from "~/store/useIntercom";
import { log } from "~/utils/logger";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});
const viewId = props.params.api.id as string;
const useDocumentViewStore = createDocumentViewerStore(viewId);

const {
  documentFamily,
  documentViewer,
  kddbDocument,
} = storeToRefs(useDocumentViewStore);

useIntercom().trackEvent("opened-document-viewer");

const completed = computed(() => {
  if (documentViewer.value && documentViewer.value.executionId) {
    const executionStore = createExecutionStore(documentViewer.value.executionId);
    const _completed = executionStore.completed && executionStore.error === undefined;
    if (_completed) {
      useDocumentViewStore.loadDocument().then(() => {
        log.info("Loaded document from execution");
      });
    }
    return _completed && kddbDocument !== undefined;
  }
  return true;
});
</script>

<template>
  <div class="p-2">
    <div v-if="documentFamily && completed === true" style="height: calc(100vh - 7rem); overflow-y: hidden; width: 100%; position: relative">
      <KodexaDocumentSpatial
        v-if="documentFamily.mixins?.indexOf('spatial') > -1" :key="`${viewId}spatial`"
        :view-id="viewId"
      />
      <KodexaDocumentWorkbook
        v-else-if="documentFamily.mixins?.indexOf('workbook') > -1" :key="`${viewId}workbook`"
        :view-id="viewId"
      />
      <div v-else style="height: calc(100vh - 12rem); overflow-y: scroll; width: 100%; position: relative">
        <div class="mt-20 text-center">
          <MaterialDesignIcon name="alertBox" class="mx-auto size-12 text-red-600" aria-hidden="true" />
          <h3 class="mt-4 text-sm font-semibold text-gray-900">
            Not Parsed
          </h3>
          <p class="mt-2 text-sm text-gray-500">
            This document is not in a format that can be displayed in the viewer, make sure that it has been parsed.
          </p>
        </div>
      </div>
    </div>
    <div
      v-else-if="documentViewer && documentViewer.executionId && !completed"
      style="height: calc(100vh - 7rem); overflow-y: scroll; width: 100%; position: relative"
    >
      <KodexaExecutionView :key="viewId" :view-id="viewId" />
    </div>
    <div v-else style="height: calc(100vh - 220px); overflow-y: scroll; width: 100%; position: relative" />
  </div>
</template>
