<script setup lang="ts">
import type { PropType } from "vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import type { Assistant, ContentObject, Option } from "~/model";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div>
    <div class="my-1 mr-1">
      <p>{{ item.label ? item.label : item.name }}</p>
      <KodexaTextInput
        v-if="!item.properties?.lines"
        v-model="localValue" class="border-0" :name="item.name" :hint="item.description"
        :type="password ? 'password' : 'text' "
      />
      <KodexaTextArea
        v-if="item.properties?.lines" v-model="localValue" :name="item.name" :rows="item.properties.lines"
        :hint="item.description" :type="password ? 'password' : 'text'"
      />
    </div>
  </div>
</template>

<style>

</style>
