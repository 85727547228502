/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AssignNextRequest,
  AssignNextTask400,
  CreateTask400,
  CreateTaskWithRequest400,
  DeleteTask400,
  ExceptionResponse,
  GetChangeSequenceForTask400,
  GetTask400,
  GetTaskTagForTask400,
  ListTasks400,
  ListTasksParams,
  NewTaskRequest,
  PageTask,
  RemoveAssignee400,
  RemoveTaskStatus400,
  Task,
  TaskStatus,
  TaskTag,
  ThrowableProblem,
  UpdateAssignee400,
  UpdateTask400,
  UpdateTaskStatus400,
  UpdateTaskTagsForTask400,
  User
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','tasks',id] as const;
    }

    
export const getGetTaskQueryOptions = <TData = Awaited<ReturnType<typeof getTask>>, TError = ErrorType<GetTask400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTask>>> = ({ signal }) => getTask(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData> 
}

export type GetTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getTask>>>
export type GetTaskQueryError = ErrorType<GetTask400 | ThrowableProblem | ExceptionResponse>



export function useGetTask<TData = Awaited<ReturnType<typeof getTask>>, TError = ErrorType<GetTask400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTaskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateTask = (
    id: MaybeRef<string>,
    task: MaybeRef<Task>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
task = unref(task);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: task
    },
      options);
    }
  


export const getUpdateTaskMutationOptions = <TError = ErrorType<UpdateTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTask>>, TError,{id: string;data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTask>>, TError,{id: string;data: Task}, TContext> => {
    
const mutationKey = ['updateTask'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTask>>, {id: string;data: Task}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTask(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateTask>>>
    export type UpdateTaskMutationBody = Task
    export type UpdateTaskMutationError = ErrorType<UpdateTask400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateTask = <TError = ErrorType<UpdateTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTask>>, TError,{id: string;data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTask>>,
        TError,
        {id: string;data: Task},
        TContext
      > => {

      const mutationOptions = getUpdateTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/tasks/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskMutationOptions = <TError = ErrorType<DeleteTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTask>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTask>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['deleteTask'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTask>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTask(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTask>>>
    
    export type DeleteTaskMutationError = ErrorType<DeleteTask400 | ThrowableProblem | ExceptionResponse>

    export const useDeleteTask = <TError = ErrorType<DeleteTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTask>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteTask>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the tags on a task
 */
export const getTaskTagForTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<TaskTag[]>(
      {url: `/api/tasks/${id}/tags`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskTagForTaskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','tasks',id,'tags'] as const;
    }

    
export const getGetTaskTagForTaskQueryOptions = <TData = Awaited<ReturnType<typeof getTaskTagForTask>>, TError = ErrorType<GetTaskTagForTask400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTagForTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskTagForTaskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskTagForTask>>> = ({ signal }) => getTaskTagForTask(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskTagForTask>>, TError, TData> 
}

export type GetTaskTagForTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskTagForTask>>>
export type GetTaskTagForTaskQueryError = ErrorType<GetTaskTagForTask400 | ThrowableProblem | ExceptionResponse>



export function useGetTaskTagForTask<TData = Awaited<ReturnType<typeof getTaskTagForTask>>, TError = ErrorType<GetTaskTagForTask400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskTagForTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTaskTagForTaskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the tags on a task
 */
export const updateTaskTagsForTask = (
    id: MaybeRef<string>,
    taskTag: MaybeRef<TaskTag[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taskTag = unref(taskTag);
      
      return customAxios<TaskTag[]>(
      {url: `/api/tasks/${id}/tags`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taskTag
    },
      options);
    }
  


export const getUpdateTaskTagsForTaskMutationOptions = <TError = ErrorType<UpdateTaskTagsForTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskTagsForTask>>, TError,{id: string;data: TaskTag[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskTagsForTask>>, TError,{id: string;data: TaskTag[]}, TContext> => {
    
const mutationKey = ['updateTaskTagsForTask'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskTagsForTask>>, {id: string;data: TaskTag[]}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTaskTagsForTask(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskTagsForTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskTagsForTask>>>
    export type UpdateTaskTagsForTaskMutationBody = TaskTag[]
    export type UpdateTaskTagsForTaskMutationError = ErrorType<UpdateTaskTagsForTask400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateTaskTagsForTask = <TError = ErrorType<UpdateTaskTagsForTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskTagsForTask>>, TError,{id: string;data: TaskTag[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTaskTagsForTask>>,
        TError,
        {id: string;data: TaskTag[]},
        TContext
      > => {

      const mutationOptions = getUpdateTaskTagsForTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the status of the task
 */
export const updateTaskStatus = (
    id: MaybeRef<string>,
    taskStatus: MaybeRef<TaskStatus>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taskStatus = unref(taskStatus);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}/status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taskStatus
    },
      options);
    }
  


export const getUpdateTaskStatusMutationOptions = <TError = ErrorType<UpdateTaskStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskStatus>>, TError,{id: string;data: TaskStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskStatus>>, TError,{id: string;data: TaskStatus}, TContext> => {
    
const mutationKey = ['updateTaskStatus'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskStatus>>, {id: string;data: TaskStatus}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTaskStatus(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskStatus>>>
    export type UpdateTaskStatusMutationBody = TaskStatus
    export type UpdateTaskStatusMutationError = ErrorType<UpdateTaskStatus400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateTaskStatus = <TError = ErrorType<UpdateTaskStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskStatus>>, TError,{id: string;data: TaskStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTaskStatus>>,
        TError,
        {id: string;data: TaskStatus},
        TContext
      > => {

      const mutationOptions = getUpdateTaskStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove the task status
 */
export const removeTaskStatus = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}/status`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveTaskStatusMutationOptions = <TError = ErrorType<RemoveTaskStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTaskStatus>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeTaskStatus>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['removeTaskStatus'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeTaskStatus>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  removeTaskStatus(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveTaskStatusMutationResult = NonNullable<Awaited<ReturnType<typeof removeTaskStatus>>>
    
    export type RemoveTaskStatusMutationError = ErrorType<RemoveTaskStatus400 | ThrowableProblem | ExceptionResponse>

    export const useRemoveTaskStatus = <TError = ErrorType<RemoveTaskStatus400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTaskStatus>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof removeTaskStatus>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getRemoveTaskStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the assignee of the task
 */
export const updateAssignee = (
    id: MaybeRef<string>,
    user: MaybeRef<NonReadonly<User>>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
user = unref(user);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}/assignee`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: user
    },
      options);
    }
  


export const getUpdateAssigneeMutationOptions = <TError = ErrorType<UpdateAssignee400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssignee>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssignee>>, TError,{id: string;data: NonReadonly<User>}, TContext> => {
    
const mutationKey = ['updateAssignee'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssignee>>, {id: string;data: NonReadonly<User>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAssignee(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAssigneeMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssignee>>>
    export type UpdateAssigneeMutationBody = NonReadonly<User>
    export type UpdateAssigneeMutationError = ErrorType<UpdateAssignee400 | ThrowableProblem | ExceptionResponse>

    export const useUpdateAssignee = <TError = ErrorType<UpdateAssignee400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssignee>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateAssignee>>,
        TError,
        {id: string;data: NonReadonly<User>},
        TContext
      > => {

      const mutationOptions = getUpdateAssigneeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove the task assignee
 */
export const removeAssignee = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}/assignee`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveAssigneeMutationOptions = <TError = ErrorType<RemoveAssignee400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeAssignee>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeAssignee>>, TError,{id: string}, TContext> => {
    
const mutationKey = ['removeAssignee'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeAssignee>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  removeAssignee(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveAssigneeMutationResult = NonNullable<Awaited<ReturnType<typeof removeAssignee>>>
    
    export type RemoveAssigneeMutationError = ErrorType<RemoveAssignee400 | ThrowableProblem | ExceptionResponse>

    export const useRemoveAssignee = <TError = ErrorType<RemoveAssignee400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeAssignee>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof removeAssignee>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getRemoveAssigneeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTasks = (
    params?: MaybeRef<ListTasksParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTask>(
      {url: `/api/tasks`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTasksQueryKey = (params?: MaybeRef<ListTasksParams>,) => {
    return ['api','tasks', ...(params ? [params]: [])] as const;
    }

    
export const getListTasksQueryOptions = <TData = Awaited<ReturnType<typeof listTasks>>, TError = ErrorType<ListTasks400 | ThrowableProblem | ExceptionResponse>>(params?: MaybeRef<ListTasksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTasks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTasks>>> = ({ signal }) => listTasks(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTasks>>, TError, TData> 
}

export type ListTasksQueryResult = NonNullable<Awaited<ReturnType<typeof listTasks>>>
export type ListTasksQueryError = ErrorType<ListTasks400 | ThrowableProblem | ExceptionResponse>



export function useListTasks<TData = Awaited<ReturnType<typeof listTasks>>, TError = ErrorType<ListTasks400 | ThrowableProblem | ExceptionResponse>>(
 params?: MaybeRef<ListTasksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTasks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTask = (
    task: MaybeRef<Task>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      task = unref(task);
      
      return customAxios<Task>(
      {url: `/api/tasks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: task, signal
    },
      options);
    }
  


export const getCreateTaskMutationOptions = <TError = ErrorType<CreateTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTask>>, TError,{data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTask>>, TError,{data: Task}, TContext> => {
    
const mutationKey = ['createTask'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTask>>, {data: Task}> = (props) => {
          const {data} = props ?? {};

          return  createTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createTask>>>
    export type CreateTaskMutationBody = Task
    export type CreateTaskMutationError = ErrorType<CreateTask400 | ThrowableProblem | ExceptionResponse>

    export const useCreateTask = <TError = ErrorType<CreateTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTask>>, TError,{data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTask>>,
        TError,
        {data: Task},
        TContext
      > => {

      const mutationOptions = getCreateTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a new task with the given request (to include document families)
 */
export const createTaskWithRequest = (
    newTaskRequest: MaybeRef<NewTaskRequest>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      newTaskRequest = unref(newTaskRequest);
      
      return customAxios<Task>(
      {url: `/api/tasks/createTaskWithRequest`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newTaskRequest, signal
    },
      options);
    }
  


export const getCreateTaskWithRequestMutationOptions = <TError = ErrorType<CreateTaskWithRequest400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskWithRequest>>, TError,{data: NewTaskRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTaskWithRequest>>, TError,{data: NewTaskRequest}, TContext> => {
    
const mutationKey = ['createTaskWithRequest'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTaskWithRequest>>, {data: NewTaskRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTaskWithRequest(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTaskWithRequestMutationResult = NonNullable<Awaited<ReturnType<typeof createTaskWithRequest>>>
    export type CreateTaskWithRequestMutationBody = NewTaskRequest
    export type CreateTaskWithRequestMutationError = ErrorType<CreateTaskWithRequest400 | ThrowableProblem | ExceptionResponse>

    export const useCreateTaskWithRequest = <TError = ErrorType<CreateTaskWithRequest400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskWithRequest>>, TError,{data: NewTaskRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTaskWithRequest>>,
        TError,
        {data: NewTaskRequest},
        TContext
      > => {

      const mutationOptions = getCreateTaskWithRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Assign the next task to the current user
 */
export const assignNextTask = (
    assignNextRequest: MaybeRef<AssignNextRequest>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      assignNextRequest = unref(assignNextRequest);
      
      return customAxios<Task>(
      {url: `/api/tasks/assignNext`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignNextRequest, signal
    },
      options);
    }
  


export const getAssignNextTaskMutationOptions = <TError = ErrorType<AssignNextTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignNextTask>>, TError,{data: AssignNextRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof assignNextTask>>, TError,{data: AssignNextRequest}, TContext> => {
    
const mutationKey = ['assignNextTask'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignNextTask>>, {data: AssignNextRequest}> = (props) => {
          const {data} = props ?? {};

          return  assignNextTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AssignNextTaskMutationResult = NonNullable<Awaited<ReturnType<typeof assignNextTask>>>
    export type AssignNextTaskMutationBody = AssignNextRequest
    export type AssignNextTaskMutationError = ErrorType<AssignNextTask400 | ThrowableProblem | ExceptionResponse>

    export const useAssignNextTask = <TError = ErrorType<AssignNextTask400 | ThrowableProblem | ExceptionResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignNextTask>>, TError,{data: AssignNextRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof assignNextTask>>,
        TError,
        {data: AssignNextRequest},
        TContext
      > => {

      const mutationOptions = getAssignNextTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/tasks/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTaskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','tasks',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTaskQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError = ErrorType<GetChangeSequenceForTask400 | ThrowableProblem | ExceptionResponse>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTaskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTask>>> = ({ signal }) => getChangeSequenceForTask(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError, TData> 
}

export type GetChangeSequenceForTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTask>>>
export type GetChangeSequenceForTaskQueryError = ErrorType<GetChangeSequenceForTask400 | ThrowableProblem | ExceptionResponse>



export function useGetChangeSequenceForTask<TData = Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError = ErrorType<GetChangeSequenceForTask400 | ThrowableProblem | ExceptionResponse>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForTaskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



