<script lang="ts" setup>
import type { Tab } from "~/utils/tab";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import appStore from "~/store";

const {
  project,
  projectLoading,
} = storeToRefs(appStore.projectStore);

const availableTabs = computed(() => {
  return [
    {
      ref: "documentStatus",
      name: "Document Status",
      singleWidget: { type: "projectDocumentStatus", properties: {
        style: { height: "calc(100vh - 180px)" },
      } },
    },
    {
      ref: "taskStatus",
      name: "Task Status",
      singleWidget: { type: "projectTaskStatus", properties: {
        style: { height: "calc(100vh - 180px)" },
      } },
    },
    {
      ref: "taskTags",
      name: "Task Tags",
      singleWidget: { type: "projectTaskTags", properties: {
        style: { height: "calc(100vh - 180px)" },
      } },
    },
    {
      ref: "projectDataForms",
      name: "Data Forms",
      singleWidget: {
        type: "projectDataForms",
        properties: {
          style: { height: "calc(100vh - 180px)" },
        },
      },
    },
    {
      ref: "projectGuidance",
      name: "Guidance Sets",
      singleWidget: {
        type: "projectGuidance",
        properties: {
          style: { height: "calc(100vh - 180px)" },
        },
      },
    },
    {
      ref: "projectOptions",
      name: "Available Options",
      singleWidget: { type: "projectOptions", properties: {
        style: { height: "calc(100vh - 210px)" },
      } },
    },
    {
      ref: "projectTaskTemplate",
      name: "Task Templates",
      singleWidget: {
        type: "projectTaskTemplate",
        properties: {
          style: { height: "calc(100vh - 210px)" },
        },
      },
    },
    {
      ref: "projectTaxonFeatureDefaults",
      name: "Data Feature Defaults",
      singleWidget: {
        type: "projectTaxonFeatureDefaults",
        properties: {
          style: { height: "calc(100vh - 210px)" },
        },
      },
    },
  ] as Tab[];
});

const selectedTab = ref(availableTabs.value[0]);
</script>

<template>
  <div v-if="project" class="bg-white dark:bg-gray-800">
    <div v-if="!projectLoading" class="mt-2">
      <KodexaTabStrip
        :small="true"
        :items="availableTabs"
        :selected="selectedTab"
        @selected="selectedTab = $event"
      />
    </div>
    <div v-if="!projectLoading">
      <KodexaDashboard
        v-if="selectedTab"
        :model-value="selectedTab"
        :setup-mode="false"
        entry-point="project"
      />
    </div>
  </div>
</template>
