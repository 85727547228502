export const componentMetadata = {
  label: "Data Element Grid",
  description: "Present data element information in a grid format",
  supportsChildren: false,
  defaultWidth: 12,
  defaultHeight: 10,
  options: [
    {
      label: "Show Header",
      name: "showHeader",
      type: "boolean",
      description: "Whether to show the header",
      required: false,
      default: true,
    },
    {
      label: "Title",
      name: "title",
      type: "string",
      description: "Header title to display",
      required: false,
      showIf: "this.showHeader === true",
    },
    {
      label: "Subtitle",
      name: "subtitle",
      type: "string",
      description: "Subtitle to display",
      required: false,
      showIf: "this.showHeader === true",
    },
    {
      label: "Group Data Element",
      name: "groupTaxon",
      type: "groupTaxon",
      description: "Data element group to use",
      required: true,
    },
    {
      label: "Hide Add",
      name: "hideAdd",
      type: "boolean",
      description: "Hide the add button",
      required: false,
      default: false,
    },
    {
      label: "Hide Exceptions",
      name: "hideExceptions",
      type: "boolean",
      description: "Hide exceptions display",
      required: false,
      default: false,
    },
  ],
};
