<script setup lang="ts">
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { toRef } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: "#000000",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  noEmit: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: undefined,
  },
  value: {
    type: String,
    required: false,
    default: undefined,
  },
  errors: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["update:modelValue", "change"]);

const name = toRef(props, "name");

const localValue = computed({
  get: () => props.value === undefined ? props.modelValue : props.value,
  set: value => !props.noEmit ? emit("update:modelValue", value) : undefined,
});

function handleChange(event) {
  localValue.value = event.value;
  emit("change", event);
}
</script>

<template>
  <div>
    <div class="flex flex-col">
      <label v-if="label" :for="name" class="font-medium text-gray-700 dark:text-gray-50 mb-2">{{ label }}</label>
      <ColorPicker
        :id="name"
        :name="name"
        :value="localValue"
        :disabled="disabled"
        format="hex"
        @change="handleChange"
      />
    </div>
    <p v-if="hint" class="mt-2 text-gray-500 text-sm dark:text-gray-400">
      {{ hint }}
    </p>
    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600 dark:text-red-400">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 dark:text-gray-300 mb-1
}
</style>
