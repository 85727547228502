<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { storeToRefs } from "pinia";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps({
  item: {
    type: Object as PropType<Option>,
    required: true,
  },
  modelValue: {
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: Object,
  contentObject: Object as PropType<ContentObject | undefined>,
  assistant: Object as PropType<Assistant | undefined>,
});

const emit = defineEmits(["update:modelValue"]);

const { project } = storeToRefs(appStore.projectStore);

const {
  isShown,
  collapsed,
  type,
  localValue,
} = useConfigurationBase(props, emit);

function clear() {
  localValue.value = null;
}
</script>

<template>
  <div v-if="item && isShown">
    <VAutocomplete
      v-model="localValue"
      :items="project.attributeStatuses"
      :label="item.label"
      item-text="status"
      item-value="id"
      :disabled="disabled"
      clearable
      persistent-hint
      return-object
      @click:clear="clear"
    />
  </div>
</template>
