import type { Taxon } from "~/model";
import type { LoadingEvent } from "~/store/usePlatform";
import { notify } from "notiwind";
import { useRegisterSW } from "virtual:pwa-register/vue";
import appStore from "~/store";

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) {
      return l;
    }
  }
  return -1;
}

export function toCamelCase(str: string): string {
  if (!str) {
    return "";
  }

  return str
    .replace(/[^A-Z0-9 ]+/gi, " ") // Remove special characters like comma, dash, etc., and replace them with space
    .replace(/\s+/g, " ") // Replace multiple spaces with a single space
    .trim() // Trim leading and trailing white spaces
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/^\w|[A-Z0-9]|\b\w|\s+/gi, (match, index) => {
      // Match various patterns like start of string, any alphabetic character, word boundary or whitespace
      if (+match === 0) {
        return "";
      } // Remove spaces
      return index === 0 ? match.toUpperCase() : match.toLowerCase();
    });
}

export function copyToClipboard(str: string, notification: string) {
  const el = document.createElement("textarea");
  if (document.getSelection() !== null) {
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected
            = document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    if (notification) {
      notify({
        group: "generic",
        title: notification,
      }, 3000);
    }
  }
}

export function getColumnsFromTaxon(groupTaxon: Taxon, columnLocks: string[] = []): any[] {
  let currentSection;
  const columns = [];

  groupTaxon.children?.forEach((taxon) => {
    if (taxon.group) {
      return;
    }

    if (!taxon.enabled) {
      return;
    }

    if (taxon.taxonType === "SECTION") {
      if (currentSection !== undefined) {
        columns.push(currentSection);
      }
      currentSection = {
        title: taxon.label,
        children: [],
      };
      return;
    }

    let filter = "text";

    switch (taxon.taxonType) {
      case "CURRENCY":
      case "NUMBER":
        filter = "numeric";
        break;
      case "DATE":
        filter = "date";
        break;
      case "DATE_TIME":
        filter = "datetime";
        break;
    }

    let width = taxon.typeFeatures?.overrideWidth ? taxon.typeFeatures.displayWidth : 250;

    if (!taxon.typeFeatures?.overrideWidth && taxon.typeFeatures?.longText) {
      width = 500;
    }

    const newColumn = {
      field: taxon.path,
      title: taxon.label,
      width,
      resizable: true,
      filter,
      groupable: true,
      sortable: true,
      locked: columnLocks.includes(taxon.path),
    };

    if (currentSection === undefined) {
      columns.push(newColumn);
    } else {
      currentSection.children.push(newColumn);
    }
  });

  if (currentSection !== undefined) {
    columns.push(currentSection);
  }

  return columns;
}

const {
  updateServiceWorker,
} = useRegisterSW();

// Clear cache in Chrome and Edge
export async function clearCache() {
  const loadingEvent = {
    id: "readload-1",
    title: "Reloading Application",
    progressMessage: "If this takes too long please reload this page.",
  } as LoadingEvent;
  appStore.platformStore.addLoadingEvent(loadingEvent);

  if (window.chrome) {
    // For Chrome and Edge
    const promises: Promise<boolean>[] = [];
    caches.keys().then((names) => {
      for (const name of names) {
        promises.push(caches.delete(name));
      }
    });

    // wait until all promises are resolved
    Promise.all(promises).then(async () => {
      await updateServiceWorker();
      setTimeout(() => {
        appStore.platformStore.removeLoadingEvent(loadingEvent.id);
        window.location.reload();
      }, 5000);
    });
  } else {
    // For other browsers, you can't clear cache programmatically
    console.warn("Cache clearing is not supported in this browser.");

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/service-worker.js").then((registration) => {
        // Check if a new service worker is waiting to activate
        if (registration.waiting) {
          // Send message to service worker to skip waiting
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }

        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          newWorker?.addEventListener("statechange", () => {
            if (newWorker.state === "installed" && navigator.serviceWorker.controller) {
              // New update available, send message to service worker to skip waiting
              newWorker.postMessage({ type: "SKIP_WAITING" });
            }
          });
        });
      });
    }

    await updateServiceWorker();
    setTimeout(() => {
      appStore.platformStore.removeLoadingEvent(loadingEvent.id);
      window.location.reload();
    }, 10000);
  }
}
