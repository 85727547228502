<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { Tab } from "~/utils/tab";
import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard"]);

const useDataFormViewer = createDataFormViewerStore(props.viewId);

const tabs = computed(() => {
  const children = props.card.children || [];
  return children.map((child: Card) => {
    return {
      ref: child.id,
      name: child.properties?.title || child.type,
    } as Tab;
  });
});

const currentTab: Ref<Tab> = ref(tabs.value[0]);

function onSelect(tab: Tab) {
  currentTab.value = tab;
}

function getCard(id: string): Card | undefined {
  return useDataFormViewer.findCard(id);
}
</script>

<template>
  <div>
    <div :class="{ 'sticky-tabs': props.card.properties?.tabsSticky }">
      <KodexaTabStrip
        :items="tabs"
        :selected="currentTab"
        :small="true"
        @selected="onSelect"
      />
    </div>
    <div class="m-2">
      <KodexaFormCard
        v-if="getCard(currentTab.ref)"
        :card="getCard(currentTab.ref)!"
        style="width: 100%"
        :view-id="viewId"
        :parent-data-object="parentDataObject"
        :parent-tag-metadata="parentTagMetadata"
        @add-card="emit('addCard', $event)"
        @select-card="emit('selectCard', $event)"
        @remove-card="emit('removeCard', $event)"
      />
    </div>
  </div>
</template>

<style scoped>
.sticky-tabs {
  position: sticky;
  top: 0;
  z-index: 40;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
