<script setup lang="ts">
import { TextBox } from "@progress/kendo-vue-inputs";
import { toRef } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loadingPlaceholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "100%",
  },
  size: {
    type: String,
    default: "medium",
  },
  hint: {
    type: String,
    default: undefined,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  borderless: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
    default: "base",
    validator: (value: string) => ["xs", "sm", "base", "lg", "xl", "2xl"].includes(value),
  },
  fontWeight: {
    type: String,
    default: "normal",
    validator: (value: string) => ["normal", "medium", "semibold", "bold"].includes(value),
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  valid: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "input", "blur", "on-enter", "on-esc", "focus"]);

const name = toRef(props, "name");

const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const inputStyle = computed(() => {
  const sizes = {
    "xs": "0.75rem",
    "sm": "0.875rem",
    "base": "1rem",
    "lg": "1.125rem",
    "xl": "1.25rem",
    "2xl": "2rem",
  };
  const weights = {
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  };
  return {
    "width": props.width || "100%",
    "--k-textbox-font-size": sizes[props.fontSize],
    "--k-textbox-font-weight": weights[props.fontWeight],
  };
});
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <div
      :style="inputStyle"
      class="kodexa-input" :class="[
        { 'kodexa-input-borderless': borderless },
        { 'kodexa-input-invalid': !valid },
      ]"
    >
      <TextBox
        :id="id || name"
        v-model="localValue"
        :size="size"
        data-1p-ignore="true"
        :type="type"
        :valid="valid"
        :name="name"
        :show-loading-icon="loading"
        :placeholder="loading && loadingPlaceholder.length > 0 ? loadingPlaceholder : placeholder"
        :autocomplete="name"
        :disabled="disabled"
        :show-clear-button="showClear"
        :readonly="readOnly"
        input-suffix="suffix"
        @keyup.enter="emit('on-enter')"
        @keyup.escape="emit('on-esc')"
        @blur="emit('blur', $event)"
        @input="emit('input', $event)"
        @focus="emit('focus', $event)"
      >
        <template #suffix>
          <slot name="suffix" />
        </template>
      </TextBox>
    </div>

    <p v-if="hint" class="text-sm text-gray-500 dark:text-gray-400">
      {{ hint }}
    </p>
    <p v-if="showErrors && errors && errors[name]" class="mt-2 text-sm text-red-600 dark:text-red-400">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 dark:text-gray-300 mb-1
}

.kodexa-input > .k-input {
  width: 100%;
  border: 0;
}

.kodexa-input, .kodexa-input > input {
  @apply appearance-none rounded-md border border-gray-300 dark:border-gray-600
  px-0 py-0 shadow-sm placeholder:text-gray-400 dark:placeholder:text-gray-500
  sm:text-sm dark:text-white
}

.kodexa-input-invalid, .kodexa-input-invalid > input {
  @apply border-red-500 dark:border-red-400
}

.kodexa-input-invalid:focus-within,
.kodexa-input-invalid > input:focus-within {
  @apply border-red-500 dark:border-red-400 ring-1 ring-red-500 dark:ring-red-400
}

.kodexa-input-invalid:focus,
.kodexa-input-invalid > input:focus {
  @apply border-red-500 dark:border-red-400 ring-1 ring-red-500 dark:ring-red-400
}

.kodexa-input:focus-within,
.kodexa-input > input:focus-within {
  @apply border-blue-500 dark:border-blue-400 ring-1 ring-blue-500 dark:ring-blue-400
}

.kodexa-input:focus,
.kodexa-input > input:focus {
  @apply border-blue-500 dark:border-blue-400 ring-1 ring-blue-500 dark:ring-blue-400
}

.kodexa-input-borderless > input {
  @apply border-0 shadow-none
}

.kodexa-input-borderless:focus-within,
.kodexa-input-borderless > input:focus-within {
  @apply ring-0
}

.kodexa-input-borderless:focus,
.kodexa-input-borderless > input:focus {
  @apply ring-0
}

.kodexa-input-borderless,
.kodexa-input-borderless .k-input {
  @apply border-0 shadow-none ring-0;
}

.kodexa-input-borderless:focus-within,
.kodexa-input-borderless .k-input:focus-within,
.kodexa-input-borderless:focus,
.kodexa-input-borderless .k-input:focus {
  @apply border-0 ring-0 shadow-none outline-none;
}

:deep(.k-input-inner) {
  font-size: var(--k-textbox-font-size) !important;
  font-weight: var(--k-textbox-font-weight) !important;
  @apply dark:text-gray-50 sm:text-sm dark:bg-gray-700
}
</style>
