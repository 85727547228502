<script lang="ts" setup>
import type { ProcessingEntity, ProcessingGroup } from "~/model";
import type { Tab } from "~/utils/tab";
import KodexaProcessingEntity from "~/components/processingGroups/kodexa-processing-entity.vue";
import appStore from "~/store";

const props = defineProps<{
  processingGroup: ProcessingGroup;
}>();

const emit = defineEmits<{
  "update:processingGroup": [value: ProcessingGroup];
}>();

const selectedEntity = ref(null);

// Create a reference to store the working copy
const workingProcessingGroup = ref(props.processingGroup);

// Watch for changes in props.processingGroup
watch(() => props.processingGroup, (newValue) => {
  workingProcessingGroup.value = newValue;
}, { deep: true });

// Watch for changes in workingProcessingGroup
watch(workingProcessingGroup, (newValue) => {
  appStore.projectStore.addProcessingGroupToUpdate(newValue);
  emit("update:processingGroup", newValue);
}, { deep: true });

function selectProcessingEntity(entity: ProcessingEntity) {
  selectedEntity.value = JSON.parse(JSON.stringify(entity));
}

const tabs = [{
  ref: "Overview",
  name: "Overview",
}, {
  ref: "Group Options",
  name: "Group Options",
}, {
  ref: "Entity Options",
  name: "Entity Options",
}, {
  ref: "Properties",
  name: "Properties",
}, {
  ref: "Entities",
  name: "Entities",
}];

const currentTab: Ref<Tab> = ref(tabs[0]);

function selectTab(tab: Tab) {
  currentTab.value = tab;
}

function deleteGroup() {

}
</script>

<template>
  <div class="mx-1">
    <KodexaHeader :title="workingProcessingGroup.name" :sidebar="false" />
    <KodexaTabStrip :items="tabs" :selected="currentTab" @selected="selectTab" />
    <div
      v-if="currentTab.ref === 'Overview'" class="p-4 bg-white rounded-lg shadow-md"
      style="height: calc(100vh - 15rem);"
    >
      <KodexaTextInput v-model="workingProcessingGroup.name" name="name" label="Name" />
      <KodexaTextArea v-model="workingProcessingGroup.description" name="description" label="Description" :rows="8" />
      <div class="bg-white dark:bg-gray-800 shadow sm:rounded-lg mt-4">
        <div class="px-4 py-5 sm:p-6">
          <div class="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 class="text-base font-semibold leading-6 text-gray-900">
                Delete Group
              </h3>
              <div class="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Deleting a group will remove it, it will also remove all the entities in this group, and also remove
                  the relationship to all documents
                </p>
                <KodexaButton id="deleteEntity" icon="delete" type="danger" class="mt-7" @click="deleteGroup()">
                  Delete Group
                </KodexaButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentTab.ref === 'Group Options'" class="p-4 bg-white rounded-lg shadow-md"
      style="height: calc(100vh - 15rem);"
    >
      <OptionsBuilder :options="workingProcessingGroup.groupOptions" />
    </div>
    <div
      v-if="currentTab.ref === 'Entity Options'" class="p-4 bg-white rounded-lg shadow-md"
      style="height: calc(100vh - 15rem);"
    >
      <OptionsBuilder :options="workingProcessingGroup.entityOptions" />
    </div>
    <div
      v-if="currentTab.ref === 'Properties'" class="p-4 bg-white rounded-lg shadow-md"
      style="height: calc(100vh - 15rem);"
    >
      <ConfigurationOption
        v-for="option in workingProcessingGroup.groupOptions" :key="option.uuid"
        v-model="workingProcessingGroup.properties" :item="option"
      />
    </div>
    <div v-if="currentTab.ref === 'Entities'" class="flex gap-6" style="height: calc(100vh - 15rem);">
      <div class="w-1/2 p-4 bg-white rounded-lg shadow-md">
        <KodexaProcessingEntities :key="workingProcessingGroup.uuid" :processing-group="workingProcessingGroup" @select="selectProcessingEntity" />
      </div>
      <div class="w-1/2 p-4 bg-white rounded-lg shadow-md">
        <KodexaProcessingEntity
          v-if="selectedEntity"
          :key="workingProcessingGroup.uuid + selectedEntity?.uuid" :processing-entity="selectedEntity"
          :processing-group="workingProcessingGroup"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
