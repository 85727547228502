export const componentMetadata = {
  label: "Tabs",
  description: "Present the children as tabs",
  supportsChildren: true,
  defaultWidth: 12,
  defaultHeight: 10,
  options: [
    {
      name: "tabsSticky",
      label: "Sticky Tabs",
      description: "If true, the tabs will be sticky",
      type: "boolean",
      default: false,
    },
  ],
};
