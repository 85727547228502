<script setup lang="ts">
import type { PropType } from "vue";
import type { DataAttribute, TagMetadata } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataAttribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "cancel"]);

function close() {
  emit("cancel");
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 700px; height: 560px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader
                  title="AI Prompt Explanation"
                  sub-title="Understanding how this attribute was created"
                />
              </div>
              <div>
                <div class="p-4" style="height: 340px">
                  <div v-if="tagMetadata?.taxon?.semanticDefinition" class="prose dark:prose-invert">
                    <div class="text-sm text-gray-700 dark:text-gray-300 mb-3">
                      <strong>The following was provided to the AI to create this attribute:</strong>
                    </div>
                    <div class="text-sm text-gray-700 dark:text-gray-300">
                      {{ tagMetadata.taxon.semanticDefinition }}
                    </div>
                  </div>
                  <div v-else class="text-sm text-gray-700 dark:text-gray-300">
                    This data element is identified as <span class="font-semibold">{{ tagMetadata?.taxon?.label || 'Unknown' }}</span> but no additional information was provided to the AI.
                  </div>
                </div>
                <div class="mr-1 mt-12 sm:flex sm:flex-row-reverse p-4">
                  <KodexaButton
                    class="mr-2" width="120px"
                    icon="close" type="secondary"
                    @click="close"
                  >
                    Close
                  </KodexaButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
