<script setup lang="ts">
import type { PropType } from "vue";
import type { TagMetadata } from "~/store/useProject";

defineProps({
  index: {
    type: Number,
    required: true,
  },
  dataItem: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
});

const emit = defineEmits(["click"]);
</script>

<template>
  <li class="k-item" @click="emit('click')">
    <span>
      {{ dataItem?.label }} <span v-if="dataItem?.parentLabel">({{ dataItem?.parentLabel }})</span>
    </span>
  </li>
</template>

<style scoped>
.k-item {
  cursor: pointer;
  padding: 4px 8px;
}
</style>
