<script setup lang="ts">
import type { PropType } from "vue";
import type { Project, ProjectTemplate } from "~/model";
import { storeToRefs } from "pinia";
import { useListOrganizations } from "~/api/organizations/organizations";
import KodexaErrorAlert from "~/components/kodexa-error-alert.vue";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import { useIntercom } from "~/store/useIntercom";
import { log } from "~/utils/logger";

const props = defineProps({
  projectTemplate: {
    type: Object as PropType<ProjectTemplate>,
    required: true,
  },
});

const emit = defineEmits(["cancel", "closed"]);
const gridHelper = createGridHelper("project-organization-list", {
  page: 1,
  pageSize: 1,
  filter: "",
  query: "",
  sort: "",
});

const {
  gridQuery,
} = storeToRefs(gridHelper);

const { data, refetch } = useListOrganizations(gridQuery);
const newProject = ref<Project>({ options: { options: [], properties: {} } });

const { currentOrganization } = storeToRefs(appStore.organizationStore);

async function fetchOrganizations() {
  log.info("fetching organizations");
  try {
    newProject.value.name = props.projectTemplate.name;
    newProject.value.description = props.projectTemplate.description;
    if (!newProject.value.options) {
      newProject.value.options = { options: [], properties: {} };
    }

    newProject.value.options.options = props.projectTemplate.options?.options;
    newProject.value.options.properties = props.projectTemplate.options?.properties || {};
    if (!props.projectTemplate?.publicAccess) {
      log.info("Setting base filter for organization list");
      gridHelper.setBaseFilter(`slug: '${props.projectTemplate?.orgSlug}'`);
      await refetch();
      newProject.value.organization = data.value?.content?.find((org: any) => org.slug === props.projectTemplate.orgSlug);
    } else if (newProject.value.organization === undefined) {
      log.info("Setting project organization to current organization");
      newProject.value.organization = currentOrganization.value;
    } else {
      log.info("No organizations found");
    }
  } catch (error) {
    log.error("Error fetching organizations", error);
  }
}

watch([currentOrganization.value, props.projectTemplate], () => fetchOrganizations(), {
  immediate: true,
  deep: true,
});

function close() {
  emit("cancel");
}

const loading = ref(false);
const lastError = ref();

function createProject() {
  loading.value = true;
  appStore.projectStore.createNewProject(newProject.value, props.projectTemplate.ref).then((createdProject: Project) => {
    useIntercom().trackEvent("project-created");
    appStore.projectStore.gotoProject(createdProject, props.projectTemplate);
    loading.value = false;
  }).catch((error: any) => {
    loading.value = false;
    lastError.value = error;
    log.error("Error creating project", error);
  });
}

const tabs = [
  { id: "details", name: "Details", icon: "info" },
  { id: "options", name: "Options", icon: "settings" },
];

const currentTab = ref(tabs[0].id);

function selectTab(tab) {
  currentTab.value = tab.id;
}
</script>

<template>
  <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
    <div class="flex-1">
      <div>
        <!-- Main -->
        <div style="overflow-y: scroll">
          <div class="ml-4 pb-2">
            <KodexaHeader :title="projectTemplate.name" :sub-title="projectTemplate.description">
              <template #actions>
                <div class="flex h-7 items-center">
                  <button type="button" class="text-gray-400 hover:text-gray-500" @click="emit('closed')">
                    <span class="sr-only">Close panel</span>
                    <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
                  </button>
                </div>
              </template>
            </KodexaHeader>
          </div>
          <div class="px-2">
            <ul
              id="hoverScrollElements"
              class="-mb-px flex overflow-scroll border-r text-center text-sm font-medium text-gray-500 dark:text-gray-400"
            >
              <li
                v-for="(item) in tabs" :key="item.id"
                class="mx-2 mt-1"
                @click="selectTab(item)"
              >
                <a
                  :class="item.id === currentTab ? 'inline-flex items-center justify-center p-2 text-theme-primary border-b-2 border-blue-600 rounded-t-sm active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                  class="text-xs"
                >
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="px-4">
            <dl v-if="currentTab === 'details'">
              <!-- Divider container -->
              <div class="space-y-1 py-1">
                <!-- Project name -->
                <div class="space-y-2">
                  <div>
                    <label
                      for="project-name"
                      class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >Name</label>
                  </div>
                  <div class="sm:col-span-2">
                    <KodexaTextInput
                      v-model="newProject.name" type="text" name="project-name"
                    />
                  </div>
                </div>
                <div v-if="currentOrganization" class="text-sm text-gray-500">
                  Will be created in the <span class="font-semibold">{{ newProject.organization?.name }}</span> organization
                  <span v-if="!projectTemplate.publicAccess">(this can't be changed since the project is not public)</span>
                </div>

                <!-- Project description -->
                <div class="space-y-2">
                  <div>
                    <label
                      for="project-description"
                      class="block text-sm font-medium leading-6 text-gray-900"
                    >Description</label>
                  </div>
                  <div class="sm:col-span-4">
                    <KodexaTextArea
                      id="projectDescription"
                      v-model="newProject.description"
                      name="project-description" :rows="5"
                    />
                  </div>
                </div>
                <div class="mt-1">
                  <KodexaErrorAlert v-if="lastError" :last-error="lastError" />
                </div>
              </div>
            </dl>
            <dl v-if="currentTab === 'options'">
              <!-- Options Side -->
              <div
                v-if="projectTemplate?.options?.options?.length > 0"
                class="border-right mt-5 flex-1 overflow-y-scroll border-gray-200 px-2" style="max-height: 470px"
              >
                <div>
                  <div v-for="option in projectTemplate.options?.options" :key="option.name">
                    <ConfigurationOption
                      v-model="newProject.options.properties"
                      :item="option"
                    />
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end space-x-3">
        <KodexaButton
          id="newProjectBack"
          :disabled="loading"
          type="secondary"
          icon="arrow-left"
          class="w-28"
          @click="close"
        >
          Back
        </KodexaButton>
        <KodexaButton
          id="newProjectCreate"
          :loading="loading"
          icon="auto-fix"
          class="w-28"
          @click="createProject"
        >
          Create
        </KodexaButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
```
