<script setup lang="ts">
import type { Option } from "~/model";
import { ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { availableOptionTypes } from "../options/options";

interface Props {
  options: Option[] | { options: Option[] };
}

const props = defineProps<Props>();

const emit = defineEmits(["update:options"]);

const localOptions = computed({
  get: () => {
    if (!props.options) {
      return [];
    }
    return Array.isArray(props.options) ? props.options : (props.options.options || []);
  },
  set: (value) => {
    if (Array.isArray(props.options)) {
      emit("update:options", value);
    } else {
      emit("update:options", { ...props.options, options: value });
    }
  },
});

const items = computed(() =>
  availableOptionTypes
    .filter((type): type is NonNullable<typeof type> => type !== null)
    .map(type => ({
      id: type.type,
      text: type.label || type.name,
    })),
);

function addOption() {
  const newOptions = [...(localOptions.value || [])];
  newOptions.push({ name: "", label: "", type: "string", description: "" });
  localOptions.value = newOptions;
}

function deleteOption(idx: number) {
  const newOptions = [...(localOptions.value || [])];
  newOptions.splice(idx, 1);
  localOptions.value = newOptions;
}
</script>

<template>
  <div class="mx-4">
    <KodexaToolbar>
      <ToolbarSpacer />
      <KodexaButton name="add" icon="plus" type="primary" size="small" @click="addOption">
        Add Option
      </KodexaButton>
    </KodexaToolbar>
    <div class="p-4 space-y-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="(option, idx) in localOptions" :key="idx" class="p-4 shadow-lg rounded-lg">
          <KodexaTextInput
            v-model="option.name"
            class="mt-2"
            name="name"
            label="Name"
          />
          <KodexaTextInput
            v-model="option.label"
            class="mt-2"
            name="label"
            label="Label"
          />
          <KodexaTextInput
            v-model="option.description"
            class="mt-2"
            name="description"
            label="Description"
          />
          <KodexaDropDown
            v-model="option.type"
            class="mt-2"
            name="type"
            :items="items"
            label="Type"
          />
          <KodexaButton name="delete" icon="delete" type="danger" class="mt-2" @click="deleteOption(idx)">
            Delete
          </KodexaButton>
        </div>
      </div>
    </div>
  </div>
</template>
