<script setup lang="ts">
import type { Store, Taxonomy, Workspace } from "~/model";
import { Button, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Pager } from "@progress/kendo-vue-data-tools";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListWorkspaces } from "~/api/workspaces/workspaces";
import router from "~/router/router";
import appStore from "~/store";
import ProjectStoreCard from "~/views/studio/organizations/project/overview-cards/project-store-card.vue";
import ProjectTaxonomyCard from "~/views/studio/organizations/project/overview-cards/project-taxonomy-card.vue";
import ProjectWorkspaceCard from "~/views/studio/organizations/project/overview-cards/project-workspace-card.vue";

const {
  project,
  documentStores,
  dataStores,
  modelStores,
  taxonomies,
  dataForms,
} = storeToRefs(appStore.projectStore);
const { currentSidebar } = storeToRefs(appStore.platformStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`project-workspaces-'${project.value.id}'`, {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "",
}, `project.id: '${project.value.id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListWorkspaces(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements ?? 0);
    gridHelper.saveState();
  }
});

const stores = computed(() => {
  const stores: Store[] = [];
  if (documentStores.value) {
    documentStores.value.forEach((documentStore: Store) => {
      stores.push(documentStore);
    });
  }
  if (dataStores.value) {
    dataStores.value.forEach((dataStore: Store) => {
      stores.push(dataStore);
    });
  }
  if (modelStores.value) {
    modelStores.value.forEach((modelStore: Store) => {
      stores.push(modelStore);
    });
  }
  return stores;
});

function openWorkspace(workspace: Workspace) {
  appStore.workspaceStore.openWorkspace(workspace);
}

function openMarketplace() {
  appStore.platformStore.setCurrentSidebar("marketplace");
}

const showAddResource = ref(false);

function openAddResource() {
  showAddResource.value = true;
}

function openTaxonomy(taxonomy: Taxonomy) {
  router.push(
    {
      name: "taxonomy",
      params: {
        organizationId: project.value.organization.id,
        projectId: project.value.id,
        taxonomySlug: taxonomy.slug,
      },
    },
  );
}

function refetchWorkspaces() {
  refetch();
}

async function newWorkspace() {
  const newWorkspaceId = await appStore.workspaceStore.createNewWorkspace();
  router.push({ path: `workspaces/${newWorkspaceId}` }).then(() => {
  });
}

function openStore(store: Store) {
  if (store.storeType === "TABLE") {
    currentSidebar.value = `dataStore-${store.ref}`;
  }
  if (store.storeType === "DOCUMENT") {
    currentSidebar.value = `documentStore-${store.ref}`;
  }
  if (store.storeType === "MODEL") {
    currentSidebar.value = `modelStore-${store.ref}`;
  }
}

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});
</script>

<template>
  <div v-if="data && data.totalElements > 0 && !isLoading" style="height: calc(100vh - 7rem); width: 100%">
    <div class="px-4">
      <KodexaToolbar>
        <ToolbarSpacer />
        <KodexaButton
          id="newProjectWorkspace"
          icon="plus"
          @click="newWorkspace"
        >
          New Workspace
        </KodexaButton>
      </KodexaToolbar>
      <Pager
        v-if="data && data.totalElements > 5"
        size="small"
        :info="true"
        :page-size="pageSettings.take as number"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :take="pageSettings.take as number"
        @pagechange="gridHelper.pageChangeHandler($event)"
      />
      <KodexaSmallLoader v-if="isLoading" class="mt-6" />
      <div
        v-if="!isLoading && data && data.content && data.content.length > 0"
        class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"
      >
        <ProjectWorkspaceCard
          v-for="workspace in data.content" :key="workspace.id" :workspace="workspace"
          @goto="openWorkspace(workspace)" @refetch="refetchWorkspaces"
        />
      </div>
      <div v-if="false" class="mt-8 flex">
        <h2 class="mt-3 text-lg font-medium leading-6 text-gray-900">
          Project Resources
        </h2>
        <div class="ml-6 mt-2">
          <Button fill-mode="flat" class="text-theme-primary" @click="openAddResource">
            Link resource
          </Button>
          <Button fill-mode="flat" class="text-theme-primary" @click="openMarketplace">
            Add resource from marketplace
          </Button>
        </div>
      </div>

      <div v-if="false" class="mt-7 grid grid-cols-1 gap-5 sm:grid-cols-4 lg:grid-cols-4">
        <ProjectStoreCard v-for="store in stores" :key="store.name" :store="store" @goto="openStore(store)" />
        <ProjectTaxonomyCard
          v-for="taxonomy in taxonomies" :key="taxonomy.ref" :taxonomy="taxonomy"
          @goto="openTaxonomy(taxonomy)"
        />
      </div>
    </div>
  </div>
  <div v-else-if="!isLoading">
    <div class="mt-32 text-center">
      <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
      </svg>
      <h3 class="mt-2 text-sm font-semibold text-gray-900">
        No workspaces
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Create a workspace to allow you to label, train, validate or work with documents and data in your project
      </p>
      <div class="mt-6">
        <KodexaButton
          id="newProjectWorkspace"
          icon="plus"
          @click="newWorkspace"
        >
          New Workspace
        </KodexaButton>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
