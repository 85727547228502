<script setup lang="ts">
import type { PropType } from "vue";
import type { Product, ProductGroup, ProjectTemplate } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import {
  ListBox,
  processListBoxDragAndDrop,
} from "@progress/kendo-vue-listbox";
import { storeToRefs } from "pinia";
import { useListProductGroups } from "~/api/product-groups/product-groups";
import { updateProduct, useListProducts } from "~/api/product-overview/product-overview";
import {
  createProductProjectTemplates,
  deleteProductProjectTemplate,
  useListProductProjectTemplates,
} from "~/api/product-project-templates/product-project-templates";
import { useListProjectTemplatesForOrganization } from "~/api/project-templates/project-templates";
import { createGridHelper } from "~/store/useGridHelper";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue", "refetchProduct"]);

const {
  data,
} = useListProductGroups({ pageSize: 20 });

const {
  data: dataParentProducts,
} = useListProducts({ pageSize: 100 });

const projectTemplateGridHelper = createGridHelper("product-template-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
});

const {
  gridQuery: projectTemplateQuery,
} = storeToRefs(projectTemplateGridHelper);

const { data: fetchedProjectTemplates } = useListProjectTemplatesForOrganization("kodexa", projectTemplateQuery.value);

const productProjectTemplateGrid = createGridHelper("product-project-template-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
}, `product.id: '${props.product.id}'`);

const {
  gridQuery: productProjectTemplateQuery,
} = storeToRefs(productProjectTemplateGrid);

watch(() => props.product, (newValue) => {
  if (newValue) {
    productProjectTemplateGrid.setBaseFilter(`product.id: '${newValue.id}'`);
  }
}, { immediate: true });

const { data: fetchedProjectTemplatesByProduct } = useListProductProjectTemplates(productProjectTemplateQuery.value);

const localProduct = ref(JSON.parse(JSON.stringify(props.product)) as typeof props.product);
const currentProductGroup = ref(localProduct.value.productGroup.name);
const currentParentProduct = ref(localProduct.value.parent?.name);

watchDebounced(() => currentProductGroup, (newValue) => {
  if (newValue) {
    const productGroup = data?.value?.content?.find((productGroup: ProductGroup) => productGroup.name === newValue.value);
    localProduct.value.productGroup = productGroup || {};
    clearError("productGroup");
  }
}, {
  debounce: 1000,
  maxWait: 500,
  deep: true,
  immediate: true,
});

watchDebounced(() => currentParentProduct, (newValue) => {
  if (newValue) {
    const product = dataParentProducts?.value?.content?.find((product: Product) => product.name === newValue.value);
    if (localProduct.value) {
      localProduct.value.parent = product || null;
    }
    clearError("parentProduct");
  }
}, {
  debounce: 1000,
  maxWait: 500,
  deep: true,
  immediate: true,
});

const requiredFields = ["name", "price", "priceId", "productGroup"];
const requiredFieldsError = ref({});

function close() {
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

const allTemplates = ref([] as ProjectTemplate[]);
const usedTemplatesData = ref([] as ProjectTemplate[]);

watchEffect(() => {
  if (fetchedProjectTemplates.value) {
    allTemplates.value = fetchedProjectTemplates.value.content || [];
  }
  if (fetchedProjectTemplatesByProduct.value?.content) {
    usedTemplatesData.value = fetchedProjectTemplatesByProduct.value.content
      .filter(template => template?.projectTemplateMetadata?.metadata !== undefined)
      .map(template => template?.projectTemplateMetadata?.metadata);
  }
});

const availableTemplates = computed(() => {
  const usedTemplateIds = usedTemplatesData.value.map(template => template._id);
  const filter = allTemplates.value.filter(template => !usedTemplateIds.includes(template._id));
  return filter;
});

const draggedItem = ref({});

function handleDragStart(e) {
  draggedItem.value = e.dataItem;
}

function handleDrop(e) {
  const result = processListBoxDragAndDrop(
    availableTemplates.value,
    usedTemplatesData.value,
    draggedItem.value,
    e.dataItem,
    "_id",
  );
  allTemplates.value = result.listBoxOneData;
  usedTemplatesData.value = result.listBoxTwoData;
}

async function saveProduct() {
  requiredFields.forEach((field) => {
    const value = localProduct.value[field];
    if (!value || (typeof value === "object" && Object.keys(value).length === 0)) {
      requiredFieldsError.value[field] = "This is required";
    } else if (requiredFieldsError.value[field]) {
      delete requiredFieldsError.value[field];
    }
  });

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    return;
  }
  if (localProduct.value && localProduct.value.id) {
    await updateHandler(updateProduct(localProduct.value.id, localProduct.value), "Product updated successfully");
  }

  const initialUsedTemplates = fetchedProjectTemplatesByProduct?.value?.content || [];
  const currentUsedTemplates = usedTemplatesData.value;

  // Identify the new templates moved to usedTemplatesData
  // const newTemplates = usedTemplatesData.value.filter(templateMetadata => !initialUsedTemplates.some(initial => initial.id === templateMetadata.id));
  const newTemplates = currentUsedTemplates.filter(template => !initialUsedTemplates.some(initial => initial.projectTemplateMetadata?.metadata?._id === template._id));

  // Identify the templates removed from usedTemplatesData
  const removedTemplates = initialUsedTemplates.filter(initial => !currentUsedTemplates.some(current => current._id === initial.projectTemplateMetadata?.metadata?._id));

  if (newTemplates) {
    await createProductProjectTemplates(props.product?.id, newTemplates);
  }

  if (removedTemplates) {
    for (const template of removedTemplates) {
      await deleteProductProjectTemplate(template.id as string);
    }
  }

  emit("refetchProduct");
  close();
}

function clearError(field) {
  const value = localProduct.value[field];
  if (value && ((typeof value !== "object") || (Object.keys(value).length !== 0))) {
    if (requiredFieldsError.value[field]) {
      delete requiredFieldsError.value[field];
      requiredFieldsError.value = { ...requiredFieldsError.value };
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Product
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              type="secondary"
                              icon="cancel"
                              size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              type="primary"
                              icon="content-save"
                              size="small"
                              @click="saveProduct"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- Product Name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="edit-product-name"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Product Name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="edit-product-name" v-model="localProduct.name" name="name"
                            :errors="requiredFieldsError" @input="clearError('name')"
                          />
                        </div>
                      </div>

                      <!-- Product Description -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="edit-product-desc"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Description</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextArea
                            id="edit-product-desc" v-model="localProduct.description" rows="2"
                            name="edit-product-desc"
                          />
                        </div>
                      </div>

                      <!-- Product Overview Markdown -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            for="overview-markdown"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Overview
                            Markdown</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaMarkdownEditor v-model="localProduct.overviewMarkdown" name="overview-markdown" />
                        </div>
                      </div>
                    </div>

                    <!-- Product Price -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-price"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Order</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaNumericInput
                          id="product-price" v-model="localProduct.order" name="order"
                          :errors="requiredFieldsError" @input="clearError('order')"
                        />
                      </div>
                    </div>

                    <!-- Product Price -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-price"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Price</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaNumericInput
                          id="product-price" v-model="localProduct.price" name="price"
                          :errors="requiredFieldsError" @input="clearError('price')"
                        />
                      </div>
                    </div>

                    <!-- Product Price Suffix -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-price"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Price Suffix</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaTextInput
                          id="product-price-suffix" v-model="localProduct.priceSuffix" name="priceSuffix"
                          :errors="requiredFieldsError" @input="clearError('priceSuffix')"
                        />
                      </div>
                    </div>

                    <!-- Promoted -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-price"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Promoted</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaCheckbox
                          id="product-price-suffix" v-model="localProduct.promoted" name="promoted"
                          :errors="requiredFieldsError" @input="clearError('promoted')"
                        />
                      </div>
                    </div>

                    <!-- Product Price ID -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-priceID"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Stripe Price ID</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaTextInput
                          id="product-priceID" v-model="localProduct.priceId" name="priceId"
                          :errors="requiredFieldsError" @input="clearError('priceId')"
                        />
                      </div>
                    </div>

                    <!-- Has Quantity -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="hasQuantity"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Has Quantity</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaCheckbox
                          v-model="localProduct.hasQuantity"
                          name="hasQuantity"
                          label=""
                        />
                      </div>
                    </div>

                    <!-- Product Number of Credits -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-numberOfCredits"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Number of Credits</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaNumericInput id="product-numberOfCredits" v-model="localProduct.numberOfCredits" name="numberOfCredits" />
                      </div>
                    </div>

                    <!-- Product Group -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          for="product-name"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Product Group</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaAutocompleteInput
                          id="product-group"
                          v-model="currentProductGroup" :items="data.content || []"
                          text-field="name" name="productGroup" class="text-sm" :errors="requiredFieldsError"
                        />
                      </div>
                    </div>

                    <!-- Project Template -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="product-name"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Project Template</label>
                      </div>
                      <div class="sm:col-span-2">
                        <div class="demo-section k-content wide">
                          <div class="flex space-x-4">
                            <div>
                              <h5 class="text-xs font-medium mb-1.5 text-gray-500">
                                List of Available Templates
                              </h5>
                              <ListBox
                                class="rounded-sm"
                                :data-items="availableTemplates || []"
                                text-field="name"
                                :draggable="true"
                                @dragstart="handleDragStart"
                                @drop="handleDrop"
                              />
                            </div>

                            <div>
                              <h5 class="text-xs font-medium mb-1.5 text-gray-500">
                                Order of Templates
                              </h5>
                              <ListBox
                                :data-items="usedTemplatesData"
                                text-field="name"
                                :draggable="true"
                                @dragstart="handleDragStart"
                                @drop="handleDrop"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Parent Product -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                      <div>
                        <label
                          for="parent-product"
                          class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >Parent Product</label>
                      </div>
                      <div class="sm:col-span-2">
                        <div class="sm:col-span-2">
                          <KodexaAutocompleteInput
                            id="parent-product"
                            v-model="currentParentProduct"
                            text-field="name"
                            name="parentProduct"
                            class="text-sm"
                            :items="dataParentProducts?.content || []"
                            :errors="requiredFieldsError"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Status -->
                    <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3 mb-10">
                      <div>
                        <label for="isActive" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">is
                          Active</label>
                      </div>
                      <div class="sm:col-span-2">
                        <KodexaCheckbox
                          v-model="localProduct.active"
                          name="isActive"
                          label="If unchecked, the Product will be unavailable and will be removed from its Product Group."
                          class="checkbox-label"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
:deep(.checkbox-label label) {
  @apply text-xs italic text-gray-400 font-normal;
}
</style>
