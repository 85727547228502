import type {
  DataAttribute,
  DataException,
  DataObject,
} from "~/model";

export function isSameDataException(left: DataException, right: DataException): boolean {
  // The equality of a Data Exception is based on the dataObject uuid, the data attribute uuid (if present), the exception type and the path
  const result = left.dataObjectUuid === right.dataObjectUuid
    && left.dataAttrUuid === right.dataAttrUuid
    && left.exceptionType === right.exceptionType
    && left.path === right.path;
  return result;
}

export function getUniqueExceptions(dataObject: DataObject) {
  const dataExceptions = [] as DataException[];
  if (dataObject.dataExceptions) {
    for (const dataException of dataObject.dataExceptions) {
      const exceptionCopy = { ...dataException } as DataException;
      exceptionCopy.dataObject = dataObject;

      // Check if this exception is already included
      const existingIndex = dataExceptions.findIndex(existing =>
        isSameDataException(existing, exceptionCopy),
      );

      if (existingIndex === -1) {
        // No duplicate found, add the new exception
        dataExceptions.push(exceptionCopy);
      } else if (exceptionCopy.open && !dataExceptions[existingIndex].open) {
        // Replace closed exception with open one
        dataExceptions[existingIndex] = exceptionCopy;
      }
    }
  }
  return dataExceptions;
}

/**
 * Marks specified exceptions as closed rather than deleting them
 */
export function closeExceptions(
  dataObject: DataObject,
  exceptionsToClose: DataException[],
): void {
  // Close object-level exceptions
  if (dataObject.dataExceptions) {
    dataObject.dataExceptions = dataObject.dataExceptions.map(exception =>
      shouldCloseException(exception, exceptionsToClose)
        ? { ...exception, open: false }
        : exception,
    );
  }

  // Close attribute-level exceptions
  exceptionsToClose.forEach((exceptionToClose) => {
    if (!exceptionToClose.dataAttribute?.uuid) {
      return;
    }

    const attribute = dataObject.attributes?.find(
      attr => attr.uuid === exceptionToClose.dataAttribute?.uuid,
    );

    if (attribute?.dataExceptions) {
      attribute.dataExceptions = attribute.dataExceptions.map(exception =>
        shouldCloseException(exception, [exceptionToClose])
          ? { ...exception, open: false }
          : exception,
      );
    }
  });
}

/**
 * Categorizes exceptions into object-level and attribute-level collections
 */
export function categorizeExceptions(
  exceptions: DataException[],
): {
    objectExceptions: DataException[];
    attributeExceptions: Map<string, DataException[]>;
  } {
  const objectExceptions: DataException[] = [];
  const attributeExceptions = new Map<string, DataException[]>();
  exceptions.forEach((exception) => {
    if (exception.dataAttribute) {
      const attributeUuid = exception.dataAttribute.uuid as string;
      const existing = attributeExceptions.get(attributeUuid) || [];
      attributeExceptions.set(
        attributeUuid,
        mergeExceptions(existing, exception),
      );
    } else {
      objectExceptions.push(exception);
    }
  });

  return { objectExceptions, attributeExceptions };
}

/**
 * Updates object-level exceptions while preventing duplicates
 */
export function updateObjectExceptions(
  dataObject: DataObject,
  newExceptions: DataException[],
): void {
  const existing = dataObject.dataExceptions || [];
  dataObject.dataExceptions = mergeExceptions(existing, ...newExceptions);
}

/**
 * Updates attribute-level exceptions while preventing duplicates
 */
export function updateAttributeExceptions(
  attributes: DataAttribute[],
  attributeExceptions: Map<string, DataException[]>,
): void {
  attributes.forEach((attribute) => {
    const newExceptions = attributeExceptions.get(attribute.uuid as string);
    if (!newExceptions) {
      return;
    }

    const existing = attribute.dataExceptions || [];

    attribute.dataExceptions = mergeExceptions(existing, ...newExceptions);
  });
}

/**
 * Merges new exceptions with existing ones:
 * - Only keeps exceptions that are either existing or currently open
 * - Updates existing exceptions with new data if they match
 * - if the new exception has a dataAttrUuid then we need to see if it matches an existing exception with or without a dataAttrUuid
 */
export function mergeExceptions(
  existing: DataException[],
  ...newExceptions: DataException[]
): DataException[] {
  // Start with existing exceptions that don't have matches in new exceptions
  const preserved = existing.filter(existingException =>
    !newExceptions.some((newException) => {
      if (newException.dataAttrUuid) {
        // For exceptions with dataAttrUuid, match against exceptions with or without dataAttrUuid
        return isSameDataException(existingException, newException)
          || isSameDataException(existingException, { ...newException, dataAttrUuid: undefined });
      }
      return isSameDataException(existingException, newException);
    }),
  );

  // Only keep new exceptions that are open
  const openNewExceptions = newExceptions.filter(exception => exception.open);

  return [...preserved, ...openNewExceptions];
}

/**
 * Determines if an exception should be closed based on a list of exceptions to close
 */
export function shouldCloseException(
  exception: DataException,
  exceptionsToClose: DataException[],
): boolean {
  return exceptionsToClose.some(toClose =>
    isSameDataException(exception, toClose),
  );
}
