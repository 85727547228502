<script setup lang="ts">
import type { Panel } from "./editor-panel";
import { GridLayout } from "grid-layout-plus";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import appStore from "~/store";
import { createDataFormEditorStore } from "~/store/useDataFormEditorStore";
import { availableCards } from "../cards/cards";

const props = defineProps<{
  panel: Panel;
  isSelected: boolean;
  viewId: string;
}>();

const emit = defineEmits<{
  (e: "click", id: string): void;
  (e: "select", id: string): void;
  (e: "delete", id: string): void;
}>();

const formEditorStore = createDataFormEditorStore(props.viewId)();
const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const showCopied = ref(false);
const showPasted = ref(false);

const supportsChildren = computed(() => {
  const cardComponent = availableCards.find(c => c.type === props.panel.type);
  return cardComponent?.supportsChildren || false;
});

function getTaxonLabel(taxonPath: string): string {
  const metadata = tagMetadataMap.value.get(taxonPath);
  return metadata?.label || taxonPath.split("/").pop() || "";
}

const childrenLayout = computed(() =>
  props.panel.children.map(child => ({
    x: child.x,
    y: child.y,
    w: child.w,
    h: child.h,
    i: child.id,
    static: child.static,
    minW: 2,
    maxW: 12,
    minH: 2,
    isDraggable: !child.static,
    isResizable: !child.static,
    margin: [10, 10],
  })),
);

function handleChildLayoutUpdate(newLayout: any) {
  formEditorStore.updateChildrenLayout(props.panel.id, newLayout);
}

function handleClick(e: Event | string) {
  if (e instanceof Event) {
    e.stopPropagation();
  }
  const panelId = typeof e === "string" ? e : props.panel.id;
  formEditorStore.selectPanel(panelId);
}

function handleDelete(e: Event) {
  e.stopPropagation();
  emit("delete", props.panel.id);
}

function generateNewIds(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => generateNewIds(item));
  }
  if (typeof obj === "object" && obj !== null) {
    const newObj = { ...obj };
    if ("id" in newObj) {
      newObj.id = uuidv4();
    }
    for (const key in newObj) {
      newObj[key] = generateNewIds(newObj[key]);
    }
    return newObj;
  }
  return obj;
}

async function handleCopy(e: Event) {
  e.stopPropagation();
  await navigator.clipboard.writeText(JSON.stringify(props.panel));
  showCopied.value = true;
  setTimeout(() => {
    showCopied.value = false;
  }, 2000);
}

async function handlePaste(e: Event) {
  e.stopPropagation();
  try {
    const text = await navigator.clipboard.readText();
    const pastedPanel = JSON.parse(text);
    const newPanel = generateNewIds(pastedPanel);
    formEditorStore.addPanel(newPanel, props.panel.id, newPanel.type);
    showPasted.value = true;
    setTimeout(() => {
      showPasted.value = false;
    }, 2000);
  } catch (error) {
    console.error("Failed to paste panel:", error);
  }
}

function openPanel() {
  appStore.platformStore.setCurrentSidebar("dataFormEditorProperties");
}
</script>

<template>
  <div
    :id="`panel-${panel.id}`"
    class="w-full h-full flex flex-col overflow-hidden rounded-md select-none"
    :class="[
      isSelected ? 'border-2 border-[#60A5FA] shadow-sm' : 'border border-gray-200',
      !supportsChildren ? 'min-h-[40px]' : 'min-h-[100px]',
    ]"
    :style="{
      backgroundColor: isSelected
        ? '#FBFDFF'
        : !supportsChildren
          ? '#fdfdfd'
          : panel.properties?.backgroundColor || 'white',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
    }"
    @click="handleClick"
  >
    <div
      class="flex justify-between items-center px-2 py-2 relative select-none"
      :class="[
        supportsChildren ? 'bg-blue-50 border-b border-blue-100' : '',
      ]"
    >
      <span class="text-gray-900 font-light select-none">
        {{ panel.properties?.taxon ? getTaxonLabel(panel.properties.taxon) : panel.properties.title }}
      </span>

      <div class="flex gap-1">
        <Transition name="fade">
          <span v-if="showCopied || showPasted" class="transform text-sm text-blue-600 mt-1 select-none">
            {{ showCopied ? 'Copied' : 'Pasted' }}
          </span>
        </Transition>
        <MaterialDesignIcon
          name="settings"
          class="text-blue-600 hover:text-blue-700 cursor-pointer select-none"
          size="14"
          @click="openPanel()"
        />
        <MaterialDesignIcon
          name="content-copy"
          class="text-blue-600 hover:text-blue-700 cursor-pointer select-none"
          size="14"
          @click="handleCopy"
        />
        <MaterialDesignIcon
          v-if="supportsChildren"
          name="content-paste"
          class="text-blue-600 hover:text-blue-700 cursor-pointer select-none"
          size="14"
          @click="handlePaste"
        />
        <MaterialDesignIcon
          name="delete"
          class="text-red-600 hover:text-red-700 cursor-pointer select-none"
          size="14"
          @click="handleDelete"
        />
      </div>
    </div>

    <div v-if="supportsChildren" class="flex-1 p-2 overflow-auto select-none">
      <GridLayout
        v-if="panel.children?.length"
        v-model:layout="childrenLayout"
        :cols="{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }"
        :row-height="30"
        :margin="[10, 10]"
        :use-css-transforms="true"
        :vertical-compact="false"
        @layout-updated="handleChildLayoutUpdate"
      >
        <template #item="{ item }">
          <EditorDataFormPanel
            :panel="panel.children.find(p => p.id === item.i)!"
            :is-selected="formEditorStore.selectedPanel?.id === item.i"
            :view-id="viewId"
            @click="handleClick"
            @select="formEditorStore.selectPanel"
            @delete="formEditorStore.deletePanel"
          />
        </template>
      </GridLayout>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
