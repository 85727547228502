<script setup lang="ts">
import type { PropType } from "vue";
import type { Membership } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { notify } from "notiwind";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { deleteMembership, updateMembership } from "~/api/memberships/memberships";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { handleError } from "~/utils/error-handler";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  member: {
    type: Object as PropType<Membership>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetchMembers"]);
const roles = [
  {
    id: "READ",
    text: "READ",
  },
  {
    id: "WRITE",
    text: "WRITE",
  },
  {
    id: "OWNER",
    text: "OWNER",
  },
];

const localMember = ref(JSON.parse(JSON.stringify(props.member)) as typeof props.member);

watch(() => props.modelValue, () => {
  localMember.value = JSON.parse(JSON.stringify(props.member)) as typeof props.member;
});

async function deleteMember() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const {
    isCanceled,
  } = await deleteConfirmDialog.reveal({
    title: `Delete
    user
    ${localMember?.value?.user.email}
    from
    this
    organization
    ?`,
    message: "This action cannot be undone.",
  });
  if (!isCanceled) {
    await deleteMembership(localMember.value.id);
    notify({
      group: "generic",
      title: "Success",
      text: "User deleted successfully",
    }, 1000);
  }
  emit("refetchMembers");
  close();
}

const errors = ref({});

async function updateMember() {
  if (localMember.value) {
    try {
      await updateMembership(localMember.value.id, localMember.value);
    } catch (err: any) {
      errors.value = err.response.data.errors;
      await handleError(err);
    }
  }
  notify({
    group: "generic",
    title: "Success",
    text: "Member updated successfully",
  }, 3000);
  emit("refetchMembers");
  close();
}

function close() {
  emit("update:modelValue", false);
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-[99]" @close="close">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="flex-1">
                    <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Member
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>

                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelChanges"
                              type="secondary"
                              icon="cancel"
                              size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="saveChanges"
                              type="primary"
                              icon="content-save"
                              size="small"
                              @click="updateMember"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <label for="email" class="block text-sm font-medium leading-3 text-gray-900 sm:mt-1.5">
                            Email Address
                          </label>
                          <div id="email" class="sm:col-span-2">
                            {{ localMember.user.email }}
                          </div>
                          <label
                            for="firstName"
                            class="block text-sm font-medium leading-7 text-gray-900 sm:mt-1.5"
                          >
                            First Name
                          </label>
                          <div id="firstName" class="sm:col-span-2">
                            {{ localMember.user.firstName }}
                          </div>
                          <label
                            for="lastName"
                            class="block text-sm font-medium leading-7 text-gray-900 sm:mt-1.5"
                          >
                            Last Name
                          </label>
                          <div id="lastName" class="sm:col-span-2">
                            {{ localMember.user.lastName }}
                          </div>
                          <label
                            for="roles"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Roles
                          </label>
                          <div class="sm:col-span-2">
                            <KodexaDropDown id="roles" v-model="localMember.role" :items="roles" name="member-role" />
                          </div>
                        </div>
                      </div>
                      <!-- Delete Member -->
                      <div class="border-2 border-red-200 bg-white dark:bg-gray-800 shadow sm:m-6 sm:mt-3 sm:rounded-lg">
                        <div class=" px-4 py-5 sm:p-6">
                          <h3 class="text-base font-semibold leading-6 text-red-600">
                            Delete User
                          </h3>
                          <div class="mt-2 items-center justify-center sm:flex">
                            Are you sure you want to delete this member? This cannot be undone.
                            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                              <KodexaButton id="deleteMember" type="danger" @click="deleteMember">
                                Delete Member
                              </KodexaButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
