<script lang="ts" setup>
import type { DataAttribute, DataException } from "~/model";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";

const props = defineProps({
  exception: {
    type: Object as PropType<DataException>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: false,
  },
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const isDetailsOpen = ref(false);
const detailsRef = ref(null);

const taxon = computed(() => getTaxon());

function getTaxon() {
  const taxon = tagMetadataMap.value.get(props.exception.path);
  if (taxon) {
    return taxon;
  }
  return null;
}

function showDetails() {
  isDetailsOpen.value = true;
}

function hideDetails() {
  isDetailsOpen.value = false;
}
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-col mb-2">
      <div class="flex items-center space-x-2">
        <svg class="h-6 w-6 text-red-500 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <h3 class="text-md font-semibold text-gray-900 dark:text-white break-words">
          {{ exception.message }}
        </h3>
      </div>
      <div class="ml-8 mt-1">
        <p v-if="taxon" class="text-xs text-gray-600 dark:text-gray-300 font-medium">
          {{ taxon.label }}
        </p>
      </div>
    </div>
    <div v-if="exception.exceptionDetails" ref="detailsRef" class="ml-8" @mouseenter="showDetails" @mouseleave="hideDetails">
      <div
        class="flex items-center text-sm text-gray-600 dark:text-gray-300 mb-2 hover:text-gray-800 dark:hover:text-white cursor-pointer"
      >
        <svg
          class="h-4 w-4 mr-1 transform transition-transform"
          :class="isDetailsOpen ? 'rotate-90' : ''"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
        Details
      </div>
      <div v-if="isDetailsOpen" class="ml-5 space-y-3">
        <div class="prose prose-sm max-w-none text-gray-600 dark:text-gray-300 break-words">
          <KodexaMarkdown :content="exception.exceptionDetails" :show-clipboard="false" />
        </div>

        <div v-if="attribute?.value !== undefined">
          <p class="text-sm text-gray-600 dark:text-gray-300 font-medium mb-1">
            Original Value
          </p>
          <div class="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p class="text-sm text-gray-600 dark:text-gray-300 break-words">
              {{ attribute.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
