<script lang="ts" setup>
import type { Store } from "pinia";
import type { PropType } from "vue";
import type { DataFlowNode } from "~/model";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import KodexaCheckbox from "~/components/inputs/kodexa-checkbox.vue";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "step-deleted"]);

const { dataStores, projectDirty } = storeToRefs(appStore.projectStore);

const dataStore = computed(() => {
  return dataStores.value.find((store: Store) => props.node && store.ref === props.node.id?.split("//")[1]);
});

watch(
  () => dataStore.value,
  (newValue) => {
    appStore.projectStore.addToDataStoresToUpdate(newValue);
  },
  { deep: true },
);

const tabs = [
  { ref: "general", name: "General" },
  { ref: "email", name: "Email" },
];

const currentNavigation = ref(tabs[0]);
</script>

<template>
  <div>
    <div v-if="dataStore" class="flex h-full flex-col bg-white dark:bg-gray-800">
      <KodexaTabStrip :selected="currentNavigation" :items="tabs" :small="true" @selected="currentNavigation = $event" />
      <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
        <div class="col-span-6 mb-1 sm:col-span-3">
          <KodexaTextInput
            v-model="dataStore.name"
            name="name"
            type="text"
            autocomplete="dataStore-name"
            label="Name"
          />
        </div>
        <div class="mt-4 mb-4 text-gray-600">
          <KodexaClipboardable
            :content="dataStore.ref"
            :show-content="false"
            message="Copy Store Reference"
            name="ref"
            color="black"
            label="Ref"
          />
        </div>
        <div class="col-span-6 mb-1 sm:col-span-3">
          <KodexaTextArea v-model="dataStore.description" name="dataStore-description" label="Description" :rows="9" />
        </div>
        <div class="col-span-6 mb-2 sm:col-span-3">
          <KodexaCheckbox
            v-model="dataStore.deleteProtection"
            name="deleteProtection"
            label="Delete Protection"
            hint="Set the store with delete protection"
          />
        </div>
        <KodexaResourceDelete :resource="dataStore" label="Data Store" @deleted="emit('step-deleted')" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
