<script lang="ts" setup>
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import type { DocumentFamily } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import WorkspaceSidecarEmpty from "~/views/studio/organizations/project/workspace/workspace-sidecar-empty.vue";

const {
  currentWorkspaceId,
  activeSelectionView,
} = storeToRefs(appStore.workspaceStore);

const sidecarStore = createSidecar(currentWorkspaceId.value);

const {
  sidecarView,
} = storeToRefs(sidecarStore);

const documentFamily = ref();

function documentFamilyChanged(family: DocumentFamily) {
  documentFamily.value = family;
}

// On mounted, focus the first page
onMounted(() => {
  console.log("Sidecar panel mounted, sidecarView:", sidecarView.value);
  console.log("Document family:", documentFamily.value);
  if (sidecarView.value) {
    console.log("Focusing page 0 of document family:", documentFamily.value?.id);
    sidecarStore.focusPage(documentFamily.value?.id, 0);
  } else {
    console.log("No sidecar view available to focus");
  }
});
</script>

<template>
  <div>
    <div>
      <div v-if="sidecarView" class="p-1">
        <KodexaDocumentView
          :key="sidecarView.id"
          :params="{ api: { id: sidecarView.id } } as IDockviewHeaderActionsProps"
          :view-id="sidecarView.id" @document-family-changed="documentFamilyChanged"
        />
        <KodexaTagPopup
          v-if="activeSelectionView && activeSelectionView.viewType === 'document'"
          :key="activeSelectionView.viewId"
          :active-selection-view="activeSelectionView"
          :kiosk-mode="true"
        />
      </div>
      <div v-else>
        <WorkspaceSidecarEmpty />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
