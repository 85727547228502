import type { DefineComponent } from "vue";
import { camelCase, startCase } from "lodash";
import type { Option } from "~/model";
import { log } from "~/utils/logger";

interface OptionModule {
  default: DefineComponent<any, any, any>;
}

interface OptionDefinition {
  type: string;
  name: string;
  label: string;
  description: string;
  implementation: () => DefineComponent<any, any, any>;
  options: Option[];
  validators?: Array<(value: any) => boolean>;
  defaultValue?: any;
  metadata?: {
    supportsList?: boolean;
    supportsGroup?: boolean;
    groupOptions?: OptionDefinition[];
  };
}

const optionModules = import.meta.glob<OptionModule>("./types/*-option.vue", { eager: true });
const optionMetadataModules = import.meta.glob<{ optionMetadata: any }>("./types/*.ts", { eager: true });

// Helper function to extract type from filename
function getTypeFromFileName(fileName: string): string {
  return fileName.replace("-option", "");
}

const availableOptionTypes = Object.entries(optionModules).map(([path, module]) => {
  const fileName = path.split("/").pop()?.replace(".vue", "");

  if (!fileName) {
    return null;
  }

  try {
    const baseType = getTypeFromFileName(fileName);
    const type = camelCase(baseType);
    const name = startCase(baseType);
    const component = module.default;

    const metadataPath = `./types/${fileName}.ts`;
    const metadata = optionMetadataModules[metadataPath]?.optionMetadata || {};

    return {
      type,
      name,
      label: metadata.label || name,
      description: metadata.description || name,
      options: metadata.options || [],
      implementation: () => component,
      validators: metadata.validators || [],
      defaultValue: metadata.defaultValue,
      metadata: metadata.metadata || {},
    } satisfies OptionDefinition;
  } catch (e) {
    log.info(`Unable to load option type ${fileName} due to error ${e}`);
    return null;
  }
}).filter((option: OptionDefinition | null) => option !== null);

export { availableOptionTypes };
